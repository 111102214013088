import React, { useCallback, useState, useEffect } from 'react';
import { debounce } from "lodash";
import axios, { Axios } from 'axios';
import "./superAdmin.css";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { basePath } from '../Constants/constant';
import Loading from '../Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight, faXmark } from '@fortawesome/free-solid-svg-icons'

function ModuleManager(props) {
    const navigate = useNavigate();

    const [moduleData, setModuleData] = useState({
        id: "",
        module_name: "",
        module_url: ""
    });
    const [isAction, setIsAction] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [allModules, setAllModules] = useState([]);
    const [moduleModal, setModuleModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const getModuleData = async () => {
        try {
            setLoading(true);
            let response = await axios.get(`${basePath}/getmodules`);
            console.log(response);
            setAllModules(response?.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    const notify = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const notifyFillTheFields = () => toast.error('All Fields Required!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const delayFunc = useCallback(debounce((value, field) => {
        console.log(value);
        if (field === "module") {
            setModuleData({ ...moduleData, module_name: value });
        } else if (field === "url") {
            setModuleData({ ...moduleData, module_url: value });
        }
    }, 1000));
    console.log(moduleData);

    const handleModule = (e) => {
        e.preventDefault();
        let data = {
            module_name: moduleData?.module_name,
            module_url: moduleData?.module_url,
        }
        console.log(data);
        if (moduleData.module_name && moduleData.module_url) {
            setLoading(true);
            const options = {
                url: `${basePath}/module`,
                method: "POST",
                headers: {
                    Accept: "application/json",
                },
                data: data,
            }
            axios(options)
                .then((response) => {
                    if (response.status == 200) {
                        setLoading(false);
                        getModuleData();
                        // document.getElementById("addModule").reset();
                        setModuleData({
                            id: "",
                            module_name: "",
                            module_url: ""
                        })

                        setModuleModal(false);
                        notify("Module Created Successfully");
                    }
                    else {
                        setLoading(false);
                        notifyFillTheFields("Unable to add module");
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    notifyFillTheFields("Unable to add module");
                });
        }
        else {
            console.log("kindly fill the complete form");
            notifyFillTheFields("kindly fill the complete form");
        }
    }
    const checkAllFields = () => {
        if (!moduleData.module_name && !moduleData.module_url) {
            notifyFillTheFields();
        }
    }
    useEffect(() => {
        getModuleData();
    }, []);
    const handleUpdateModule = (e) => {
        e.preventDefault();
        let data = {
            module_name: moduleData?.module_name,
            module_url: moduleData?.module_url,
        }
        if (moduleData.module_name && moduleData.module_url && moduleData.id) {
            setLoading(true);
            const options = {
                url: `${basePath}/updatemodule/${moduleData?.id}`,
                method: "PUT",
                headers: {
                    Accept: "application/json",
                },
                data: data,
            }
            axios(options)
                .then((response) => {
                    if (response.status == 200) {
                        setLoading(false);
                        getModuleData();
                        document.getElementById("updateModule").reset();
                        notify("Module Updated Successfully");
                        setEditModal(false);
                        setModuleData({ id: "", module_name: "", module_url: "" });
                        console.log("Information Updated successfully");
                    }
                    else {
                        setLoading(false);
                        alert("Enter all information as they were asked");
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    alert("kindly fill the complete form");
                });
        }
        else {
            console.log("kindly fill the complete form");
            notifyFillTheFields();
        }
    }
    const handleEdit = (idx) => {
        setModuleData({
            id: allModules[idx]?.id,
            module_name: allModules[idx]?.module_name,
            module_url: allModules[idx]?.module_url,
        })
        setEditModal(true);
        setIsAction(false);
    }
    const handleDelete = (e, id) => {
        if (id) {
            setLoading(true);
            const options = {
                url: `${basePath}/deletemodule/${id}`,
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                },
            }
            axios(options)
                .then((response) => {
                    if (response.status == 200) {
                        setLoading(false);
                        getModuleData();
                        notify("Module Deleted Successfully");
                        console.log("Information Deleted successfully");
                    }
                    else {
                        setLoading(false);
                        alert("Unable to Delete");
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    alert("Unable to delete");
                });
        }
        else {
            notifyFillTheFields();
        }
    }
    console.log(isAction);
    return (
        <section onClick={() => { setIsAction(false); }} className='lightBabyGreen relative w-full mb-10'>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            <div className=' fixed bottom-3 right-4'>
                <div onClick={getModuleData} className='cursor-pointer bg-red-600 px-2.5 py-2 rounded-full hoverRed'>
                    <FontAwesomeIcon icon={faRotateRight} size={"lg"} style={{ color: "#ffffff", }} />
                </div>
            </div>
            <div className=' m-auto '>
                <div className='welcomeBarHeight babyGreen smoothTransition textDarkPurple px-10 rounded-3xl  flex items-center justify-between navAnimation '>
                    <div className=' text-left'>
                        <h1 className=' text-5xl font-bold'>Module Master</h1>
                        <p className=' text-gray-500 font-semibold'>Ready to start your day</p>
                    </div>
                    <img className=' w-44' src="/img/master.png" alt="" />
                </div>
                {/* <div className=' w-full my-2 pl-4 flex items-center'>
                    <button onClick={() => { navigate("/main"); }} className=' text-left w-fit darkPurple hoverGreen text-white rounded-lg px-3 '>Back</button>
                </div> */}
                <div onClick={(e) => {
                    setModuleModal(true); setModuleData([
                        {
                            module_name: "",
                            module_url: ""
                        },
                    ])
                }} className='darkPurple w-fit my-2 cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'><img className=' w-8 ' src="/img/addBtn.png" alt="" /> <button>Add New Modules</button></div>
            </div>
            <div className=' '>
                {moduleModal ?
                    <div onClick={() => { setModuleModal(false); }} className=' w-full h-screen fixed left-0 top-0 flex items-center justify-center blurBack z-50'>
                        <form id='addModule' onClick={(e) => { e.stopPropagation(); }} onSubmit={handleModule} className='relative flex flex-col gap-2 items-center navAnimation py-5 px-10 bg-white grayBlurShadow rounded-2xl addModuleBox'>
                            <button onClick={(e) => { setModuleModal(false); }} className="absolute bg-red-600 px-1.5 py-0.5    rounded-full w-fit top-1 right-1 z-[11] ">
                                <FontAwesomeIcon icon={faXmark} size='xl' style={{ color: "#ffffff", }} />
                            </button>
                            <h1 className=' text-3xl font-semibold babyGreen w-full py-3 mb-3 rounded-lg'>Add Module</h1>
                            <div className=' flex flex-col items-center h-full gap-4 w-full'>
                                <div className='input-group relative w-full mb-3'>
                                    <input value={moduleData?.module_name} onChange={(e) => { setModuleData({ ...moduleData, module_name: e.target.value }); }} autoComplete='off' id='module' placeholder='Module Name' className=' w-full' required type="text" />
                                    <label htmlFor="module" className='label'>Module Name</label>
                                </div>
                                <div className='input-group relative w-full '>
                                    <input value={moduleData?.module_url} onChange={(e) => { setModuleData({ ...moduleData, module_url: e.target.value }); }} autoComplete='off' id='url' placeholder='URL*' className=' w-full' required type="text" />
                                    <label htmlFor="url" className='label'>URL</label>
                                </div>
                            </div>
                            <button type='submit' onClick={checkAllFields} className=' darkPurple text-white py-2 px-5 rounded-lg mt-5 hoverGreen font-semibold'>{!loading ? "Submit" : <img className=' h-7  py-1' src="/img/loadingBlue.webp" alt="Loading..." />}</button>
                        </form>
                    </div> : null}
                {editModal ?
                    <div onClick={() => { setEditModal(false); setModuleData({ id: "", module_name: "", module_url: "" }); }} className=' w-full h-screen fixed left-0 top-0 flex items-center justify-center blurBack z-50'>
                        <form id='updateModule' onClick={(e) => { e.stopPropagation(); }} onSubmit={handleUpdateModule} className='relative flex flex-col gap-2 items-center navAnimation py-5 px-10 bg-white grayBlurShadow rounded-2xl addModuleBox'>
                            <button onClick={(e) => { setEditModal(false); setModuleData({ id: "", module_name: "", module_url: "" }); }} className="absolute bg-red-600 px-1.5 py-0.5    rounded-full w-fit top-1 right-1 z-[11] ">
                                <FontAwesomeIcon icon={faXmark} size='xl' style={{ color: "#ffffff", }} />
                            </button>
                            <h1 className=' text-3xl font-semibold babyGreen w-full py-3 mb-3 rounded-lg'>Update Module</h1>
                            <div className=' flex flex-col items-center h-full gap-4 w-full'>
                                <div className='input-group relative w-full mb-3'>
                                    <input value={moduleData?.module_name} onChange={(e) => { setModuleData({ ...moduleData, module_name: e.target.value }) }} autoComplete='off' id='module' placeholder='Module Name' className=' w-full' required type="text" />
                                    <label htmlFor="module" className='label'>Module Name</label>
                                </div>
                                <div className='input-group relative w-full '>
                                    <input value={moduleData?.module_url} onChange={(e) => { setModuleData({ ...moduleData, module_url: e.target.value }) }} autoComplete='off' id='url' placeholder='URL*' className=' w-full' required type="text" />
                                    <label htmlFor="url" className='label'>URL</label>
                                </div>
                            </div>
                            <button type='submit' onClick={checkAllFields} className=' darkPurple text-white py-2 px-5 rounded-lg mt-5 hoverGreen font-semibold'>{!loading ? "Submit" : <img className=' h-7 py-1' src="/img/loadingBlue.webp" alt="Loading..." />}</button>
                        </form>
                    </div> : null}
                <div className=' grayBlurShadow rounded-3xl p-5 bg-white  '>
                    {!loading ?
                        <>
                            {Array.isArray(allModules) && allModules?.length >= 1 ?
                                <div className='  overflow-y-scroll customScroll tableBox'>
                                    <table className=' w-full navAnimation '>
                                        <thead className=' bg-gray-300 rounded-lg text-xl headingRow z-10'>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Module Name</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className=' font-semibold ' >
                                            {allModules?.map((val, idx) => {
                                                return (
                                                    <tr key={val?.madule_id} className='backWhite'>
                                                        <td>{idx + 1}</td>
                                                        <td>{val?.module_name}</td>
                                                        <td className='  flex justify-center items-center'>
                                                            <div className='relative w-fit'>
                                                                <div onClick={(e) => { e.stopPropagation(); setIsAction(val?.id); }} className=' flex flex-row gap-1 cursor-pointer w-fit p-2 rounded-lg hoverGreen'>
                                                                    <div className=' w-2 h-2 darkPurple rounded-full '></div>
                                                                    <div className=' w-2 h-2 darkPurple rounded-full '></div>
                                                                    <div className=' w-2 h-2 darkPurple rounded-full '></div>
                                                                </div>
                                                                {isAction === val?.id ?
                                                                    < div className={`absolute ${allModules?.length - 2 <= idx && allModules?.length > 2 ? "bottom-5 z-10 right-2" : "right-2 z-10 top-5"} w-40 h-fit p-3 rounded-xl border-2 bg-white grayBlurShadow`} >
                                                                        <div className='flex flex-col'>
                                                                            <button onClick={() => { handleEdit(idx); }} className=' text-yellow-600 bg-yellow-200 m-2 px-4 py-1 rounded-lg hoverYellow' >Edit</button>
                                                                            <button onClick={(e) => { handleDelete(e, val?.id) }} className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed'>Delete</button>
                                                                        </div>
                                                                    </div> : null
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div> :
                                <div className=' flex flex-col items-center mt-5'>
                                    <img className=' w-1/4' src="/img/addReports.png" alt="" />
                                    <h1 className=' text-xl font-bold'>Oops!! "<span className=' text-blue-950'>No Modules Found!!!</span>"</h1>
                                </div>}
                        </> :
                        <div className=' loaderBox mb-5 bg-white mt-5 rounded-3xl '>
                            <img className=' m-auto w-[28%]' src="/img/loader2.gif" alt="loading..." />
                        </div>
                    }
                </div>
            </div>
        </section >
    );
}

export default ModuleManager;
import React, { useContext, useEffect, useState } from 'react';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { basePath, monthData } from '../Constants/constant';
import axios from 'axios';
import { AppContext } from '../ContextApi/ContextApi';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

function InterviewSchedule(props) {
    const { index } = useParams();
    const { industry, setIndustry, isModalOpen, setIsModalOpen, user } = useContext(AppContext);
    const [isAction, setIsAction] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingFilter, setLoadingFilter] = useState(true);
    const [loadingExport, setLoadingExport] = useState(false);
    const [loadingInterviewSchedule, setLoadingInterviewSchedule] = useState(false);
    const [candidateName, setCandidateName] = useState("");
    const [editModal, setEditModal] = useState(false);
    const [companyIndex, setCompanyIndex] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [allInterviews, setAllInterviews] = useState([]);
    const [allCandidate, setAllCandidate] = useState([]);
    const [interviewModal, setInterviewModal] = useState(false);
    const [interviewDate, setInterviewDate] = useState(false);
    const [candidateId, setCandidateId] = useState("");
    const [interviewStatusOrDone, setInterviewStatusOrDone] = useState("");
    const [totalRecords, setTotalRecords] = useState(0);
    const [allPages, setAllPages] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalRecordsPositionWise, setTotalRecordsPositionWise] = useState(0);
    const [allPagesPositionWise, setAllPagesPositionWise] = useState(0);
    const [pagePositionWise, setPagePositionWise] = useState(1);
    const [limitPositionWise, setLimitPositionWise] = useState(10);
    const [positionWiseView, setPositionWiseView] = useState(false);
    const [positionWiseData, setPositionWiseData] = useState([]);
    const [interviewReport, setInterviewReport] = useState([
        {
            candidate_id: '',
            company: '',
            position: '',
            location: '',
            recruiter: '',
            interview_round: '',
            interview_mode: '',
            interview_date: '',
            interview_time: '',
            interview_location: '',
            interview_link: '',
            interview_status: '',
            interview_done: '',
            interview_remarks: ''
        }
    ]);
    const [filterData, setFilterData] = useState({
        fromDate: "",
        toDate: "",
        interview_round: "",
        interview_date: "",
        interview_status: "",
        interview_location: "",
        company: "",
        position: "",
        candidate: "",
        interview_done: "In Progress",
        orderBy: "scheduled_date",
        orderDirection: "DESC",
    });
    const [positionFilter, setPositionFilter] = useState({
        fromDate: "",
        toDate: "",
        company: "",
        position: "",
        orderBy: "upload_date",
        orderDirection: "DESC",
        interview_status: ""
    });
    const [interviewScheduleModal, setInterviewScheduleModal] = useState(false);
    const [interviewScheduleData, setInterviewScheduleData] = useState({
        scheduled_date: new Date().toISOString().split("T")[0],
        candidate_id: "",
        interview_round: "",
        interview_mode: "",
        interview_date: "",
        interview_time: "",
        interview_location: "",
        interview_status: "Open",
        interview_remarks: "",
        interview_done: "No",
    });
    const [interviewDetailsModal, setInterviewDetailsModal] = useState(false);
    const [interviewIndex, setInterviewIndex] = useState(false);
    useEffect(() => {
        let today = new Date();
        let date = today.getDate();
        let month = today.getMonth() + 1;
        let year = today.getFullYear();
        setInterviewDate(`${year}-${month.toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}`);
    }, [])

    const notify = (msg = "successfull!!") => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const notifyInfo = (msg) => toast.info(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const notifyFillTheFields = (msg = "There some issue!!") => toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });

    async function fetchCandidate() {
        let res = await axios.get(`${basePath}/candidates`);
        // console.log("===>>>>>1");
        // console.log(res.data);
        setAllCandidate(res.data);
    }
    async function fetchInterviews() {
        try {
            setLoading(true);
            let res = await axios.get(`${basePath}/getinterview?limit=${limit}&page=${page}&id=${localStorage.getItem("userId")}`);
            // console.log("===>>>>>res?.data?.Interview-1");
            // console.log(res?.data?.Interview);
            setAllInterviews(res?.data?.Interview);
            setTotalRecords(res?.data?.totalRecords);
            setAllPages(res?.data?.pages);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    async function fetchFilterInterview() {
        try {
            setLoading(true);
            let res = await axios.get(`${basePath}/getinterview?limit=${limit}&page=${page}&id=${localStorage.getItem("userId")}&filter=${JSON.stringify(filterData)}`);
            // console.log("===>>>>>res?.data?.Interview-2", filterData);
            // console.log(res?.data?.Interview);
            setAllInterviews(res?.data?.Interview);
            setTotalRecords(res?.data?.totalRecords);
            setAllPages(res?.data?.pages);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    const handleInterviewSort = (columnName) => {
        if (filterData?.orderBy === columnName) {
            if (filterData?.orderDirection === "DESC") {
                setFilterData({ ...filterData, orderBy: columnName, orderDirection: 'ASC' });
            }
            else {
                setFilterData({ ...filterData, orderBy: columnName, orderDirection: 'DESC' });
            }
        }
        else {
            setFilterData({ ...filterData, orderBy: columnName, orderDirection: 'ASC' });
        }
    }
    async function getPositionWiseData() {
        try {
            setLoading(true);
            let res = await axios.get(`${basePath}/getpositionwiseinterviewcandidatecount?limit=${limitPositionWise}&page=${pagePositionWise}&id=${localStorage.getItem("userId")}&filter=${JSON.stringify(positionFilter)}`);
            // console.log("+++++>>>>>res.data", res.data);
            let temp = res.data?.report?.filter((val) => {
                if (val?.interview_count) {
                    return val;
                }
            });
            // console.log("+++++>>>>>res.data", temp);
            setPositionWiseData(temp);
            setTotalRecordsPositionWise(temp?.length);
            let pages = temp?.length / 10;
            pages = Math.ceil(pages);
            setAllPagesPositionWise(pages);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
        // setRowData(sent);
    }
    const handleSort = (columnName) => {
        if (positionFilter?.orderBy === columnName) {
            if (positionFilter?.orderDirection === "DESC") {
                setPositionFilter({ ...positionFilter, orderBy: columnName, orderDirection: 'ASC' });
            }
            else {
                setPositionFilter({ ...positionFilter, orderBy: columnName, orderDirection: 'DESC' });
            }
        }
        else {
            setPositionFilter({ ...positionFilter, orderBy: columnName, orderDirection: 'ASC' });
        }
    }
    useEffect(() => {
        getPositionWiseData();
    }, [limitPositionWise, pagePositionWise, positionFilter])
    useEffect(() => {
        if (!loadingFilter &&
            !filterData?.fromDate &&
            !filterData?.toDate &&
            !filterData?.company &&
            !filterData?.position &&
            !filterData?.candidate &&
            !filterData?.interview_date &&
            !filterData?.interview_location &&
            !filterData?.interview_round &&
            !filterData?.interview_status &&
            !filterData?.orderBy &&
            !filterData?.orderDirection
        ) {
            fetchInterviews();
        }
        else if (!loadingFilter) {
            fetchFilterInterview();
        }

    }, [page, limit, filterData]);

    useEffect(() => {
        setLoadingFilter(true);
        fetchInterviews();
        setLoadingFilter(false);
    }, []);


    // updating candidate current application status
    const handleInterviewStatus = async () => {
        let today = new Date();
        let date = today.toISOString().split("T")[0];
        // console.log("============>>>>> candiate id", candidateId, interviewStatusOrDone);
        // console.log("=======>",month,month.toString().padStart(2, '0'))
        let data = {
            interview_status: interviewStatusOrDone,
            recruiter_id: localStorage.getItem("userId")
        }
        // console.log("interviewStatus========>>>>>>>", data);
        const options = {
            url: `${basePath}/updateinterviewstatus/${candidateId}`,
            method: "PATCH",
            headers: {
                Accept: "application/json",
            },
            data: data,
        }
        axios(options)
            .then((response) => {
                if (response.status == 200) {
                    setLoading(false);
                    // console.log(response);
                    fetchInterviews();
                    setCandidateId(false);
                    setConfirmation(false);
                    // setStatus(false);
                    notify("Interview Status Updated Successfully");
                }
                else {
                    setLoading(false);

                    notifyFillTheFields("Unable to Update");
                }
            })
            .catch((e) => {
                setLoading(false);
                if (e?.response?.status === 404) {
                    notifyInfo(e.response?.data?.error);
                } else {
                    notifyFillTheFields("Unable to Update");
                }

            });



    }

    const handleInterviewDone = async () => {
        let today = new Date();
        let date = today.toISOString().split("T")[0];
        // console.log("============>>>>> candiate id", candidateId, interviewStatusOrDone);
        // console.log("=======>",month,month.toString().padStart(2, '0'))
        let data = {
            interview_done: interviewStatusOrDone,
            recruiter_id: localStorage.getItem("userId")
        }
        // console.log("interviewDone========>>>>>>>", data);
        const options = {
            url: `${basePath}/markinterviewdone/${candidateId}`,
            method: "PATCH",
            headers: {
                Accept: "application/json",
            },
            data: data,
        }
        axios(options)
            .then((response) => {
                if (response.status == 200) {
                    setLoading(false);
                    // console.log(response);
                    fetchInterviews();
                    setCandidateId(false);
                    setConfirmation(false);
                    // setStatus(false);
                    notify("Interview Done Updated Successfully");
                }
                else {
                    setLoading(false);

                    notifyFillTheFields("Unable to Update");
                }
            })
            .catch((e) => {
                setLoading(false);
                if (e.response.status === 404) {

                    notifyInfo(e.response?.data?.error);
                } else {
                    notifyFillTheFields("Unable to Update");
                }

            });



    }

    const handleCandidate = (e, index) => {
        let newRow = [...interviewReport];

        newRow[index].candidate_id = e.target.value;
        let positionArr = allCandidate?.filter((val) => {
            if (e.target.value == val?.id) {
                return val;
            }
        });
        // console.log("allCandidate>>>>", e.target.value);
        newRow[index].company = positionArr[0]?.Position.Company.company_name;
        newRow[index].position = positionArr[0]?.Position.position;
        newRow[index].location = positionArr[0]?.Position.location;
        setInterviewReport(newRow);
    }
    const handleAddRow = (e) => {
        let rowIndex = interviewReport?.length - 1;
        if (
            interviewReport[rowIndex].candidate_id &&
            interviewReport[rowIndex].company &&
            interviewReport[rowIndex].position &&
            interviewReport[rowIndex].recruiter &&
            interviewReport[rowIndex].interview_round &&
            interviewReport[rowIndex].interview_mode &&
            interviewReport[rowIndex].interview_time &&
            interviewReport[rowIndex].interview_location ||
            interviewReport[rowIndex].interview_link &&
            interviewReport[rowIndex].interview_status &&
            interviewReport[rowIndex].interview_done
        ) {
            setInterviewReport([...interviewReport,
            {
                candidate_id: '',
                company: '',
                position: '',
                location: '',
                recruiter: '',
                interview_round: '',
                interview_mode: '',
                interview_date: '',
                interview_time: '',
                interview_location: '',
                interview_link: '',
                interview_status: '',
                interview_done: '',
                interview_remarks: ''
            },]);
        }
        else {
            notifyFillTheFields("Please fill all the fields");
        }
    }
    const handlePostData = async () => {
        let data = {
            candidate_id: interviewReport[0]?.candidate_id,
            interview_round: interviewReport[0]?.interview_round,
            interview_mode: interviewReport[0]?.interview_mode,
            interview_date: interviewReport[0]?.interview_date,
            interview_time: interviewReport[0]?.interview_time,
            interview_location: interviewReport[0]?.interview_location,
            interview_link: interviewReport[0]?.interview_link,
            interview_status: interviewReport[0]?.interview_status,
            interview_done: interviewReport[0]?.interview_done,
            interview_remarks: interviewReport[0]?.interview_remarks
        }

        // console.log(data);
        if (interviewReport?.length > 1) {
            const options = {
                url: `${basePath}/bulkinterview`,
                method: "POST",
                headers: {
                    Accept: "application/json",
                },
                data: interviewReport,
            }
            axios(options)
                .then((response) => {
                    if (response.status == 200) {
                        setLoading(false);
                        fetchCandidate();
                        setInterviewReport(
                            [{
                                candidate_id: '',
                                company: '',
                                position: '',
                                location: '',
                                recruiter: '',
                                interview_round: '',
                                interview_mode: '',
                                interview_date: '',
                                interview_time: '',
                                interview_location: '',
                                interview_link: '',
                                interview_status: '',
                                interview_done: '',
                                interview_remarks: ''
                            }]);
                        notify("Successfully Added");
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    console.log(e);
                    if (e.response.status === 400) {
                        notifyFillTheFields(e.response.data.error);
                    }
                });
        }
        else {
            const options = {
                url: `${basePath}/addinterview`,
                method: "POST",
                headers: {
                    Accept: "application/json",
                },
                data: data,
            }
            axios(options)
                .then((response) => {
                    if (response.status == 200) {
                        setLoading(false);
                        // console.log(response);
                        fetchCandidate();
                        setInterviewReport(
                            [{
                                candidate_id: '',
                                company: '',
                                position: '',
                                location: '',
                                recruiter: '',
                                interview_round: '',
                                interview_mode: '',
                                interview_date: '',
                                interview_time: '',
                                interview_location: '',
                                interview_link: '',
                                interview_status: '',
                                interview_done: '',
                                interview_remarks: ''
                            }]);
                        notify("Successfully Added");
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    console.log(e);
                    notifyFillTheFields("Unable to Add");
                    if (e.response.status === 400) {
                        notifyFillTheFields(e.response.data.error);
                    }
                });

        }

    }
    const handleInterviewUpdate = async (values) => {
        // console.log("==========>>>>>>>>interviewScheduleData", interviewScheduleData, candidateId);
        setLoadingInterviewSchedule(true);
        let data = {
            scheduled_date: new Date().toISOString().split("T")[0],
            candidate_id: candidateId,
            interview_round: values?.interview_round,
            interview_mode: values?.interview_mode,
            interview_date: values?.interview_date,
            interview_time: values?.interview_time,
            interview_location: values?.interview_location,
            interview_status: interviewScheduleData?.interview_status,
            interview_remarks: values?.interview_remarks,
            interview_done: interviewScheduleData?.interview_done,
            scheduled_date: interviewScheduleData?.scheduled_date,
            recruiter_id: localStorage.getItem("userId")
        }
        // console.log("=====>>>>handleInterviewUpdate", data);
        const options = {
            url: `${basePath}/updateinterviewdetails/${candidateId}`,
            method: "PATCH",
            headers: {
                Accept: "application/json",
            },
            data: data,
        }
        axios(options)
            .then((response) => {
                if (response.status == 200) {
                    // console.log(response);
                    fetchInterviews();
                    setInterviewScheduleModal(false);
                    setLoadingInterviewSchedule(false);
                    notify("Interview Details Updated Successfully");
                }
                else {
                    setLoadingInterviewSchedule(false);
                    notifyFillTheFields("Unable to update Interview Details");
                }
            })
            .catch((e) => {
                console.log(e);
                setLoadingInterviewSchedule(false);
                notifyFillTheFields("Unable to update Interview Details");
            });

    }
    const handleDeleteRow = (e, index) => {
        let data = interviewReport?.filter((val, idx) => {
            if (idx != index) {
                return val;
            }
        });
        setInterviewReport(data);
    }

    const validationSchemaInterviewSchedule = Yup.object({
        interview_round: Yup.string().required('Round is required').min(1, `Round must be ${1} digit`).max(2, `Round must be ${2} digits`),
        interview_date: Yup.date().required('Date is required'),
        interview_time: Yup.string().required('Time is required'),
        interview_mode: Yup.string().required('Interview mode is required'),
        interview_location: Yup.string().required('Interview location is required'),
        // interview_location: Yup.string().when('interview_mode', {
        //     is: 'In Person',
        //     then: Yup.string().required('Interview location is required'),
        //     // otherwise: Yup.string(),
        // }),
        interview_remarks: Yup.string(),
    });

    const handleEdit = (idx, isShortListed = false) => {
        // console.log(allInterviews);
        let round;
        if (isShortListed === "Shortlisted Next Round") {
            round = parseInt(allInterviews[idx]?.interview_round) + 1;
        }
        else {
            round = allInterviews[idx]?.interview_round
        }
        setCandidateId(allInterviews[idx]?.candidate_id);
        setInterviewScheduleData({
            scheduled_date: new Date().toISOString().split("T")[0],
            candidate_id: allInterviews[idx]?.candidate_id,
            interview_round: round,
            interview_mode: allInterviews[idx]?.interview_mode,
            interview_date: allInterviews[idx]?.interview_date,
            interview_time: allInterviews[idx]?.interview_time,
            interview_location: allInterviews[idx]?.interview_location,
            interview_status: allInterviews[idx]?.interview_status,
            interview_remarks: allInterviews[idx]?.interview_remarks,
            interview_done: allInterviews[idx]?.interview_done,
            scheduled_date: allInterviews[idx]?.scheduled_date,
        });
        setInterviewScheduleModal(true);
    }

    return (
        <section onClick={() => { setIsAction(false); }} className='lightBabyGreen baseHeightWidth customScroll mb-5'>
            {/* show message  */}
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            {/* refresh button for table */}
            <div className=' absolute bottom-3 right-4'>
                <div onClick={fetchInterviews} className='cursor-pointer bg-red-600 px-2.5 py-2 rounded-full hoverRed'>
                    <FontAwesomeIcon icon={faRotateRight} size={"lg"} style={{ color: "#ffffff", }} />
                </div>
            </div>
            <div className='welcomeBarHeight babyGreen smoothTransition textDarkPurple px-10 rounded-3xl flex items-center justify-between navAnimation  m-auto'>
                <div className=' text-left'>
                    <h1 className=' text-5xl font-bold'>Interview Schedule</h1>
                    <p className=' text-gray-500 font-semibold'>Ready to start your day</p>
                </div>
                <img className=' w-44' src="/img/registration.png" alt="registration" />
            </div>
            {/* view details of interview */}
            {interviewDetailsModal ?
                <div onClick={(e) => { setInterviewDetailsModal(false); }} className=' w-full h-screen fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                    <div onClick={(e) => { e.stopPropagation(); }} className='relative w-[70vw]  bg-white overflow-auto mx-10 mb-5 py-5 px-8 rounded-2xl navAmination '>
                        <button onClick={(e) => { setInterviewDetailsModal(false); }} className="absolute bg-white rounded-full w-fit top-1 right-1 z-[11] ">
                            <img className='w-7' src="/img/close.png" alt="close" />
                        </button>
                        {
                            allInterviews?.map((val) => {
                                return (
                                    interviewDetailsModal === val?.id ?
                                        <div className=' p-3 rounded-lg '>
                                            <h1 className='text-center text-xl font-semibold py-2 text-blue-950'>Interview Details</h1>
                                            <div>
                                                <h1 className='text-lg font-semibold text-gray-500 text-left'>Interview Date: <span className=' text-blue-800'>{val?.interview_date?.split("-")[2]}-{monthData[parseInt(val?.interview_date?.split("-")[1])]}-{val?.interview_date?.split("-")[0]}</span></h1>
                                                <div className='flex items-center gap-2'>
                                                    <h1 className=' text-lg text-gray-500 font-semibold'>Candidate Name: </h1>
                                                    <h1 className=' text-lg text-blue-800 font-semibold'>{candidateName}</h1>
                                                </div>
                                                <div className=' flex flex-col gap-3'>
                                                    <div className='flex items-center gap-2 w-full text-left'>
                                                        <div className='w-full'>
                                                            <h1>Interview Round</h1>
                                                            <h1 className=' border-2 rounded-lg px-3 py-1 text-blue-800 font-semibold'>{val?.interview_round ? val?.interview_round : <span className='text-red-400 font-semibold'>None</span>}</h1>
                                                        </div>
                                                        <div className='w-full'>
                                                            <h1>Interview Time</h1>
                                                            <h1 className=' border-2 rounded-lg px-3 py-1 text-blue-800 font-semibold'>{val?.interview_time ? val?.interview_time : <span className='text-red-400 font-semibold'>None</span>}</h1>
                                                        </div>
                                                    </div>
                                                    <div className='flex items-center gap-2 w-full text-left'>
                                                        <div className='w-full'>
                                                            <h1>Interview Mode</h1>
                                                            <h1 className=' border-2 rounded-lg px-3 py-1 text-blue-800 font-semibold'>{val?.interview_mode ? val?.interview_mode : <span className='text-red-400 font-semibold'>None</span>}</h1>
                                                        </div>
                                                        <div className='w-full'>
                                                            <h1>Interview Location</h1>
                                                            <h1 className=' border-2 rounded-lg px-3 py-1 text-blue-800 font-semibold'>{val?.interview_location ? val?.interview_location : <span className='text-red-400 font-semibold'>None</span>}</h1>
                                                        </div>
                                                    </div>
                                                    <div className='flex items-center gap-2 w-full text-left'>
                                                        <div className='w-full'>
                                                            <h1>Interview Remarks</h1>
                                                            <h1 className=' border-2 rounded-lg px-3 py-1 text-blue-800 font-semibold'>{val?.interview_remarks ? val?.interview_remarks : <span className='text-red-400 font-semibold'>None</span>}</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null
                                )
                            })}
                    </div>
                </div> : null

            }
            {/* edit and schedule interview */}
            {interviewScheduleModal ?
                <div onClick={(e) => { setInterviewScheduleModal(false); }} className=' w-full h-screen fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                    <div onClick={(e) => { e.stopPropagation(); }} className='relative w-[70vw]  bg-white overflow-auto mx-10 mb-5 py-5 px-8 rounded-2xl navAmination '>
                        <button onClick={(e) => { setInterviewScheduleModal(false); }} className="absolute bg-white rounded-full w-fit top-1 right-1 z-[11] ">
                            <img className='w-7' src="/img/close.png" alt="close" />
                        </button>
                        <h1 className='text-center text-xl font-semibold py-2 text-blue-950'>Candidate Interview Schedule</h1>
                        <div className='flex items-center gap-2'>
                            <h1 className=' text-lg text-gray-500 font-semibold'>Schedule Date: </h1>
                            <h1 className=' text-lg text-blue-800 font-semibold'>{interviewScheduleData?.scheduled_date?.split("-")[2]}-{monthData[parseInt(interviewScheduleData?.scheduled_date?.split("-")[1])]}-{interviewScheduleData?.scheduled_date?.split("-")[0]}</h1>
                        </div>
                        <div className='flex items-center gap-2'>
                            <h1 className=' text-lg text-gray-500 font-semibold'>Candidate Name: </h1>
                            <h1 className=' text-lg text-blue-800 font-semibold'>{candidateName}</h1>
                        </div>
                        <Formik
                            initialValues={{
                                interview_round: interviewScheduleData?.interview_round,
                                interview_date: interviewScheduleData?.interview_date,
                                interview_time: interviewScheduleData?.interview_time,
                                interview_mode: interviewScheduleData?.interview_mode,
                                interview_location: interviewScheduleData?.interview_location,
                                interview_remarks: interviewScheduleData?.interview_remarks,
                            }}
                            validationSchema={validationSchemaInterviewSchedule}
                            onSubmit={(values) => {
                                // console.log(values);
                                setInterviewScheduleData({
                                    scheduled_date: new Date().toISOString().split("T")[0],
                                    candidate_id: candidateId,
                                    interview_round: values?.interview_round,
                                    interview_mode: values?.interview_mode,
                                    interview_date: values?.interview_date,
                                    interview_time: values?.interview_time,
                                    interview_location: values?.interview_location,
                                    interview_status: interviewScheduleData?.interview_status,
                                    interview_remarks: values?.interview_remarks,
                                    interview_done: interviewScheduleData?.interview_done,
                                });
                                if (interviewStatusOrDone === "Shortlisted Next Round") {
                                    handleInterviewStatus();
                                    handleInterviewUpdate(values);
                                } else {
                                    handleInterviewUpdate(values);
                                }

                            }}
                        >
                            {({ values, setFieldValue }) => (
                                <Form className='text-left flex flex-col gap-x-2 gap-y-4 max-h-[70vh] overflow-y-scroll'>
                                    <div>
                                        <div>
                                            <h2>Interview Round</h2>
                                            <Field
                                                type="number"
                                                name="interview_round"
                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                placeholder='Round*'
                                                disabled
                                            />
                                            <ErrorMessage name="interview_round" component="div" className="text-red-500" />
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-2 gap-3'>
                                        <div>
                                            <h2>Interview Date</h2>
                                            <Field
                                                type="date"
                                                name="interview_date"
                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                placeholder='Date*'
                                            />
                                            <ErrorMessage name="interview_date" component="div" className="text-red-500" />
                                        </div>
                                        <div>
                                            <h2>Interview Time</h2>
                                            <Field
                                                type="time"
                                                name="interview_time"
                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                placeholder='Time*'
                                            />
                                            <ErrorMessage name="interview_time" component="div" className="text-red-500" />
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-2 gap-3'>
                                        <div>
                                            <h2>Interview Mode</h2>
                                            <Field
                                                as="select"
                                                name="interview_mode"
                                                className='outline-none border-2 rounded-lg px-2 w-full py-1'
                                                onChange={(e) => { setFieldValue("interview_mode", e.target.value); setInterviewScheduleData({ ...interviewScheduleData, interview_mode: e.target.value }); }}
                                            >
                                                <option value="" disabled>Select</option>
                                                <option value="In Person">In Person</option>
                                                <option value="Virtual">Virtual</option>
                                            </Field>
                                            <ErrorMessage name="interview_mode" component="div" className="text-red-500" />
                                        </div>

                                        <div>
                                            <h2>Interview Location</h2>
                                            <Field
                                                name="interview_location"
                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                type="text"
                                                placeholder="Interview Location*"
                                            />
                                            <ErrorMessage name="interview_location" component="div" className="text-red-500" />
                                        </div>

                                    </div>
                                    <div className='w-full'>
                                        <div>
                                            <h2>Remarks</h2>
                                            <Field
                                                as="textarea"
                                                name="interview_remarks"
                                                className='outline-none border-2 rounded-lg px-2 w-full'
                                                placeholder='Remarks...'
                                            />
                                            <ErrorMessage name="interview_remarks" component="div" className="text-red-500" />
                                        </div>
                                    </div>
                                    <div className='flex items-center mt-5'>
                                        <button onClick={(e) => { setInterviewScheduleModal(false); }} className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed' >Cancel</button>
                                        <button type="submit" className='darkPurple text-white font-semibold rounded-xl px-4 py-1 hoverGreen'>{!loadingInterviewSchedule ? "Submit" : "Loading..."}</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div> : null
            }
            {/* ask for confirmation of status */}
            {confirmation ?
                <div onClick={(e) => { }} className=' w-full h-screen fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                    <div onClick={(e) => { e.stopPropagation(); }} className='relative   bg-white overflow-auto mx-10 mb-5 py-5 px-8 rounded-2xl navAmination '>
                        <div>
                            <h1>
                                <span className=' text-blue-900 font-semibold'>Are you sure</span> ?
                            </h1>
                            <div className=' flex gap-4 items-center justify-center'>
                                <button
                                    className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed'
                                    onClick={(e) => {
                                        setConfirmation(false);
                                    }}>Cancel</button>
                                <button
                                    className='darkPurple text-white font-semibold rounded-xl px-4 py-1 hoverGreen'
                                    onClick={(e) => {
                                        if (confirmation === "interview_status" &&
                                            candidateId && interviewStatusOrDone
                                        ) {
                                            if (interviewStatusOrDone === "Shortlisted Next Round") {

                                            }
                                            // console.log(confirmation);
                                            handleInterviewStatus();
                                        }
                                        else if (confirmation === "interview_done" &&
                                            candidateId && interviewStatusOrDone) {
                                            // console.log(confirmation);
                                            handleInterviewDone();
                                        }

                                    }}
                                >Save</button>
                            </div>
                        </div>
                    </div>
                </div> : null
            }
            {/* {interviewModal ?
                <div onClick={(e) => { setInterviewModal(false); }} className=' w-full h-screen fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                    <div onClick={(e) => { e.stopPropagation(); }} className='grayBlurShadow bg-white mx-5 mb-10 p-10 rounded-3xl overflow-y-scroll scrollbar-hide navAnimation text-left addSourceTable'>
                        <div>
                            <h1 className=' text-lg font-semibold mb-4'>Date: <span className=' text-blue-950'>{interviewDate.split("-")[2]}-{monthData[parseInt(interviewDate.split("-")[1])]}-{interviewDate.split("-")[0]}</span></h1>
                        </div>
                        <div className=' overflow-auto sourcingTable '>
                            <table className=' navAnimation rounded-xl baseWidth'>
                                <thead className=' text-lg tableHead'>
                                    <tr className=' bg-gray-200'>
                                        <th>Candidate</th>
                                        <th >Company</th>
                                        <th >Position</th>
                                        <th >Location</th>
                                        <th className='whitespace-nowrap'>Interview Round</th>
                                        <th className='whitespace-nowrap'>Interview Mode</th>
                                        <th className='whitespace-nowrap'>Interview date</th>
                                        <th className='whitespace-nowrap'>Interview Time</th>
                                        <th className='whitespace-nowrap'>Interview Location</th>
                                        <th className='whitespace-nowrap'>Interview Link</th>
                                        <th className='whitespace-nowrap'>Interview Status</th>
                                        <th>interview_done</th>
                                        <th>interview_remarks</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className='tableBody '>
                                    {interviewReport?.map((val, index) => {
                                        return (
                                            <tr key={val?.id} className=' backWhite font-semibold text-sm'>
                                                <td>
                                                    <select value={interviewReport[index].candidate_id}
                                                        onChange={(e) => { handleCandidate(e, index) }} className=' border-2 rounded-lg text-center'>
                                                        <option value="" selected disabled>Candidate</option>
                                                        {
                                                            allCandidate?.map((val) => {
                                                                return <option value={val?.id}>{val?.candidate}</option>
                                                            })
                                                        }
                                                    </select>
                                                </td>
                                                <td>
                                                    <input value={interviewReport[index].company} disabled type="text" />
                                                </td>
                                                <td>
                                                    <input value={interviewReport[index].position} disabled type="text" />
                                                </td>
                                                <td >
                                                    <input value={interviewReport[index].location} disabled type="text" />
                                                </td>

                                                <td>
                                                    <input value={interviewReport[index].interview_round} onChange={(e) => {
                                                        let newRow = [...interviewReport];
                                                        newRow[index].interview_round = e.target.value;
                                                        setInterviewReport(newRow);
                                                    }} type="number" />
                                                </td>
                                                <td>
                                                    <select value={interviewReport[index].interview_mode} onChange={(e) => {
                                                        let newRow = [...interviewReport];
                                                        newRow[index].interview_mode = e.target.value;
                                                        setInterviewReport(newRow);
                                                    }} className=' border-2 rounded-lg text-center w-full'>
                                                        <option value="" disabled selected > Select</option>
                                                        <option value="Online">Online</option>
                                                        <option value="In Person">In Person</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <input value={interviewReport[index].interview_date} onChange={(e) => {
                                                        let newRow = [...interviewReport];
                                                        newRow[index].interview_date = e.target.value;
                                                        setInterviewReport(newRow);
                                                    }} type="date" />
                                                </td>
                                                <td>
                                                    <input value={interviewReport[index].interview_time} onChange={(e) => {
                                                        let newRow = [...interviewReport];
                                                        newRow[index].interview_time = e.target.value;
                                                        setInterviewReport(newRow);
                                                    }} type="text" />
                                                </td>
                                                <td>
                                                    <input value={interviewReport[index].interview_location} onChange={(e) => {
                                                        let newRow = [...interviewReport];
                                                        newRow[index].interview_location = e.target.value;
                                                        setInterviewReport(newRow);
                                                    }} type="text" />
                                                </td>
                                                <td>
                                                    <input value={interviewReport[index].interview_link} onChange={(e) => {
                                                        let newRow = [...interviewReport];
                                                        newRow[index].interview_link = e.target.value;
                                                        setInterviewReport(newRow);
                                                    }} type="text" />
                                                </td>
                                                <td>
                                                    <input value={interviewReport[index].interview_status} onChange={(e) => {
                                                        let newRow = [...interviewReport];
                                                        newRow[index].interview_status = e.target.value;
                                                        setInterviewReport(newRow);
                                                    }} type="text" />
                                                </td>
                                                <td>
                                                    <select value={interviewReport[index].interview_done} onChange={(e) => {
                                                        let newRow = [...interviewReport];
                                                        newRow[index].interview_done = e.target.value;
                                                        setInterviewReport(newRow);
                                                    }} className=' border-2 rounded-lg text-center'>
                                                        <option value="" disabled selected > Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </td>

                                                <td>

                                                    <textarea value={interviewReport[index].interview_remarks} onChange={(e) => {
                                                        let newRow = [...interviewReport];
                                                        newRow[index].interview_remarks = e.target.value;
                                                        setInterviewReport(newRow);
                                                    }}></textarea>
                                                </td>
                                                <td>
                                                    {interviewReport?.length > 1 ?
                                                        <button onClick={(e) => { handleDeleteRow(e, index) }} className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed'>Delete</button> :
                                                        <button className=' text-red-300 bg-red-200 mx-2 px-2 py-1 rounded-lg cursor-not-allowed'>Delete</button>}
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <button onClick={handleAddRow} className=' darkPurple text-lg w-fit font-semibold py-1 px-3 rounded-lg mt-4 text-white hoverGreen'><span>+</span> Add Row</button>

                        <div className=' flex justify-center'>
                            <button onClick={handlePostData} className=' bg-slate-600 text-lg w-fit font-semibold py-2 px-10 rounded-lg mt-4 text-white hoverGreen'>Submit</button>
                        </div>
                    </div>
                </div> : null} */}
            {/* show position wise details */}
            {positionWiseView ?
                <>
                    <div onClick={(e) => { setPositionWiseView(false); }} className=' w-full h-screen  fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                        <div onClick={(e) => { e.stopPropagation(); }} className=' relative h-[95vh] w-[95%]  lightBabyGreen rounded-2xl mx-10 px-2 py-4   '>
                            <div className=' absolute bottom-3 right-4'>
                                <div onClick={(e) => { getPositionWiseData(); }} className='cursor-pointer bg-red-600 px-2.5 py-2 rounded-full hoverRed'>
                                    <FontAwesomeIcon icon={faRotateRight} size={"lg"} style={{ color: "#ffffff", }} />
                                </div>
                            </div>
                            <button onClick={(e) => { setPositionWiseView(false); }} className="absolute bg-white rounded-full w-fit top-1 right-1 z-[11] ">
                                <img className='w-7' src="/img/close.png" alt="close" />
                            </button>
                            <div className='h-full w-full  rounded-2xl overflow-y-scroll noScrollbar px-8'>
                                {/* filter */}
                                <div className=' flex flex-wrap items-center gap-3 grayBlurShadow bg-white p-3 mb-5 rounded-xl'>
                                    <div className='flex items-center gap-3'>
                                        <div className=' flex flex-col justify-start items-start'>
                                            <label>From: </label>
                                            <input
                                                className=' border-2 rounded-lg px-2 py-1'
                                                type="date"
                                                value={positionFilter?.fromDate}
                                                onChange={e => setPositionFilter({ ...positionFilter, fromDate: e.target.value })}
                                            />
                                        </div>
                                        <div className=' flex flex-col justify-start items-start'>
                                            <label>To: </label>
                                            <input
                                                className=' border-2 rounded-lg px-2 py-1'
                                                type="date"
                                                value={positionFilter?.toDate}
                                                onChange={e => setPositionFilter({ ...positionFilter, toDate: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <label >Company</label>
                                        <input
                                            value={positionFilter?.company}
                                            className=' border-2 rounded-lg px-2 py-1'
                                            onChange={e => setPositionFilter({ ...positionFilter, company: e.target.value })}
                                            type="text" />
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <label >Position</label>
                                        <input
                                            value={positionFilter?.position}
                                            className=' border-2 rounded-lg px-2 py-1'
                                            onChange={e => setPositionFilter({ ...positionFilter, position: e.target.value })}
                                            type="text" />
                                    </div>
                                    <button onClick={getPositionWiseData} className=' darkPurple cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>Apply filter</button>
                                    <button onClick={() => {
                                        setPositionFilter({
                                            fromDate: "",
                                            toDate: "",
                                            company: "",
                                            position: "",
                                            orderBy: "upload_date",
                                            orderDirection: "DESC",
                                            status: ""
                                        })
                                        getPositionWiseData();
                                    }} className=' text-red-700 bg-red-200  py-2 px-3 rounded-lg hoverRed'>Clear Filter</button>
                                    {/* <div className=' text-left w-fit'>
                                        <button onClick={handleExport} className=' py-2 px-3 bg-green-800 text-white rounded-lg '>{loadingExport ? "Exporting..." : "Excel"}</button>
                                    </div> */}
                                </div>
                                {
                                    !loading ?
                                        Array.isArray(positionWiseData) && positionWiseData?.length >= 1 ?
                                            <div className=' bg-white px-3 pt-5 rounded-2xl'>
                                                <div className=' overflow-auto tableBody  h-[79vh]'>
                                                    <table className=' navAnimation rounded-2xl baseWidth p-5 text-left'>
                                                        <thead className='text-lg tableHead'>
                                                            <tr className=' bg-gray-200'>
                                                                <th className='whitespace-nowrap text-center' >Sr.No.</th>
                                                                <th className='whitespace-nowrap text-center cursor-pointer' onClick={() => { handleSort("upload_date") }} > <div className='flex items-center gap-2 justify-center'>Date {positionFilter?.orderBy === "upload_date" && positionFilter?.orderDirection === "ASC" ? <img className='w-4' src="/img/upArrow.png" alt="up" /> : positionFilter?.orderBy === "upload_date" && positionFilter?.orderDirection === "DESC" ? <img className='w-4' src="/img/downArrow.png" alt="up" /> : <img className='w-4' src="/img/upDownArrow.png" alt="up" />}</div> </th>
                                                                <th className='whitespace-nowrap cursor-pointer' onClick={() => { handleSort("company_name") }}><div className='flex items-center gap-2'>Company Name {positionFilter?.orderBy === "company_name" && positionFilter?.orderDirection === "ASC" ? <img className='w-4' src="/img/upArrow.png" alt="up" /> : positionFilter?.orderBy === "company_name" && positionFilter?.orderDirection === "DESC" ? <img className='w-4' src="/img/downArrow.png" alt="up" /> : <img className='w-4' src="/img/upDownArrow.png" alt="up" />}</div></th>
                                                                <th className='whitespace-nowrap cursor-pointer' onClick={() => { handleSort("position") }}><div className='flex items-center gap-2'>Company Position {positionFilter?.orderBy === "position" && positionFilter?.orderDirection === "ASC" ? <img className='w-4' src="/img/upArrow.png" alt="up" /> : positionFilter?.orderBy === "position" && positionFilter?.orderDirection === "ASC" ? <img className='w-4' src="/img/downArrow.png" alt="up" /> : <img className='w-4' src="/img/upDownArrow.png" alt="up" />}</div></th>
                                                                <th className='whitespace-nowrap text-center'>Count</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='tableBody '>
                                                            {
                                                                Array.isArray(positionWiseData) && positionWiseData?.map((val, idx) => {
                                                                    return (
                                                                        <>
                                                                            {/* {val?.created_at?.split("T")[0] === sourceViewModal ? */}
                                                                            <tr key={val?.id} id={val?.id} className=' backWhite font-semibold text-sm'>
                                                                                <td className='whitespace-nowrap text-center'>{idx + 1}</td>
                                                                                <td className='whitespace-nowrap text-center'>{val?.upload_date?.split("-")[2]} {monthData[parseInt(val?.upload_date?.split("-")[1])]} {val?.upload_date?.split("-")[0]}</td>
                                                                                <td className=' hover:text-blue-700 cursor-pointer'
                                                                                    onClick={() => {
                                                                                        setFilterData({
                                                                                            ...filterData,
                                                                                            company: val?.company_name,
                                                                                            position: val?.position_name,
                                                                                            fromDate: "",
                                                                                            toDate: "",
                                                                                            interview_round: "",
                                                                                            interview_date: "",
                                                                                            interview_status: "",
                                                                                            interview_location: "",
                                                                                            candidate: "",
                                                                                            interview_done: "In Progress",
                                                                                            orderBy: "scheduled_date",
                                                                                            orderDirection: "DESC",
                                                                                        });
                                                                                        setPositionWiseView(false);
                                                                                    }} >{val?.company_name}</td>
                                                                                <td className=' hover:text-blue-700 cursor-pointer'
                                                                                    onClick={() => {
                                                                                        setFilterData({
                                                                                            ...filterData,
                                                                                            company: val?.company_name,
                                                                                            position: val?.position_name,
                                                                                            fromDate: "",
                                                                                            toDate: "",
                                                                                            interview_round: "",
                                                                                            interview_date: "",
                                                                                            interview_status: "",
                                                                                            interview_location: "",
                                                                                            candidate: "",
                                                                                            interview_done: "In Progress",
                                                                                            orderBy: "scheduled_date",
                                                                                            orderDirection: "DESC",
                                                                                        });
                                                                                        setPositionWiseView(false);
                                                                                    }}>{val?.position_name}</td>
                                                                                <td className='whitespace-nowrap text-center'>{val?.interview_count}</td>
                                                                            </tr>
                                                                            {/* : null} */}

                                                                        </>

                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table >
                                                </div>
                                                <div className=' flex items-center justify-between pb-4'>
                                                    <div className=' flex items-center gap-2'>
                                                        <div className='grayBlurShadow px-3 py-1'>
                                                            <select value={limitPositionWise} onChange={(e) => { setLimitPositionWise(e.target.value); }} >
                                                                <option value="10" selected>10</option>
                                                                <option value="20" >20</option>
                                                                <option value="50" >50</option>
                                                                <option value="100" >100</option>
                                                                <option value="200" >200</option>
                                                                <option value="500" >500</option>

                                                            </select>
                                                        </div>
                                                        <div className='grayBlurShadow px-1 py-1 text-sm font-semibold'>
                                                            <h1>Total Records: {totalRecordsPositionWise}</h1>
                                                        </div>
                                                        <div className='grayBlurShadow px-1 py-1  text-sm font-semibold'>
                                                            <h1>Total Pages: {allPagesPositionWise}</h1>
                                                        </div>
                                                    </div>
                                                    <div className='flex items-center grayBlurShadow px-3 py-1 rounded-lg'>
                                                        <div onClick={(e) => {
                                                            if (pagePositionWise > 1) {
                                                                setPage(page - 1);
                                                            }
                                                        }} className={` ${pagePositionWise === 1 ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                                            <img className='w-5' src="/img/leftArrow.png" alt="leftArrow" />
                                                        </div>
                                                        <div className=' px-3'>
                                                            {page}
                                                        </div>
                                                        <div onClick={(e) => {
                                                            if (allPagesPositionWise > pagePositionWise) {
                                                                setPage(pagePositionWise + 1);
                                                            }
                                                        }} className={` ${pagePositionWise == allPagesPositionWise ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                                            <img className='w-5' src="/img/rightArrow.png" alt="rightArrow" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> :
                                            <div className='navAnimation w-full flex flex-col justify-center items-center bg-white mt-5 py-5 mb-10 rounded-3xl '>
                                                <img className=' w-[25%]' src="/img/addReports.png" alt="" />
                                                <h1 className=' text-2xl font-bold'>Oops!! "<span className=' text-blue-950'>No Reports Found</span>"</h1>
                                            </div> :
                                        <div className=' h-[60vh] mb-5 w-full bg-white mt-5 rounded-3xl '>
                                            <img className=' m-auto w-1/4' src="/img/loader2.gif" alt="loading..." />
                                        </div>
                                }
                            </div>

                        </div>
                    </div>
                </> : null}
            {/* all filters */}
            {!loadingFilter ?
                <div className=' flex flex-wrap items-center gap-3 grayBlurShadow bg-white p-3 mt-5 rounded-xl'>
                    <div className='flex items-center gap-3'>
                        <div className=' flex flex-col justify-start items-start'>
                            <label>From: </label>
                            <input
                                className=' border-2 rounded-lg px-2 py-1'
                                type="date"
                                value={filterData?.fromDate}
                                onChange={e => setFilterData({ ...filterData, fromDate: e.target.value })}
                            />
                        </div>
                        <div className=' flex flex-col justify-start items-start'>
                            <label>To: </label>
                            <input
                                className=' border-2 rounded-lg px-2 py-1'
                                type="date"
                                value={filterData?.toDate}
                                onChange={e => setFilterData({ ...filterData, toDate: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col justify-start items-start mb-2'>
                        <label >Company</label>
                        <input
                            value={filterData?.company}
                            className=' border-2 rounded-lg px-2 py-1'
                            onChange={e => setFilterData({ ...filterData, company: e.target.value })}
                            type="text" />
                    </div>
                    <div className='flex flex-col justify-start items-start mb-2'>
                        <label >Position</label>
                        <input
                            value={filterData?.position}
                            className=' border-2 rounded-lg px-2 py-1'
                            onChange={e => setFilterData({ ...filterData, position: e.target.value })}
                            type="text" />
                    </div>
                    <div className='flex flex-col justify-start items-start mb-2'>
                        <label >candidate</label>
                        <input
                            value={filterData?.candidate}
                            className=' border-2 rounded-lg px-2 py-1'
                            onChange={e => setFilterData({ ...filterData, candidate: e.target.value })}
                            type="text" />
                    </div>
                    <div className='flex flex-col justify-start items-start mb-2'>
                        <label >Interview Round</label>
                        <input
                            value={filterData?.interview_round}
                            className=' border-2 rounded-lg px-2 py-1'
                            onChange={(e) => {
                                let num = `${e.target.value}`;
                                if (!num.includes(".") && !num.includes("-") && num?.length <= 2) {
                                    setFilterData({ ...filterData, interview_round: e.target.value })
                                }
                                else if (num.includes(".") || num.includes("-")) {
                                    setFilterData({ ...filterData, interview_round: "" })
                                }
                            }}
                            type="number" />
                    </div>
                    <div className='flex flex-col justify-start items-start mb-2'>
                        <label >Interview Status</label>
                        <select value={filterData?.interview_status}
                            className=' border-2 rounded-lg px-2 py-1'
                            onChange={e => setFilterData({ ...filterData, interview_status: e.target.value })} >
                            <option value="" selected disabled>Select Status</option>
                            <option value="Interview Scheduled">Interview Scheduled</option>
                            <option value="Interview Feedback Pending">Interview Feedback Pending</option>
                            <option value="Shortlisted Next Round">Shortlisted Next Round</option>
                            <option value="Rejected Post Interview">Rejected Post Interview</option>
                            <option value="Hold Post Interview">Hold Post Interview</option>
                            <option value="Backout">Backout</option>
                            <option value="Final Selection">Final Selection</option>
                        </select>
                    </div>
                    <div className='flex flex-col justify-start items-start '>
                        <label >Interview Done</label>
                        <select value={filterData?.interview_done}
                            className=' border-2 rounded-lg px-2 py-1 w-full'
                            onChange={e => setFilterData({ ...filterData, interview_done: e.target.value })}>
                            <option value="All">All</option>
                            <option value="In Progress">In Progress</option>
                            <option value="Completed">Completed</option>
                        </select>
                    </div>
                    {/* <div className='flex flex-col justify-start items-start mb-2'>
                        <label >Interview Location</label>
                        <input
                            value={filterData?.interview_location}
                            className=' border-2 rounded-lg px-2 py-1'
                            onChange={e => setFilterData({ ...filterData, interview_location: e.target.value })}
                            type="text" />
                    </div> */}
                    <div className='flex items-center gap-3'>
                        <button onClick={fetchFilterInterview} className=' darkPurple cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>Apply filter</button>
                        <button onClick={() => {
                            setFilterData({
                                fromDate: "",
                                toDate: "",
                                interview_round: "",
                                interview_date: "",
                                interview_status: "",
                                interview_location: "",
                                company: "",
                                position: "",
                                candidate: "",
                                interview_done: "In Progress",
                                orderBy: "scheduled_date",
                                orderDirection: "DESC",
                            });
                            // fetchCandidate();
                        }} className=' text-red-700 bg-red-200  py-2 px-3 rounded-lg hoverRed'>Clear Filter</button>
                        <div className=' text-left w-fit'>
                            <button onClick={(e) => {
                                setFilterData({
                                    fromDate: "",
                                    toDate: "",
                                    interview_round: "",
                                    interview_date: "",
                                    interview_status: "",
                                    interview_location: "",
                                    company: "",
                                    position: "",
                                    candidate: "",
                                    interview_done: "In Progress",
                                    orderBy: "scheduled_date",
                                    orderDirection: "DESC",
                                });
                                setPositionWiseView(true);
                            }} className=' py-2 px-3 bg-purple-700 text-white rounded-lg '>Position Details</button>
                        </div>
                    </div>

                </div>
                : null}
            {/* candidate interview deatils table */}
            <div className=' mt-5'>
                {
                    !loading ?
                        Array.isArray(allInterviews) && allInterviews?.length >= 1 ?
                            <section className='bg-white grayBlurShadow p-7 rounded-3xl mb-8 mt-5 '>
                                <div className=' overflow-auto tableBody tableBox'>
                                    {/* candidate interview deatils table */}
                                    <table className=' navAnimation rounded-xl baseWidth text-left'>
                                        <thead className='  tableHead'>
                                            <tr className=' bg-gray-200'>
                                                <th className='whitespace-nowrap text-center' >Sr.No.</th>
                                                <th className='whitespace-nowrap text-center'>
                                                    <div onClick={() => handleInterviewSort("scheduled_date")} className='flex item-center gap-2 pr-2 justify-center w-full cursor-pointer'>
                                                        Scheduled Date
                                                        {filterData?.orderBy === "scheduled_date" && filterData?.orderDirection === "ASC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                            filterData?.orderBy === "scheduled_date" && filterData?.orderDirection === "DESC" ?
                                                                <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                                <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                    </div>
                                                </th>
                                                <th className=' whitespace-nowrap'>
                                                    <div onClick={() => handleInterviewSort("candidate")} className='flex item-center gap-2 pr-8 w-full cursor-pointer'>
                                                        Candidate Name
                                                        {filterData?.orderBy === "candidate" && filterData?.orderDirection === "ASC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                            filterData?.orderBy === "candidate" && filterData?.orderDirection === "DESC" ?
                                                                <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                                <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                    </div>

                                                </th>
                                                <th className=' whitespace-nowrap' >
                                                    <div onClick={() => handleInterviewSort("company_name")} className='flex item-center gap-2 w-full cursor-pointer'>
                                                        Company Name
                                                        {filterData?.orderBy === "company_name" && filterData?.orderDirection === "ASC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                            filterData?.orderBy === "company_name" && filterData?.orderDirection === "DESC" ?
                                                                <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                                <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                    </div>
                                                </th>
                                                <th className=' whitespace-nowrap' >
                                                    <div onClick={() => handleInterviewSort("position")} className='flex item-center gap-2 w-full cursor-pointer'>
                                                        Position Name
                                                        {filterData?.orderBy === "position" && filterData?.orderDirection === "ASC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                            filterData?.orderBy === "position" && filterData?.orderDirection === "DESC" ?
                                                                <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                                <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                    </div>
                                                </th>
                                                <th >Location</th>
                                                <th className='whitespace-nowrap text-center' >
                                                    <div onClick={() => handleInterviewSort("interview_round")} className='flex item-center gap-2 justify-center w-full cursor-pointer'>
                                                        Interview Round
                                                        {filterData?.orderBy === "interview_round" && filterData?.orderDirection === "ASC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                            filterData?.orderBy === "interview_round" && filterData?.orderDirection === "DESC" ?
                                                                <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                                <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                    </div>
                                                </th>
                                                <th className='whitespace-nowrap text-center'>Interview Mode</th>
                                                <th className='whitespace-nowrap text-center'>Interview date</th>
                                                <th className='whitespace-nowrap text-center'>Interview Time</th>
                                                <th className='whitespace-nowrap'>Interview Location</th>
                                                {/* <th>Interview Link</th> */}
                                                <th className='whitespace-nowrap text-center'>Interview Status</th>
                                                <th className='whitespace-nowrap text-center'>interview Done</th>
                                                <th className='whitespace-nowrap'>Interview Remarks</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className='tableBody '>
                                            {
                                                Array.isArray(allInterviews) && allInterviews?.map((val, idx) => {

                                                    return (
                                                        <tr key={val?.id} id={val?.id} className=' backWhite font-semibold text-sm'>
                                                            <td className='whitespace-nowrap text-center' >{idx + 1}</td>
                                                            <td className='whitespace-nowrap text-center' >{val?.scheduled_date?.split("-")[2]} {monthData[parseInt(val?.scheduled_date?.split("-")[1])]} {val?.scheduled_date?.split("-")[0]}</td>
                                                            <td className=' whitespace-nowrap' >{val?.Candidate?.candidate}</td>
                                                            <td className=' whitespace-nowrap' >{val?.Candidate?.Position?.Company?.company_name}</td>

                                                            <td className=' whitespace-nowrap' >{val?.Candidate?.Position?.position}</td>
                                                            <td >{val?.Candidate?.Position?.location}</td>

                                                            <td className='whitespace-nowrap text-center' >{val?.interview_round}</td>
                                                            <td className='whitespace-nowrap text-center'>{val?.interview_mode === "Virtual" ? <div className=' bg-blue-100 text-blue-900 py-1 px-2 rounded-lg w-full'>{val?.interview_mode}</div> : <div className=' bg-yellow-100 text-yellow-900 py-1 px-2 rounded-lg w-full'>{val?.interview_mode}</div>}</td>
                                                            <td className='whitespace-nowrap text-center'>{val?.interview_date?.split("-")[2]} {monthData[parseInt(val?.interview_date?.split("-")[1])]} {val?.interview_date?.split("-")[0]}</td>
                                                            <td className='whitespace-nowrap text-center'>{val?.interview_time}</td>
                                                            <td>{val?.interview_location ? val?.interview_location : <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700'>None</div>}</td>
                                                            {/* <td>{val?.interview_link}</td> */}
                                                            <td className='whitespace-nowrap text-center'>
                                                                {
                                                                    val?.interview_status === "Rejected Post Interview" ?
                                                                        <h3 className={` whitespace-nowrap w-full text-center outline-none py-2 px-1 text-red-500 redBox`}>
                                                                            Rejected Post Interview
                                                                        </h3> :
                                                                        val?.interview_status === "Backout" ?
                                                                            <h3 className={` whitespace-nowrap w-full text-center outline-none py-2 px-1 text-red-500 redBox`}>
                                                                                Backout
                                                                            </h3> :
                                                                            val?.interview_status === "Final Selection" ?
                                                                                <h3 className={` whitespace-nowrap w-full text-center outline-none py-2 px-1 text-green-500 greenBox`}>
                                                                                    Final Selection
                                                                                </h3> :
                                                                                <div>
                                                                                    <select value={val?.interview_status}
                                                                                        onChange={(e) => {
                                                                                            setInterviewStatusOrDone(e.target.value);
                                                                                            setCandidateId(val?.candidate_id);
                                                                                            if (e.target.value === "Shortlisted Next Round") {
                                                                                                setCandidateName(val?.Candidate?.candidate);
                                                                                                handleEdit(idx, "Shortlisted Next Round");
                                                                                            } else {
                                                                                                setConfirmation("interview_status");
                                                                                            }
                                                                                        }}
                                                                                        className={`whitespace-nowrap w-[14rem] text-center outline-none py-2 px-1 rounded-lg ${val?.interview_status === "Interview Scheduled" ? " bg-purple-200 text-purple-800" :
                                                                                            val?.interview_status === "Interview Feedback Pending" ? " text-amber-800 bg-amber-300" :
                                                                                                val?.interview_status === "Shortlisted Next Round" ? "text-blue-500 blueBox" :
                                                                                                    val?.interview_status === "Rejected Post Interview" ? "text-red-500 redBox" :
                                                                                                        val?.interview_status === "Hold Post Interview" ? "text-yellow-500 yellowBox" :
                                                                                                            val?.interview_status === "Backout" ? "text-red-500 redBox" :
                                                                                                                val?.interview_status === "Final Selection" ? "text-green-500 greenBox" :
                                                                                                                    " text-amber-800 bg-amber-200"}`} >
                                                                                        <option selected disabled value="Interview Scheduled">Interview Scheduled</option>
                                                                                        <option value="Interview Feedback Pending">Interview Feedback Pending</option>
                                                                                        {/* <option value="Shortlisted Post Interview">Shortlisted Post Interview</option> */}
                                                                                        <option value="Shortlisted Next Round">Shortlisted Next Round</option>
                                                                                        <option value="Rejected Post Interview">Rejected Post Interview</option>
                                                                                        <option value="Hold Post Interview">Hold Post Interview</option>
                                                                                        <option value="Backout">Backout</option>
                                                                                        <option value="Final Selection">Final Selection</option>
                                                                                    </select>
                                                                                </div>
                                                                }
                                                            </td>
                                                            <td className='whitespace-nowrap text-center'>
                                                                <select value={val?.interview_done} onChange={(e) => { setInterviewStatusOrDone(e.target.value); setCandidateId(val?.candidate_id); setConfirmation("interview_done") }}
                                                                    className={`whitespace-nowrap w-full text-center outline-none py-2 px-1 ${val?.interview_done === "Completed" ? " text-green-500 greenBox" : " text-yellow-700 yellowBox"}`}>
                                                                    <option value="In Progress">In Progress</option>
                                                                    <option value="Completed">Completed</option>
                                                                </select>
                                                            </td>
                                                            <td className={` whitespace-nowrap ${val?.interview_remarks ? "" : "text-center"}`}>{val?.interview_remarks ? val?.interview_remarks : <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700'>NA</div>}</td>
                                                            <td className=' flex justify-center items-center'>
                                                                <div className=' relative w-fit'>
                                                                    <div onClick={(e) => { e.stopPropagation(); setIsAction(val?.id); }} className=' flex flex-row gap-1 cursor-pointer w-fit p-2 rounded-lg hoverGreen'>
                                                                        <div className=' w-2 h-2 darkPurple rounded-full '></div>
                                                                        <div className=' w-2 h-2 darkPurple rounded-full '></div>
                                                                        <div className=' w-2 h-2 darkPurple rounded-full '></div>
                                                                    </div>
                                                                    {isAction === val?.id ?
                                                                        < div className={`absolute ${allInterviews?.length - 2 <= idx && allInterviews?.length > 3 ? "bottom-5 z-10 right-2" : "right-2 z-10 top-5"} w-40 h-fit p-3 rounded-xl border-2 bg-white grayBlurShadow`} >
                                                                            <div className='flex flex-col gap-2'>
                                                                                {user[index].view_access ?
                                                                                    <button onClick={(e) => { setCandidateName(val?.Candidate?.candidate); setInterviewDetailsModal(val?.id); setInterviewIndex(idx); }}
                                                                                        className=' darkPurple text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2 hoverGreen'>
                                                                                        View</button> : null}
                                                                                {user[index].edit_access ?
                                                                                    <button onClick={() => { setCandidateName(val?.Candidate?.candidate); handleEdit(idx); }} className=' text-yellow-600 bg-yellow-200 mx-2 px-4 py-1 rounded-lg hoverYellow' >
                                                                                        Edit</button> : null}
                                                                                {/* {user[index].delete_access ?
                                                                                    <button onClick={(e) => { handleDelete(e, val?.id) }} className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed'>
                                                                                        Delete</button> : null} */}
                                                                            </div>
                                                                        </div> : null
                                                                    }
                                                                </div>
                                                            </td>


                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {/* pagination of table */}
                                <div className=' flex items-center justify-between py-4'>
                                    <div className=' flex items-center gap-2'>
                                        <div className='grayBlurShadow px-3 py-1'>
                                            <select value={limit} onChange={(e) => { setLimit(e.target.value); }} >
                                                <option value="10" selected>10</option>
                                                <option value="20" >20</option>
                                                <option value="50" >50</option>
                                                <option value="100" >100</option>
                                                <option value="200" >200</option>
                                                <option value="500" >500</option>
                                                <option value="All" >All</option>
                                            </select>
                                        </div>
                                        <div className='grayBlurShadow px-1 py-1 text-sm font-semibold'>
                                            <h1>Total Records: {totalRecords}</h1>
                                        </div>
                                        <div className='grayBlurShadow px-1 py-1  text-sm font-semibold'>
                                            <h1>Total Pages: {allPages}</h1>
                                        </div>
                                    </div>
                                    <div className='flex items-center grayBlurShadow px-3 py-1 rounded-lg'>
                                        <div onClick={(e) => {
                                            if (page > 1) {
                                                setPage(page - 1);
                                            }
                                        }} className={` ${page === 1 ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                            <img className='w-5' src="/img/leftArrow.png" alt="leftArrow" />
                                        </div>
                                        <div className=' px-3'>
                                            {page}
                                        </div>
                                        <div onClick={(e) => {
                                            if (allPages > page) {
                                                setPage(page + 1);
                                            }
                                        }} className={` ${page == allPages ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                            <img className='w-5' src="/img/rightArrow.png" alt="rightArrow" />
                                        </div>
                                    </div>
                                </div>
                            </section> :
                            // if interview data not found then show this div
                            <div className='navAnimation flex flex-col justify-center items-center bg-white  py-5 mb-10 rounded-3xl '>
                                <img className=' w-[28%]' src="/img/addReports.png" alt="" />
                                <h1 className=' text-2xl font-bold'>Oops!! "<span className=' text-blue-950'>No Interview Schedule</span>"</h1>
                            </div> : <div className=' loaderBox mb-5 bg-white mt-4 rounded-3xl '><img className=' m-auto w-1/4' src="/img/loader2.gif" alt="loading..." /></div>
                }
            </div >
        </section >
    );
}

export default InterviewSchedule;
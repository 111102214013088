import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { AppContext } from '../../ContextApi/ContextApi';
import axios from 'axios';
import { basePath } from '../../Constants/constant';

function UserRegistration(props) {
    const { index } = useParams();
    const { industry, setIndustry, isModalOpen, setIsModalOpen, user } = useContext(AppContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [allRoles, setAllRoles] = useState([]);
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        designation: "",
        email: "",
        phone: "",
        role_id: "",
        username_login: "",
        password_login: ""
    });

    const getRoleData = async () => {
        let response = await axios.get(`${basePath}/getroles`);
        console.log(response.data);
        setAllRoles(response?.data);
    }
    useEffect(() => {
        getRoleData();
    }, []);

    const notify = (msg = "successfull!!") => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const notifyFillTheFields = (msg = "There some issue!!") => toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            name: `${formData?.firstName.trim()} ${formData?.lastName.trim()}`,
            designation: formData?.designation,
            email: formData?.email,
            phone: formData?.phone,
            role_id: formData?.role_id,
            username_login: formData?.username_login,
            password_login: formData?.password_login
        }
        setLoading(true);
        const options = {
            url: `${basePath}/user`,
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            data: data,
        }
        axios(options)
            .then((response) => {
                if (response.status == 200) {
                    
                    document.getElementById("user").reset();
                    setFormData({
                        firstName: "",
                        lastName: "",
                        designation: "",
                        email: "",
                        phone: "",
                        role_id: "",
                        username_login: "",
                        password_login: ""
                    });
                    setLoading(false);
                    notify("User Registered Successfully");
                    console.log("Information entered successfully");
                }
                else {
                    setLoading(false);
                    notifyFillTheFields("Unable to register");
                }
            })
            .catch((e) => {
                setLoading(false);
                console.log(e);
                notifyFillTheFields("Unable to register");
            });
    }
    console.log(formData);

    return (
        <section onClick={() => { setIsModalOpen(false) }} className='lightBabyGreen baseHeightWidth customScroll '>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            <div className=' customScroll'>
                <div className='welcomeBarHeight babyGreen smoothTransition textDarkPurple px-10 rounded-3xl flex items-center justify-between navAnimation width80 m-auto'>
                    <div className=' text-left'>
                        <h1 className=' text-5xl font-bold'>User Registration</h1>
                        <p className=' text-gray-500 font-semibold'>Ready to start your day</p>
                    </div>
                    <img className=' w-44' src="/img/registration.png" alt="registration" />
                </div>
                <div className=' w-full my-3 pr-4 flex items-center justify-end'>
                    <button onClick={() => { localStorage.getItem("role") === "Super Admin" ? navigate(`/main/user-details/${index}`) : navigate(`/user-details/${index}`) }} className=' text-left w-fit darkPurple hoverGreen text-white rounded-lg px-3 py-1'>Back</button>
                </div>
                <div className=' bg-white px-10 py-5 mb-10 grayBlurShadow rounded-3xl navAnimation'>
                    <form id='user' onSubmit={handleSubmit} className=' bg-white p-4 '>
                        <h1 className=' text-3xl font-bold babyGreen  rounded-xl py-3 mb-10'>User Registration</h1>
                        <div className='relative flex flex-col gap-5 p-7 borderDarkBlue rounded-3xl'>
                            <h1 className=' bg-white absolute top-15px font-semibold px-1 textDarkBlue'>User Details</h1>
                            <div className=' grid gridResponsive gap-3'>
                                <div className='input-group relative'>
                                    <input value={formData?.firstName} onChange={(e) => { setFormData({ ...formData, firstName: e.target.value }) }} id='firstName' placeholder='First Name*' className=' w-full' required type="text" />
                                    <label htmlFor="firstName" className='label'>First Name</label>
                                </div>
                                <div className='input-group relative'>
                                    <input value={formData?.lastName} onChange={(e) => { setFormData({ ...formData, lastName: e.target.value }) }} id='lastName' placeholder='Last Name*' className=' w-full' required type="text" />
                                    <label htmlFor="lastName" className='label'>Last Name</label>
                                </div>
                            </div>
                            <div className=' grid gridResponsive gap-3'>
                                <div className='input-group relative'>
                                    <input value={formData?.email} onChange={(e) => { setFormData({ ...formData, email: e.target.value }) }} id='email' placeholder='Email*' className=' w-full' required type="text" />
                                    <label htmlFor="email" className='label'>Email</label>
                                </div>
                                <div className='input-group relative'>
                                    <input value={formData?.phone} onChange={(e) => { setFormData({ ...formData, phone: e.target.value }) }} id='phone' placeholder='Mobile*' className=' w-full' required type="text" />
                                    <label htmlFor="phone" className='label'>Mobile</label>
                                </div>
                            </div>
                            <div className=' grid gridResponsive gap-3'>
                                <div className='input-group relative'>
                                    <input value={formData?.designation} onChange={(e) => { setFormData({ ...formData, designation: e.target.value }) }} id='designation' placeholder='Designation*' className=' w-full' required type="text" />
                                    <label htmlFor="designation" className='label'>Designation</label>
                                </div>
                                <div className='input-group relative'>
                                    <select onChange={(e) => { setFormData({ ...formData, role_id: parseInt(e.target.value) }) }} className=' w-full outline-none'>
                                        <option value="" selected>--Select Role--</option>
                                        {
                                            localStorage.getItem("role") === "Super Admin" ?
                                                allRoles?.map((val) => {
                                                    return (
                                                       <option value={parseInt(val?.id)}>{val?.role_name}</option>
                                                    )
                                                }):
                                                allRoles?.map((val) => {
                                                    return (
                                                        val?.role_name != "Super Admin"?<option value={parseInt(val?.id)}>{val?.role_name}</option>:null
                                                    )
                                                })
                                    }
                                    </select>
                                </div>
                            </div>
                            <div className=' grid gridResponsive gap-3'>
                                <div className='input-group relative'>
                                    <input value={formData?.username_login} onChange={(e) => { setFormData({ ...formData, username_login: e.target.value }) }} id='username' placeholder='Username' className=' w-full' required type="text" />
                                    <label htmlFor="username" className='label'>Username</label>
                                </div>
                                <div className='input-group relative'>
                                    <input value={formData?.password_login} onChange={(e) => { setFormData({ ...formData, password_login: e.target.value }) }} id='password' placeholder='Password' className=' w-full' required type="text" />
                                    <label htmlFor="password" className='label'>Password</label>
                                </div>
                            </div>
                            <div>
                                {
                                    !loading ?
                                    <button type='submit' className=' w-fit darkPurple text-white font-semibold rounded-xl px-7 text-sm py-2 hoverGreen'>Submit</button>:
                                    <button className=' w-fit darkPurple text-white font-semibold rounded-xl px-7 text-sm py-2 hoverGreen'>Loading...</button>
                                }
                                
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default UserRegistration;
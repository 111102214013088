import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../ContextApi/ContextApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronDown, faCaretDown, faAddressCard, faTable, faUserLock } from '@fortawesome/free-solid-svg-icons'
import { basePath } from '../Constants/constant';
import axios, { Axios } from 'axios';

function SuperAdminNavbar(props) {
    const { adminNavbar, setAdminNavbar, isModalOpen, setIsModalOpen, user } = useContext(AppContext);
    const [nav, setNav] = useState("home");
    const [toolTip, setToolTip] = useState(false);
    const [isNavClose, setIsNavClose] = useState(false);
    const [allModules, setAllModules] = useState([]);
    const [isSelect, setIsSelect] = useState("");
    const navigate = useNavigate();

    // const handleHamburger=()=>{
    //     if (!isNavClose) {
    //         setIsNavClose(true);
    //         document.getElementById("navContainer").classList.add("hamOn");
    //         document.getElementById("navContainer").classList.remove("hamOff");

    //     }
    //     else {
    //         setIsNavClose(false);
    //         document.getElementById("navContainer").classList.add("hamOff");
    //         document.getElementById("navContainer").classList.remove("hamOn");
    //     }
    // }
    const handleNav = () => {
        if (!isNavClose) {
            setIsNavClose(true);
            document.getElementById("navbar").style.width = "4.5rem";
            // document.getElementById("navbarCover").style.width = "6rem";
        }
        else {
            setIsNavClose(false);
            document.getElementById("navbar").style.width = "17rem";
            // document.getElementById("navbarCover").style.width = "18rem";
        }
    }
    console.log(isModalOpen);
    // const getModuleData = async () => {
    //     let response = await axios.get(`${basePath}/getmodules`);
    //     console.log(response);
    //     setAllModules(response?.data);
    // }
    // useEffect(() => {
    //     getModuleData();
    // }, []);

    const handleNavbar = (e, type) => {
        e.stopPropagation();
        setAdminNavbar(true);
        if (type === "master") {
            if (isSelect && isSelect != "module") {
                setIsSelect("");
            }
            else {
                setIsSelect("master");
            }
            setNav("master");
        }
        else if (type === "module") {
            if (isSelect && isSelect != "master") {
                setIsSelect("");
            }
            else {
                setIsSelect("module");
            }
            // getModuleData();
            setNav("module");

        }
        else if(type==="access") {
            setIsSelect("");
            setNav("access");
        }
        else{
            setIsSelect("");
            setNav("home");
        }
    }
    const handleLogout = (e) => {
        localStorage.removeItem("name");
        localStorage.removeItem("role");
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        window.location.reload();
        // navigate("/login");
    }
    useEffect(() => {
        if (!isModalOpen && isSelect) {
            setIsSelect("");
        }
    }, [isModalOpen])
    return (
        <>
            <nav id='navContainer'>
                <nav onClick={(e) => { e.stopPropagation(); setAdminNavbar(false); setIsModalOpen(false); }} id='navbarCover' className='relative w-fit h-screen navAnimation'>
                    <div className='h-full pl-4 py-4'>
                        <header id='navbar' className=' relative header h-full darkPurple text-left font-semibold text-white'>
                            <button onClick={(e) => { handleLogout(e) }} className='absolute bottom-4 right-4 px-4 py-1 hoverRed flex flex-row items-center w-fit rounded-full bg-red-200 text-red-600 font-semibold'>{localStorage.getItem("role")} Logout</button>
                            <div className=' '>
                                {!isNavClose ?
                                    <div className=' p-5'>
                                        <div className=' relative ml-2 mb-5 flex items-center justify-between'>
                                            <img className=' w-36' src="/img/tcoLogo3.png" alt="" />
                                            {/* <div onClick={(e) => { e.stopPropagation(); handleNav(); }} className=' bg-white px-3 py-1 w-fit rounded-full mb-2 grayBlurShadow hoverGreen navOpenCloseIcon'>
                                                <FontAwesomeIcon icon={faCaretDown} rotation={90} style={{ color: "#1f1e30", }} />
                                            </div> */}
                                        </div>
                                        <nav onClick={(e) => { e.stopPropagation(); setAdminNavbar(false); }} className='flex flex-col'>
                                        <nav onClick={(e) => { navigate("/main"); handleNavbar(e, "home") }} className={`px-4 py-3 cursor-pointer flex flex-row items-center font-semibold  rounded-full blueBorder hoverWhiteBorder ${nav === "home" ? "textDarkPurple babyGreen navAnimation font-bold" : ""}`}>Home</nav>
                                            <nav className={`smoothTransition ${nav === "master" ? "babyGreen rounded-2xl " : ""}`}>
                                                <nav onClick={(e) => { handleNavbar(e, "master"); }} className={`px-4 py-3 cursor-pointer flex flex-row items-center justify-between font-semibold  rounded-full  ${nav === "master" ? "textDarkPurple babyGreen  font-bold babyGreenBorder" : "blueBorder hoverWhiteBorder"}`}>
                                                    Master
                                                    {isSelect === "master" ?
                                                        <FontAwesomeIcon icon={faChevronDown} style={{ color: nav === "master" ? "#1f1e30" : "white", }} /> :
                                                        <FontAwesomeIcon icon={faChevronRight} />}
                                                </nav>
                                                {isSelect === "master" ?
                                                    <div className=' mb-2 cursor-pointer mx-3 flex flex-col gap-1 slideDown'>
                                                        <h3 onClick={() => { setAdminNavbar(false); setIsSelect(""); navigate("/main/master-role") }} className=' px-4 py-1 darkPurple blueBorder rounded-full  hoverGreen '>Add Roles</h3>
                                                        <h3 onClick={() => { setAdminNavbar(false); setIsSelect(""); navigate("/main/module") }} className=' px-4 py-1 darkPurple blueBorder rounded-full hoverGreen '>Add Modules</h3>
                                                    </div> : null}
                                            </nav>
                                            <nav className={`smoothTransition ${nav === "module" ? "babyGreen rounded-2xl " : ""}`}>
                                                <nav onClick={(e) => { handleNavbar(e, "module"); }} className={`px-4 py-3 cursor-pointer flex flex-row items-center justify-between font-semibold  rounded-full  ${nav === "module" ? "textDarkPurple babyGreen  font-bold babyGreenBorder" : "blueBorder hoverWhiteBorder"}`}>
                                                    Modules
                                                    {isSelect === "module" ?
                                                        <FontAwesomeIcon icon={faChevronDown} style={{ color: nav === "module" ? "#1f1e30" : "white", }} /> :
                                                        <FontAwesomeIcon icon={faChevronRight} />}
                                                </nav>
                                                {isSelect === "module" ?
                                                    <div className=' mb-2 max-h-[28vh] overflow-y-scroll overflow-x-hidden cursor-pointer ml-2 mr-1 pb-2 flex flex-col gap-1 slideDown'>
                                                        {user?.map((val, idx) => {
                                                            return (
                                                                <h3 onClick={() => { setAdminNavbar(false); setIsSelect(""); navigate(`/main/${val?.Module.module_url}/${idx}`) }} className=' pl-2 py-1 darkPurple blueBorder rounded-full  hoverGreen '>{val?.Module.module_name}</h3>
                                                            )
                                                        })}
                                                    </div> : null}
                                            </nav>
                                            <nav onClick={(e) => { navigate("/main/access-control"); handleNavbar(e, "access") }} className={`px-4 py-3 cursor-pointer flex flex-row items-center font-semibold  rounded-full blueBorder hoverWhiteBorder ${nav === "access" ? "textDarkPurple babyGreen navAnimation font-bold" : ""}`}>Access Control</nav>
                                        </nav>
                                    </div> :
                                    <div className=' py-5 px-2 flex flex-col gap-4'>
                                        <div className='mb-5 flex flex-col items-center gap-2 '>
                                            <div className='flex flex-col items-center'>
                                                <img className=' w-5' src="/img/tcoMiniLogo.png" alt="" />
                                                <h4 className=' text-xs'>TalentCo</h4>
                                            </div>
                                            <div onClick={(e) => { e.stopPropagation(); handleNav(); }} className=' bg-white px-3 py-2  w-fit rounded-full mb-2 grayBlurShadow hoverGreen navOpenCloseIconMob'>
                                                <FontAwesomeIcon icon={faCaretDown} rotation={270} size='xl' style={{ color: "#1f1e30", }} />
                                            </div>
                                        </div>
                                        <nav onClick={(e) => { e.stopPropagation(); setAdminNavbar(false); }} className='flex flex-col gap-4'>
                                            <nav className={`smoothTransition ${nav === "master" ? "babyGreen rounded-2xl " : ""}`}>
                                                <div className=' relative'>
                                                    <nav onClick={(e) => { handleNavbar(e, "master"); setIsModalOpen(true); }}
                                                        className={` cursor-pointer flex flex-col items-center justify-between font-semibold px-1 py-1 hoverGreen rounded-lg textDarkPurple  ${nav === "master" ? " babyGreen  font-bold babyGreenBorder" : " bg-white  blueBorder"} `}>
                                                        <FontAwesomeIcon icon={faAddressCard} style={{ color: "#1f1e30", }} />
                                                        <h3 className='text-xs font-bold'>Master</h3>
                                                    </nav>
                                                    {isSelect === "master" ?
                                                        <div className='babyGreen p-2 w-52 rounded-xl absolute top-11 left-10 z-50 mb-2 cursor-pointer mx-3 flex flex-col gap-1 slideDown'>
                                                            <h3 onClick={(e) => { e.stopPropagation(); setAdminNavbar(false); setIsSelect(""); navigate("/main/master-role") }} className=' px-4 py-1 darkPurple blueBorder rounded-full  hoverGreen '>Add Roles</h3>
                                                            <h3 onClick={(e) => { e.stopPropagation(); setAdminNavbar(false); setIsSelect(""); navigate("/main/module") }} className=' px-4 py-1 darkPurple blueBorder rounded-full hoverGreen '>Add Modules</h3>
                                                        </div> : null}
                                                </div>
                                            </nav>
                                            <nav className={`smoothTransition ${nav === "module" ? "babyGreen rounded-2xl " : ""}`}>
                                                <div className=' relative'>
                                                    <nav onClick={(e) => { handleNavbar(e, "module"); }} className={`cursor-pointer flex flex-col items-center justify-between font-semibold px-1 py-1 hoverGreen rounded-lg textDarkPurple   ${nav === "module" ? " babyGreen  font-bold babyGreenBorder" : " bg-white  blueBorder"} `}>
                                                        <FontAwesomeIcon icon={faTable} style={{ color: "#1f1e30", }} />
                                                        <h3 className='text-xs font-bold'>Modules</h3>
                                                    </nav>
                                                    {isSelect === "module" ?
                                                        <div className='babyGreen p-2 w-52  rounded-xl absolute top-11 left-10 z-50 overflow-y-scroll mb-2 cursor-pointer mx-3 flex flex-col gap-1 slideDown'>
                                                            {user?.map((val, idx) => {
                                                                return (
                                                                    <h3 onClick={() => { setAdminNavbar(false); setIsSelect(""); navigate(`/main/${val?.Module.module_url}/${idx}`) }} className=' px-4 py-1 darkPurple blueBorder rounded-full  hoverGreen '>{val?.Module.module_name}</h3>
                                                                )
                                                            })}
                                                        </div> : null}
                                                </div>
                                            </nav>
                                            <nav onClick={(e) => { navigate("/main/access-control"); handleNavbar(e, "access") }} className={`cursor-pointer flex flex-col items-center justify-between font-semibold px-1 py-1 hoverGreen rounded-lg textDarkPurple   ${nav === "access" ? " babyGreen  font-bold babyGreenBorder" : " bg-white  blueBorder"}`}>
                                                <FontAwesomeIcon icon={faUserLock} style={{ color: "#1f1e30", }} />
                                                <h3 className='text-xs font-bold'>Access</h3>
                                            </nav>
                                        </nav>
                                    </div>}
                            </div>
                        </header>
                    </div>
                </nav>
            </nav>
            {/* <nav id='navContainer'>
                <nav onClick={(e) => { e.stopPropagation(); setAdminNavbar(false); }} id='navbarCover' className='relative w-fit h-screen  navAnimation'>
                    <div className='h-full p-4'>
                        <header id='navbar' className='header h-full darkPurple text-left p-5 font-semibold text-white'>
                            <div className=''>
                                <div className=' ml-2 mb-5'>
                                    <img className=' w-36' src="/img/tcoLogo3.png" alt="" />
                                </div>
                                <nav onClick={(e) => { e.stopPropagation(); setAdminNavbar(false); }} className='flex flex-col'>
                                    <nav className={`smoothTransition ${nav === "master" ? "babyGreen rounded-2xl " : ""}`}>
                                        <nav onClick={(e) => { handleNavbar(e, "master"); }} className={`px-4 py-3 cursor-pointer flex flex-row items-center justify-between font-semibold  rounded-full  ${nav === "master" ? "textDarkPurple babyGreen  font-bold babyGreenBorder" : "blueBorder hoverWhiteBorder"}`}>
                                            Master
                                            {isSelect === "master" ?
                                                <FontAwesomeIcon icon={faChevronDown} style={{ color: nav === "master" ? "#1f1e30" : "white", }} /> :
                                                <FontAwesomeIcon icon={faChevronRight} />}
                                        </nav>
                                        {isSelect === "master" ?
                                            <div className=' mb-2 cursor-pointer mx-3 flex flex-col gap-1 slideDown'>
                                                <h3 onClick={() => { setAdminNavbar(false); setIsSelect(""); navigate("/main/master-role") }} className=' px-4 py-1 darkPurple blueBorder rounded-full  hoverGreen '>Add Roles</h3>
                                                <h3 onClick={() => { setAdminNavbar(false); setIsSelect(""); navigate("/main/module") }} className=' px-4 py-1 darkPurple blueBorder rounded-full hoverGreen '>Add Modules</h3>
                                            </div> : null}
                                    </nav>
                                    <nav className={`smoothTransition ${nav === "module" ? "babyGreen rounded-2xl " : ""}`}>
                                        <nav onClick={(e) => { handleNavbar(e, "module"); }} className={`px-4 py-3 cursor-pointer flex flex-row items-center justify-between font-semibold  rounded-full  ${nav === "module" ? "textDarkPurple babyGreen  font-bold babyGreenBorder" : "blueBorder hoverWhiteBorder"}`}>
                                            Modules
                                            {isSelect === "module" ?
                                                <FontAwesomeIcon icon={faChevronDown} style={{ color: nav === "module" ? "#1f1e30" : "white", }} /> :
                                                <FontAwesomeIcon icon={faChevronRight} />}
                                        </nav>
                                        {isSelect === "module" ?
                                            <div className=' mb-2 cursor-pointer mx-3 flex flex-col gap-1 slideDown'>
                                                {allModules?.map((val) => {
                                                    return (
                                                        <h3 onClick={() => { setAdminNavbar(false); setIsSelect(""); navigate(`${val?.module_url}`) }} className=' px-4 py-1 darkPurple blueBorder rounded-full  hoverGreen '>{val?.module_name}</h3>
                                                    )
                                                })}
                                            </div> : null}
                                    </nav>
                                    <nav onClick={(e) => { navigate("/main/access-control"); handleNavbar(e, "access") }} className={`px-4 py-3 cursor-pointer flex flex-row items-center font-semibold  rounded-full blueBorder hoverWhiteBorder ${nav === "access" ? "textDarkPurple babyGreen navAnimation font-bold" : ""}`}>Access Control</nav>
                                </nav>
                            </div>
                            <div>
                            <div className=' border-2 rounded-full bg-green-600 overflow-hidden p-2 w-fit flex flex-col gap-1 '>
                                <div className=' w-6 h-1 bg-white'></div>
                                <div className=' w-6 h-1 bg-white'></div>
                                <div className=' w-6 h-1 bg-white'></div>
                            </div>
                        </div>
                        </header>
                        
                    </div>
                </nav>
            </nav> */}
            {/* <nav onClick={handleHamburger} className='hamburger darkPurple px-3 rounded-lg mt-3 ml-3'>
                <img className='' src="/img/tcoMiniLogo.png" alt="mini logo" />
            </nav> */}
        </>
    );
}

export default SuperAdminNavbar;
import React from 'react';

function NotFound(props) {
    return (
        <div className='lightBabyGreen flex justify-center items-center w-screen h-screen'>
            <img src="/img/errorpage.png" alt="" />
        </div>
    );
}

export default NotFound;
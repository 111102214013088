import React, { useContext, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import "./superAdmin.css"
import SuperAdminNavbar from '../Navbar/SuperAdminNavbar';
import { AppContext } from '../ContextApi/ContextApi';

function SuperAdmin(props) {
    const {adminNavbar,setAdminNavbar}=useContext(AppContext);
    const [nav, setNav] = useState("Registration");
    const navigate = useNavigate();
    return (
        <main onClick={()=>{setAdminNavbar(false);}} className=' lightBabyGreen flex w-full h-screen overflow-hidden homeStructure'>
            <aside className=' h-fit'>
                <SuperAdminNavbar />
            </aside>
            <section className=' w-full baseHeightWidth mt-4 pl-4 pr-4 pb-1 customScroll overflow-y-scroll '>
                <Outlet/>
            </section>
        </main>
    );
}

export default SuperAdmin;
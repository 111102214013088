import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { AppContext } from '../ContextApi/ContextApi';
import { debounce } from "lodash";
import { basePath, formatDate, getCurrentDate, monthData } from '../Constants/constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight, faXmark } from '@fortawesome/free-solid-svg-icons'

function AssignRecruiter(props) {
    const { industry, setIndustry, isModalOpen, setIsModalOpen } = useContext(AppContext);
    const [allJobs, setAllJobs] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [recruiterWiseData, setRecruiterWiseData] = useState([]);
    const [assignRecruiter, setAssignRecruiter] = useState("");
    const [isUpdate, setIsUpdate] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [isAction, setIsAction] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingAssignRecruiter, setLoadingAssignRecruiter] = useState(false);
    const [loadingFilter, setLoadingFilter] = useState(true);
    const [loadingRecruiter, setLoadingRecruiter] = useState(true);
    const [loadingExport, setLoadingExport] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [recruiterModal, setRecruiterModal] = useState(false);
    const [recruiterName, setRecruiterName] = useState("");
    const [recruiterId, setRecruiterId] = useState("");
    const [confirmation, setConfirmation] = useState(false);
    const [recruiterWiseView, setRecruiterWiseView] = useState(false);
    const [positionId, setPositionId] = useState("");
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [recruiterRemarks, setRecruiterRemarks] = useState('');
    const [filteredJobs, setFilteredJobs] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [allPages, setAllPages] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalRecordsRecruiterWise, setTotalRecordsRecruiterWise] = useState(0);
    const [allPagesRecruiterWise, setAllPagesRecruiterWise] = useState(0);
    const [pageRecruiterWise, setPageRecruiterWise] = useState(1);
    const [limitRecruiterWise, setLimitRecruiterWise] = useState(10);
    const [filter, setFilter] = useState({});
    const [filterData, setFilterData] = useState({
        fromDate: "",
        toDate: "",
        company: "",
        position: "",
        location: "",
        gender: "",
        recruiterId: "",
        qualification: "",
        positionStatus: "Open",
    });
    const [recruiterReportFilter, setRecruiterReportFilter] = useState({
        fromDate: "",
        toDate: "",
        company: "",
        position: "",
        recruiter: "",
        recruiterStatus: 1,
        orderBy: "assigned_at",
        orderDirection: "DESC"
    });

    // fetching all posted jobs 
    const getJobs = async () => {
        try {
            setLoading(true);
            let response = await axios.get(`${basePath}/getjobBypage?limit=${limit}&page=${page}`);
            console.log("jobs==>>", response, response?.data?.totalRecords, response?.data?.pages);
            setAllJobs(response?.data?.data);
            setTotalRecords(response?.data?.totalRecords);
            setAllPages(response?.data?.pages);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    // filter all posted jobs
    const filterJobs = async () => {
        try {
            setLoading(true);
            let response = await axios.get(`${basePath}/getjobBypage?limit=${limit}&page=${page}&filter=${JSON.stringify(filterData)}`);
            console.log("jobs Filter==>>", response);
            setAllJobs(response?.data?.data);
            setTotalRecords(response?.data?.totalRecords);
            setAllPages(response?.data?.pages);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    async function getRecruiterWiseData() {
        try {
            setLoadingRecruiter(true);
            let res = await axios.get(`${basePath}/getassignrecruiterlog?limit=${limitRecruiterWise}&page=${pageRecruiterWise}&id=${localStorage.getItem("userId")}&filter=${JSON.stringify(recruiterReportFilter)}`);
            console.log("getRecruiterWiseData ~ res", res.data);
            setRecruiterWiseData(res.data?.data);
            setTotalRecordsRecruiterWise(res?.data?.totalRecords);
            setAllPagesRecruiterWise(res?.data?.pages);
            setLoadingRecruiter(false);
        } catch (error) {
            console.log(error);
            setLoadingRecruiter(false);
        }
        // setRowData(sent);
    }

    const handleSort = (columnName) => {
        if (recruiterReportFilter?.orderBy === columnName) {
            if (recruiterReportFilter?.orderDirection === "DESC") {
                setRecruiterReportFilter({ ...recruiterReportFilter, orderBy: columnName, orderDirection: 'ASC' });
            }
            else {
                setRecruiterReportFilter({ ...recruiterReportFilter, orderBy: columnName, orderDirection: 'DESC' });
            }
        }
        else {
            setRecruiterReportFilter({ ...recruiterReportFilter, orderBy: columnName, orderDirection: 'ASC' });
        }
    }

    const handleFilter = debounce(() => {
        if (
            filterData?.fromDate ||
            filterData?.toDate ||
            filterData?.company ||
            filterData?.position ||
            filterData?.location ||
            filterData?.recruiterId ||
            filterData?.gender ||
            filterData?.qualification
        ) {
            filterJobs();
        }
    }, 800);


    const getUserData = async () => {
        let response = await axios.get(`${basePath}/getRecruiter`);
        console.log(response.data);
        setAllUsers(response?.data);
    }
    // const getUserData = async () => {
    //     let response = await axios.get(`http://localhost:3002/getrecruiterbypage`);
    //     console.log(response.data);
    //     setAllUsers(response?.data);
    // }
    useEffect(() => {
        if (
            !loadingFilter &&
            !filterData?.fromDate &&
            !filterData?.toDate &&
            !filterData?.company &&
            !filterData?.position &&
            !filterData?.location &&
            !filterData?.recruiterId &&
            !filterData?.gender &&
            !filterData?.qualification &&
            filterData?.positionStatus === 'Open'
        ) {
            getJobs();
        } else if (!loadingFilter) {
            filterJobs();
        }
    }, [page, limit, filterData]);
    useEffect(() => {
        getRecruiterWiseData();
    }, [pageRecruiterWise, limitRecruiterWise, recruiterReportFilter]);

    useEffect(() => {
        setLoadingFilter(true);
        getJobs();
        getRecruiterWiseData();
        setLoadingFilter(false);
        getUserData();

    }, []);

    const notify = (msg = "successfull!!") => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const notifyFillTheFields = (msg = "There some issue!!") => toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });

    const handleRecruiter = async (e, position) => {
        setLoadingAssignRecruiter(true);
        let data = {
            recruiter_id: assignRecruiter,
            user_id: localStorage.getItem("userId"),
            current_date: getCurrentDate()
        }
        console.log("handleRecruiter ~ data", data);
        const options = {
            url: `${basePath}/recruiter/${position}`,
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            data: data,
        }
        axios(options)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response);
                    if (
                        !loadingFilter &&
                        !filterData?.fromDate &&
                        !filterData?.toDate &&
                        !filterData?.company &&
                        !filterData?.position &&
                        !filterData?.location &&
                        !filterData?.recruiterId &&
                        !filterData?.gender &&
                        !filterData?.qualification
                    ) {
                        getJobs();
                    } else if (!loadingFilter) {
                        filterJobs();
                    }
                    setRecruiterModal(false);
                    setLoadingAssignRecruiter(false);
                    setIsUpdate(false);
                    notify("Recruiter Assign Successfully");
                    console.log("Information entered successfully");
                }
                else {
                    setLoadingAssignRecruiter(false);
                    notifyFillTheFields("Unable to Change Status");
                }
            })
            .catch((e) => {
                setLoadingAssignRecruiter(false);
                console.log(e);
                if (e.response?.request?.status === 404) {
                    notifyFillTheFields(e.response?.data?.error);
                }
                else {
                    notifyFillTheFields("Internal server issue!!");
                }
            });
    }
    const deleteRecruiter = async (e, position) => {

        let data = {
            recruiter_id: recruiterId,
            user_id: localStorage.getItem("userId"),
            current_date: getCurrentDate(),
            remarks: recruiterRemarks || ""
        }
        const options = {
            url: `${basePath}/deleteassingrecruiter/${positionId}`,
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
            data: data,
        }
        setLoadingDelete(true);
        axios(options)
            .then((response) => {
                if (response.status == 200) {

                    notify("Recruiter Removed Successfully");
                    console.log(response);
                    if (
                        !loadingFilter &&
                        !filterData?.fromDate &&
                        !filterData?.toDate &&
                        !filterData?.company &&
                        !filterData?.position &&
                        !filterData?.location &&
                        !filterData?.recruiterId &&
                        !filterData?.gender &&
                        !filterData?.qualification
                    ) {
                        getJobs();
                    } else if (!loadingFilter) {
                        filterJobs();
                    }
                    setConfirmation(false);
                    setLoadingDelete(false);
                    console.log("Information entered successfully");
                }
                else {
                    setLoadingDelete(false);
                    notifyFillTheFields("Unable to Remove!!");
                }
            })
            .catch((e) => {
                setLoadingDelete(false);
                console.log(e);
                if (e.response?.request?.status === 404) {
                    notifyFillTheFields(e.response?.data?.error);
                }
                else {
                    notifyFillTheFields("Internal Server Issue!!");
                }
            });
    }

    const handleExport = async () => {
        try {
            setLoadingExport(true);
            const response = await axios.get(`${basePath}/exportAssignRecruiter?download=yes&filter=${JSON.stringify(filterData)}`, {
                responseType: 'blob', // Ensure response is treated as a blob
            });

            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a temporary <a> element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'exported_jobs.xlsx');
            document.body.appendChild(link);
            link.click();

            // Clean up resources
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            setLoadingExport(false);
        } catch (error) {
            console.error('Error exporting jobs:', error);
            setLoadingExport(false);
        }
    };
    const handleRecruiterWiseExport = async () => {
        try {
            setLoadingExport(true);
            const response = await axios.get(`${basePath}/getassignrecruiterlog?download=yes&id=${localStorage.getItem("userId")}&filter=${JSON.stringify(recruiterReportFilter)}`, {
                responseType: 'blob', // Ensure response is treated as a blob
            });

            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a temporary <a> element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'exported_jobs.xlsx');
            document.body.appendChild(link);
            link.click();

            // Clean up resources
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            setLoadingExport(false);
        } catch (error) {
            console.error('Error exporting jobs:', error);
            setLoadingExport(false);
        }
    };
    // const handleFilter = (e, filterType) => {
    //     console.log(fromDate, toDate);
    //     const from = new Date(fromDate);
    //     const to = new Date(toDate);

    //     if (filterType === "date") {
    //         const filtered = allJobs.filter(date => {
    //             const currentDate = new Date(date?.upload_date?.split("T")[0]);
    //             return currentDate >= from && currentDate <= to;
    //         });
    //         setFilteredJobs(filtered);
    //         setDateModal(false);
    //     }
    //     else if (filterType === "search") {
    //         if (filteredJobs?.length >= 1) {
    //             const results = filteredJobs.filter(item =>
    //                 Object.values(item).some(val =>
    //                     String(val).toLowerCase().includes(searchTerm.toLowerCase())
    //                 )
    //             );
    //             console.log(results);
    //             setFilteredJobs(results);
    //         }
    //         else {
    //             const results = allJobs.filter(item => {

    //                 console.log(item?.recruiter_assign);
    //                 let lowerCaseCompany = item?.Company?.company_name.toLowerCase();
    //                 let lowerCasePosition = item?.position.toLowerCase();
    //                 let lowerCaseQualification = item?.qualification.toLowerCase();
    //                 let lowerCaseLocation = item?.location.toLowerCase();
    //                 let lowerCaseGender = item?.gender_pref.toLowerCase();


    //                 if (lowerCaseCompany.startsWith(searchTerm?.toLowerCase())) {
    //                     return lowerCaseCompany.startsWith(searchTerm?.toLowerCase());
    //                 }
    //                 else if (lowerCasePosition.startsWith(searchTerm?.toLowerCase())) {
    //                     return lowerCasePosition.startsWith(searchTerm?.toLowerCase());
    //                 }
    //                 else if (lowerCaseQualification.startsWith(searchTerm?.toLowerCase())) {
    //                     return lowerCaseQualification.startsWith(searchTerm?.toLowerCase());
    //                 }
    //                 else if (lowerCaseLocation.startsWith(searchTerm?.toLowerCase())) {
    //                     return lowerCaseLocation.startsWith(searchTerm?.toLowerCase());
    //                 }
    //                 else if (lowerCaseGender.startsWith(searchTerm?.toLowerCase())) {
    //                     return lowerCaseGender.startsWith(searchTerm?.toLowerCase());
    //                 }


    //             });
    //             console.log(results);
    //             setFilteredJobs(results);
    //         }

    //     }

    // };



    // useEffect(() => {
    //     if (searchTerm?.length > 2) {
    //         handleSearch();
    //     }
    //     else if (!searchTerm && !fromDate && !toDate) {
    //         setFilteredJobs(false);
    //     }

    // }, [searchTerm])

    // const handleEdit = (idx) => {
    //     setFormData({
    //         id: "",
    //         company: "",
    //         position: "",
    //         location: "",
    //         experience: "",
    //         minCtc: "",
    //         maxCtc: "",
    //         totalPositions: "",
    //         genderPreference: "",
    //         qualification: "",
    //         description: ""
    //     });
    //     setEditModal(true);
    // }

    return (
        <section onClick={() => {
            setIsModalOpen(false);
            setIsAction(false);
            if (!loadingAssignRecruiter) {
                setRecruiterModal(false);
            }
        }} className='lightBabyGreen baseHeightWidth customScroll mb-5'>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            <div className=' absolute bottom-3 right-4'>
                <div onClick={() => {
                    if (
                        !loadingFilter &&
                        !filterData?.fromDate &&
                        !filterData?.toDate &&
                        !filterData?.company &&
                        !filterData?.position &&
                        !filterData?.location &&
                        !filterData?.recruiterId &&
                        !filterData?.gender &&
                        !filterData?.qualification
                    ) {
                        getJobs();
                    } else if (!loadingFilter) {
                        filterJobs();
                    }
                }} className='cursor-pointer bg-red-600 px-2.5 py-2 rounded-full hoverRed'>
                    <FontAwesomeIcon icon={faRotateRight} size={"lg"} style={{ color: "#ffffff", }} />
                </div>
            </div>
            <div>
                <div className='welcomeBarHeight babyGreen smoothTransition textDarkPurple px-10 rounded-3xl flex items-center justify-between navAnimation  m-auto'>
                    <div className=' text-left'>
                        <h1 className=' text-5xl font-bold'>Assign Recruiter</h1>
                        <p className=' text-gray-500 font-semibold'>Ready to start your day</p>
                    </div>
                    <img className=' w-44' src="/img/registration.png" alt="registration" />
                </div>
                {/* <div className=' mt-5 flex items-center gap-2 px-2'>
                    <div className=' relative w-fit flex items-center  gap-2'>
                        <div className=' relative'>
                            <button className=' darkPurple cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'
                                onClick={(e) => { e.stopPropagation(); setDateModal(true) }}>Date Filter </button>

                            {
                                dateModal ?
                                    <div onClick={(e) => { e.stopPropagation(); }} className='absolute py-3 px-8 rounded-lg top-9 left-3 z-[31] darkPurple text-white'>
                                        <h2>Date Range Filter</h2>
                                        <div className=' flex flex-col justify-start items-start mb-2'>
                                            <label>From: </label>
                                            <input
                                                className=' text-black rounded-md px-1'
                                                type="date"
                                                value={fromDate}
                                                onChange={e => setFromDate(e.target.value)}
                                            />
                                        </div>
                                        <div className=' flex flex-col justify-start items-start'>
                                            <label>To: </label>
                                            <input
                                                className=' text-black rounded-md px-1'
                                                type="date"
                                                value={toDate}
                                                onChange={e => setToDate(e.target.value)}
                                            />
                                        </div>
                                        <div className=' flex items-center justify-between mt-3'>
                                            <button
                                                className=' text-green-700 bg-green-200  px-2 hoverLightGreen rounded-lg'
                                                onClick={(e) => { handleFilter(e, "date") }}
                                            >Apply</button>
                                            <button
                                                className='text-red-700 bg-red-200  px-2  rounded-lg hoverRed'
                                                onClick={(e) => { setFilteredJobs(false); setFromDate(""); setToDate(""); }}
                                            >Clear</button>
                                        </div>
                                    </div> : null
                            }
                        </div>
                        <div className=' darkPurple cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow navAnimation'>

                            <input value={searchTerm} onChange={(e) => { setSearchTerm(e.target.value) }} className="px-2 round-md outline-none text-black rounded-lg" placeholder='Search...' type="text" />
                          
                        </div>
                    </div>
                    <div>
                        {Array.isArray(filteredJobs) ?
                            <button onClick={() => { setFilteredJobs(false); setFromDate(""); setToDate(""); setSearchTerm(""); }}
                                className=' text-red-700 bg-red-200  py-2 px-3 rounded-lg hoverRed'>
                                Clear Filter
                            </button> : null}
                    </div>
                </div> */}
                {confirmation ?
                    <div onClick={(e) => { }} className=' w-full h-screen fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                        <div onClick={(e) => { e.stopPropagation(); }} className='relative  max-w-[39rem] bg-white overflow-auto mx-10 mb-5 py-5 px-8 rounded-2xl navAmination '>
                            <div>
                                <h1 className=' text-blue-500 font-semibold pb-5'>
                                    Are you sure to <span className='text-red-500 font-semibold '>remove</span> <span className='text-blue-900 font-semibold '>{recruiterName}</span> from "<span className='text-blue-900 font-semibold '>{allJobs[parseInt(confirmation)]?.position}</span>" position?
                                </h1>
                                <h2 className=' text-left'>Recruiter Remarks:</h2>
                                <textarea value={recruiterRemarks} onChange={(e) => setRecruiterRemarks(e.target.value)}
                                    placeholder='Remarks...'
                                    className=' outline-none border-2 rounded-lg px-2 w-full' ></textarea>
                                <div className=' flex gap-4 items-center justify-center'>
                                    {!loadingDelete ?
                                        <button
                                            className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed'
                                            onClick={(e) => {
                                                setConfirmation(false);
                                            }}>Cancel</button> :
                                        <button
                                            className='opacity-70 text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg '
                                        >Cancel</button>}
                                    {!loadingDelete ?
                                        <button
                                            className='darkPurple text-white font-semibold rounded-xl px-4 py-1 hoverGreen'
                                            onClick={(e) => {
                                                deleteRecruiter();
                                            }}
                                        >Remove</button> :
                                        <button
                                            className='darkPurple text-white font-semibold rounded-xl px-4 py-1'
                                        >Removing...</button>
                                    }

                                </div>
                            </div>
                        </div>
                    </div> : null
                }
                {recruiterWiseView ?
                    <div onClick={(e) => { setRecruiterWiseView(false); }} className=' w-full h-screen  fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                        <div onClick={(e) => { e.stopPropagation(); }} className=' relative h-[95vh] w-[95%]  lightBabyGreen rounded-2xl mx-10 px-2 py-4   '>
                            <button onClick={(e) => {
                                setRecruiterWiseView(false);
                                setRecruiterReportFilter({
                                    fromDate: "",
                                    toDate: "",
                                    company: "",
                                    position: "",
                                    orderBy: "assigned_at",
                                    recruiter: "",
                                    recruiterStatus: 1,
                                    orderDirection: "DESC",
                                    status: ""
                                });

                            }} className="absolute bg-red-600 px-1.5 py-0.5    rounded-full w-fit top-1 right-1 z-[11] ">
                                <FontAwesomeIcon icon={faXmark} size='xl' style={{ color: "#ffffff", }} />
                            </button>
                            <div className=' absolute bottom-3 right-4'>
                                <div onClick={(e) => { getRecruiterWiseData(); }} className='cursor-pointer bg-red-600 px-2.5 py-2 rounded-full hoverRed'>
                                    <FontAwesomeIcon icon={faRotateRight} size={"lg"} style={{ color: "#ffffff", }} />
                                </div>
                            </div>
                            <div className='h-full w-full  rounded-2xl overflow-y-scroll noScrollbar px-8'>
                                {/* filter */}
                                <div className=' flex flex-wrap items-center gap-3 grayBlurShadow bg-white p-3 mb-5 rounded-xl'>
                                    <div className='flex items-center gap-3'>
                                        <div className=' flex flex-col justify-start items-start'>
                                            <label>From: </label>
                                            <input
                                                className=' border-2 rounded-lg px-2 py-1'
                                                type="date"
                                                value={recruiterReportFilter?.fromDate}
                                                onChange={e => { setRecruiterReportFilter({ ...recruiterReportFilter, fromDate: e.target.value }) }}
                                            />
                                        </div>
                                        <div className=' flex flex-col justify-start items-start'>
                                            <label>To: </label>
                                            <input
                                                className=' border-2 rounded-lg px-2 py-1'
                                                type="date"
                                                value={recruiterReportFilter?.toDate}
                                                onChange={e => { setRecruiterReportFilter({ ...recruiterReportFilter, toDate: e.target.value }) }}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start '>
                                        <label >Company</label>
                                        <input
                                            value={recruiterReportFilter?.company}
                                            className=' border-2 rounded-lg px-2 py-1'
                                            onChange={e => { setFilterData({ ...filterData, company: e.target.value }); setRecruiterReportFilter({ ...recruiterReportFilter, company: e.target.value }) }}
                                            type="text" />
                                    </div>
                                    <div className='flex flex-col justify-start items-start '>
                                        <label >Position</label>
                                        <input
                                            value={recruiterReportFilter?.position}
                                            className=' border-2 rounded-lg px-2 py-1'
                                            onChange={e => { setFilterData({ ...filterData, position: e.target.value }); setRecruiterReportFilter({ ...recruiterReportFilter, position: e.target.value }) }}
                                            type="text" />
                                    </div>
                                    <div className='flex flex-col justify-start items-start mb-2'>
                                        <label >Recruiter</label>
                                        <select value={recruiterReportFilter?.recruiter}
                                            className=' border-2 rounded-lg px-2 py-1'
                                            onChange={e => { setFilterData({ ...filterData, recruiter: e.target.value }); setRecruiterReportFilter({ ...recruiterReportFilter, recruiter: e.target.value }); }} >
                                            <option value="" selected disabled>Select Recruiter</option>
                                            {allUsers?.map((val) => {
                                                return (
                                                    <option value={val?.id}>{val?.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className='flex flex-col justify-start items-start mb-2'>
                                        <label >Assigned Status</label>
                                        <select value={recruiterReportFilter?.recruiterStatus}
                                            className=' border-2 rounded-lg px-2 py-1'
                                            onChange={e => { setRecruiterReportFilter({ ...recruiterReportFilter, recruiterStatus: e.target.value }); }} >
                                            <option value="" selected disabled>Select Status</option>
                                            <option value={"All"}>{"All"}</option>
                                            <option value={1}>{"On Going"}</option>
                                            <option value={0}>{"Completed"}</option>
                                        </select>
                                    </div>
                                    <div className=' flex items-center gap-3'>
                                        <button onClick={getRecruiterWiseData} className=' darkPurple cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>Apply filter</button>
                                        <button onClick={() => {
                                            setRecruiterReportFilter({
                                                fromDate: "",
                                                toDate: "",
                                                company: "",
                                                position: "",
                                                orderBy: "assigned_at",
                                                recruiter: "",
                                                recruiterStatus: 1,
                                                orderDirection: "DESC",
                                                status: ""
                                            });
                                            setFilterData({
                                                fromDate: "",
                                                toDate: "",
                                                company: "",
                                                position: "",
                                                candidate: "",
                                                recruiter: "",
                                                orderBy: "upload_date",
                                                orderDirection: "DESC"
                                            });
                                            getRecruiterWiseData();
                                        }} className=' text-red-700 bg-red-200  py-2 px-3 rounded-lg hoverRed'>Clear Filter</button>
                                        <div className=' text-left w-fit'>
                                            <button onClick={handleRecruiterWiseExport} className=' py-2 px-3 bg-green-800 text-white rounded-lg '>{loadingExport ? "Exporting..." : "Excel"}</button>
                                        </div>
                                    </div>
                                </div>
                                {
                                    !loading ?
                                        Array.isArray(recruiterWiseData) && recruiterWiseData?.length >= 1 ?
                                            <div className=' bg-white px-3 pt-5 rounded-2xl'>
                                                <div className=' overflow-auto tableBody  h-[79vh]'>
                                                    <table className=' navAnimation rounded-2xl baseWidth p-5 text-left'>
                                                        <thead className='text-lg tableHead'>
                                                            <tr className=' bg-gray-200'>
                                                                <th className='whitespace-nowrap text-center' >Sr.No.</th>
                                                                <th className='whitespace-nowrap text-center cursor-pointer' onClick={() => { handleSort("assigned_at") }} >
                                                                    <div className='flex items-center gap-2 justify-center'>Assigned Date
                                                                        {recruiterReportFilter?.orderBy === "assigned_at" && recruiterReportFilter?.orderDirection === "ASC" ?
                                                                            <img className='w-4' src="/img/upArrow.png" alt="up" /> :
                                                                            recruiterReportFilter?.orderBy === "assigned_at" && recruiterReportFilter?.orderDirection === "DESC" ?
                                                                                <img className='w-4' src="/img/downArrow.png" alt="up" /> :
                                                                                <img className='w-4' src="/img/upDownArrow.png" alt="up" />}
                                                                    </div>
                                                                </th>
                                                                <th className='whitespace-nowrap text-center cursor-pointer' onClick={() => { handleSort("removed_at") }} >
                                                                    <div className='flex items-center gap-2 justify-center'>End Date
                                                                        {recruiterReportFilter?.orderBy === "removed_at" && recruiterReportFilter?.orderDirection === "ASC" ?
                                                                            <img className='w-4' src="/img/upArrow.png" alt="up" /> :
                                                                            recruiterReportFilter?.orderBy === "removed_at" && recruiterReportFilter?.orderDirection === "DESC" ?
                                                                                <img className='w-4' src="/img/downArrow.png" alt="up" /> :
                                                                                <img className='w-4' src="/img/upDownArrow.png" alt="up" />}
                                                                    </div>
                                                                </th>
                                                                <th className='whitespace-nowrap cursor-pointer'>
                                                                    <div className='flex items-center gap-2' onClick={() => { handleSort("recruiter") }}>
                                                                        Recruiter Name
                                                                        {recruiterReportFilter?.orderBy === "recruiter" && recruiterReportFilter?.orderDirection === "ASC" ?
                                                                            <img className='w-4' src="/img/upArrow.png" alt="up" /> :
                                                                            recruiterReportFilter?.orderBy === "recruiter" && recruiterReportFilter?.orderDirection === "DESC" ?
                                                                                <img className='w-4' src="/img/downArrow.png" alt="up" /> :
                                                                                <img className='w-4' src="/img/upDownArrow.png" alt="up" />}
                                                                    </div></th>
                                                                <th className='whitespace-nowrap cursor-pointer' onClick={() => { handleSort("company_name") }}>
                                                                    <div className='flex items-center gap-2'>
                                                                        Company Name
                                                                        {recruiterReportFilter?.orderBy === "company_name" && recruiterReportFilter?.orderDirection === "ASC" ?
                                                                            <img className='w-4' src="/img/upArrow.png" alt="up" /> :
                                                                            recruiterReportFilter?.orderBy === "company_name" && recruiterReportFilter?.orderDirection === "DESC" ?
                                                                                <img className='w-4' src="/img/downArrow.png" alt="up" /> :
                                                                                <img className='w-4' src="/img/upDownArrow.png" alt="up" />}
                                                                    </div>
                                                                </th>
                                                                <th className='whitespace-nowrap cursor-pointer' onClick={() => { handleSort("position") }}>
                                                                    <div className='flex items-center gap-2'>
                                                                        Position Name
                                                                        {recruiterReportFilter?.orderBy === "position" && recruiterReportFilter?.orderDirection === "ASC" ?
                                                                            <img className='w-4' src="/img/upArrow.png" alt="up" /> :
                                                                            recruiterReportFilter?.orderBy === "position" && recruiterReportFilter?.orderDirection === "DESC" ?
                                                                                <img className='w-4' src="/img/downArrow.png" alt="up" /> :
                                                                                <img className='w-4' src="/img/upDownArrow.png" alt="up" />}
                                                                    </div>
                                                                </th>
                                                                <th className='whitespace-nowrap text-center'>Remarks</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='tableBody '>
                                                            {
                                                                Array.isArray(recruiterWiseData) && recruiterWiseData?.map((val, idx) => {
                                                                    return (
                                                                        <>
                                                                            {/* {val?.created_at?.split("T")[0] === sourceViewModal ? */}
                                                                            <tr key={val?.id} id={val?.id} className=' backWhite font-semibold text-sm'>
                                                                                <td className='whitespace-nowrap text-center'>{idx + 1}</td>
                                                                                <td className='whitespace-nowrap text-center'>{formatDate(val?.assigned_at)}</td>
                                                                                <td className='whitespace-nowrap text-center'>{val?.removed_at ? formatDate(val?.removed_at) : <div className=' text-green-800 bg-green-200 rounded-lg py-1 px-2'>On Going</div>}</td>
                                                                                <td className=' text-left'>{val?.User?.name}</td>
                                                                                <td className=' hover:text-blue-700 cursor-pointer'
                                                                                    onClick={() => {
                                                                                        setFilterData({
                                                                                            ...filterData,
                                                                                            company: val?.Position?.Company?.company_name,
                                                                                            position: val?.Position?.position,
                                                                                            fromDate: "",
                                                                                            toDate: "",
                                                                                            candidate: "",
                                                                                            orderBy: "assigned_at",
                                                                                            orderDirection: "DESC"
                                                                                        });
                                                                                        setRecruiterReportFilter({
                                                                                            ...recruiterReportFilter,
                                                                                            company: val?.Position?.Company?.company_name,
                                                                                            position: val?.Position?.position,
                                                                                            fromDate: "",
                                                                                            toDate: "",
                                                                                            orderBy: "upload_date",
                                                                                            orderDirection: "DESC"
                                                                                        });
                                                                                        setRecruiterWiseView(false);
                                                                                    }}
                                                                                >{val?.Position?.Company?.company_name}</td>
                                                                                <td className=' hover:text-blue-700 cursor-pointer'
                                                                                    onClick={() => {
                                                                                        setFilterData({
                                                                                            ...filterData,
                                                                                            company: val?.Position?.Company?.company_name,
                                                                                            position: val?.Position?.position,
                                                                                            fromDate: "",
                                                                                            toDate: "",
                                                                                            candidate: "",
                                                                                            orderBy: "assigned_at",
                                                                                            orderDirection: "DESC"
                                                                                        });
                                                                                        setRecruiterReportFilter({
                                                                                            ...recruiterReportFilter,
                                                                                            company: val?.Position?.Company?.company_name,
                                                                                            position: val?.Position?.position,
                                                                                            fromDate: "",
                                                                                            toDate: "",
                                                                                            orderBy: "upload_date",
                                                                                            orderDirection: "DESC"
                                                                                        });
                                                                                        setRecruiterWiseView(false);
                                                                                    }}>{val?.Position?.position}</td>
                                                                                <td className={` whitespace-nowrap ${val?.remarks?.length > 30 ? " textEllipse" : "text-center"}`}>{val?.remarks}</td>
                                                                            </tr>
                                                                            {/* : null} */}

                                                                        </>

                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table >
                                                </div>
                                                <div className=' flex items-center justify-between pb-4'>
                                                    <div className=' flex items-center gap-2'>
                                                        <div className='grayBlurShadow px-3 py-1'>
                                                            <select value={limitRecruiterWise} onChange={(e) => { setLimitRecruiterWise(e.target.value); }} >
                                                                <option value="10" selected>10</option>
                                                                <option value="20" >20</option>
                                                                <option value="50" >50</option>
                                                                <option value="100" >100</option>
                                                                <option value="200" >200</option>
                                                                <option value="500" >500</option>

                                                            </select>
                                                        </div>
                                                        <div className='grayBlurShadow px-1 py-1 text-sm font-semibold'>
                                                            <h1>Total Records: {totalRecordsRecruiterWise}</h1>
                                                        </div>
                                                        <div className='grayBlurShadow px-1 py-1  text-sm font-semibold'>
                                                            <h1>Total Pages: {allPagesRecruiterWise}</h1>
                                                        </div>
                                                    </div>
                                                    <div className='flex items-center grayBlurShadow px-3 py-1 rounded-lg'>
                                                        <div onClick={(e) => {
                                                            if (pageRecruiterWise > 1) {
                                                                setPageRecruiterWise(pageRecruiterWise - 1);
                                                            }
                                                        }} className={` ${pageRecruiterWise === 1 ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                                            <img className='w-5' src="/img/leftArrow.png" alt="leftArrow" />
                                                        </div>
                                                        <div className=' px-3'>
                                                            {pageRecruiterWise}
                                                        </div>
                                                        <div onClick={(e) => {
                                                            if (allPagesRecruiterWise > pageRecruiterWise) {
                                                                setPageRecruiterWise(pageRecruiterWise + 1);
                                                            }
                                                        }} className={` ${pageRecruiterWise == allPagesRecruiterWise ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                                            <img className='w-5' src="/img/rightArrow.png" alt="rightArrow" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> :
                                            <div className='navAnimation w-full flex flex-col justify-center items-center bg-white mt-5 py-5 mb-10 rounded-3xl '>
                                                <img className=' w-[25%]' src="/img/addReports.png" alt="" />
                                                <h1 className=' text-2xl font-bold'>Oops!! "<span className=' text-blue-950'>No Reports Found</span>"</h1>
                                            </div> :
                                        <div className=' h-[60vh] mb-5 w-full bg-white mt-5 rounded-3xl '>
                                            <img className=' m-auto w-1/4' src="/img/loader2.gif" alt="loading..." />
                                        </div>
                                }
                            </div>
                        </div>
                    </div> : null
                }
                {!loadingFilter ?
                    <>
                        <div className=' flex flex-wrap items-center gap-3 grayBlurShadow bg-white p-3 mt-5 rounded-xl'>
                            {/* <h1>Date Filter</h1> */}
                            <div className='flex items-center gap-3'>
                                <div className=' flex flex-col justify-start items-start'>
                                    <label>From: </label>
                                    <input
                                        className=' border-2 rounded-lg px-2 py-1'
                                        type="date"
                                        value={filterData?.fromDate}
                                        onChange={e => setFilterData({ ...filterData, fromDate: e.target.value })}
                                    />
                                </div>
                                <div className=' flex flex-col justify-start items-start'>
                                    <label>To: </label>
                                    <input
                                        className=' border-2 rounded-lg px-2 py-1'
                                        type="date"
                                        value={filterData?.toDate}
                                        onChange={e => setFilterData({ ...filterData, toDate: e.target.value })}
                                    />
                                </div>
                            </div>

                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Company</label>
                                <input
                                    value={filterData?.company}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => setFilterData({ ...filterData, company: e.target.value })}
                                    type="text" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Position</label>
                                <input
                                    value={filterData?.position}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => setFilterData({ ...filterData, position: e.target.value })}
                                    type="text" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Location</label>
                                <input
                                    value={filterData?.location}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => setFilterData({ ...filterData, location: e.target.value })}
                                    type="text" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Qualification</label>
                                <input
                                    value={filterData?.qualification}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => setFilterData({ ...filterData, qualification: e.target.value })}
                                    type="text" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Recruiter</label>
                                <select value={filterData?.recruiterId}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => setFilterData({ ...filterData, recruiterId: e.target.value })} >
                                    <option value="" selected disabled>Select Recruiter</option>
                                    <option value="Only Assigned">Assigned</option>
                                    <option value="Not Assigned">Not Assigned</option>
                                    {allUsers?.map((val) => {
                                        return (
                                            <option value={val?.id}>{val?.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Gender</label>
                                <select value={filterData?.gender}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => setFilterData({ ...filterData, gender: e.target.value })}>
                                    <option value="" selected disabled>Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="No preference">No preference</option>
                                </select>
                            </div>
                            <div className='flex flex-col justify-start items-start '>
                                <label >Position Status</label>
                                <select value={filterData?.positionStatus}
                                    className=' border-2 rounded-lg px-2 py-1 w-full'
                                    onChange={e => setFilterData({ ...filterData, positionStatus: e.target.value })}>
                                    <option value="All">All</option>
                                    <option value="Open">Open</option>
                                    <option value="Close">Close</option>
                                </select>
                            </div>
                            <button onClick={handleFilter} className=' darkPurple cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>Apply filter</button>
                            <button onClick={() => {
                                setFilterData({
                                    fromDate: "",
                                    toDate: "",
                                    company: "",
                                    position: "",
                                    location: "",
                                    gender: "",
                                    recruiterId: "",
                                    qualification: "",
                                    positionStatus: "Open",
                                })
                                getJobs();
                            }} className=' text-red-700 bg-red-200  py-2 px-3 rounded-lg hoverRed'>Clear FIlter</button>
                            <div className=' text-left w-fit'>
                                <button onClick={handleExport} className=' py-2 px-3 bg-green-800 text-white rounded-lg '>{loadingExport ? "Exporting..." : "Excel"}</button>
                            </div>
                            <div className=' text-left w-fit'>
                                <button onClick={(e) => {
                                    setRecruiterReportFilter({
                                        fromDate: "",
                                        toDate: "",
                                        company: "",
                                        position: "",
                                        orderBy: "assigned_at",
                                        recruiter: "",
                                        recruiterStatus: 1,
                                        orderDirection: "DESC",
                                        status: ""
                                    });
                                    setRecruiterWiseView(true);
                                    getRecruiterWiseData();
                                }} className=' py-2 px-3 bg-purple-700 hover:bg-purple-900 text-white rounded-lg '>Recruiter Details</button>
                            </div>
                        </div>
                    </> : null}

                {!loading ?
                    Array.isArray(allJobs) && allJobs?.length >= 1 ?
                        <section className='bg-white grayBlurShadow px-7 pt-7 rounded-3xl mb-8 mt-5'>
                            <div className=' overflow-auto tableBody tableBox text-left'>
                                <table className=' navAnimation rounded-xl baseWidth'>
                                    <thead className='  tableHead'>
                                        <tr className=' bg-gray-200'>
                                            <th className=' whitespace-nowrap text-center'>Sr.No.</th>
                                            <th className=' whitespace-nowrap text-center'>Posted Date</th>
                                            <th >Company</th>
                                            <th >Position</th>
                                            {/* <th>Qualification</th> */}
                                            <th >Location</th>
                                            {/* <th className=' text-center'>Min CTC</th> */}
                                            {/* <th className=' text-center'>Max CTC</th> */}
                                            <th>CTC</th>
                                            {/* <th className=' whitespace-nowrap'>No. of Positions</th> */}
                                            <th className=' whitespace-nowrap'>Gender Preference</th>
                                            <th className=' whitespace-nowrap text-center'>Recruiter Name</th>
                                            <th className=' whitespace-nowrap'>Assign Recruiter</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tableBody '>
                                        {
                                            Array.isArray(allJobs) && allJobs?.map((val, idx) => {

                                                return (
                                                    <tr key={val?.id} id={val?.id} className=' backWhite font-semibold text-sm'>
                                                        <td className=' text-center'>{idx + 1}</td>
                                                        <td className=' whitespace-nowrap text-center'>{val?.upload_date?.split("T")[0]?.split("-")[2]} {monthData[parseInt(val?.upload_date?.split("T")[0]?.split("-")[1])]} {val?.upload_date?.split("T")[0]?.split("-")[0]}</td>
                                                        <td className=' whitespace-nowrap' >{val?.Company?.company_name}</td>
                                                        <td className=' whitespace-nowrap' >{val?.position}</td>
                                                        {/* <td >{val?.qualification}</td> */}
                                                        <td >{val?.location}</td>
                                                        {/* <td className=' text-center'>{val?.min_ctc}</td> */}
                                                        <td className=' whitespace-nowrap'>{val?.max_ctc}</td>
                                                        {/* <td>{val?.no_of_positions}</td> */}
                                                        <td>{val?.gender_pref}</td>
                                                        <td>
                                                            {
                                                                val?.assignRecuiters?.length >= 1 ?
                                                                    <div className=' flex items-center gap-2 flex-grow-1'>
                                                                        {
                                                                            val?.assignRecuiters?.length >= 1 && val?.assignRecuiters
                                                                                ?.map((item) => {

                                                                                    return <h1 onClick={(e) => {
                                                                                        if (!loadingAssignRecruiter) {
                                                                                            setRecruiterName(item?.User?.name);
                                                                                            setPositionId(val?.id);
                                                                                            setRecruiterId(item?.recruiter_id);
                                                                                            setConfirmation(`${idx}`);
                                                                                        }
                                                                                    }} className=' p-2 rounded-lg greenBox w-full whitespace-nowrap text-center cursor-pointer hoverRed'>{item?.User?.name}</h1>
                                                                                })
                                                                        }
                                                                    </div> :
                                                                    <h1 className='text-center p-2 rounded-lg yellowBox w-full whitespace-nowrap '>
                                                                        No Recruiter Assigned
                                                                    </h1>
                                                            }
                                                        </td>
                                                        <td className=' text-center'>
                                                            <div className={`relative   `}>

                                                                <button onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    if (!loadingAssignRecruiter) {
                                                                        setIsUpdate("");
                                                                        setAssignRecruiter("");
                                                                        setRecruiterModal(val?.id)
                                                                    }
                                                                }} className='darkPurple text-white font-semibold rounded-xl px-4 text-sm py-1 hoverGreen'>Assign</button>
                                                                {
                                                                    recruiterModal === val?.id ?
                                                                        <div onClick={(e) => { e.stopPropagation(); }} className={`absolute grayBlurShadow top-[-0.7rem] left-[-13rem] z-20 bg-white rounded-lg p-2 flex items-center gap-3`}>
                                                                            <select onChange={(e) => { setIsUpdate(val?.id); setAssignRecruiter(e.target.value); }} className={`p-2 yellowBox `} >
                                                                                <option selected disabled>Select Recruiter</option>
                                                                                {allUsers?.map((val) => {
                                                                                    return (
                                                                                        <option value={val?.id}>{val?.name}</option>
                                                                                    )
                                                                                })}
                                                                            </select>
                                                                            {isUpdate === val?.id ?
                                                                                !loadingAssignRecruiter ?
                                                                                    <button onClick={(e) => { handleRecruiter(e, val?.id) }} className=' bg-green-200 text-green-600 rounded-xl px-3 py-1 font-semibold'>Save</button> :
                                                                                    <button className=' bg-green-200 text-green-600 rounded-xl px-3 py-1 font-semibold'>Loading...</button> :
                                                                                <button className=' bg-gray-200 text-gray-400 cursor-not-allowed rounded-xl px-3 py-1 font-semibold'>Save</button>

                                                                            }
                                                                        </div> : null
                                                                }
                                                            </div>
                                                        </td>
                                                        {/* <div className={`relative p-2 ${val?.recruiter_assign ? "greenBox" : "yellowBox"} `}>
                                                                <select value={val?.recruiter_assign} onChange={(e) => { setIsUpdate(val?.id); setAssignRecruiter(e.target.value); }} className=' bg-transparent outline-none' >
                                                                    <option selected disabled>Select Recruiter</option>
                                                                    {allUsers?.map((val) => {
                                                                        return (
                                                                            <option value={val?.id}>{val?.name}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                                <div>

                                                                </div>
                                                            </div> */}

                                                        {/* <td className=' flex justify-center items-center'>
                                                            {isUpdate === val?.id ?
                                                                <button onClick={(e) => { setIsUpdate(false); handleRecruiter(e, val?.id) }} className=' bg-green-200 text-green-600 rounded-xl px-3 py-1 font-semibold'>Save</button> :
                                                                <button className=' bg-gray-200 text-gray-400 cursor-not-allowed rounded-xl px-3 py-1 font-semibold'>Save</button>
                                                                // <div className=' relative w-fit'>
                                                                //     <div onClick={(e) => { e.stopPropagation(); setIsAction(val?.id); }} className=' flex flex-row gap-1 cursor-pointer w-fit p-2 rounded-lg hoverGreen'>
                                                                //         <div className=' w-2 h-2 darkPurple rounded-full '></div>
                                                                //         <div className=' w-2 h-2 darkPurple rounded-full '></div>
                                                                //         <div className=' w-2 h-2 darkPurple rounded-full '></div>
                                                                //     </div>
                                                                //     {isAction === val?.id ?
                                                                //         < div className={`absolute ${allJobs?.length - 2 <= idx && allJobs?.length > 2 ? "bottom-5 z-10 right-2" : "right-2 z-10 top-5"} w-40 h-fit p-3 rounded-xl border-2 bg-white grayBlurShadow`} >
                                                                //             <div className='flex flex-col'>
                                                                //                 <button onClick={(e) => { setViewModal(true); setIsAction(false); }} className=' darkPurple text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2 hoverGreen'>View</button>
                                                                //                 <button onClick={() => { handleEdit(idx); }} className=' text-yellow-600 bg-yellow-200 m-2 px-4 py-1 rounded-lg hoverYellow' >Edit</button>
                                                                //                 <button onClick={(e) => { }} className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed'>Delete</button>
                                                                //             </div>
                                                                //         </div> : null
                                                                //     }
                                                                // </div>
                                                            }
                                                        </td> */}
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className=' flex items-center justify-between py-4'>
                                <div className=' flex items-center gap-2'>
                                    <div className='grayBlurShadow px-3 py-1'>
                                        <select value={limit} onChange={(e) => { setLimit(e.target.value); }} >
                                            <option value="10" selected>10</option>
                                            <option value="20" >20</option>
                                            <option value="50" >50</option>
                                            <option value="100" >100</option>
                                            <option value="200" >200</option>
                                            <option value="500" >500</option>
                                            <option value="All" >All</option>
                                        </select>
                                    </div>
                                    <div className='grayBlurShadow px-1 py-1 text-sm font-semibold'>
                                        <h1>Total Records: {totalRecords}</h1>
                                    </div>
                                    <div className='grayBlurShadow px-1 py-1  text-sm font-semibold'>
                                        <h1>Total Pages: {allPages}</h1>
                                    </div>
                                </div>
                                <div className='flex items-center grayBlurShadow px-3 py-1 rounded-lg'>
                                    <div onClick={(e) => {
                                        if (page > 1) {
                                            setPage(page - 1);
                                        }
                                    }} className={` ${page === 1 ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                        <img className='w-5' src="/img/leftArrow.png" alt="leftArrow" />
                                    </div>
                                    <div className=' px-3'>
                                        {page}
                                    </div>
                                    <div onClick={(e) => {
                                        if (allPages > page) {
                                            setPage(page + 1);
                                        }
                                    }} className={` ${page == allPages ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                        <img className='w-5' src="/img/rightArrow.png" alt="rightArrow" />
                                    </div>
                                </div>
                            </div>
                        </section> :
                        <div className='navAnimation flex flex-col justify-center items-center bg-white mt-5 py-5 mb-10 rounded-3xl '>
                            <img className=' w-[24%]' src="/img/addReports.png" alt="" />
                            <h1 className=' text-2xl font-bold'>Oops!! "<span className=' text-blue-950'>No Reports Found</span>"</h1>
                        </div> :
                    <div className=' loaderBox mb-5 bg-white mt-5 rounded-3xl '>
                        <img className=' m-auto w-1/4' src="/img/loader2.gif" alt="loading..." />
                    </div>
                }

            </div>

        </section>
    );
}

export default AssignRecruiter;
import React, { useContext, useEffect, useState } from 'react';
import "./companyRegistartion.css";
import IndustryModal from './IndustryModal';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { debounce } from "lodash";
import { useNavigate, useParams } from 'react-router-dom';
import axios, { Axios } from 'axios';
import { AppContext } from '../../ContextApi/ContextApi';
import { basePath } from '../../Constants/constant';


function CompanyRegistration(props) {
    const { index } = useParams();
    const { industry, setIndustry, isModalOpen, setIsModalOpen } = useContext(AppContext);
    const navigate = useNavigate();
    const [industryModal, setIndustryModal] = useState(false);
    const [allRoles, setAllRoles] = useState([]);
    const [formPage, setFormPage] = useState("page1");
    const [loading, setLoading] = useState(false);
    const [loadingRegisterCompany, setLoadingRegisterCompany] = useState(false);
    const [formData, setFormData] = useState({
        // id:"",	
        company_name: "",
        summary: "",
        industry: "",
        address: "",
        poc1_name: "",
        poc1_designation: "",
        poc1_phone: "",
        poc1_email: "",
        poc2_name: "",
        poc2_designation: "",
        poc2_phone: "",
        poc2_email: "",
        company_username: "",
        company_password: "",
        role_id: "",
    });
    const getRoleData = async () => {
        let response = await axios.get(`${basePath}/getroles`);
        console.log(response.data);
        setAllRoles(response?.data);
    }
    useEffect(() => {
        getRoleData();
    }, []);
    const notify = () => toast.success('Company registered Successfully!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const notifyFillTheFields = () => toast.error('All Fields Required!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const handlePage1 = (e) => {
        e.preventDefault();
        if (formData?.company_name && formData?.summary &&
            formData?.industry && formData?.address) {
            setFormPage("page2")
        } else {
            notifyFillTheFields();
        }
    }
    const handlePage2 = (e) => {
        e.preventDefault();
        if (formData?.poc1_name && formData?.poc1_designation &&
            formData?.poc1_phone && formData?.poc1_email) {
            setFormPage("page3");
        } else {
            notifyFillTheFields();
        }
    }
    const handleCompanySubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        setLoadingRegisterCompany(true);
        setLoading(true);
        const options = {
            url: `${basePath}/companyregistration?recruiterId=${localStorage.getItem("userId")}`,
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            data: formData,
        }
        axios(options)
            .then((response) => {
                if (response.status == 200) {
                    setLoading(false);
                    notify();
                    setFormData({
                        company_name: "",
                        summary: "",
                        industry: "",
                        address: "",
                        poc1_name: "",
                        poc1_designation: "",
                        poc1_phone: "",
                        poc1_email: "",
                        poc2_name: "",
                        poc2_designation: "",
                        poc2_phone: "",
                        poc2_email: "",
                        company_username: "",
                        company_password: "",
                        role_id: "",
                    });
                    setIndustry("");
                    setFormPage("page1");
                    setLoadingRegisterCompany(false);
                    console.log("Information entered successfully");
                }
                else {
                    setLoadingRegisterCompany(false);
                    notifyFillTheFields();
                }
            })
            .catch((e) => {
                setLoadingRegisterCompany(false);
                console.log(e);
                notifyFillTheFields();
            });


    }
    console.log("formData===>");
    console.log(formData);

    useEffect(() => {
        if (industry) {
            setFormData({ ...formData, industry: industry });
        }
    }, [industry])
    useEffect(() => {
        if (!isModalOpen) {
            setIndustryModal(false);
        }
    }, [isModalOpen])
    return (
        <section onClick={() => { setIsModalOpen(false) }} className='lightBabyGreen baseHeightWidth customScroll '>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            <div className=' customScroll'>
                <div className='welcomeBarHeight babyGreen smoothTransition textDarkPurple px-10 rounded-3xl flex items-center justify-between navAnimation width80 m-auto'>
                    <div className=' text-left'>
                        <h1 className=' text-5xl font-bold'>Company Registration</h1>
                        <p className=' text-gray-500 font-semibold'>Ready to start your day</p>
                    </div>
                    <img className=' w-44' src="/img/registration.png" alt="registration" />
                </div>
                <div className=' w-full my-3 pr-4 flex items-center justify-end'>
                    <button onClick={() => { localStorage.getItem("role") === "Super Admin" ? navigate(`/main/company-details/${index}`) : navigate(`/company-details/${index}`); }} className=' text-left w-fit darkPurple hoverGreen text-white rounded-lg px-3 py-1'>Back</button>
                </div>
                <div className=' relative customScroll w-full h-screen'>
                    <div className=' width80 m-auto text-white font-semibold text-xl h-full navAnimation'>
                        <div className='darkPurple  leftBox py-16 px-5 h-full text-left rounded-3xl'>
                            <h1 className={`relative  py-4 ${formPage === "page1" ? "textBabyGreen" : "text-white"}  mb-3`}>Company Details
                            </h1>
                            <h1 className={`relative  py-4 ${formPage === "page2" ? "textBabyGreen" : "text-white"} pr-10  mb-3`}>Company Contact Persons Details
                            </h1>
                            <h1 className={`relative  py-4 ${formPage === "page3" ? "textBabyGreen" : "text-white"}  mb-3`}>Access Details
                            </h1>
                            <img className=' w-40 mt-4' src="/img/registration2.png" alt=" registration" />
                        </div>
                    </div>
                    <div className='customScroll absolute top-0 left-0 w-full h-full px-5 py-5 '>
                        <div className=' width80 m-auto h-full customScroll flex justify-end navAnimation pr-5'>
                            {formPage === "page1" ?
                                <form id='page1' className=' relative flex flex-col gap-5 h-full bg-white navAnimation grayBlurShadow rounded-xl py-4 px-14 registrationFormBox'>
                                    <div className=' cursor-pointer absolute top-12 pageIcon bg-white grayBlurShadow borderBabyGreen lightBabyGreen textDarkPurple px-5 py-3 font-bold  rounded-full z-10'>1</div>
                                    <div className=' cursor-pointer absolute  page2Top pageIcon  grayBlurShadow  bg-white navAnimation textDarkPurple px-5 py-3 font-bold   rounded-full z-10'>2</div>
                                    <div className=' cursor-pointer absolute top-56 pageIcon  grayBlurShadow  bg-white navAnimation textDarkPurple px-5 py-3 font-bold   rounded-full z-10'>3</div>
                                    <h1 className=' text-3xl font-bold babyGreen  rounded-xl py-3'>Company Registration</h1>
                                    <div className='relative flex flex-col gap-5 p-7 borderDarkBlue rounded-3xl'>
                                        <h1 className=' bg-white absolute top-15px font-semibold px-1 textDarkBlue'>Company Details</h1>
                                        <div className=' grid gridResponsive gap-3'>
                                            <div className='input-group relative'>
                                                <input value={formData?.company_name} onChange={(e) => { setFormData({ ...formData, company_name: e.target.value }) }} id='company' placeholder='Company Name' className=' w-full' required type="text" />
                                                <label htmlFor="company" className='label'>Company Name</label>
                                            </div>
                                            <div className='input-group relative'>
                                                <input onClick={(e) => { e.stopPropagation(); setIndustryModal(true); setIsModalOpen(true); }} value={industry} id='industry' placeholder='Industry' className=' w-full' required type="text" />
                                                <label htmlFor="industry" className='label'>Industry</label>
                                                {industryModal ?
                                                    <div className=' absolute top-3 z-10 left-0 px-3 w-full'>
                                                        <IndustryModal />
                                                    </div> : null}
                                            </div>
                                        </div>
                                        <div className='input-group relative'>
                                            <textarea value={formData?.summary} onChange={(e) => { setFormData({ ...formData, summary: e.target.value }) }} id='summary' cols="20" rows="3" placeholder='Summary...' required></textarea>
                                            <label htmlFor="summary" className='label'>Summary</label>
                                        </div>
                                        <div className='input-group relative'>
                                            <textarea value={formData?.address} onChange={(e) => { setFormData({ ...formData, address: e.target.value }) }} id='address' cols="20" rows="3" placeholder='Address...' required></textarea>
                                            <label htmlFor="address" className='label'>Address</label>
                                        </div>
                                    </div>
                                    <div className=' w-full text-right'>
                                        <button onClick={(e) => { handlePage1(e); }} className=' w-fit darkPurple text-white font-semibold rounded-xl px-7 text-sm py-2 hoverGreen'>Next</button>
                                    </div>
                                </form> :
                                formPage === "page2" ?
                                    <form id='page2' className=' relative flex flex-col gap-5 h-full bg-white navAnimation grayBlurShadow rounded-xl py-4 px-14 registrationFormBox'>
                                        <div className=' cursor-pointer absolute top-12 pageIcon bg-white grayBlurShadow  textDarkPurple px-5 py-3 font-bold  rounded-full z-10'>1</div>
                                        <div className=' cursor-pointer absolute  page2Top pageIcon  grayBlurShadow borderBabyGreen lightBabyGreen bg-white  textDarkPurple px-5 py-3 font-bold   rounded-full z-10'>2</div>
                                        <div className=' cursor-pointer absolute top-56 pageIcon  grayBlurShadow  bg-white  textDarkPurple px-5 py-3 font-bold   rounded-full z-10'>3</div>
                                        <h1 className=' text-4xl font-bold babyGreen  rounded-xl py-3'>Company Contact Persons Details</h1>
                                        <div className=' relative flex flex-col gap-2 p-7 borderDarkBlue rounded-3xl'>
                                            <h1 className=' bg-white absolute top-15px font-semibold px-1 textDarkBlue'>Person 1 Details</h1>
                                            <div className=' grid gridResponsive gap-3'>
                                                <div className='input-group relative'>
                                                    <input value={formData?.poc1_name} onChange={(e) => { setFormData({ ...formData, poc1_name: e.target.value }) }} id='poc1Name' placeholder='Name' className=' w-full' required type="text" />
                                                    <label htmlFor="poc1Name" className='label'>Name</label>
                                                </div>
                                                <div className='input-group relative'>
                                                    <input value={formData?.poc1_designation} onChange={(e) => { setFormData({ ...formData, poc1_designation: e.target.value }) }} id='poc1Designation' placeholder='Designation' className=' w-full' required type="text" />
                                                    <label htmlFor="poc1Designation" className='label'>Designation</label>
                                                </div>
                                            </div>
                                            <div className=' grid gridResponsive gap-3'>
                                                <div className='input-group relative'>
                                                    <input value={formData?.poc1_phone} onChange={(e) => { setFormData({ ...formData, poc1_phone: e.target.value }) }} id='poc1Phone' placeholder='Mobile' className=' w-full' required type="number" />
                                                    <label htmlFor="poc1Phone" className='label'>Mobile</label>
                                                </div>
                                                <div className='input-group relative'>
                                                    <input value={formData?.poc1_email} onChange={(e) => { setFormData({ ...formData, poc1_email: e.target.value }) }} id='poc1Email' placeholder='Email' className=' w-full' required type="email" />
                                                    <label htmlFor="poc1Email" className='label'>Email</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=' relative flex flex-col gap-2 p-7 borderDarkBlue rounded-3xl'>
                                            <h1 className=' bg-white absolute top-15px font-semibold px-1 textDarkBlue'>Person 2 Details</h1>
                                            <div className=' grid gridResponsive gap-3'>
                                                <div className='input-group relative'>
                                                    <input value={formData?.poc2_name} onChange={(e) => { setFormData({ ...formData, poc2_name: e.target.value }) }} id='poc2Name' placeholder='Name' className=' w-full' type="text" />
                                                    <label htmlFor="poc2Name" className='label'>Name</label>
                                                </div>
                                                <div className='input-group relative'>
                                                    <input value={formData?.poc2_designation} onChange={(e) => { setFormData({ ...formData, poc2_designation: e.target.value }) }} id='poc2Designation' placeholder='Designation' className=' w-full' type="text" />
                                                    <label htmlFor="poc2Designation" className='label'>Designation</label>
                                                </div>
                                            </div>
                                            <div className=' grid gridResponsive gap-3'>
                                                <div className='input-group relative'>
                                                    <input value={formData?.poc2_phone} onChange={(e) => { setFormData({ ...formData, poc2_phone: e.target.value }) }} id='poc2Phone' placeholder='Mobile' className=' w-full' type="number" />
                                                    <label htmlFor="poc2Phone" className='label'>Mobile</label>
                                                </div>
                                                <div className='input-group relative'>
                                                    <input value={formData?.poc2_email} onChange={(e) => { setFormData({ ...formData, poc2_email: e.target.value }) }} id='poc2Email' placeholder='Email' className=' w-full' type="email" />
                                                    <label htmlFor="poc2Email" className='label'>Email</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=' w-full text-right'>
                                            <button onClick={() => { setFormPage("page1") }} className=' w-fit darkPurple text-white font-semibold rounded-xl px-7 text-sm py-2 hoverGreen mr-3'>Back</button>
                                            <button onClick={(e) => { handlePage2(e); }} className=' w-fit darkPurple text-white font-semibold rounded-xl px-7 text-sm py-2 hoverGreen'>Next</button>
                                        </div>
                                    </form> :
                                    <form id='page3' onSubmit={handleCompanySubmit} className=' relative flex flex-col gap-8 h-full bg-white navAnimation grayBlurShadow rounded-xl py-4 px-14 registrationFormBox'>
                                        <div className=' cursor-pointer absolute top-12 pageIcon bg-white grayBlurShadow  textDarkPurple px-5 py-3 font-bold  rounded-full z-10'>1</div>
                                        <div className=' cursor-pointer absolute  page2Top pageIcon  grayBlurShadow  bg-white  textDarkPurple px-5 py-3 font-bold   rounded-full z-10'>2</div>
                                        <div className=' cursor-pointer absolute top-56 pageIcon  grayBlurShadow borderBabyGreen lightBabyGreen bg-white textDarkPurple px-5 py-3 font-bold   rounded-full z-10'>3</div>
                                        <h1 className=' text-4xl font-bold babyGreen  rounded-xl py-3'>Company Access Details</h1>
                                        <div className=' relative flex flex-col gap-5 p-7 borderDarkBlue rounded-3xl'>
                                            <h1 className=' bg-white absolute top-15px font-semibold px-1 textDarkBlue'>Access Details</h1>
                                            <div className='input-group relative'>
                                                <input value={formData?.company_username} onChange={(e) => { setFormData({ ...formData, company_username: e.target.value }) }} id='username' placeholder='Username' className=' w-full' required type="text" />
                                                <label htmlFor="username" className='label'>Username</label>
                                            </div>
                                            <div className='input-group relative'>
                                                <input value={formData?.company_password} onChange={(e) => { setFormData({ ...formData, company_password: e.target.value }) }} id='password' placeholder='Password' className=' w-full' required type="text" />
                                                <label htmlFor="password" className='label'>Password</label>
                                            </div>

                                            <div className='input-group relative'>
                                                <select onChange={(e) => { setFormData({ ...formData, role_id: parseInt(e.target.value) }) }} className=' w-full outline-none'>
                                                    <option value="" selected disabled>--Select Role--</option>
                                                    {allRoles?.map((val) => {
                                                        return (
                                                            val?.role_name === "Company" ? <option value={parseInt(val?.id)}>{val?.role_name}</option> : null
                                                        )
                                                    })}
                                                </select>
                                            </div>

                                        </div>
                                        {!loadingRegisterCompany ?
                                            <div className=' w-full text-right'>
                                                <button onClick={() => { setFormPage("page2") }} className=' w-fit darkPurple text-white font-semibold rounded-xl px-7 text-sm py-2 hoverGreen mr-3'>Back</button>
                                                <button type='submit' className=' w-fit darkPurple text-white font-semibold rounded-xl px-7 text-sm py-2 hoverGreen'>Submit</button>
                                            </div> :
                                            <div className=' w-full text-right'>
                                                <button className=' opacity-65 w-fit darkPurple text-white font-semibold rounded-xl px-7 text-sm py-2  mr-3'>Back</button>
                                                <button className=' opacity-65 w-fit darkPurple text-white font-semibold rounded-xl px-7 text-sm py-2 '>Loading...</button>
                                            </div>
                                        }
                                    </form>
                            }


                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CompanyRegistration;
import React, { useCallback, useState, useEffect } from 'react';
import { debounce } from "lodash";
import axios, { Axios } from 'axios';
import "./roleManager.css";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { basePath } from '../Constants/constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight, faXmark } from '@fortawesome/free-solid-svg-icons'


function RoleManager(props) {
    const navigate = useNavigate();
    const [roleData, setRoleData] = useState({
        id: "",
        role_name: ""
    });
    const [allRoles, setAllRoles] = useState([]);
    const [roleModal, setRoleModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isAction, setIsAction] = useState(false);
    const [editModal, setEditModal] = useState(false);


    const getRoleData = async () => {
        try {
            setLoading(true);
            let response = await axios.get(`${basePath}/getroles`);
            console.log(response.data);
            setAllRoles(response?.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    const notify = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const notifyFillTheFields = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    // const delayFunc = useCallback(debounce((value, field) => {
    //     if (field === "id") {
    //         setRoleData({ ...roleData, id: value });
    //     } else if (field === "role") {
    //         setRoleData({ ...roleData, role_name: value });
    //     }
    // }, 1000));
    console.log(roleData);
    const handleMasterRole = (e) => {
        e.preventDefault();
        let data = {
            role_name: roleData?.role_name
        }
        if (roleData.role_name) {
            setLoading(true);
            const options = {
                url: `${basePath}/role`,
                method: "POST",
                headers: {
                    Accept: "application/json",
                },
                data: data,
            }
            axios(options)
                .then((response) => {
                    if (response.status == 200) {
                        setLoading(false);
                        getRoleData();
                        document.getElementById("addRole").reset();
                        setRoleData({
                            id: "",
                            role_name: ""
                        })
                        setRoleModal(false);
                        notify("Role Created Successfully");
                        console.log("Information entered successfully");
                    }
                    else {
                        setLoading(false);
                        alert("Enter all information as they were asked");
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    alert("kindly fill the complete form ");
                });
        }
        else {
            console.log("fill fields 1");
            notifyFillTheFields();
        }
    }
    const checkAllFields = () => {
        if (!roleData.id && !roleData.role_name) {
            notifyFillTheFields();
        }
    }
    useEffect(() => {
        getRoleData();
    }, []);
    const handleUpdateRole = (e) => {
        e.preventDefault();
        let data = {
            role_name: roleData?.role_name,
        }
        if (roleData.role_name) {
            setLoading(true);
            const options = {
                url: `${basePath}/updaterole/${roleData?.id}`,
                method: "PUT",
                headers: {
                    Accept: "application/json",
                },
                data: data,
            }
            axios(options)
                .then((response) => {
                    if (response.status == 200) {
                        setLoading(false);
                        getRoleData();
                        // document.getElementById("updateModule").reset();
                        setEditModal(false);
                        setRoleData({
                            id: "",
                            role_name: ""
                        })
                        notify("Role Upadated Successfully");
                        console.log("Information Updated successfully");
                    }
                    else {
                        setLoading(false);
                        notifyFillTheFields("Unable to update");
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    notifyFillTheFields("Unable to update");
                });
        }
        else {
            notifyFillTheFields();
        }
    }
    const handleEdit = (idx) => {
        setRoleData({
            id: allRoles[idx]?.id,
            role_name: allRoles[idx]?.role_name,
        })
        setEditModal(true);
    }
    const handleDelete = (e, id) => {
        if (id) {
            setLoading(true);
            const options = {
                url: `${basePath}/deleterole/${id}`,
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                },
            }
            axios(options)
                .then((response) => {
                    if (response.status == 200) {
                        setLoading(false);
                        getRoleData();
                        notify("Role Deleted Successfully");
                        console.log("Information Deleted successfully");
                    }
                    else {
                        setLoading(false);
                        notifyFillTheFields("Unable to delete")
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    notifyFillTheFields("Unable to delete!! Role is already in used, Please first change the role and then try again.")
                });
        }
        else {
            notifyFillTheFields("All Fields Required!!");
        }
    }
    return (
        <section onClick={() => { setIsAction(false); }} className='lightBabyGreen relative w-full mb-10'>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            <div className=' fixed bottom-3 right-4'>
                <div onClick={getRoleData} className='cursor-pointer bg-red-600 px-2.5 py-2 rounded-full hoverRed'>
                    <FontAwesomeIcon icon={faRotateRight} size={"lg"} style={{ color: "#ffffff", }} />
                </div>
            </div>
            <div className=' m-auto '>
                <div className='welcomeBarHeight babyGreen smoothTransition textDarkPurple px-10 rounded-3xl  flex items-center justify-between navAnimation '>
                    <div className=' text-left'>
                        <h1 className=' text-5xl font-bold'>Role Master</h1>
                        <p className=' text-gray-500 font-semibold'>Ready to start your day</p>
                    </div>
                    <img className=' w-44' src="/img/master.png" alt="" />
                </div>
                <div className=' w-full my-2 pl-4 flex items-center justify-between'>
                    <div onClick={(e) => {
                        setRoleModal(true); setRoleData([
                            {
                                role_name: ""
                            },
                        ])
                    }} className='darkPurple w-fit my-2 cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>
                        <img className=' w-8 ' src="/img/addBtn.png" alt="" />
                        <button>Add New Roles</button>
                    </div>
                    <button onClick={() => { navigate("/main"); }} className=' text-left w-fit darkPurple hoverGreen text-white rounded-lg px-3 py-1 mr-3 '>Back</button>
                </div>

                <div className=''>
                    {roleModal ?
                        <div onClick={() => { setRoleModal(false); }} className=' w-full h-screen fixed left-0 top-0 flex items-center justify-center blurBack z-50'>
                            <form id='addRole' onClick={(e) => { e.stopPropagation(); }} onSubmit={handleMasterRole} className='relative flex justify-between flex-col gap-2 items-center navAnimation py-5 px-10 bg-white grayBlurShadow rounded-2xl'>
                                <button onClick={(e) => { setRoleModal(false); }} className="absolute bg-red-600 px-1.5 py-0.5    rounded-full w-fit top-1 right-1 z-[11] ">
                                    <FontAwesomeIcon icon={faXmark} size='xl' style={{ color: "#ffffff", }} />
                                </button>
                                <h1 className=' text-3xl font-semibold babyGreen w-full py-3 rounded-lg'>Add Role</h1>
                                <div className='input-group relative w-full '>
                                    <input value={roleData?.role_name} onChange={(e) => { setRoleData({ ...roleData, role_name: e.target.value }); }} autoComplete='off' id='role' placeholder='Role' className=' w-full' required type="text" />
                                    <label htmlFor="role" className='label'>Role</label>
                                </div>
                                <button type='submit' onClick={checkAllFields} className=' darkPurple text-white py-2 px-5 rounded-lg mt-5 hoverGreen font-semibold'>{!loading ? "Submit" : <img className=' h-10 ml-8 py-1' src="/img/loadingBlue.webp" alt="Loading..." />}</button>
                            </form>
                        </div>
                        : null}
                    {editModal ?
                        <div onClick={() => { setEditModal(false); }} className=' w-full h-screen fixed left-0 top-0 flex items-center justify-center blurBack z-50'>
                            <form id='addRole' onClick={(e) => { e.stopPropagation(); }} onSubmit={handleUpdateRole} className='relative flex justify-between flex-col gap-2 items-center navAnimation py-5 px-10 bg-white grayBlurShadow rounded-2xl'>
                                <button onClick={(e) => { setEditModal(false); }} className="absolute bg-red-600 px-1.5 py-0.5    rounded-full w-fit top-1 right-1 z-[11] ">
                                    <FontAwesomeIcon icon={faXmark} size='xl' style={{ color: "#ffffff", }} />
                                </button>
                                <h1 className=' text-3xl font-semibold babyGreen w-full py-3 rounded-lg'>Add Role</h1>
                                <div className='input-group relative w-full '>
                                    <input value={roleData?.role_name} onChange={(e) => { setRoleData({ ...roleData, role_name: e.target.value }); }} autoComplete='off' id='role' placeholder='Role' className=' w-full' required type="text" />
                                    <label htmlFor="role" className='label'>Role</label>
                                </div>
                                <button type='submit' onClick={checkAllFields} className=' darkPurple text-white py-2 px-5 rounded-lg mt-5 hoverGreen font-semibold'>{!loading ? "Submit" : <img className=' h-10 ml-8 py-1' src="/img/loadingBlue.webp" alt="Loading..." />}</button>
                            </form>
                        </div>
                        : null}
                    {
                        !loading ?
                            Array.isArray(allRoles) && allRoles?.length >= 1 ?
                                <div className=' grayBlurShadow rounded-3xl p-5 bg-white  overflow-hidden'>
                                    <div className='  overflow-y-scroll customScroll tableBox'>
                                        <table className=' w-full navAnimation '>
                                            <thead className=' bg-gray-300 rounded-lg text-xl headingRow z-10'>
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>ROLE</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody className=' font-semibold ' >
                                                {allRoles?.map((val, idx) => {
                                                    return (
                                                        <tr className=''>
                                                            <td>{idx + 1}</td>
                                                            <td>{val?.role_name}</td>
                                                            <td className=' flex justify-center items-center'>
                                                                <div className=' relative w-fit'>
                                                                    <div onClick={(e) => { e.stopPropagation(); setIsAction(val?.id); }} className=' flex flex-row gap-1 cursor-pointer w-fit p-2 rounded-lg hoverGreen'>
                                                                        <div className=' w-2 h-2 darkPurple rounded-full '></div>
                                                                        <div className=' w-2 h-2 darkPurple rounded-full '></div>
                                                                        <div className=' w-2 h-2 darkPurple rounded-full '></div>
                                                                    </div>
                                                                    {isAction === val?.id ?
                                                                        < div className={`absolute ${allRoles?.length - 2 <= idx && allRoles?.length > 2 ? "bottom-5 z-10 right-2" : "right-2 z-10 top-5"} w-40 h-fit p-3 rounded-xl border-2 bg-white grayBlurShadow`} >
                                                                            <div className='flex flex-col'>
                                                                                <button onClick={() => { handleEdit(idx); }} className=' text-yellow-600 bg-yellow-200 m-2 px-4 py-1 rounded-lg hoverYellow' >Edit</button>
                                                                                <button onClick={(e) => { handleDelete(e, val?.id) }} className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed'>Delete</button>
                                                                            </div>
                                                                        </div> : null
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                    </div>
                                </div> :
                                <div className='navAnimation flex flex-col justify-center items-center bg-white mx-4 py-5 mb-10 rounded-3xl '>
                                    <img className=' w-1/3' src="/img/addReports.png" alt="" />
                                    <h1 className=' text-2xl font-bold'>Oops!! "<span className=' text-blue-950'>No Reports Found Add New Reports</span>"</h1>
                                </div> :
                            <div className=' loaderBox mb-5 bg-white mt-5 rounded-3xl '>
                                <img className=' m-auto w-1/4' src="/img/loader2.gif" alt="loading..." />
                            </div>
                    }
                </div>
            </div>
        </section >
    );
}

export default RoleManager;
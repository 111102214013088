import React, { useContext, useEffect, useState } from 'react';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { basePath, formatDate, monthData } from '../Constants/constant';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight, faXmark } from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../ContextApi/ContextApi';
import { useParams } from 'react-router-dom';

function AdminReport(props) {
    const { index } = useParams();
    const { industry, setIndustry, isModalOpen, setIsModalOpen, user } = useContext(AppContext);
    const [allAdminReport, setAllAdminReport] = useState([]);
    const [allDailyAdminReport, setAllDailyAdminReport] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [allAdminFilteredReport, setAllAdminFilteredReport] = useState([]);
    const [adminReportDate, setAdminReportDate] = useState("");
    const [totalCvSent, setTotalCvSent] = useState(0);
    const [loadingExport, setLoadingExport] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingFilter, setLoadingFilter] = useState(true);
    const [loadingRecruiterFilter, setLoadingRecruiterFilter] = useState(true);
    const [adminView, setAdminView] = useState(false);
    const [recruiterIndex, setRecruiterIndex] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [allPages, setAllPages] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalRecruiterRecords, setTotalRecruiterRecords] = useState(0);
    const [allRecruiterPages, setAllRecruiterPages] = useState(0);
    const [pageRecruiter, setPageRecruiter] = useState(1);
    const [limitRecruiter, setLimitRecruiter] = useState(10);
    const [dateFilter, setDateFilter] = useState({
        fromDate: "",
        toDate: "",
    });
    const [recruiterFilter, setAdminReportFilter] = useState({
        fromDate: "",
        toDate: "",
        company: "",
        position: "",
        recruiter: "",
        orderBy: "upload_date",
        orderDirection: "DESC"
    });

    const notify = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const notifyFillTheFields = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });

    // async function getSourcingReport() {
    //     let res = await axios.get("http://127.0.0.1:3002/getsourcingreport");
    //     console.log("===>>>>> 1");
    //     console.log(res.data);
    //     setAllSourceReport(res.data.Candidates);
    // }
    async function getAdminReport() {
        try {
            setIsLoading(true);
            let res = await axios.get(`${basePath}/getadminreportbypage?limit=${limit}&page=${page}`);
            console.log("===>>>>> 1", res);
            console.log(res.data.Candidates);
            setAllAdminReport(res.data.Candidates);
            setTotalRecords(res?.data?.totalRecords);
            setAllPages(res?.data?.pages);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    async function handleDateFilter() {
        try {
            setIsLoading(true);
            let res = await axios.get(`${basePath}/getadminreportbypage?limit=${limit}&page=${page}&filter=${JSON.stringify(dateFilter)}`);
            console.log("handleDateFilter===>", res.data);
            setAllAdminReport(res.data.Candidates);
            setTotalRecords(res?.data?.totalRecords);
            setAllPages(res?.data?.pages);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }
    async function handleDailyAdminReportFilter(date) {
        try {
            setLoadingRecruiterFilter(true);
            console.log("date",date);
            let res = await axios.get(`${basePath}/getadminreportbydate?limit=${limitRecruiter}&page=${pageRecruiter}&date=${date}&filter=${JSON.stringify(recruiterFilter)}`);
            console.log("handleDailyAdminReportFilter===>", res.data?.report);
            let total = 0;
            res?.data?.report?.forEach((val) => {
                total = total + parseInt(val?.candidate_count);
            });
            console.log(total);
            setTotalCvSent(total);
            setAllDailyAdminReport(res.data?.report);
            setTotalRecruiterRecords(res?.data?.totalRecords);
            setAllRecruiterPages(res?.data?.pages);
            setLoadingRecruiterFilter(false);
        } catch (error) {
            console.log(error);
            setLoadingRecruiterFilter(false);
        }
    }
    console.log("totalCvSent", totalCvSent);
    const getUserData = async () => {
        let response = await axios.get(`${basePath}/getRecruiter`);
        console.log(response.data);
        setAllUsers(response?.data);
    }
    const handleExport = async () => {
        try {
            setLoadingExport(true);
            const response = await axios.get(`${basePath}/getadminreportbypage?download=yes&filter=${JSON.stringify(dateFilter)}`, {
                responseType: 'blob', // Ensure response is treated as a blob
            });

            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a temporary <a> element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Admin_Report_${formatDate(new Date())}.xlsx`);
            document.body.appendChild(link);
            link.click();

            // Clean up resources
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            setLoadingExport(false);
        } catch (error) {
            console.error('Error exporting jobs:', error);
            setLoadingExport(false);
        }
    };
    const handleDailyAdminReportExport = async () => {
        try {
            setLoadingExport(true);
            console.log("adminReportDate",adminReportDate)
            const response = await axios.get(`${basePath}/getadminreportbydate?download=yes&date=${adminReportDate}&filter=${JSON.stringify(recruiterFilter)}`, {
                responseType: 'blob', // Ensure response is treated as a blob
            });
            console.log("response.data",response);
            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a temporary <a> element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Daily_Admin_Report_${formatDate(new Date())}.xlsx`);
            document.body.appendChild(link);
            link.click();

            // Clean up resources
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            setLoadingExport(false);
        } catch (error) {
            console.error('Error exporting jobs:', error);
            setLoadingExport(false);
        }
        // try {
        //     const response = await fetch(`${basePath}/getadminreportbydate?filter=${JSON.stringify(recruiterFilter)}&download=yes&date=2024-05-22`);
        //  console.log("=======>>>>",response);
        //     // Check if the response is okay
        //     if (!response.ok) {
        //       throw new Error('Network response was not ok');
        //     }
        
        //     const blob = await response.blob();
        //     const url = window.URL.createObjectURL(blob);
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.setAttribute('download', `Daily_Admin_Report_${formatDate(new Date())}.xlsx`); // Filename for the downloaded file
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        
        //     // Clean up the URL object
        //     window.URL.revokeObjectURL(url);
        //   } catch (error) {
        //     console.error('Error exporting jobs:', error);
        //   }
    };
    async function getAdminFitleredReport() {
        try {
            setIsLoading(true);
            let res = await axios.get(`${basePath}/filteredadmin`);
            console.log("===>>>>> 1");
            console.log(res.data);
            setAllAdminFilteredReport(res.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    useEffect(() => {
        if (
            !loadingFilter &&
            !dateFilter?.fromDate &&
            !dateFilter?.toDate
        ) {
            getAdminReport();
        }
        else if (!loadingFilter) {
            handleDateFilter();
        }
    }, [limit, page, dateFilter]);

    useEffect(() => {
        if(adminReportDate){
            handleDailyAdminReportFilter(adminReportDate);
        }
    }, [limitRecruiter, pageRecruiter, recruiterFilter])

    useEffect(() => {
        setLoadingFilter(true);
        getUserData();
        getAdminReport();
        getAdminFitleredReport();
        setLoadingFilter(false);
    }, [])

    const handleReportView = (e, date) => {
        let result = allAdminReport?.filter((val) => {
            if (val?.date === date) {
                return val;
            }
        });
        setAllAdminReport(result);
        setAdminReportDate(date);
    }
    return (
        <section className='  w-full'>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            <div className=' fixed bottom-3 right-4'>
                <div onClick={(e) => {
                    if (
                        !loadingFilter &&
                        !dateFilter?.fromDate &&
                        !dateFilter?.toDate
                    ) {
                        getAdminReport();
                    }
                    else if (!loadingFilter) {
                        handleDateFilter();
                    }
                }} className='cursor-pointer bg-red-600 px-2.5 py-2 rounded-full hoverRed'>
                    <FontAwesomeIcon icon={faRotateRight} size={"lg"} style={{ color: "#ffffff", }} />
                </div>
            </div>
            <div>
                <div className='welcomeBarHeight babyGreen smoothTransition textDarkPurple px-10 rounded-3xl  flex items-center justify-between navAnimation '>
                    <div className=' text-left'>
                        <h1 className=' text-5xl font-bold'>Daily Admin Report</h1>
                        <p className=' text-gray-500 font-semibold'>Ready to start your day</p>
                    </div>
                    <img className=' w-44' src="/img/master.png" alt="" />
                </div>
                {
                    adminReportDate ?
                        <div onClick={(e) => { setAdminReportDate(false); }} className='w-full h-screen  fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                            <div onClick={(e) => { e.stopPropagation(); }} className='relative h-[95vh] w-[95%]  lightBabyGreen rounded-2xl mx-10 px-2 py-4 '>
                                <button onClick={(e) => {
                                    setAdminReportDate(false);
                                    setAdminReportFilter({
                                        company: "",
                                        position: "",
                                        orderBy: "sourcing_date",
                                        recruiter: "",
                                        orderDirection: "DESC",
                                    });
                                }} className="absolute bg-red-600 px-1.5 py-0.5    rounded-full w-fit top-1 right-1 z-[11] ">
                                    <FontAwesomeIcon icon={faXmark} size='xl' style={{ color: "#ffffff", }} />
                                </button>
                                <div className=' absolute bottom-3 right-4'>
                                    <div onClick={(e) => { handleDailyAdminReportFilter(adminReportDate); }} className='cursor-pointer bg-red-600 px-2.5 py-2 rounded-full hoverRed'>
                                        <FontAwesomeIcon icon={faRotateRight} size={"lg"} style={{ color: "#ffffff", }} />
                                    </div>
                                </div>
                                <div className='h-full w-full  rounded-2xl overflow-y-scroll noScrollbar px-8'>
                                    {/* filter */}
                                    <div className=' flex flex-wrap items-center gap-3 grayBlurShadow bg-white p-3 mb-5 rounded-xl'>
                                        {/* <div className='flex items-center gap-3'>
                                            <div className=' flex flex-col justify-start items-start'>
                                                <label>From: </label>
                                                <input
                                                    className=' border-2 rounded-lg px-2 py-1'
                                                    type="date"
                                                    value={recruiterFilter?.fromDate}
                                                    onChange={e => { setAdminReportFilter({ ...recruiterFilter, fromDate: e.target.value }) }}
                                                />
                                            </div>
                                            <div className=' flex flex-col justify-start items-start'>
                                                <label>To: </label>
                                                <input
                                                    className=' border-2 rounded-lg px-2 py-1'
                                                    type="date"
                                                    value={recruiterFilter?.toDate}
                                                    onChange={e => { setAdminReportFilter({ ...recruiterFilter, toDate: e.target.value }) }}
                                                />
                                            </div>
                                        </div> */}
                                        <div className='flex flex-col justify-start items-start '>
                                            <label >Recruiter</label>
                                            <select value={recruiterFilter?.recruiter}
                                                className=' border-2 rounded-lg px-2 py-1'
                                                onChange={e => { setAdminReportFilter({ ...recruiterFilter, recruiter: e.target.value }); }} >
                                                <option value="" selected disabled>Select Recruiter</option>
                                                {allUsers?.map((val) => {
                                                    return (
                                                        <option value={val?.id}>{val?.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className='flex flex-col justify-start items-start '>
                                            <label >Company</label>
                                            <input
                                                value={recruiterFilter?.company}
                                                className=' border-2 rounded-lg px-2 py-1'
                                                onChange={e => { setAdminReportFilter({ ...recruiterFilter, company: e.target.value }) }}
                                                type="text" />
                                        </div>
                                        <div className='flex flex-col justify-start items-start '>
                                            <label >Position</label>
                                            <input
                                                value={recruiterFilter?.position}
                                                className=' border-2 rounded-lg px-2 py-1'
                                                onChange={e => { setAdminReportFilter({ ...recruiterFilter, position: e.target.value }) }}
                                                type="text" />
                                        </div>
                                        <button onClick={() => { handleDailyAdminReportFilter(adminReportDate) }} className=' darkPurple cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>Apply filter</button>
                                        <button onClick={() => {
                                            setAdminReportFilter({
                                                company: "",
                                                position: "",
                                                orderBy: "sourcing_date",
                                                recruiter: "",
                                                orderDirection: "DESC",

                                            });
                                            // setDateFilter({
                                            //     fromDate: "",
                                            //     toDate: "",
                                            // });

                                        }} className=' text-red-700 bg-red-200  py-2 px-3 rounded-lg hoverRed'>Clear Filter</button>
                                        <div className=' text-left w-fit'>
                                            <button onClick={handleDailyAdminReportExport} className=' py-2 px-3 bg-green-800 text-white rounded-lg '>{loadingExport ? "Exporting..." : "Excel"}</button>
                                        </div>
                                    </div>
                                    {Array.isArray(allDailyAdminReport) && allDailyAdminReport?.length >= 1 ?
                                        <div className=' bg-white h-[80%] px-3 rounded-2xl'>
                                            <div className=' relative h-[90%] overflow-auto tableBody tableBox'>
                                                <div className=' text-left font-semibold py-3 flex justify-between items-center w-[93%]'>
                                                    <h1>Date: <span className=' text-blue-900'>{formatDate(adminReportDate)}</span> </h1>
                                                    {/* <h1>Total CV Sent: <span className=' text-blue-900'>{totalCvSent}</span></h1> */}
                                                </div>
                                                <table className=' navAnimation rounded-2xl baseWidth'>
                                                    <thead className=' text-lg tableHead text-left'>
                                                        <tr className=' bg-gray-200'>
                                                            <th className=' text-center'>Sr.No.</th>
                                                            <th>Recruiter</th>
                                                            <th>Company</th>
                                                            <th>Position</th>
                                                            <th className=' text-center'>No. Of CV's Sent</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='  tableBody text-left'>
                                                        {
                                                            Array.isArray(allDailyAdminReport) && allDailyAdminReport?.map((val, idx) => {
                                                                return (
                                                                    <>
                                                                        <tr key={val?.id} id={val?.id} className=' backWhite font-semibold text-sm'>
                                                                            <td className=' text-center'>{idx + 1}</td>
                                                                            <td>{val?.recruiter_name}</td>
                                                                            <td>{val?.company_name}</td>
                                                                            <td className=' whitespace-nowrap'>{val?.position_name}</td>
                                                                            <td className=' text-center'>{val?.candidate_count}</td>
                                                                        </tr>

                                                                    </>

                                                                )
                                                            })
                                                        }
                                                        <tr className='  rounded-xl bg-gray-200 font-semibold text-sm'>
                                                            <td className=' text-center'>Total</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td className=' whitespace-nowrap'></td>
                                                            <td className=' text-center'>{totalCvSent}</td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className=' bg-white flex items-center justify-between pb-4'>
                                                <div className=' flex items-center gap-2'>
                                                    <div className='grayBlurShadow px-3 py-1'>
                                                        <select value={limitRecruiter} onChange={(e) => { setLimitRecruiter(e.target.value); }} >
                                                            <option value="10" selected>10</option>
                                                            <option value="20" >20</option>
                                                            <option value="50" >50</option>
                                                            <option value="100" >100</option>
                                                            <option value="200" >200</option>
                                                            <option value="500" >500</option>

                                                        </select>
                                                    </div>
                                                    <div className='grayBlurShadow px-1 py-1 text-sm font-semibold'>
                                                        <h1>Total Records: {totalRecruiterRecords}</h1>
                                                    </div>
                                                    <div className='grayBlurShadow px-1 py-1  text-sm font-semibold'>
                                                        <h1>Total Pages: {allRecruiterPages}</h1>
                                                    </div>
                                                </div>
                                                <div className='flex items-center grayBlurShadow px-3 py-1 rounded-lg'>
                                                    <div onClick={(e) => {
                                                        if (pageRecruiter > 1) {
                                                            setPageRecruiter(pageRecruiter - 1);
                                                        }
                                                    }} className={` ${pageRecruiter === 1 ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                                        <img className='w-5' src="/img/leftArrow.png" alt="leftArrow" />
                                                    </div>
                                                    <div className=' px-3'>
                                                        {pageRecruiter}
                                                    </div>
                                                    <div onClick={(e) => {
                                                        if (allRecruiterPages > pageRecruiter) {
                                                            setPageRecruiter(pageRecruiter + 1);
                                                        }
                                                    }} className={` ${pageRecruiter == allRecruiterPages ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                                        <img className='w-5' src="/img/rightArrow.png" alt="rightArrow" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className='navAnimation flex flex-col justify-center items-center bg-white py-5 mt-5 mb-10 rounded-3xl '>
                                            <img className=' w-[30%]' src="/img/addReports.png" alt="" />
                                            <h1 className=' text-2xl font-bold'>"Opps! <span className=' text-blue-950'>CV not found</span>"</h1>
                                        </div>}
                                </div>
                            </div>
                        </div> : null
                }
                {!loadingFilter ?
                    <div className=' flex flex-wrap items-center gap-3 grayBlurShadow bg-white p-3 mt-5 rounded-xl'>
                        <div className='flex items-center gap-3'>
                            <div className=' flex flex-col justify-start items-start'>
                                <label>From: </label>
                                <input
                                    className=' border-2 rounded-lg px-2 py-1'
                                    type="date"
                                    value={dateFilter?.fromDate}
                                    onChange={e => setDateFilter({ ...dateFilter, fromDate: e.target.value })}
                                />
                            </div>
                            <div className=' flex flex-col justify-start items-start'>
                                <label>To: </label>
                                <input
                                    className=' border-2 rounded-lg px-2 py-1'
                                    type="date"
                                    value={dateFilter?.toDate}
                                    onChange={e => setDateFilter({ ...dateFilter, toDate: e.target.value })}
                                />
                            </div>
                        </div>
                        <button onClick={handleDateFilter} className=' darkPurple cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>Apply filter</button>
                        <button onClick={() => {
                            setDateFilter({
                                fromDate: "",
                                toDate: "",
                            })
                            getAdminReport();
                        }} className=' text-red-700 bg-red-200  py-2 px-3 rounded-lg hoverRed'>Clear Filter</button>
                        <div className=' text-left w-fit'>
                            <button onClick={handleExport} className=' py-2 px-3 bg-green-800 text-white rounded-lg '>{loadingExport ? "Exporting..." : "Excel"}</button>
                        </div>
                    </div> : null}
                {
                    !isLoading ?
                        Array.isArray(allAdminReport) && allAdminReport?.length >= 1 ?
                            <>
                                <section className='bg-white grayBlurShadow p-7 rounded-3xl mb-8 mt-5 '>
                                    <div className=' overflow-auto tableBody tableBox'>

                                        <table className=' navAnimation rounded-2xl baseWidth'>
                                            <thead className=' text-lg tableHead headingRow'>
                                                <tr className=' bg-gray-200'>
                                                    <th >Sr.No.</th>
                                                    <th>Date</th>
                                                    <th className=' whitespace-nowrap'>No. Of CV's Sent</th>
                                                    {user[index]?.view_access ?
                                                        <th key={"report"}>Report</th> : null}
                                                </tr>
                                            </thead>
                                            <tbody className='tableBody  '>
                                                {
                                                    Array.isArray(allAdminReport) && allAdminReport?.map((val, idx) => {
                                                        return (
                                                            <tr key={val?.id} id={val?.id} className=' backWhite font-semibold text-sm'>
                                                                <td>{idx + 1}</td>
                                                                <td>{val?.date.split("-")[2]} {monthData[parseInt(val?.date.split("-")[1])]} {val?.date.split("-")[0]}</td>
                                                                <td>{val?.sentToClientCount}</td>
                                                                {user[index]?.view_access ?
                                                                    <td>
                                                                        <button
                                                                            className=' darkPurple text-white font-semibold rounded-xl px-4 text-sm py-1 hoverGreen'
                                                                            onClick={(e) => { setAdminReportDate(val?.date); setLimitRecruiter("10"); setPageRecruiter(1); handleDailyAdminReportFilter(val?.date) }}>
                                                                            View</button>
                                                                    </td> : null}
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className=' flex items-center justify-between py-4'>
                                        <div className=' flex items-center gap-2'>
                                            <div className='grayBlurShadow px-3 py-1'>
                                                <select value={limit} onChange={(e) => { setLimit(e.target.value); }} >
                                                    <option value="10" selected>10</option>
                                                    <option value="20" >20</option>
                                                    <option value="50" >50</option>
                                                    <option value="100" >100</option>
                                                    <option value="200" >200</option>
                                                    <option value="500" >500</option>
                                                    <option value="All" >All</option>
                                                </select>
                                            </div>
                                            <div className='grayBlurShadow px-1 py-1 text-sm font-semibold'>
                                                <h1>Total Records: {totalRecords}</h1>
                                            </div>
                                            <div className='grayBlurShadow px-1 py-1  text-sm font-semibold'>
                                                <h1>Total Pages: {allPages}</h1>
                                            </div>
                                        </div>
                                        <div className='flex items-center grayBlurShadow px-3 py-1 rounded-lg'>
                                            <div onClick={(e) => {
                                                if (page > 1) {
                                                    setPage(page - 1);
                                                }
                                            }} className={` ${page === 1 ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                                <img className='w-5' src="/img/leftArrow.png" alt="leftArrow" />
                                            </div>
                                            <div className=' px-3'>
                                                {page}
                                            </div>
                                            <div onClick={(e) => {
                                                if (allPages > page) {
                                                    setPage(page + 1);
                                                }
                                            }} className={` ${page == allPages ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                                <img className='w-5' src="/img/rightArrow.png" alt="rightArrow" />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </> :
                            <div className='navAnimation flex flex-col justify-center items-center bg-white py-5 mt-5 mb-10 rounded-3xl '>
                                <img className=' w-[27%]' src="/img/addReports.png" alt="" />
                                <h1 className=' text-2xl font-bold'>Oops!! "<span className=' text-blue-950'>No Reports Found</span>"</h1>
                            </div> :
                        <div className=' loaderBox mb-5 bg-white mt-5 rounded-3xl '>
                            <img className=' m-auto w-1/4' src="/img/loader2.gif" alt="loading..." />
                        </div>
                }
            </div>

        </section>
    );
}

export default AdminReport;
import React, { useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import "./atsPipeline.css";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { AppContext } from '../ContextApi/ContextApi';
import { debounce } from "lodash";
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { atsStatusFinalJoining, atsStatusFinalSelection, atsStatusList, atsStatusSentToClient, atsStatusShortlisted, basePath, countryPhoneCode, monthData } from '../Constants/constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useLocation, useParams } from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function AtsPipeline(props) {
    const { index } = useParams();
    const query = useQuery();
    const statusAts = query.get("status");
    // console.log("=====>>>statusAts",statusAts);
    const { industry, setIndustry, isModalOpen, setIsModalOpen } = useContext(AppContext);
    const [allUsers, setAllUsers] = useState([]);
    const [atsPipeline, setAtsPipeline] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isRemarks, setIsRemarks] = useState("");
    const [status, setStatus] = useState(false);
    const [isAction, setIsAction] = useState(false);
    const [ctcType, setCtcType] = useState("");
    const [confirmation, setConfirmation] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingFilter, setLoadingFilter] = useState(true);
    const [loadingExport, setLoadingExport] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [loadingInterviewSchedule, setLoadingInterviewSchedule] = useState(false);
    const [candidateEdit, setCandidateEdit] = useState(false);
    const [positionWiseView, setPositionWiseView] = useState(false);
    const [positionWiseData, setPositionWiseData] = useState([]);
    const [viewCandidate, setViewCandidate] = useState(false);
    const [viewCandidateHistory, setViewCandidateHistory] = useState(false);
    const [candidate, setCandidate] = useState({
        candidate_email: "",
        candidate_phone: "",
        candidate_alt_phone: "",
        candidate_location: "",
        candidate_experience: "",
        candidate_current_ctc: "",
        candidate_expected_ctc: "",
        candidate_organization: "",
        candidate_designation: "",
        candidate_notice_period: "",
        candidate_qualification: "",
        candidate_gender: "",
        candidate_remarks: "",
        candidate_resume: "",
    });
    const [filterData, setFilterData] = useState({
        fromDate: "",
        toDate: "",
        status: statusAts ? statusAts : "",
        company: "",
        position: "",
        candidate: "",
        location: "",
        mobile: "",
        email: "",
        recruiter: "",
        positionStatus: "Open",
        orderBy: "sent_to_client_date",
        orderDirection: "DESC"
    });
    const [positionFilter, setPositionFilter] = useState({
        fromDate: "",
        toDate: "",
        company: "",
        position: "",
        recruiter: "",
        orderBy: "upload_date",
        orderDirection: "DESC"
    });
    const [interviewScheduleModal, setInterviewScheduleModal] = useState(false);
    const [interviewScheduleData, setInterviewScheduleData] = useState({
        scheduled_date: new Date().toISOString().split("T")[0],
        candidate_id: "",
        interview_round: "1",
        interview_mode: "",
        interview_date: "",
        interview_time: "",
        interview_location: "",
        interview_status: "Interview Scheduled",
        interview_remarks: "",
        interview_done: "In Progress",
    });
    const [isClearFilter, setIsClearFilter] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [allPages, setAllPages] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalRecordsPositionWise, setTotalRecordsPositionWise] = useState(0);
    const [allPagesPositionWise, setAllPagesPositionWise] = useState(0);
    const [pagePositionWise, setPagePositionWise] = useState(1);
    const [limitPositionWise, setLimitPositionWise] = useState(10);
    const [countryPhone, setCountryPhone] = useState("+91");
    const [countryPhoneLength, setCountryPhoneLength] = useState(10);
    const [candidateId, setCandidateId] = useState("");
    const containerStyle = useMemo(() => ({ width: "100%", height: "82.5vh", textAlign: "center" }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const [rowData, setRowData] = useState();
    const [columnDefs, setColumnDefs] = useState([
        { headerName: "Candidate", field: "candidate", headerClass: "tableHeaderStyle", cellClass: "tableCellsStyle" },
        { headerName: "Company", field: "company", headerClass: "tableHeaderStyle", cellClass: "tableCellsStyle" },
        { headerName: "Position", field: "position", headerClass: "tableHeaderStyle", cellClass: "tableCellsStyle" },
        { headerName: "Location", field: "location", headerClass: "tableHeaderStyle", cellClass: "tableCellsStyle" },
        { headerName: "CTC", field: "ctc", headerClass: "tableHeaderStyle", cellClass: "tableCellsStyle" },
        {
            headerName: "Current Status",
            field: "candidateStatus",
            editable: true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ["Sent To Client", "Shortlisted", "Interview Done", "Selected", "Rejected", "Backout"],
            },
            headerClass: "tableHeaderStyle"
        },
        { headerName: "Date", field: "date", headerClass: "tableHeaderStyle" },
        { headerName: "Status History", field: "button", cellRenderer: CustomButtonComponent, headerClass: "tableHeaderStyle" },

    ]);
    function CustomButtonComponent(props) {
        return <button onClick={(e) => window.alert(`${e.target.id}`)} className=' darkPurple text-white font-semibold rounded-xl px-4 text-sm py-1 hoverGreen'>View</button>;
    };

    const notify = (msg = "successfull!!") => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const notifyInfo = (msg) => toast.info(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const notifyFillTheFields = (msg = "There some issue!!") => toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });

    // fetching ATS pipeline data
    async function fetchData() {
        try {
            setLoading(true);
            let res = await axios.get(`${basePath}/getatspipelinebypage?limit=${limit}&page=${page}&id=${localStorage.getItem("userId")}`);
            // console.log("=====>>>>>>", res.data);
            setAtsPipeline(res.data.Candidates);
            setTotalRecords(res?.data?.totalRecords);
            setAllPages(res?.data?.pages);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
        // setRowData(sent);
    }
    async function fetchFilterData() {
        try {
            setLoading(true);
            let res = await axios.get(`${basePath}/getatspipelinebypage?limit=${limit}&page=${page}&id=${localStorage.getItem("userId")}&filter=${JSON.stringify(filterData)}`);
            // console.log(res.data);
            setAtsPipeline(res.data.Candidates);
            setTotalRecords(res?.data?.totalRecords);
            setAllPages(res?.data?.pages);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
        // setRowData(sent);
    }
    const handleAtsSort = (columnName) => {
        if (filterData?.orderBy === columnName) {
            if (filterData?.orderDirection === "DESC") {
                setFilterData({ ...filterData, orderBy: columnName, orderDirection: 'ASC' });
            }
            else {
                setFilterData({ ...filterData, orderBy: columnName, orderDirection: 'DESC' });
            }
        }
        else {
            setFilterData({ ...filterData, orderBy: columnName, orderDirection: 'ASC' });
        }
    }
    async function getPositionWiseData() {
        try {
            setLoading(true);
            let res = await axios.get(`${basePath}/getpositionwisecandidatecount?limit=${limitPositionWise}&page=${pagePositionWise}&id=${localStorage.getItem("userId")}&filter=${JSON.stringify(positionFilter)}`);
            let temp = res.data?.report?.filter((val) => {
                if (val?.candidate_count) {
                    return val;
                }
            });
            // console.log("+++++>>>>>getPositionWiseData ~ temp", temp);
            setPositionWiseData(temp);
            setTotalRecordsPositionWise(temp?.length);
            let pages = temp?.length / 10;
            pages = Math.ceil(pages);
            setAllPagesPositionWise(pages);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
        // setRowData(sent);
    }
    useEffect(() => {
        // console.log("positionFilter =>", positionFilter);
        getPositionWiseData();
    }, [limitPositionWise, pagePositionWise, positionFilter]);
    const getUserData = async () => {
        try {
            let response = await axios.get(`${basePath}/getRecruiter`);
            // console.log(response.data);
            setAllUsers(response?.data);
        } catch (error) {
            notifyFillTheFields("There is some server issue!!")
        }
    }
    const handleExport = async () => {
        try {
            setLoadingExport(true);
            const response = await axios.get(`${basePath}/getatspipelinebypage?download=yes&id=${localStorage.getItem("userId")}&filter=${JSON.stringify(filterData)}`, {
                responseType: 'blob', // Ensure response is treated as a blob
            });

            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a temporary <a> element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'ATS_Pipeline.xlsx');
            document.body.appendChild(link);
            link.click();

            // Clean up resources
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            setLoadingExport(false);
        } catch (error) {
            // console.error('Error exporting jobs:', error);
            setLoadingExport(false);
        }
    };

    // fetching candidate history
    async function getCandidateHistory(e, id) {
        try {
            let res = await axios.get(`${basePath}/statushistory/${id}`);
            // console.log(res.data);
            setViewCandidateHistory(res.data.history);
        } catch (error) {
            console.log(error);
        }
        // setRowData(sent);
    }

    useEffect(() => {
        setLoadingFilter(true);
        // console.log("filterData?.status====>>>>", filterData?.status);
        if (filterData?.status) {
            fetchFilterData();
        } else {
            fetchData();
        }
        getUserData();
        setLoadingFilter(false);

    }, []);
    useEffect(() => {
        if (!loadingFilter &&
            !filterData?.fromDate &&
            !filterData?.toDate &&
            !filterData?.status &&
            !filterData?.company &&
            !filterData?.position &&
            !filterData?.candidate &&
            !filterData?.location &&
            !filterData?.mobile &&
            !filterData?.email &&
            !filterData?.recruiter &&
            filterData?.positionStatus === "Open" &&
            !filterData?.orderBy &&
            !filterData?.orderDirection
        ) {
            fetchData();
        }
        else if (!loadingFilter) {
            fetchFilterData();
        }

    }, [page, limit, filterData]);
    useEffect(() => {
        if (isClearFilter) {
            if (!loadingFilter &&
                !filterData?.fromDate &&
                !filterData?.toDate &&
                !filterData?.status &&
                !filterData?.company &&
                !filterData?.position &&
                !filterData?.candidate &&
                !filterData?.location &&
                !filterData?.mobile &&
                !filterData?.email &&
                !filterData?.recruiter
            ) {
                fetchData();
                setIsClearFilter(false);
            }
            else if (!loadingFilter) {
                fetchFilterData();
                setIsClearFilter(false);
            }
        }
    }, [isClearFilter]);

    const handleSort = (columnName) => {
        if (positionFilter?.orderBy === columnName) {
            if (positionFilter?.orderDirection === "DESC") {
                setPositionFilter({ ...positionFilter, orderBy: columnName, orderDirection: 'ASC' });
            }
            else {
                setPositionFilter({ ...positionFilter, orderBy: columnName, orderDirection: 'DESC' });
            }
        }
        else {
            setPositionFilter({ ...positionFilter, orderBy: columnName, orderDirection: 'ASC' });
        }
    }

    const handleRemarks = async (e, candidateId) => {

        let data = {
            remarks: isRemarks,

        }
        // console.log(candidateId);
        const options = {
            url: `${basePath}/updatecandidateremarks/${candidateId}`,
            method: "PATCH",
            headers: {
                Accept: "application/json",
            },
            data: data,
        }
        axios(options)
            .then((response) => {
                if (response.status == 200) {
                    setLoading(false);
                    // console.log(response);
                    fetchFilterData();
                    notify("Candidate Remark Updated Successfully");
                    setIsUpdate(false);
                    setIsRemarks(false);
                }
                else {
                    setLoading(false);
                    notifyFillTheFields("Unable to Update");
                }
            })
            .catch((e) => {
                setLoading(false);
                console.log(e);
                notifyFillTheFields("There is some network error!! Please try again later.");
            });
    }
    async function checkCandidateExist(candidateId) {
        try {
            // console.log("candidateId====>>>", candidateId);
            let res = await axios.get(`${basePath}/existedcandidate?id=${candidateId}`);
            // console.log("=========>>>>>>>>>>", res);
            if (res?.status === 200) {
                setInterviewScheduleModal("Interview Scheduled");
                setInterviewScheduleData({
                    scheduled_date: new Date().toISOString().split("T")[0],
                    candidate_id: candidateId,
                    interview_round: "",
                    interview_mode: "",
                    interview_date: "",
                    interview_time: "",
                    interview_location: "",
                    interview_status: "Interview Scheduled",
                    interview_remarks: "",
                    interview_done: "In Progress",
                });
            }
        } catch (error) {
            console.log(error);
            if (error?.response?.status === 404) {
                notifyInfo(error?.response?.data?.error);
            } else {
                notifyFillTheFields("There is some network error!! Please try again later.");
            }

        }
        // setRowData(sent);
    }
    // updating candidate current application status
    const handleStatus = async (e, candiId, status) => {
        let today = new Date();
        let date = today.toISOString().split("T")[0];
        // console.log("============>>>>> candiate id", candidateId, status);
        let data = {
            candidate_status: status,
            status_date: date,
            recruiter_id: localStorage.getItem("userId")
        }
        // console.log("dataATSStatus========>>>>>>>", data, candidateId);
        if (status != "Interview Scheduled") {
            const options = {
                url: `${basePath}/atsstatus/${candidateId}`,
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                },
                data: data,
            }
            axios(options)
                .then((response) => {
                    if (response.status == 200) {
                        setLoading(false);
                        // console.log(response);
                        if (
                            !filterData?.fromDate &&
                            !filterData?.toDate &&
                            !filterData?.status &&
                            !filterData?.company &&
                            !filterData?.position &&
                            !filterData?.candidate &&
                            !filterData?.location &&
                            !filterData?.mobile &&
                            !filterData?.email &&
                            !filterData?.recruiter
                        ) {
                            fetchData();
                        }
                        else {
                            fetchFilterData();
                        }
                        setIsUpdate(false);
                        setCandidateId(false);
                        setConfirmation(false);
                        // setStatus(false);
                        notify("Candidate Status Updated Successfully");
                    }
                    else {
                        setLoading(false);
                        setIsUpdate(false);
                        notifyFillTheFields("Unable to Update");
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    if (e.response.status === 404) {
                        setIsUpdate(false);
                        setCandidateId(false);
                        setConfirmation(false);
                        // setStatus(false);
                        notifyInfo(e.response?.data?.error);
                    } else {
                        notifyFillTheFields("There is some network error!! Please try again later.");
                    }
                    setIsUpdate(false);
                });
        }
        else if (status === "Interview Scheduled" && interviewScheduleModal) {
            // let interviewData = {
            //     scheduled_date: new Date().toISOString().split("T")[0],
            //     candidate_id: candidateId,
            //     interview_round: values?.interview_round,
            //     interview_mode: values?.interview_mode,
            //     interview_date: values?.interview_date,
            //     interview_time: values?.interview_time,
            //     interview_location: values?.interview_location,
            //     interview_status: "Open",
            //     interview_remarks: values?.interview_remarks,
            //     interview_done: "No",
            // }
            data = {
                candidate_status: status,
                status_date: date,
                recruiter_id: localStorage.getItem("userId"),
                interview_data: interviewScheduleData,
            }
            setLoadingInterviewSchedule(true);
            // console.log("============>>>>> candiate id 2", candidateId, status, data);
            const options = {
                url: `${basePath}/atsstatus/${candidateId}`,
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                },
                data: data,
            }
            axios(options)
                .then((response) => {
                    if (response.status == 200) {

                        // console.log(response);
                        fetchFilterData();
                        setLoadingInterviewSchedule(false);
                        setInterviewScheduleModal(false);
                        setIsUpdate(false);
                        setCandidateId(false);
                        setConfirmation(false);
                        // setStatus(false);
                        notify("Candidate Status Updated Successfully");
                    }
                    else {
                        setLoadingInterviewSchedule(false);

                        notifyFillTheFields("Unable to Update");
                    }
                })
                .catch((e) => {
                    setLoadingInterviewSchedule(false);
                    // console.log(e);
                    if (e.response.status === 404) {
                        notify(e.response?.data?.error);
                        setIsUpdate(false);
                        setCandidateId(false);
                        setConfirmation(false);
                        // setStatus(false);
                    } else {
                        notifyFillTheFields("There is some network error!! Please try again later.");
                    }

                });

        }
        else if (status === "Interview Scheduled" && !interviewScheduleModal) {
            checkCandidateExist(candidateId);
            setConfirmation(false);
            // setInterviewScheduleModal(status);
            // setInterviewScheduleData({
            //     scheduled_date: new Date().toISOString().split("T")[0],
            //     candidate_id: candidateId,
            //     interview_round: "",
            //     interview_mode: "",
            //     interview_date: "",
            //     interview_time: "",
            //     interview_location: "",
            //     interview_status: "Open",
            //     interview_remarks: "",
            //     interview_done: "No",
            // });
        }
    }

    useEffect(() => {
        if (
            interviewScheduleData?.interview_round &&
            interviewScheduleData?.interview_date &&
            interviewScheduleData?.interview_time &&
            interviewScheduleData?.interview_mode
        ) {
            // console.log("interviewScheduleData======>>>>>>", interviewScheduleData, candidateId);
            handleStatus("e", candidateId, "Interview Scheduled")
        }
    }, [interviewScheduleData])
    // console.log(interviewScheduleData);

    const validationSchema = Yup.object().shape({
        candidate_name: Yup.string().required('Name is required'),
        candidate_email: Yup.string().email('Invalid email').required('Email is required'),
        candidate_phone: Yup.string()
            .required('Mobile is required')
            .min(countryPhoneLength, `Mobile must be ${countryPhoneLength} digits`)
            .max(countryPhoneLength, `Mobile must be ${countryPhoneLength} digits`),
        candidate_alt_phone: Yup.string()
            .nullable()
            .notRequired()
            .min(countryPhoneLength, `Mobile must be ${countryPhoneLength} digits`)
            .max(countryPhoneLength, `Mobile must be ${countryPhoneLength} digits`),
        candidate_location: Yup.string().required('Location is required'),
        candidate_qualification: Yup.string().required('Qualification is required'),
        candidate_current_ctc: Yup.string()
            .required('Current CTC is required')
            .min(1, `Current CTC must be ${1} digits`)
            .max(5, `Current CTC must be ${5} digits`),
        candidate_ectc_type: Yup.string().required('Expected CTC Type is required'),
        candidate_expected_ctc: Yup.string()
            .required('Expected CTC is required')
            .min(1, `Expected CTC must be ${1} digits`)
            .max(5, `Expected CTC must be ${5} digits`),
        candidate_organization: Yup.string().required('Organization is required'),
        candidate_designation: Yup.string().required('Designation is required'),
        candidate_experience: Yup.number().required('Experience is required').typeError('Must be a number'),
        candidate_notice_period: Yup.string()
            .required('Notice Period is required')
            .min(1, `Notice Period must be ${1} digits`)
            .max(2, `Notice Period must be ${2} digits`),
        candidate_gender: Yup.string().required('Gender is required'),
        candidate_resume: Yup.mixed()
            .nullable()
            .notRequired()
            .test('fileSize', 'File size must be less than 5MB', (value) => {
                return value ? value.size <= 5 * 1024 * 1024 : true;
            })
            .test('fileType', 'Only PDFs are allowed', (value) => {
                return value ? value.type === 'application/pdf' : true;
            }),
    });
    const validationSchemaInterviewSchedule = Yup.object({
        interview_round: Yup.string().required('Round is required').min(1, `Round must be ${1} digit`).max(2, `Round must be ${2} digits`),
        interview_date: Yup.date().required('Date is required'),
        interview_time: Yup.string().required('Time is required'),
        interview_mode: Yup.string().required('Interview mode is required'),
        interview_location: Yup.string().required('Interview location is required'),
        // interview_location: Yup.string().when('interview_mode', {
        //     is: 'In Person',
        //     then: Yup.string().required('Interview location is required'),
        //     // otherwise: Yup.string(),
        // }),
        interview_remarks: Yup.string(),
    });
    const handleCandidateSubmit = (values) => {
        // console.log(values);
        setLoadingUpdate(true);
        let data = {
            candidate: values?.candidate_name,
            candidate_email: values?.candidate_email,
            candidate_phone: `${countryPhone}-${values?.candidate_phone}`,
            candidate_alt_phone: `${countryPhone}-${values?.candidate_alt_phone ? values?.candidate_alt_phone:""}`,
            candidate_location: values?.candidate_location,
            candidate_experience: `${values?.candidate_experience} Yrs.`,
            candidate_current_ctc: `${values?.candidate_current_ctc} ${"LPA"}`,
            candidate_expected_ctc: `${values?.candidate_expected_ctc} ${values?.candidate_ectc_type === "%" ? `${values?.candidate_ectc_type} Hike` : values?.candidate_ectc_type}`,
            candidate_organization: values?.candidate_organization,
            candidate_designation: values?.candidate_designation,
            candidate_notice_period: `${values?.candidate_notice_period} Days`,
            candidate_qualification: values?.candidate_qualification,
            candidate_gender: values?.candidate_gender,
            candidate_remarks: values?.candidate_remarks,
            candidate_resume: values?.candidate_resume,
            recruiter_id: localStorage.getItem("userId")
        }

        const options = {
            url: `${basePath}/editatspipeline/${viewCandidate}`,
            method: "PATCH",
            headers: {
                Accept: "application/json",
                'Content-Type': 'multipart/form-data',
            },
            data: data,
        }
        axios(options)
            .then((response) => {
                if (response.status == 200) {

                    // console.log(response);
                    if (
                        !filterData?.fromDate &&
                        !filterData?.toDate &&
                        !filterData?.status &&
                        !filterData?.company &&
                        !filterData?.position &&
                        !filterData?.candidate &&
                        !filterData?.location &&
                        !filterData?.mobile &&
                        !filterData?.email &&
                        !filterData?.recruiter
                    ) {
                        fetchData();
                    }
                    else {
                        fetchFilterData();
                    }
                    setCandidate({
                        candidate_email: "",
                        candidate_phone: "",
                        candidate_alt_phone: "",
                        candidate_location: "",
                        candidate_experience: "",
                        candidate_current_ctc: "",
                        candidate_expected_ctc: "",
                        candidate_organization: "",
                        candidate_designation: "",
                        candidate_notice_period: "",
                        candidate_qualification: "",
                        candidate_gender: "",
                        candidate_remarks: "",
                        candidate_resume: "",
                    });
                    setCandidateEdit(false);
                    setLoadingUpdate(false);
                    notify("Updated Successfully");
                }
                else {
                    setLoadingUpdate(false);
                    notifyFillTheFields("Unable to Update");
                }
            })
            .catch((e) => {
                setLoadingUpdate(false);
                console.log(e);
                notifyFillTheFields("There is some network error!! Please try again later.");
            });
    }
    const downloadButton = async (canId, candidateName) => {
        setLoadingDownload(true);
        try {
            const response = await fetch(`${basePath}/downloadresume/${canId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${candidateName.replace(/ /g, "_")}_resume_${new Date().toISOString().split("T")[0]?.split("-")[2]}-${monthData[parseInt(new Date().toISOString().split("T")[0]?.split("-")[1])]}-${new Date().toISOString().split("T")[0]?.split("-")[0]}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url); // Cleanup the URL object
            setLoadingDownload(false);
        } catch (error) {
            console.error('There was a problem with the download:', error);
            if (error?.status === 404) {
                notifyFillTheFields(error?.response?.error);
            }
            else {
                notifyFillTheFields("There was a problem with the download");
            }
            setLoadingDownload(false);
        }
    }
    // handling edit button 
    const handleEdit = (e, idx) => {
        setCtcType(atsPipeline[idx]?.candidate_expected_ctc?.split(" ")[1]);
        setCandidate({
            candidate_email: atsPipeline[idx]?.candidate_email,
            candidate_phone: atsPipeline[idx]?.candidate_phone?.split("-")[1],
            candidate_alt_phone: atsPipeline[idx]?.candidate_alt_phone?.split("-")[1],
            candidate_location: atsPipeline[idx]?.candidate_location,
            candidate_experience: atsPipeline[idx]?.candidate_experience?.split(" ")[0],
            candidate_current_ctc: atsPipeline[idx]?.candidate_current_ctc?.split(" ")[0],
            candidate_expected_ctc: atsPipeline[idx]?.candidate_expected_ctc?.split(" ")[0],
            candidate_organization: atsPipeline[idx]?.candidate_organization,
            candidate_designation: atsPipeline[idx]?.candidate_designation,
            candidate_notice_period: atsPipeline[idx]?.candidate_notice_period?.split(" ")[0],
            candidate_qualification: atsPipeline[idx]?.candidate_qualification,
            candidate_gender: atsPipeline[idx]?.candidate_gender,
            candidate_remarks: atsPipeline[idx]?.candidate_remarks,
            candidate_resume: atsPipeline[idx]?.candidate_resume,
        });
        setCandidateEdit(true);
    }
    const handleFilter = debounce(() => {
        if (
            filterData?.fromDate ||
            filterData?.toDate ||
            filterData?.status ||
            filterData?.company ||
            filterData?.position ||
            filterData?.candidate ||
            filterData?.location ||
            filterData?.mobile ||
            filterData?.email ||
            filterData?.recruiter
        ) {
            fetchFilterData();
        }
    }, 800);

    // console.log(candidate, countryPhone, countryPhoneLength);
    return (
        <section onClick={() => { setIsModalOpen(false); setIsAction(false); setIsUpdate(false); }} className='lightBabyGreen baseHeightWidth customScroll mb-5'>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            <div className=' absolute bottom-3 right-4'>
                <div onClick={(e) => {
                    if (
                        !filterData?.fromDate &&
                        !filterData?.toDate &&
                        !filterData?.status &&
                        !filterData?.company &&
                        !filterData?.position &&
                        !filterData?.candidate &&
                        !filterData?.location &&
                        !filterData?.mobile &&
                        !filterData?.email &&
                        !filterData?.recruiter &&
                        filterData?.positionStatus === "Open" &&
                        !filterData?.orderBy &&
                        !filterData?.orderDirection
                    ) {
                        fetchData();
                    }
                    else {
                        fetchFilterData();
                    }
                }} className='cursor-pointer bg-red-600 px-2.5 py-2 rounded-full hoverRed'>
                    <FontAwesomeIcon icon={faRotateRight} size={"lg"} style={{ color: "#ffffff", }} />
                </div>
            </div>
            <div className='welcomeBarHeight babyGreen smoothTransition textDarkPurple px-10 rounded-3xl flex items-center justify-between navAnimation  m-auto'>
                <div className=' text-left'>
                    <h1 className=' text-5xl font-bold'>ATS Pipeline</h1>
                    <p className=' text-gray-500 font-semibold'>Ready to start your day</p>
                </div>
                <img className=' w-44' src="/img/registration.png" alt="registration" />
            </div>
            {confirmation ?
                <div onClick={(e) => { }} className=' w-full h-screen fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                    <div onClick={(e) => { e.stopPropagation(); }} className='relative   bg-white overflow-auto mx-10 mb-5 py-5 px-8 rounded-2xl navAmination '>
                        <div>
                            <h1 className=' text-blue-500 font-semibold pb-5'>
                                Are you sure to change status to
                                <span className='text-blue-900 font-semibold'>" {status} "</span> ?
                            </h1>
                            <div className=' flex gap-4 items-center justify-center'>
                                <button
                                    className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed'
                                    onClick={(e) => {
                                        setStatus("");
                                        setCandidateId("");
                                        setIsUpdate("");
                                        setConfirmation(false);
                                    }}>Cancel</button>
                                <button
                                    className='darkPurple text-white font-semibold rounded-xl px-4 py-1 hoverGreen'
                                    onClick={(e) => {
                                        if (candidateId && status) {

                                            handleStatus(e, candidateId, status);
                                        }
                                        else {
                                            notifyFillTheFields(`${candidateId} - ${status}`);
                                        }
                                    }}
                                >Save</button>
                            </div>
                        </div>
                    </div>
                </div> : null
            }
            {interviewScheduleModal ?
                <div onClick={(e) => { setInterviewScheduleModal(false); }} className=' w-full h-screen fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                    <div onClick={(e) => { e.stopPropagation(); }} className='relative w-[70vw]  bg-white overflow-auto mx-10 mb-5 py-5 px-8 rounded-2xl navAmination '>
                        <button onClick={(e) => { setInterviewScheduleModal(false); }} className="absolute bg-white rounded-full w-fit top-1 right-1 z-[11] ">
                            <img className='w-7' src="/img/close.png" alt="close" />
                        </button>
                        <h1 className='text-center text-xl font-semibold py-2 text-blue-950'>Candidate Interview Schedule</h1>
                        <div className='flex items-center gap-2'>
                            <h1 className=' text-lg text-gray-400 font-semibold'>Schedule Date: </h1>
                            <h1 className=' text-lg textDarkPurple font-semibold'>{interviewScheduleData?.scheduled_date}</h1>
                        </div>
                        {/* <div className=' text-left flex flex-col gap-x-2 gap-y-4 max-h-[70vh] overflow-y-scroll'>
                            <div className=''>
                                <div className=''>
                                    <h2 className=' '>Interview Round</h2>
                                    <input value={interviewScheduleData?.interview_round} onChange={(e) => {
                                        setInterviewScheduleData({ ...interviewScheduleData, interview_round: e.target.value })
                                    }} className=' border-2 outline-none rounded-lg px-2 w-full py-1' autoFocus type="number" placeholder='Round*' required />
                                </div>
                            </div>
                            <div className=' grid grid-cols-2 gap-3'>
                                <div className=''>
                                    <h2 className=' '>Interview Date</h2>
                                    <input value={interviewScheduleData?.interview_date} onChange={(e) => {
                                        setInterviewScheduleData({ ...interviewScheduleData, interview_date: e.target.value })
                                    }} className=' border-2 outline-none rounded-lg px-2 w-full py-1' autoFocus type="date" placeholder='Date*' required />
                                </div>
                                <div className=''>
                                    <h2 className=' '>Interview Time</h2>
                                    <input value={interviewScheduleData?.interview_time} onChange={(e) => {
                                        setInterviewScheduleData({ ...interviewScheduleData, interview_time: e.target.value })
                                    }} className=' border-2 outline-none rounded-lg px-2 w-full py-1' autoFocus type="time" placeholder='Time*' required />
                                </div>

                            </div>
                            <div className=' grid grid-cols-2 gap-3'>
                                <div className=''>
                                    <h2 className=' '>Interview Mode</h2>
                                    <select value={interviewScheduleData?.interview_mode}
                                        onChange={(e) => {
                                            setInterviewScheduleData({ ...interviewScheduleData, interview_mode: e.target.value })
                                        }} className=' outline-none border-2 rounded-lg px-2 w-full py-1' required>
                                        <option value="" selected disabled>Select</option>
                                        <option value="In Person">In Person</option>
                                        <option value="Virtual">Virtual</option>
                                    </select>
                                </div>
                                {interviewScheduleData?.interview_mode === "In Person" ?
                                    <div className=''>
                                        <h2 className=' '>Interview Location</h2>
                                        <textarea value={interviewScheduleData?.interview_location} name="" id=""
                                            onChange={(e) => {
                                                setInterviewScheduleData({ ...interviewScheduleData, interview_location: e.target.value });
                                            }} className=' outline-none border-2 rounded-lg px-2 w-full' placeholder='Interview Location...'></textarea>
                                    </div> : null
                                }
                            </div>
                            <div className=' w-full'>
                                <div className=''>
                                    <h2 className=' '>Remarks</h2>
                                    <textarea value={interviewScheduleData?.interview_remarks} name="" id=""
                                        onChange={(e) => {
                                            setInterviewScheduleData({ ...interviewScheduleData, interview_remarks: e.target.value });
                                        }} className=' outline-none border-2 rounded-lg px-2 w-full' placeholder='Remarks...'></textarea>
                                </div>
                            </div>
                        </div> */}
                        <Formik
                            initialValues={{
                                interview_round: '1',
                                interview_date: '',
                                interview_time: '',
                                interview_mode: '',
                                interview_location: '',
                                interview_remarks: '',
                            }}
                            validationSchema={validationSchemaInterviewSchedule}
                            onSubmit={(values) => {
                                // console.log(values);

                                setInterviewScheduleData({
                                    scheduled_date: new Date().toISOString().split("T")[0],
                                    candidate_id: candidateId,
                                    interview_round: values?.interview_round,
                                    interview_mode: values?.interview_mode,
                                    interview_date: values?.interview_date,
                                    interview_time: values?.interview_time,
                                    interview_location: values?.interview_location,
                                    interview_status: "Interview Scheduled",
                                    interview_remarks: values?.interview_remarks,
                                    interview_done: "In Progress",
                                });



                            }}
                        >
                            {({ values, setFieldValue }) => (
                                <Form className='text-left flex flex-col gap-x-2 gap-y-4 max-h-[70vh] overflow-y-scroll'>
                                    <div>
                                        <div>
                                            <h2>Interview Round</h2>
                                            <Field
                                                type="number"
                                                name="interview_round"
                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                placeholder='Round*'
                                                disabled
                                            />
                                            <ErrorMessage name="interview_round" component="div" className="text-red-500" />
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-2 gap-3'>
                                        <div>
                                            <h2>Interview Date</h2>
                                            <Field
                                                type="date"
                                                name="interview_date"
                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                placeholder='Date*'
                                            />
                                            <ErrorMessage name="interview_date" component="div" className="text-red-500" />
                                        </div>
                                        <div>
                                            <h2>Interview Time</h2>
                                            <Field
                                                type="time"
                                                name="interview_time"
                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                placeholder='Time*'
                                            />
                                            <ErrorMessage name="interview_time" component="div" className="text-red-500" />
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-2 gap-3'>
                                        <div>
                                            <h2>Interview Mode</h2>
                                            <Field
                                                as="select"
                                                name="interview_mode"
                                                className='outline-none border-2 rounded-lg px-2 w-full py-1'
                                                onChange={(e) => { setFieldValue("interview_mode", e.target.value); setInterviewScheduleData({ ...interviewScheduleData, interview_mode: e.target.value }); }}
                                            >
                                                <option value="" disabled>Select</option>
                                                <option value="In Person">In Person</option>
                                                <option value="Virtual">Virtual</option>
                                            </Field>
                                            <ErrorMessage name="interview_mode" component="div" className="text-red-500" />
                                        </div>

                                        <div>
                                            <h2>Interview Location</h2>
                                            <Field
                                                type="text"
                                                name="interview_location"
                                                className='outline-none border-2 rounded-lg px-2 py-1 w-full'
                                                placeholder='Interview Location...'
                                            />
                                            <ErrorMessage name="interview_location" component="div" className="text-red-500" />
                                        </div>

                                    </div>
                                    <div className='w-full'>
                                        <div>
                                            <h2>Remarks</h2>
                                            <Field
                                                as="textarea"
                                                name="interview_remarks"
                                                className='outline-none border-2 rounded-lg px-2 w-full'
                                                placeholder='Remarks...'
                                            />
                                            <ErrorMessage name="interview_remarks" component="div" className="text-red-500" />
                                        </div>
                                    </div>
                                    <div className='flex items-center mt-5'>
                                        {!loadingInterviewSchedule ?
                                            <>
                                                <button onClick={(e) => { setInterviewScheduleModal(false); }} className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed' >Cancel</button>
                                                <button type='submit' className='darkPurple text-white font-semibold rounded-xl px-4 py-1 hoverGreen'>Submit</button>
                                            </> :
                                            <>
                                                <button className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed' >Cancel</button>
                                                <button className='darkPurple text-white font-semibold rounded-xl px-4 py-1 hoverGreen'>Loading...</button>
                                            </>
                                        }

                                    </div>
                                </Form>
                            )}
                        </Formik>


                    </div>
                </div> : null
            }
            {viewCandidate ?
                <div onClick={(e) => { setViewCandidate(false); setCandidateEdit(false); }} className=' w-full h-screen fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                    <div onClick={(e) => { e.stopPropagation(); }} className=' bg-white overflow-auto mx-10 mb-5 p-10 rounded-3xl candidateInfoBox'>
                        {Array.isArray(atsPipeline) && atsPipeline?.map((val, idx) => {
                            return (
                                <>
                                    {viewCandidate === val?.id ?
                                        <>
                                            {!candidateEdit ?
                                                <div onClick={(e) => { }} className=' w-full h-screen fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                                                    <div onClick={(e) => { e.stopPropagation(); }} className='relative w-[70vw]  bg-white overflow-auto mx-10 mb-5 py-5 px-8 rounded-2xl '>
                                                        <button onClick={(e) => { setViewCandidate(false); }} className="absolute bg-white rounded-full w-fit top-1 right-1 z-[11] ">
                                                            <img className='w-7' src="/img/close.png" alt="close" />
                                                        </button>
                                                        <h1 className='text-center text-xl font-semibold py-2 text-blue-950'>Candidate Imformation</h1>
                                                        <div className=' text-left flex flex-col gap-x-2 gap-y-2 max-h-[70vh] overflow-y-scroll'>
                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Name</h2>
                                                                    <input
                                                                        value={val?.candidate}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' autoFocus type="text" placeholder="Name*" required />
                                                                </div>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Email</h2>
                                                                    <input value={val?.candidate_email}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' autoFocus type="email" placeholder='Email*' required />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className=' grid grid-cols-2 gap-3'>
                                                                    <div className=''>
                                                                        <h2 className=' w-32'>Mobile</h2>
                                                                        <input value={val?.candidate_phone}
                                                                            disabled
                                                                            className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Mobile*' required />
                                                                    </div>
                                                                    <div className=''>
                                                                        <h2 className=' w-32'>Alternate Mobile</h2>
                                                                        <input value={val?.candidate_alt_phone}
                                                                            disabled
                                                                            className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Alternate Mobile' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Location</h2>
                                                                    <input value={val?.candidate_location}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Location*' required />
                                                                </div>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Qualification</h2>
                                                                    <input value={val?.candidate_qualification}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Qualification*' required />
                                                                </div>
                                                            </div>
                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Current CTC</h2>
                                                                    <input value={val?.candidate_current_ctc}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Current CTC*' required />
                                                                </div>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Expected CTC</h2>
                                                                    <input value={val?.candidate_expected_ctc}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder="Expected CTC*" required />
                                                                </div>
                                                            </div>
                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Organization</h2>
                                                                    <input value={val?.candidate_organization}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Current/Past Organization' />
                                                                </div>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Designation</h2>
                                                                    <input value={val?.candidate_designation}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Current/Past Designation' />
                                                                </div>
                                                            </div>
                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Experience</h2>
                                                                    <input value={val?.candidate_experience}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Experience*' required />
                                                                </div>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Notice Period</h2>

                                                                    <input value={val?.candidate_notice_period}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Experience*' required />
                                                                </div>
                                                            </div>
                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Gender</h2>
                                                                    <input value={val?.candidate_gender}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Experience*' required />

                                                                </div>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Resume</h2>
                                                                    <h2> {val?.candidate_resume ?
                                                                        !loadingDownload ?
                                                                            <button className=" darkPurple text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2 hoverGreen" onClick={(e) => { downloadButton(val?.id, val?.candidate) }} >Download</button> :
                                                                            <button className=" darkPurple text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2"  >Downloading...</button> :
                                                                        <h2 className=" bg-gray-200 text-red-300 font-semibold outline-none rounded-lg px-2 w-full py-1">Resume Not Uploaded</h2>}
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                            <div className=''>
                                                                <h2 className=''>Remarks</h2>
                                                                <textarea value={val.candidate_remarks}
                                                                    disabled
                                                                    className=' outline-none bg-gray-200 text-blue-700 font-semibold rounded-lg px-2 w-full'></textarea>
                                                            </div>
                                                            <div className='flex items-center justify-center mt-2'>
                                                                <button onClick={(e) => { handleEdit(e, idx) }} className=' text-yellow-600 bg-yellow-200 m-2 px-10 py-1 rounded-lg hoverYellow' >Edit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div onClick={(e) => { e.stopPropagation(); }} className=' w-full h-screen fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                                                    <div onClick={(e) => { e.stopPropagation(); }} className='relative w-[70vw]  bg-white overflow-auto mx-10 mb-5 py-5 px-8 rounded-2xl '>
                                                        {!loadingUpdate ?
                                                            <button onClick={(e) => { setCandidateEdit(false); }} className="absolute bg-white rounded-full w-fit top-1 right-1 z-[11] ">
                                                                <img className='w-7' src="/img/close.png" alt="close" />
                                                            </button> :
                                                            <button className="absolute cursor-not-allowed bg-white rounded-full w-fit top-1 right-1 z-[11] ">
                                                                <img className='w-7' src="/img/close.png" alt="close" />
                                                            </button>
                                                        }
                                                        <h1 className='text-center text-xl font-semibold py-2 text-blue-950'>Candidate Imformation</h1>
                                                        {/* <div className=' text-left flex flex-col gap-x-2 gap-y-2 max-h-[70vh] overflow-y-scroll'>
                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Name</h2>
                                                                    <input
                                                                        className=' border-2 outline-none rounded-lg px-2 w-full py-1' autoFocus type="text" placeholder="Name*" required />
                                                                </div>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Email</h2>
                                                                    <input value={candidate?.candidate_email} onChange={(e) => {

                                                                        setCandidate({ ...candidate, candidate_email: e.target.value });
                                                                    }} className=' border-2 outline-none rounded-lg px-2 w-full py-1' autoFocus type="email" placeholder='Email*' required />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className=' grid grid-cols-2 gap-3'>
                                                                    <div className=''>
                                                                        <h2 className=' w-32'>Mobile</h2>
                                                                        <div className=" flex items-center gap-2">
                                                                            <div className=' border-2 rounded-lg flex items-center pl-1 w-[7rem]'>
                                                                                {
                                                                                    countryPhoneCode?.map((val) => {
                                                                                        return val?.phoneCode === countryPhone ? <img className=" rounded w-[1.5rem]" src={val?.flag} alt={val?.name} /> : null
                                                                                    })
                                                                                }
                                                                                <select className=" outline-none py-1" value={countryPhone} onChange={(e) => { setCountryPhone(e.target.value); }} >
                                                                                    {
                                                                                        countryPhoneCode?.map((val) => {
                                                                                            return (
                                                                                                <option key={val?.isoCode} value={val?.phoneCode}>
                                                                                                    {val?.phoneCode}</option>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                            <input value={candidate?.candidate_phone} onChange={(e) => {
                                                                                setCandidate({ ...candidate, candidate_phone: e.target.value });
                                                                            }} className=' border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                type="number"


                                                                                placeholder='Mobile*' required />
                                                                        </div>
                                                                    </div>
                                                                    <div className=''>
                                                                        <h2 className=' w-32'>Alternate Mobile</h2>
                                                                        <input value={candidate?.candidate_alt_phone} onChange={(e) => {
                                                                            setCandidate({ ...candidate, candidate_alt_phone: e.target.value });
                                                                        }} className=' border-2 outline-none rounded-lg px-2 w-full py-1' type="number" maxLength={"10"} placeholder='Alternate Mobile' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Location</h2>
                                                                    <input value={candidate?.candidate_location}
                                                                        onChange={(e) => {
                                                                            setCandidate({ ...candidate, candidate_location: e.target.value });
                                                                        }} className=' border-2 outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Location*' required />
                                                                </div>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Qualification</h2>
                                                                    <input value={candidate?.candidate_qualification}
                                                                        onChange={(e) => {
                                                                            setCandidate({ ...candidate, candidate_qualification: e.target.value });
                                                                        }} className=' border-2 outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Qualification*' required />
                                                                </div>
                                                            </div>
                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Current CTC</h2>
                                                                    <input value={candidate?.candidate_current_ctc}
                                                                        onChange={(e) => {
                                                                            setCandidate({ ...candidate, candidate_current_ctc: e.target.value });
                                                                        }} className=' border-2 outline-none rounded-lg px-2 w-full py-1' type="number" placeholder='Current CTC*' required />
                                                                </div>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Expected CTC</h2>
                                                                    <input value={candidate?.candidate_expected_ctc}
                                                                        onChange={(e) => {
                                                                            setCandidate({ ...candidate, candidate_expected_ctc: e.target.value });
                                                                        }} className=' border-2 outline-none rounded-lg px-2 w-full py-1' type="number" placeholder="Expected CTC*" required />
                                                                </div>
                                                            </div>
                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Organization</h2>
                                                                    <input value={candidate?.candidate_organization}
                                                                        onChange={(e) => {
                                                                            setCandidate({ ...candidate, candidate_organization: e.target.value });
                                                                        }} className=' border-2 outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Current/Past Organization' />
                                                                </div>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Designation</h2>
                                                                    <input value={candidate?.candidate_designation}
                                                                        onChange={(e) => {
                                                                            setCandidate({ ...candidate, candidate_designation: e.target.value });
                                                                        }} className=' border-2 outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Current/Past Designation' />
                                                                </div>
                                                            </div>
                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Experience</h2>
                                                                    <input value={candidate?.candidate_experience}
                                                                        onChange={(e) => {
                                                                            setCandidate({ ...candidate, candidate_experience: e.target.value });
                                                                        }} className=' border-2 outline-none rounded-lg px-2 w-full py-1' type="number" placeholder='Experience*' required />
                                                                </div>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Notice Period</h2>
                                                                    <select value={candidate?.candidate_notice_period}
                                                                        onChange={(e) => {
                                                                            setCandidate({ ...candidate, candidate_notice_period: e.target.value });
                                                                        }} className=' outline-none border-2 rounded-lg px-2 w-full py-1' required>
                                                                        <option value="" selected disabled>Notice Period*</option>
                                                                        <option value="Immediate">Immediate</option>
                                                                        <option value="15 days">upto 15 Days</option>
                                                                        <option value="30 days">upto 30 Days</option>
                                                                        <option value="2 months">2 Months</option>
                                                                        <option value="3 months">3 Months</option>
                                                                        <option value="More Than 3 months">More Than 3 Months</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Gender</h2>
                                                                    <select value={candidate?.candidate_gender}
                                                                        onChange={(e) => {
                                                                            setCandidate({ ...candidate, candidate_gender: e.target.value });
                                                                        }} className=' outline-none border-2 rounded-lg px-2 w-full py-1' required>
                                                                        <option value="" selected disabled>Gender*</option>
                                                                        <option value="Male">Male</option>
                                                                        <option value="Female">Female</option>
                                                                    </select>
                                                                </div>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Upload Resume*</h2>
                                                                    <input onChange={(e) => {
                                                                        setCandidate({ ...candidate, candidate_resume: e.target.value });
                                                                    }}
                                                                        type="file" accept='.pdf, .doc, .docx' />
                                                                </div>
                                                            </div>
                                                            <div className=''>
                                                                <h2 className=''>Remarks</h2>
                                                                <textarea value={candidate.candidate_remarks} onChange={(e) => {
                                                                    setCandidate({ ...candidate, candidate_remarks: e.target.value });
                                                                }} className=' outline-none border-2 rounded-lg px-2 w-full'></textarea>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center mt-5'>
                                                            <button onClick={(e) => { setCandidateEdit(false); }} className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed' >Cancel</button>
                                                            <button onClick={(e) => { handleCandidateSubmit(e, val?.id) }} className='darkPurple text-white font-semibold rounded-xl px-4 py-1 hoverGreen'>Submit</button>
                                                        </div> */}
                                                        <Formik
                                                            initialValues={{
                                                                candidate_name: val?.candidate,
                                                                candidate_email: candidate.candidate_email || '',
                                                                candidate_phone: candidate.candidate_phone || '',
                                                                candidate_alt_phone: candidate.candidate_alt_phone || '',
                                                                candidate_location: candidate.candidate_location || '',
                                                                candidate_qualification: candidate.candidate_qualification || '',
                                                                candidate_current_ctc: candidate.candidate_current_ctc || '',
                                                                candidate_ectc_type: ctcType || "",
                                                                candidate_expected_ctc: candidate.candidate_expected_ctc || '',
                                                                candidate_organization: candidate.candidate_organization || '',
                                                                candidate_designation: candidate.candidate_designation || '',
                                                                candidate_experience: candidate.candidate_experience || '',
                                                                candidate_notice_period: candidate.candidate_notice_period || '',
                                                                candidate_gender: candidate.candidate_gender || '',
                                                                candidate_resume: '',
                                                                candidate_remarks: candidate.candidate_remarks || '',
                                                            }}
                                                            validationSchema={validationSchema}
                                                            onSubmit={(values) => {
                                                                // console.log(values);
                                                                setCandidate({
                                                                    candidate_email: values?.candidate_email,
                                                                    candidate_phone: `${countryPhone}-${values?.candidate_phone}`,
                                                                    candidate_alt_phone: `${countryPhone}-${values?.candidate_alt_phone}`,
                                                                    candidate_location: values?.candidate_location,
                                                                    candidate_experience: values?.candidate_experience,
                                                                    candidate_current_ctc: values?.candidate_current_ctc,
                                                                    candidate_expected_ctc: values?.candidate_expected_ctc,
                                                                    candidate_organization: values?.candidate_organization,
                                                                    candidate_designation: values?.candidate_designation,
                                                                    candidate_notice_period: values?.candidate_notice_period,
                                                                    candidate_qualification: values?.candidate_qualification,
                                                                    candidate_gender: values?.candidate_gender,
                                                                    candidate_remarks: values?.candidate_remarks,
                                                                    candidate_resume: values?.candidate_resume,
                                                                });
                                                                handleCandidateSubmit(values);

                                                            }}
                                                        >
                                                            {({ values, setFieldValue }) => (
                                                                <Form className='text-left flex flex-col gap-x-2 gap-y-2 max-h-[70vh] overflow-y-scroll'>
                                                                    <div className='grid grid-cols-2 gap-3'>
                                                                        <div>
                                                                            <h2 className='w-32'>Name</h2>
                                                                            <Field
                                                                                name="candidate_name"
                                                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                type="text"
                                                                                placeholder="Name*"
                                                                            />
                                                                            <ErrorMessage name="candidate_name" component="div" className="text-red-500" />
                                                                        </div>
                                                                        <div>
                                                                            <h2 className='w-32'>Email</h2>
                                                                            <Field
                                                                                name="candidate_email"
                                                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                type="email"
                                                                                placeholder='Email*'
                                                                            />
                                                                            <ErrorMessage name="candidate_email" component="div" className="text-red-500" />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className='grid grid-cols-2 gap-3'>
                                                                            <div>
                                                                                <h2 className='w-32'>Mobile</h2>
                                                                                <div className="flex items-center gap-2">
                                                                                    <div className='border-2 rounded-lg flex items-center pl-1 w-[7rem]'>
                                                                                        {
                                                                                            countryPhoneCode.map((val) => {
                                                                                                return val.phoneCode === countryPhone ? <img key={val.isoCode} className="rounded w-[1.5rem]" src={val.flag} alt={val.name} /> : null
                                                                                            })
                                                                                        }
                                                                                        <Field as="select" className="outline-none py-1 font-semibold" name="countryPhone" value={countryPhone} onChange={(e) => {
                                                                                            countryPhoneCode?.forEach((val) => {
                                                                                                if (e.target.value == val.phoneCode) {
                                                                                                    setCountryPhoneLength(val?.phoneNumberLength);
                                                                                                }
                                                                                            })
                                                                                            setCountryPhone(e.target.value);
                                                                                        }} >
                                                                                            {
                                                                                                countryPhoneCode.map((val) => (
                                                                                                    <option key={val.isoCode} value={val.phoneCode}>
                                                                                                        {val.phoneCode}
                                                                                                    </option>
                                                                                                ))
                                                                                            }
                                                                                        </Field>
                                                                                    </div>
                                                                                    <Field
                                                                                        name="candidate_phone"
                                                                                        className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                        type="number"
                                                                                        placeholder='Mobile*'
                                                                                    />
                                                                                </div>
                                                                                <ErrorMessage name="candidate_phone" component="div" className="text-red-500" />
                                                                            </div>
                                                                            <div>
                                                                                <h2 className='w-32'>Alternate Mobile</h2>
                                                                                <div className="flex items-center gap-2">
                                                                                    <div className='border-2 rounded-lg flex items-center pl-1 w-[7rem]'>
                                                                                        {
                                                                                            countryPhoneCode.map((val) => {
                                                                                                return val.phoneCode === countryPhone ? <img key={val.isoCode} className="rounded w-[1.5rem]" src={val.flag} alt={val.name} /> : null
                                                                                            })
                                                                                        }
                                                                                        <Field as="select" className="outline-none py-1 font-semibold" name="countryPhone" value={countryPhone} onChange={(e) => {
                                                                                            countryPhoneCode?.forEach((val) => {
                                                                                                if (e.target.value == val.phoneCode) {
                                                                                                    setCountryPhoneLength(val?.phoneNumberLength);
                                                                                                }
                                                                                            })
                                                                                            setCountryPhone(e.target.value);
                                                                                        }} >
                                                                                            {
                                                                                                countryPhoneCode.map((val) => (
                                                                                                    <option key={val.isoCode} value={val.phoneCode}>
                                                                                                        {val.phoneCode}
                                                                                                    </option>
                                                                                                ))
                                                                                            }
                                                                                        </Field>
                                                                                    </div>
                                                                                    <Field
                                                                                        name="candidate_alt_phone"
                                                                                        className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                        type="number"
                                                                                        placeholder='Alternate Mobile*'
                                                                                    />
                                                                                </div>
                                                                                <ErrorMessage name="candidate_alt_phone" component="div" className="text-red-500" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='grid grid-cols-2 gap-3'>
                                                                        <div>
                                                                            <h2 className='w-32'>Location</h2>
                                                                            <Field
                                                                                name="candidate_location"
                                                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                type="text"
                                                                                placeholder='Location*'
                                                                            />
                                                                            <ErrorMessage name="candidate_location" component="div" className="text-red-500" />
                                                                        </div>
                                                                        <div>
                                                                            <h2 className='w-32'>Qualification</h2>
                                                                            <Field
                                                                                name="candidate_qualification"
                                                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                type="text"
                                                                                placeholder='Qualification*'
                                                                            />
                                                                            <ErrorMessage name="candidate_qualification" component="div" className="text-red-500" />
                                                                        </div>
                                                                    </div>
                                                                    <div className='grid grid-cols-2 gap-3'>
                                                                        <div>
                                                                            <h2 className='w-32'>Current CTC</h2>
                                                                            <Field
                                                                                name="candidate_current_ctc"
                                                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                type="number"
                                                                                placeholder='Current CTC*'
                                                                            />
                                                                            <ErrorMessage name="candidate_current_ctc" component="div" className="text-red-500" />
                                                                        </div>
                                                                        <div>
                                                                            <h2 className='w-32'>Expected CTC</h2>
                                                                            <div className='flex items-center gap-2'>
                                                                                <div className=" flex-1">
                                                                                    <Field as="select" name="candidate_ectc_type"
                                                                                        className='outline-none border-2 rounded-lg px-2 w-fit py-1' required>
                                                                                        <option disabled value="" >Select</option>
                                                                                        <option value="LPA">LPA</option>
                                                                                        <option value="%">%</option>
                                                                                    </Field>
                                                                                    <ErrorMessage name="candidate_ectc_type" component="div" className="text-red-600 text-sm" />
                                                                                </div>

                                                                                {values.candidate_ectc_type === "LPA" ?
                                                                                    <div className="w-full flex-2">
                                                                                        <Field name="candidate_expected_ctc" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="number" placeholder="Expected CTC in LPA*" />
                                                                                        <ErrorMessage name="candidate_expected_ctc" component="div" className="text-red-600 text-sm" />
                                                                                    </div> :
                                                                                    values.candidate_ectc_type === "%" ?
                                                                                        <div className="w-full flex-2">
                                                                                            <Field name="candidate_expected_ctc" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="number" placeholder="Expected CTC In %*" />
                                                                                            <ErrorMessage name="candidate_expected_ctc" component="div" className="text-red-600 text-sm" />
                                                                                        </div> : null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='grid grid-cols-2 gap-3'>
                                                                        <div>
                                                                            <h2 className='w-32'>Organization</h2>
                                                                            <Field
                                                                                name="candidate_organization"
                                                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                type="text"
                                                                                placeholder='Current/Past Organization'
                                                                            />
                                                                            <ErrorMessage name="candidate_organization" component="div" className="text-red-500" />
                                                                        </div>
                                                                        <div>
                                                                            <h2 className='w-32'>Designation</h2>
                                                                            <Field
                                                                                name="candidate_designation"
                                                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                type="text"
                                                                                placeholder='Current/Past Designation'
                                                                            />
                                                                            <ErrorMessage name="candidate_designation" component="div" className="text-red-500" />
                                                                        </div>
                                                                    </div>
                                                                    <div className='grid grid-cols-2 gap-3'>
                                                                        <div>
                                                                            <h2 className='w-32'>Experience</h2>
                                                                            <Field
                                                                                name="candidate_experience"
                                                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                type="number"
                                                                                placeholder='Experience*'
                                                                            />
                                                                            <ErrorMessage name="candidate_experience" component="div" className="text-red-500" />
                                                                        </div>
                                                                        <div className=''>
                                                                            <h2 className='w-32'>Notice Period</h2>
                                                                            <Field name="candidate_notice_period" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="number" placeholder="Notice Period In Days*" />
                                                                            <ErrorMessage name="candidate_notice_period" component="div" className="text-red-500" />
                                                                        </div>
                                                                    </div>
                                                                    <div className='grid grid-cols-2 gap-3'>
                                                                        <div>
                                                                            <h2 className='w-32'>Gender</h2>
                                                                            <Field as="select" name="candidate_gender"
                                                                                className='outline-none border-2 rounded-lg px-2 w-full py-1' required>
                                                                                <option value="" disabled>Gender*</option>
                                                                                <option value="Male">Male</option>
                                                                                <option value="Female">Female</option>
                                                                            </Field>
                                                                            <ErrorMessage name="candidate_gender" component="div" className="text-red-500" />
                                                                        </div>
                                                                        <div>
                                                                            <h2 className='w-32'>Upload Resume*</h2>
                                                                            <input type="file" accept='.pdf' onChange={(event) => {
                                                                                setFieldValue("candidate_resume", event.currentTarget.files[0]);
                                                                            }} />
                                                                            <ErrorMessage name="candidate_resume" component="div" className="text-red-500" />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <h2 className=''>Remarks</h2>
                                                                        <Field as="textarea" name="candidate_remarks"
                                                                            className='outline-none border-2 rounded-lg px-2 w-full'>
                                                                        </Field>
                                                                    </div>
                                                                    <div className='flex items-center mt-5'>
                                                                        {!loadingUpdate ?
                                                                            <><button onClick={(e) => { setCandidateEdit(false); }} className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed' >Cancel</button>
                                                                                <button type='submit' className='darkPurple text-white font-semibold rounded-xl px-4 py-1 hoverGreen'>Submit</button></> :
                                                                            <><button className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg ' >Cancel</button>
                                                                                <button className='darkPurple text-white font-semibold rounded-xl px-4 py-1 '>Loading...</button>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </Form>
                                                            )}
                                                        </Formik>
                                                    </div>
                                                </div>
                                            }
                                        </> : null}
                                </>
                            )
                        })
                        }
                    </div>
                </div> : null
            }
            {viewCandidateHistory ?
                <div onClick={(e) => { setViewCandidateHistory(false); }} className=' w-full h-screen fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                    <div onClick={(e) => { e.stopPropagation(); }} className='relative bg-white overflow-auto mx-10 mb-5 p-10 rounded-2xl '>
                        <button onClick={(e) => { setViewCandidateHistory(false); }} className="absolute bg-white rounded-full w-fit top-1 right-1 z-[11] ">
                            <img className='w-7' src="/img/close.png" alt="close" />
                        </button>
                        {viewCandidateHistory?.length >= 1 ?
                            <div className=' overflow-auto tableBody tableBox'>
                                <table className=' navAnimation rounded-2xl baseWidth'>
                                    <thead className=' text-lg tableHead headingRow'>
                                        <tr className=' bg-gray-200'>
                                            <th>Sr. No.</th>
                                            <th className=' whitespace-nowrap'>Candidate Status</th>
                                            <th className=' whitespace-nowrap'>Updated at date</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tableBody  '>
                                        {
                                            viewCandidateHistory?.map((val, idx) => {
                                                return (
                                                    <tr>
                                                        <td>{idx + 1}</td>
                                                        <td>{val?.candidate_status}</td>
                                                        <td>{val?.status_date?.split("-")[2]} {monthData[parseInt(val?.status_date?.split("-")[1])]} {val?.status_date?.split("-")[0]}</td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div> :
                            <div className='navAnimation flex flex-col justify-center items-center bg-white mx-4 py-5 mb-10 rounded-3xl '>
                                <img className=' w-1/3' src="/img/addReports.png" alt="" />
                                <h1 className=' text-2xl font-bold'>Oops!! "<span className=' text-blue-950'>No History Found</span>"</h1>
                            </div>}
                    </div>
                </div> : null
            }
            {positionWiseView ?
                <>
                    <div onClick={(e) => { setPositionWiseView(false); }} className=' w-full h-screen  fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                        <div onClick={(e) => { e.stopPropagation(); }} className=' relative h-[95vh] w-[95%]  lightBabyGreen rounded-2xl mx-10 px-2 py-4   '>
                            <div className=' absolute bottom-3 right-4'>
                                <div onClick={(e) => { getPositionWiseData(); }} className='cursor-pointer bg-red-600 px-2.5 py-2 rounded-full hoverRed'>
                                    <FontAwesomeIcon icon={faRotateRight} size={"lg"} style={{ color: "#ffffff", }} />
                                </div>
                            </div>
                            <button onClick={(e) => { setPositionWiseView(false); }} className="absolute bg-white rounded-full w-fit top-1 right-1 z-[11] ">
                                <img className='w-7' src="/img/close.png" alt="close" />
                            </button>
                            <div className='h-full w-full  rounded-2xl overflow-y-scroll noScrollbar px-8'>
                                {/* filter */}
                                <div className=' flex flex-wrap items-center gap-3 grayBlurShadow bg-white p-3 mb-5 rounded-xl'>
                                    <div className='flex items-center gap-3'>
                                        <div className=' flex flex-col justify-start items-start'>
                                            <label>From: </label>
                                            <input
                                                className=' border-2 rounded-lg px-2 py-1'
                                                type="date"
                                                value={positionFilter?.fromDate}
                                                onChange={e => setPositionFilter({ ...positionFilter, fromDate: e.target.value })}
                                            />
                                        </div>
                                        <div className=' flex flex-col justify-start items-start'>
                                            <label>To: </label>
                                            <input
                                                className=' border-2 rounded-lg px-2 py-1'
                                                type="date"
                                                value={positionFilter?.toDate}
                                                onChange={e => setPositionFilter({ ...positionFilter, toDate: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <label >Company</label>
                                        <input
                                            value={positionFilter?.company}
                                            className=' border-2 rounded-lg px-2 py-1'
                                            onChange={e => { setFilterData({ ...filterData, company: e.target.value }); setPositionFilter({ ...positionFilter, company: e.target.value }) }}
                                            type="text" />
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <label >Position</label>
                                        <input
                                            value={positionFilter?.position}
                                            className=' border-2 rounded-lg px-2 py-1'
                                            onChange={e => { setFilterData({ ...filterData, position: e.target.value }); setPositionFilter({ ...positionFilter, position: e.target.value }) }}
                                            type="text" />
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <label >Status</label>
                                        <select value={positionFilter?.status}
                                            className=' border-2 rounded-lg px-2 py-1'
                                            onChange={e => { setPositionFilter({ ...positionFilter, status: e.target.value }); setFilterData({ ...filterData, status: e.target.value }) }}>
                                            <option selected disabled value={""}>Select</option>
                                            <option value={"Sent To Client"}>Sent To Client</option>
                                            {
                                                atsStatusList?.map((val) => {
                                                    return (
                                                        <option value={val?.value}>{val?.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    {
                                        localStorage.getItem("role") === "Recruiter" || localStorage.getItem("role") === "Team Lead" ?
                                            null :
                                            <div className='flex flex-col justify-start items-start mb-2'>
                                                <label >Recruiter</label>
                                                <select value={positionFilter?.recruiter}
                                                    className=' border-2 rounded-lg px-2 py-1'
                                                    onChange={e => { setFilterData({ ...filterData, recruiter: e.target.value }); setPositionFilter({ ...positionFilter, recruiter: e.target.value }); }} >
                                                    <option value="" selected disabled>Select Recruiter</option>
                                                    {allUsers?.map((val) => {
                                                        return (
                                                            <option value={val?.id}>{val?.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                    }

                                    <button onClick={getPositionWiseData} className=' darkPurple cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>Apply filter</button>
                                    <button onClick={() => {
                                        setPositionFilter({
                                            fromDate: "",
                                            toDate: "",
                                            company: "",
                                            position: "",
                                            orderBy: "",
                                            recruiter: "",
                                            orderDirection: "",
                                            status: ""
                                        });
                                        setFilterData({
                                            fromDate: "",
                                            toDate: "",
                                            status: statusAts ? statusAts : "",
                                            company: "",
                                            position: "",
                                            candidate: "",
                                            location: "",
                                            mobile: "",
                                            email: "",
                                            recruiter: "",
                                            positionStatus: "Open",
                                            orderBy: "sent_to_client_date",
                                            orderDirection: "DESC"
                                        });

                                    }} className=' text-red-700 bg-red-200  py-2 px-3 rounded-lg hoverRed'>Clear Filter</button>
                                    {/* <div className=' text-left w-fit'>
                                        <button onClick={handleExport} className=' py-2 px-3 bg-green-800 text-white rounded-lg '>{loadingExport ? "Exporting..." : "Excel"}</button>
                                    </div> */}
                                </div>
                                {
                                    !loading ?
                                        Array.isArray(positionWiseData) && positionWiseData?.length >= 1 ?
                                            <div className=' bg-white px-3 pt-5 rounded-2xl'>
                                                <div className=' overflow-auto tableBody  h-[79vh]'>
                                                    <table className=' navAnimation rounded-2xl baseWidth p-5 text-left'>
                                                        <thead className='text-lg tableHead'>
                                                            <tr className=' bg-gray-200'>
                                                                <th className='whitespace-nowrap text-center' >Sr.No.</th>
                                                                <th className='whitespace-nowrap text-center cursor-pointer' onClick={() => { handleSort("upload_date") }} > <div className='flex items-center gap-2 justify-center'>Date {positionFilter?.orderBy === "upload_date" && positionFilter?.orderDirection === "ASC" ? <img className='w-4' src="/img/upArrow.png" alt="up" /> : positionFilter?.orderBy === "upload_date" && positionFilter?.orderDirection === "DESC" ? <img className='w-4' src="/img/downArrow.png" alt="up" /> : <img className='w-4' src="/img/upDownArrow.png" alt="up" />}</div> </th>
                                                                <th className='whitespace-nowrap cursor-pointer' onClick={() => { handleSort("company_name") }}><div className='flex items-center gap-2'>Company Name {positionFilter?.orderBy === "company_name" && positionFilter?.orderDirection === "ASC" ? <img className='w-4' src="/img/upArrow.png" alt="up" /> : positionFilter?.orderBy === "company_name" && positionFilter?.orderDirection === "DESC" ? <img className='w-4' src="/img/downArrow.png" alt="up" /> : <img className='w-4' src="/img/upDownArrow.png" alt="up" />}</div></th>
                                                                <th className='whitespace-nowrap cursor-pointer' onClick={() => { handleSort("position") }}><div className='flex items-center gap-2'>Company Position {positionFilter?.orderBy === "position" && positionFilter?.orderDirection === "ASC" ? <img className='w-4' src="/img/upArrow.png" alt="up" /> : positionFilter?.orderBy === "position" && positionFilter?.orderDirection === "ASC" ? <img className='w-4' src="/img/downArrow.png" alt="up" /> : <img className='w-4' src="/img/upDownArrow.png" alt="up" />}</div></th>
                                                                <th className='whitespace-nowrap text-center'>Count</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='tableBody '>
                                                            {
                                                                Array.isArray(positionWiseData) && positionWiseData?.map((val, idx) => {
                                                                    return (
                                                                        <>
                                                                            {/* {val?.created_at?.split("T")[0] === sourceViewModal ? */}
                                                                            <tr key={val?.id} id={val?.id} className=' backWhite font-semibold text-sm'>
                                                                                <td className='whitespace-nowrap text-center'>{idx + 1}</td>
                                                                                <td
                                                                                    className='whitespace-nowrap text-center'>{val?.upload_date?.split("-")[2]} {monthData[parseInt(val?.upload_date?.split("-")[1])]} {val?.upload_date?.split("-")[0]}</td>
                                                                                <td className=' hover:text-blue-700 cursor-pointer'
                                                                                    onClick={() => {
                                                                                        setFilterData({
                                                                                            ...filterData,
                                                                                            company: val?.company_name,
                                                                                            position: val?.position_name,
                                                                                            fromDate: "",
                                                                                            toDate: "",
                                                                                            candidate: "",
                                                                                            location: "",
                                                                                            mobile: "",
                                                                                            email: "",
                                                                                            orderBy: "sent_to_client_date",
                                                                                            orderDirection: "DESC"
                                                                                        });
                                                                                        setPositionWiseView(false);
                                                                                    }} >{val?.company_name}</td>
                                                                                <td className=' hover:text-blue-700 cursor-pointer'
                                                                                    onClick={() => {
                                                                                        setFilterData({
                                                                                            ...filterData,
                                                                                            company: val?.company_name,
                                                                                            position: val?.position_name,
                                                                                            fromDate: "",
                                                                                            toDate: "",
                                                                                            candidate: "",
                                                                                            location: "",
                                                                                            mobile: "",
                                                                                            email: "",
                                                                                            orderBy: "sent_to_client_date",
                                                                                            orderDirection: "DESC"
                                                                                        });
                                                                                        setPositionWiseView(false);
                                                                                    }}
                                                                                >{val?.position_name}</td>
                                                                                <td className='whitespace-nowrap text-center'>{val?.candidate_count}</td>
                                                                            </tr>
                                                                            {/* : null} */}

                                                                        </>

                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table >
                                                </div>
                                                <div className=' flex items-center justify-between pb-4'>
                                                    <div className=' flex items-center gap-2'>
                                                        <div className='grayBlurShadow px-3 py-1'>
                                                            <select value={limitPositionWise} onChange={(e) => { setLimitPositionWise(e.target.value); }} >
                                                                <option value="10" selected>10</option>
                                                                <option value="20" >20</option>
                                                                <option value="50" >50</option>
                                                                <option value="100" >100</option>
                                                                <option value="200" >200</option>
                                                                <option value="500" >500</option>

                                                            </select>
                                                        </div>
                                                        <div className='grayBlurShadow px-1 py-1 text-sm font-semibold'>
                                                            <h1>Total Records: {totalRecordsPositionWise}</h1>
                                                        </div>
                                                        <div className='grayBlurShadow px-1 py-1  text-sm font-semibold'>
                                                            <h1>Total Pages: {allPagesPositionWise}</h1>
                                                        </div>
                                                    </div>
                                                    <div className='flex items-center grayBlurShadow px-3 py-1 rounded-lg'>
                                                        <div onClick={(e) => {
                                                            if (pagePositionWise > 1) {
                                                                setPage(page - 1);
                                                            }
                                                        }} className={` ${pagePositionWise === 1 ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                                            <img className='w-5' src="/img/leftArrow.png" alt="leftArrow" />
                                                        </div>
                                                        <div className=' px-3'>
                                                            {page}
                                                        </div>
                                                        <div onClick={(e) => {
                                                            if (allPagesPositionWise > pagePositionWise) {
                                                                setPage(pagePositionWise + 1);
                                                            }
                                                        }} className={` ${pagePositionWise == allPagesPositionWise ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                                            <img className='w-5' src="/img/rightArrow.png" alt="rightArrow" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> :
                                            <div className='navAnimation w-full flex flex-col justify-center items-center bg-white mt-5 py-5 mb-10 rounded-3xl '>
                                                <img className=' w-[25%]' src="/img/addReports.png" alt="" />
                                                <h1 className=' text-2xl font-bold'>Oops!! "<span className=' text-blue-950'>No Reports Found</span>"</h1>
                                            </div> :
                                        <div className=' h-[60vh] mb-5 w-full bg-white mt-5 rounded-3xl '>
                                            <img className=' m-auto w-1/4' src="/img/loader2.gif" alt="loading..." />
                                        </div>
                                }
                            </div>

                        </div>
                    </div>
                </> : null}
            {!loadingFilter ?
                <>
                    <div className=' flex flex-wrap items-center gap-x-3 gap-y-1 grayBlurShadow bg-white p-3 mt-5 rounded-xl'>
                        {/* <h1>Date Filter</h1> */}
                        <div className='flex items-center gap-3'>
                            <div className=' flex flex-col justify-start items-start'>
                                <label>From: </label>
                                <input
                                    className=' border-2 rounded-lg px-2 py-1'
                                    type="date"
                                    value={filterData?.fromDate}
                                    onChange={e => setFilterData({ ...filterData, fromDate: e.target.value })}
                                />
                            </div>
                            <div className=' flex flex-col justify-start items-start'>
                                <label>To: </label>
                                <input
                                    className=' border-2 rounded-lg px-2 py-1'
                                    type="date"
                                    value={filterData?.toDate}
                                    onChange={e => setFilterData({ ...filterData, toDate: e.target.value })}
                                />
                            </div>
                        </div>

                        <div className='flex flex-col justify-start items-start mb-2'>
                            <label >Company</label>
                            <input
                                value={filterData?.company}
                                className=' border-2 rounded-lg px-2 py-1'
                                onChange={e => { setFilterData({ ...filterData, company: e.target.value }); setPositionFilter({ ...positionFilter, company: e.target.value }) }}
                                type="text" />
                        </div>
                        <div className='flex flex-col justify-start items-start mb-2'>
                            <label >Position</label>
                            <input
                                value={filterData?.position}
                                className=' border-2 rounded-lg px-2 py-1'
                                onChange={e => { setFilterData({ ...filterData, position: e.target.value }); setPositionFilter({ ...positionFilter, position: e.target.value }) }}
                                type="text" />
                        </div>
                        <div className='flex flex-col justify-start items-start mb-2'>
                            <label >Candidate</label>
                            <input
                                value={filterData?.candidate}
                                className=' border-2 rounded-lg px-2 py-1'
                                onChange={e => { setFilterData({ ...filterData, candidate: e.target.value }); setPositionFilter({ ...positionFilter, candidate: e.target.value }) }}
                                type="text" />
                        </div>
                        <div className='flex flex-col justify-start items-start mb-2'>
                            <label >Location</label>
                            <input
                                value={filterData?.location}
                                className=' border-2 rounded-lg px-2 py-1'
                                onChange={e => setFilterData({ ...filterData, location: e.target.value })}
                                type="text" />
                        </div>
                        <div className='flex flex-col justify-start items-start mb-2'>
                            <label >Mobile</label>
                            <input
                                value={filterData?.mobile}
                                className=' border-2 rounded-lg px-2 py-1'
                                onChange={e => setFilterData({ ...filterData, mobile: e.target.value })}
                                type="text" />
                        </div>
                        <div className='flex flex-col justify-start items-start mb-2'>
                            <label >Email</label>
                            <input
                                value={filterData?.email}
                                className=' border-2 rounded-lg px-2 py-1'
                                onChange={e => setFilterData({ ...filterData, email: e.target.value })}
                                type="text" />
                        </div>
                        {
                            localStorage.getItem("role") === "Recruiter" || localStorage.getItem("role") === "Team Lead" ?
                                null :
                                <div className='flex flex-col justify-start items-start mb-2'>
                                    <label >Recruiter</label>
                                    <select value={filterData?.recruiter}
                                        className=' border-2 rounded-lg px-2 py-1'
                                        onChange={e => { setFilterData({ ...filterData, recruiter: e.target.value }); setPositionFilter({ ...positionFilter, recruiter: e.target.value }); }} >
                                        <option value="" selected disabled>Select Recruiter</option>
                                        {allUsers?.map((val) => {
                                            return (
                                                <option value={val?.id}>{val?.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                        }

                        <div className='flex flex-col justify-start items-start mb-2'>
                            <label >Status</label>
                            <select value={filterData?.status}
                                className=' border-2 rounded-lg px-2 py-1'
                                onChange={e => { setFilterData({ ...filterData, status: e.target.value }); setPositionFilter({ ...positionFilter, status: e.target.value }) }}>
                                <option selected disabled value={""}>Select</option>
                                <option value={"Sent To Client"}>Sent To Client</option>
                                {
                                    atsStatusList?.map((val) => {
                                        return (
                                            <option value={val?.value}>{val?.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='flex flex-col justify-start items-start '>
                            <label >Position Status</label>
                            <select value={filterData?.positionStatus}
                                className=' border-2 rounded-lg px-2 py-1 w-full'
                                onChange={e => setFilterData({ ...filterData, positionStatus: e.target.value })}>
                                <option value="All">All</option>
                                <option value="Open">Open</option>
                                <option value="Close">Close</option>
                            </select>
                        </div>
                        <div className='flex gap-3 justify-start items-start'>
                            <button onClick={handleFilter} className=' darkPurple cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>Apply filter</button>
                            <button onClick={() => {
                                setPositionFilter({
                                    fromDate: "",
                                    toDate: "",
                                    company: "",
                                    position: "",
                                    orderBy: "",
                                    recruiter: "",
                                    orderDirection: "",
                                    status: ""
                                });
                                setFilterData({
                                    fromDate: "",
                                    toDate: "",
                                    status: statusAts ? statusAts : "",
                                    company: "",
                                    position: "",
                                    candidate: "",
                                    location: "",
                                    mobile: "",
                                    email: "",
                                    recruiter: "",
                                    positionStatus: "Open",
                                    orderBy: "sent_to_client_date",
                                    orderDirection: "DESC"
                                });
                                setIsClearFilter(true);
                                // fetchData();
                            }} className=' text-red-700 bg-red-200  py-2 px-3 rounded-lg hoverRed'>Clear Filter</button>
                            <div className=' text-left w-fit'>
                                <button onClick={handleExport} className=' py-2 px-3 bg-green-800 text-white rounded-lg '>{loadingExport ? "Exporting..." : "Excel"}</button>
                            </div>
                            <div className=' text-left w-fit'>
                                <button onClick={(e) => {

                                    setPositionWiseView(true);
                                }} className=' py-2 px-3 bg-purple-700 hover:bg-purple-900 text-white rounded-lg '>Position Details</button>
                            </div>
                        </div>
                    </div>
                </> : null
            }
            <section className="bg-white navAnimation grayBlurShadow px-7 pt-7 rounded-3xl mb-8 mt-5 " >
                {
                    !loading ?
                        Array.isArray(atsPipeline) && atsPipeline?.length >= 1 ?
                            <section className=' bg-white overflow-auto  '>
                                <div className=' overflow-auto tableBody tableBox'>
                                    <table className='  rounded-2xl baseWidth text-left'>
                                        <thead className='  text-lg tableHead'>
                                            <tr className=' bg-gray-200'>
                                                <th className='whitespace-nowrap text-center'>Sr. No.</th>
                                                <th className='whitespace-nowrap text-center'>
                                                    <div onClick={() => handleAtsSort("sent_to_client_date")} className='flex item-center gap-2 justify-center w-full cursor-pointer'>
                                                        Sent Date
                                                        {filterData?.orderBy === "sent_to_client_date" && filterData?.orderDirection === "ASC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                            filterData?.orderBy === "sent_to_client_date" && filterData?.orderDirection === "DESC" ?
                                                                <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                                <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                    </div></th>
                                                <th className=' whitespace-nowrap text-center'>Current Status</th>
                                                <th className=' whitespace-nowrap' >
                                                    <div onClick={() => handleAtsSort("candidate")} className='flex item-center gap-2 justify-center w-full cursor-pointer'>
                                                        Candidate
                                                        {filterData?.orderBy === "candidate" && filterData?.orderDirection === "ASC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                            filterData?.orderBy === "candidate" && filterData?.orderDirection === "DESC" ?
                                                                <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                                <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                    </div></th>
                                                <th className=' whitespace-nowrap' >
                                                    <div onClick={() => handleAtsSort("company_name")} className='flex item-center gap-2 justify-center w-full cursor-pointer'>
                                                        Company Name
                                                        {filterData?.orderBy === "company_name" && filterData?.orderDirection === "ASC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                            filterData?.orderBy === "company_name" && filterData?.orderDirection === "DESC" ?
                                                                <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                                <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                    </div></th>
                                                <th className=' whitespace-nowrap' >
                                                    <div onClick={() => handleAtsSort("position")} className='flex item-center gap-2 justify-center w-full cursor-pointer'>
                                                        Position Name
                                                        {filterData?.orderBy === "position" && filterData?.orderDirection === "ASC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                            filterData?.orderBy === "position" && filterData?.orderDirection === "DESC" ?
                                                                <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                                <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                    </div></th>
                                                <th className=' whitespace-nowrap' >Location</th>
                                                <th >Mobile</th>
                                                {/* <th className=' whitespace-nowrap'>Candidate CCTC</th> */}
                                                {/* <th className=' whitespace-nowrap'>Candidate ECTC</th> */}
                                                <th className=' whitespace-nowrap'>Candidate CTC</th>
                                                <th className=' whitespace-nowrap'>Notice Period</th>
                                                <th >Email</th>
                                                <th className=' whitespace-nowrap'>Candidate Info</th>
                                                <th className=' whitespace-nowrap'>Status History</th>
                                                {localStorage.getItem("role") === "Recruiter" || localStorage.getItem("role") === "Team Lead" ?
                                                    null : <th className=' whitespace-nowrap'>Assigned Recruiter</th>
                                                }
                                                <th className=' whitespace-nowrap'>Remarks</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className='tableBody '>
                                            {
                                                Array.isArray(atsPipeline) && atsPipeline?.map((val, idx) => {
                                                    return (
                                                        <tr key={val?._id} id={val?._id} className=' backWhite font-semibold text-sm'>
                                                            <td className='whitespace-nowrap text-center'>{idx + 1}</td>
                                                            <td className='whitespace-nowrap text-center'>{val?.sent_to_client_date?.split("-")[2]} {monthData[parseInt(val?.sent_to_client_date?.split("-")[1])]} {val?.sent_to_client_date?.split("-")[0]}</td>
                                                            <td >
                                                                {
                                                                    val?.candidate_status === "CV Rejected" ?
                                                                        <h3 className={` whitespace-nowrap w-full text-center outline-none py-2 px-1 text-red-500 redBox`}>
                                                                            CV Rejected
                                                                        </h3> :
                                                                        val?.candidate_status === "Duplicate CV" ?
                                                                            <h3 className={` whitespace-nowrap w-full text-center outline-none py-2 px-1 text-white rounded-lg bg-yellow-700`}>
                                                                                Duplicate CV
                                                                            </h3> :
                                                                            val?.candidate_status === "Backout" ?
                                                                                <h3 className={` whitespace-nowrap w-full text-center outline-none py-2 px-1 text-red-700 redBox`}>
                                                                                    Backout
                                                                                </h3> :
                                                                                val?.candidate_status === "Rejected Post Interview" ?
                                                                                    <h3 className={` whitespace-nowrap w-full text-center outline-none py-2 px-1 text-red-500 redBox`}>
                                                                                        Rejected Post Interview
                                                                                    </h3> :
                                                                                    val?.candidate_status === "Interview Scheduled" ?
                                                                                        <h3 className={` whitespace-nowrap w-full text-center outline-none py-2 px-1 rounded-lg bg-purple-200 text-purple-800`}>
                                                                                            Interview Scheduled
                                                                                        </h3> :
                                                                                        val?.candidate_status === "Interview Feedback Pending" ?
                                                                                            <h3 className={` whitespace-nowrap w-full text-center outline-none py-2 px-1 rounded-lg text-amber-800 bg-amber-300`}>
                                                                                                Interview Feedback Pending
                                                                                            </h3> :
                                                                                            val?.candidate_status === "Shortlisted Next Round" ?
                                                                                                <h3 className={` whitespace-nowrap w-full text-center outline-none py-2 px-1 rounded-lg text-blue-500 blueBox`}>
                                                                                                    Shortlisted Next Round
                                                                                                </h3> :
                                                                                                val?.candidate_status === "Hold Post Interview" ?
                                                                                                    <h3 className={` whitespace-nowrap w-full text-center outline-none py-2 px-1 rounded-lg text-yellow-500 yellowBox`}>
                                                                                                        Hold Post Interview
                                                                                                    </h3> :
                                                                                                    val?.candidate_status === "Sent To Client" ?
                                                                                                        <div className={` p-2 greenBox `}>
                                                                                                            <select value={status && isUpdate === val?.id ? status : val?.candidate_status}
                                                                                                                onChange={(e) => { e.stopPropagation(); setIsUpdate(val?.id); setStatus(e.target.value); setCandidateId(val?.id); setConfirmation(true); }}
                                                                                                                className=' bg-transparent outline-none w-full' >
                                                                                                                <option selected disabled value={"Sent To Client"}>Sent To Client</option>
                                                                                                                {
                                                                                                                    atsStatusSentToClient?.map((val) => {
                                                                                                                        return (
                                                                                                                            <option value={val?.value}>{val?.name}</option>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </select>
                                                                                                        </div> :
                                                                                                        val?.candidate_status === "Shortlisted" ?
                                                                                                            <div className={` p-2 greenBox `}>
                                                                                                                <select value={status && isUpdate === val?.id ? status : val?.candidate_status}
                                                                                                                    onChange={(e) => { e.stopPropagation(); setIsUpdate(val?.id); setStatus(e.target.value); setCandidateId(val?.id); setConfirmation(true); }}
                                                                                                                    className=' bg-transparent outline-none w-full' >
                                                                                                                    <option selected disabled value={"Shortlisted"}>Shortlisted</option>
                                                                                                                    {
                                                                                                                        atsStatusShortlisted?.map((val) => {
                                                                                                                            return (
                                                                                                                                <option value={val?.value}>{val?.name}</option>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </select>
                                                                                                            </div> :
                                                                                                            val?.candidate_status === "Final Selection" ?
                                                                                                                <div className={` p-2 text-green-500 greenBox`}>
                                                                                                                    <select value={status && isUpdate === val?.id ? status : val?.candidate_status}
                                                                                                                        onChange={(e) => { e.stopPropagation(); setIsUpdate(val?.id); setStatus(e.target.value); setCandidateId(val?.id); setConfirmation(true); }}
                                                                                                                        className=' bg-transparent outline-none w-full' >
                                                                                                                        <option selected disabled value={"Final Selection"}>Final Selection</option>
                                                                                                                        {
                                                                                                                            atsStatusFinalSelection?.map((val) => {
                                                                                                                                return (
                                                                                                                                    <option value={val?.value}>{val?.name}</option>
                                                                                                                                )
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </select>
                                                                                                                </div> :
                                                                                                                val?.candidate_status === "Final Joining" ?
                                                                                                                    <div className={` p-2 greenBox `}>
                                                                                                                        <select value={status && isUpdate === val?.id ? status : val?.candidate_status}
                                                                                                                            onChange={(e) => { e.stopPropagation(); setIsUpdate(val?.id); setStatus(e.target.value); setCandidateId(val?.id); setConfirmation(true); }}
                                                                                                                            className=' bg-transparent outline-none w-full' >
                                                                                                                            <option selected disabled value={"Final Joining"}>Candidate Joined</option>
                                                                                                                            {
                                                                                                                                atsStatusFinalJoining?.map((val) => {
                                                                                                                                    return (
                                                                                                                                        <option value={val?.value}>{val?.name}</option>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }
                                                                                                                        </select>
                                                                                                                    </div> :
                                                                                                                    <div className={` p-2 greenBox `}>
                                                                                                                        <h3 className={` whitespace-nowrap w-full text-center outline-none py-2 px-1 rounded-lg text-yellow-500 yellowBox`}>
                                                                                                                            {val?.candidate_status}
                                                                                                                        </h3>
                                                                                                                    </div>
                                                                }
                                                            </td>
                                                            <td className=' whitespace-nowrap'>{val?.candidate}</td>
                                                            <td className=' whitespace-nowrap'> {val?.Position.Company.company_name} </td>
                                                            <td className=' whitespace-nowrap'>{val?.Position.position}</td>

                                                            <td >{val?.candidate_location ? val?.candidate_location : "NA"} </td>
                                                            <td className=' whitespace-nowrap' >{val?.candidate_phone ? val?.candidate_phone : "NA"} </td>
                                                            {/* <td >{val?.candidate_current_ctc ? val?.candidate_current_ctc : "NA"}</td> */}
                                                            {/* <td >{val?.candidate_expected_ctc ? val?.candidate_expected_ctc : "NA"}</td> */}
                                                            <td className=' whitespace-nowrap'>{val?.candidate_current_ctc ? val?.candidate_current_ctc : "NA"} <span className='text-blue-600'>-</span> {val?.candidate_expected_ctc ? val?.candidate_expected_ctc : "NA"}</td>
                                                            <td >{val?.candidate_notice_period ? val?.candidate_notice_period : "NA"}</td>
                                                            <td >{val?.candidate_email ? val?.candidate_email : "NA"}</td>
                                                            <td className='text-center'><button onClick={(e) => { setViewCandidate(val?.id) }} className=' darkPurple text-white font-semibold rounded-xl px-4 text-sm py-1 hoverGreen'>View</button></td>
                                                            <td className='text-center'><button onClick={(e) => { getCandidateHistory(e, val?.id) }} className=' darkPurple text-white font-semibold rounded-xl px-4 text-sm py-1 hoverGreen'>View</button></td>
                                                            {localStorage.getItem("role") === "Recruiter" || localStorage.getItem("role") === "Team Lead" ?
                                                                null :
                                                                <td>
                                                                    <h1 className=' p-2 rounded-lg greenBox w-full whitespace-nowrap text-center'>{val?.User?.name}</h1>

                                                                </td>}
                                                            <td>
                                                                <div className=' min-w-[13rem]'>
                                                                    {
                                                                        isUpdate === val?.id ?
                                                                            <textarea value={isRemarks} onChange={(e) => { setIsUpdate(val?.id); setIsRemarks(e.target.value); }} placeholder='Remarks...' className=' outline-none border-2 rounded-lg px-2 w-full'></textarea> :
                                                                            <textarea value={val?.remarks} onChange={(e) => { setIsUpdate(val?.id); setIsRemarks(val?.remarks) }} placeholder='Remarks...' className=' outline-none border-2 rounded-lg px-2 w-full'></textarea>
                                                                    }

                                                                </div>
                                                            </td>

                                                            <td className=' flex justify-center items-center'>
                                                                {isUpdate === val?.id ?
                                                                    <button onClick={(e) => { handleRemarks(e, val?.id) }} className=' bg-green-200 text-green-600 rounded-xl px-3 py-1 font-semibold'>Save</button> :
                                                                    <button className=' bg-gray-200 text-gray-400 cursor-not-allowed rounded-xl px-3 py-1 font-semibold'>Save</button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className=' flex items-center justify-between py-4'>
                                    <div className=' flex items-center gap-2'>
                                        <div className='grayBlurShadow px-3 py-1'>
                                            <select value={limit} onChange={(e) => { setLimit(e.target.value); }} >
                                                <option value="10" selected>10</option>
                                                <option value="20" >20</option>
                                                <option value="50" >50</option>
                                                <option value="100" >100</option>
                                                <option value="200" >200</option>
                                                <option value="500" >500</option>

                                            </select>
                                        </div>
                                        <div className='grayBlurShadow px-1 py-1 text-sm font-semibold'>
                                            <h1>Total Records: {totalRecords}</h1>
                                        </div>
                                        <div className='grayBlurShadow px-1 py-1  text-sm font-semibold'>
                                            <h1>Total Pages: {allPages}</h1>
                                        </div>
                                    </div>
                                    <div className='flex items-center grayBlurShadow px-3 py-1 rounded-lg'>
                                        <div onClick={(e) => {
                                            if (page > 1) {
                                                setPage(page - 1);
                                            }
                                        }} className={` ${page === 1 ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                            <img className='w-5' src="/img/leftArrow.png" alt="leftArrow" />
                                        </div>
                                        <div className=' px-3'>
                                            {page}
                                        </div>
                                        <div onClick={(e) => {
                                            if (allPages > page) {
                                                setPage(page + 1);
                                            }
                                        }} className={` ${page == allPages ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                            <img className='w-5' src="/img/rightArrow.png" alt="rightArrow" />
                                        </div>
                                    </div>
                                </div>
                            </section>
                            :
                            <div className='navAnimation flex flex-col justify-center items-center bg-white mt-5 py-5 mb-10 rounded-3xl '>
                                <img className=' w-[24%]' src="/img/addReports.png" alt="" />
                                <h1 className=' text-2xl font-bold'>Oops!! "<span className=' text-blue-950'>No Reports Found</span>"</h1>
                            </div>
                        :
                        <div className=' loaderBox mb-5 min-h-[90vh] bg-white mt-5 rounded-3xl '>
                            <img className=' m-auto w-1/4' src="/img/loader2.gif" alt="loading..." />
                        </div>
                }
            </section>

        </section>
    )
}

export default AtsPipeline;
import React, { useContext, useEffect, useState } from 'react';
import "./loginSignup.css";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { basePath, path } from '../Constants/constant';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../ContextApi/ContextApi';

function Login(props) {
    const navigate = useNavigate();
    const { user, setUser,token,setToken } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        username: "",
        password: "",
    });

    const notify = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const notifyInfo = (msg) => toast.info(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const notifyFillTheFields = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let data = {
            username_login: formData?.username,
            password_login: formData?.password
        }
        console.log(data);
        let options = {
            url: `${basePath}/login`,
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            data: data,
        }
        axios(options)
            .then((responce) => {
                console.log(responce);
                if (responce.status === 200) {
                    localStorage.setItem("name", responce.data.name);
                    localStorage.setItem("userId", responce.data.id);
                    localStorage.setItem("token",responce.data.token);
                    localStorage.setItem("role", responce?.data?.permissions[0].Role.role_name);
                    setToken(responce.data.token);
                    notify("Login Successfully");
                    let role = localStorage.getItem("role");
                    if (role === "Super Admin") {
                        navigate("/main");
                    }
                    else if (role) {
                        navigate("/");
                    }
                    setFormData({
                        username: "",
                        password: "",
                    });
                    setLoading(false)
                    console.log("Information entered successfully");
                }
                else {
                    setLoading(false);
                    notifyFillTheFields("Unable to login 1");
                }
            })
            .catch((e) => {
                setLoading(false);
                console.log(e);
                notifyInfo(e.response?.data?.error);
            });
    }
    const checkField = () => {
        if (!formData?.username && !formData?.password) {
            notifyFillTheFields("Username and password required");
        } else if (!formData?.username) {
            notifyFillTheFields("Username is required");
        } else if (!formData?.password) {
            notifyFillTheFields("Password is required");
        }
    }
    console.log(formData);
    useEffect(() => {
        let role = localStorage.getItem("role");
        if (role === "Super Admin") {
            navigate("/main");
        }
        else if (role) {
            navigate("/");
        }
    }, []);
    return (
        <section onClick={() => { }} className=' lightBabyGreen relative w-screen h-screen'>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            {/* <div className='babyGreen smoothTransition textDarkPurple px-10 rounded-3xl flex items-center justify-between navAnimation width80 m-auto mb-10'>
                <div className=' text-left'>
                    <h1 className=' text-5xl font-bold'>User Registration</h1>
                    <p className=' text-gray-500 font-semibold'>Ready to start your day</p>
                </div>
                <img className=' w-44' src="/img/registration.png" alt="registration" />
            </div> */}
            <div className=' w-full h-full flex items-center justify-center'>
                <div className=' flex flex-wrap w-fit rounded-xl overflow-hidden grayBlurShadow'>
                    <div className=''>
                        <img className='loginImg' src="/img/login.png" alt="login image" />
                    </div>
                    <div className=' flex items-center bg-white p-5 loginBox'>
                        <div className='relative p-7  rounded-xl w-full'>
                            <img className=' w-36 m-auto' src="/img/tcoLogo4.png" alt="" />
                            <h1 className=' font-semibold text-xl text-blue-950 mb-5'>Login to your account</h1>
                            {/* <h1 className=' bg-white absolute top-15px font-semibold px-1 textDarkBlue '>Login</h1> */}
                            <form id='login' onSubmit={handleSubmit} className=' flex flex-col gap-5 '>
                                <div className='input-group relative w-full '>
                                    <input value={formData?.username} onChange={(e) => { setFormData({ ...formData, username: e.target.value }) }} id='username' placeholder='Username' className=' w-full' required type="text" />
                                    <label htmlFor="username" className='label'>Username</label>
                                </div>
                                <div className='input-group relative w-full '>
                                    <input value={formData?.password} onChange={(e) => { setFormData({ ...formData, password: e.target.value }) }} autoComplete='off' id='password' placeholder='Password' className=' w-full' required type="password" />
                                    <label htmlFor="password" className='label'>Password</label>
                                </div>
                                <div>
                                    <button onClick={checkField} type='submit' className=' w-fit darkPurple text-white font-semibold rounded-xl px-7 text-sm py-2 hoverGreen'>{!loading? "Login" : "Loading..." }</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Login;
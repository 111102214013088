import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { debounce } from "lodash";
import { basePath, countryPhoneCode, monthData } from '../Constants/constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight, faXmark } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom';
import { AppContext } from '../ContextApi/ContextApi';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

function ScreenedCandidate(props) {
    const { index } = useParams();
    const { industry, setIndustry, isModalOpen, setIsModalOpen, user } = useContext(AppContext);
    const [allCandidates, setAllCandidates] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [viewCandidate, setViewCandidate] = useState(false);
    const [viewCompany, setViewCompany] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [status, setStatus] = useState(false);
    const [loadingFilter, setLoadingFilter] = useState(true);
    const [loadingExport, setLoadingExport] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [loading, setLoading] = useState(true);
    const [candidateEdit, setCandidateEdit] = useState(false);
    const [isClearFilter, setIsClearFilter] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [allPages, setAllPages] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalRecordsPositionWise, setTotalRecordsPositionWise] = useState(0);
    const [allPagesPositionWise, setAllPagesPositionWise] = useState(0);
    const [pagePositionWise, setPagePositionWise] = useState(1);
    const [limitPositionWise, setLimitPositionWise] = useState(10);
    const [countryPhone, setCountryPhone] = useState("+91");
    const [countryPhoneLength, setCountryPhoneLength] = useState(10);
    const [ctcType, setCtcType] = useState("");
    const [positionWiseView, setPositionWiseView] = useState(false);
    const [positionWiseData, setPositionWiseData] = useState([]);
    const [candidate, setCandidate] = useState({
        candidate_email: "",
        candidate_phone: "",
        candidate_alt_phone: "",
        candidate_location: "",
        candidate_experience: "",
        candidate_current_ctc: "",
        candidate_expected_ctc: "",
        candidate_organization: "",
        candidate_designation: "",
        candidate_notice_period: "",
        candidate_qualification: "",
        candidate_gender: "",
        candidate_remarks: "",
        candidate_resume: "",
    });
    const [filterData, setFilterData] = useState({
        fromDate: "",
        toDate: "",
        company: "",
        position: "",
        candidate: "",
        recruiter: "",
        orderBy: "sourcing_date",
        orderDirection: "DESC"
    });
    const [positionFilter, setPositionFilter] = useState({
        fromDate: "",
        toDate: "",
        company: "",
        position: "",
        recruiter: "",
        orderBy: "upload_date",
        orderDirection: "DESC"
    });

    const notify = (msg = "successfull!!") => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const notifyFillTheFields = (msg = "There some issue!!") => toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });


    // async function fetchCandidate() {
    //     try {
    //         setLoading(true);
    //         let res = await axios.get("http://127.0.0.1:3002/screenedcandidate");
    //         console.log("===>>>>>");
    //         console.log(res.data);
    //         setAllCandidates(res.data);
    //         setLoading(false);
    //     } catch (error) {
    //         setLoading(false);
    //         console.log(error);
    //     }
    // }
    async function fetchCandidate() {
        try {
            setLoading(true);
            let res = await axios.get(`${basePath}/getscreenedcandidatepagination?limit=${limit}&page=${page}`);
            console.log("fetchCandidate===>>>>>");
            console.log(res.data);
            setAllCandidates(res.data.candidates);
            setTotalRecords(res?.data?.totalRecords);
            setAllPages(res?.data?.pages);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    async function fetchFilterCandidate() {
        try {
            setLoading(true);
            let res = await axios.get(`${basePath}/getscreenedcandidatepagination?limit=${limit}&page=${page}&filter=${JSON.stringify(filterData)}`);
            console.log("Filter===>>>>>");
            console.log(res.data);
            setAllCandidates(res.data.candidates);
            setTotalRecords(res?.data?.totalRecords);
            setAllPages(res?.data?.pages);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    const handleScreenedSort = (columnName) => {
        if (filterData?.orderBy === columnName) {
            if (filterData?.orderDirection === "DESC") {
                setFilterData({ ...filterData, orderBy: columnName, orderDirection: 'ASC' });
            }
            else {
                setFilterData({ ...filterData, orderBy: columnName, orderDirection: 'DESC' });
            }
        }
        else {
            setFilterData({ ...filterData, orderBy: columnName, orderDirection: 'ASC' });
        }
    }
    async function getPositionWiseData() {
        try {
            setLoading(true);
            let res = await axios.get(`${basePath}/getpositionwisescreenedcandidatecount?limit=${limitPositionWise}&page=${pagePositionWise}&id=${localStorage.getItem("userId")}&filter=${JSON.stringify(positionFilter)}`);
            console.log("+++++>>>>>res.data", res.data);
            let temp = res.data?.report?.filter((val) => {
                if (val?.candidate_count) {
                    return val;
                }
            });
            console.log("+++++>>>>>res.data", temp);
            setPositionWiseData(temp);
            setTotalRecordsPositionWise(temp?.length);
            let pages = temp?.length / 10;
            pages = Math.ceil(pages);
            setAllPagesPositionWise(pages);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
        // setRowData(sent);
    }
    const getUserData = async () => {
        let response = await axios.get(`${basePath}/getRecruiter`);
        console.log(response.data);
        setAllUsers(response?.data);
    }
    useEffect(() => {
        getPositionWiseData();
    }, [limitPositionWise, pagePositionWise, positionFilter])
    const handleExport = async () => {
        try {
            setLoadingExport(true);
            const response = await axios.get(`${basePath}/getscreenedcandidatepagination?download=yes&filter=${JSON.stringify(filterData)}`, {
                responseType: 'blob', // Ensure response is treated as a blob
            });

            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a temporary <a> element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Screened_Candidates_${new Date()}.xlsx`);
            document.body.appendChild(link);
            link.click();

            // Clean up resources
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            setLoadingExport(false);
        } catch (error) {
            console.error('Error exporting jobs:', error);
            setLoadingExport(false);
        }
    };
    const handleSort = (columnName) => {
        if (positionFilter?.orderBy === columnName) {
            if (positionFilter?.orderDirection === "DESC") {
                setPositionFilter({ ...positionFilter, orderBy: columnName, orderDirection: 'ASC' });
            }
            else {
                setPositionFilter({ ...positionFilter, orderBy: columnName, orderDirection: 'DESC' });
            }
        }
        else {
            setPositionFilter({ ...positionFilter, orderBy: columnName, orderDirection: 'ASC' });
        }
    }
    const downloadButton = async (canId, candidateName) => {
        setLoadingDownload(canId);
        try {
            const response = await fetch(`${basePath}/downloadresume/${canId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${candidateName.replace(/ /g, "_")}_resume_${new Date().toISOString().split("T")[0]?.split("-")[2]}-${monthData[parseInt(new Date().toISOString().split("T")[0]?.split("-")[1])]}-${new Date().toISOString().split("T")[0]?.split("-")[0]}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url); // Cleanup the URL object
            setLoadingDownload(false);
        } catch (error) {
            console.error('There was a problem with the download:', error);
            if (error?.status === 404) {
                notifyFillTheFields(error?.response?.error);
            }
            else {
                notifyFillTheFields("There was a problem with the download");
            }
            setLoadingDownload(false);
        }
    }
    useEffect(() => {
        console.log(filterData);
        if (
            !loadingFilter &&
            !filterData?.candidate &&
            !filterData?.company &&
            !filterData?.position &&
            !filterData?.fromDate &&
            !filterData?.toDate &&
            !filterData?.orderBy &&
            !filterData?.orderDirection
        ) {
            fetchCandidate();
        }
        else if (!loadingFilter) {
            fetchFilterCandidate();
        }
    }, [limit, page, filterData]);
    const handleFilter = debounce(() => {
        if (
            !loadingFilter &&
            !filterData?.fromDate &&
            !filterData?.toDate &&
            !filterData?.company &&
            !filterData?.position &&
            !filterData?.candidate
        ) {
            fetchCandidate();
        }
        else if (!loadingFilter) {
            fetchFilterCandidate();
        }
    }, 100);

    useEffect(() => {
        if (isClearFilter) {
            if (
                !loadingFilter &&
                !filterData?.fromDate &&
                !filterData?.toDate &&
                !filterData?.company &&
                !filterData?.position &&
                !filterData?.candidate
            ) {
                fetchCandidate();
                setIsClearFilter(false);
            }
            else if (!loadingFilter) {
                fetchFilterCandidate();
                setIsClearFilter(false);
            }
        }
    }, [filterData]);
    useEffect(() => {
        getUserData();
        setLoadingFilter(true);
        fetchCandidate();
        setLoadingFilter(false);
    }, []);

    // updating candidate current application status
    const handleStatus = async (e, statusId) => {

        let data = {
            sourcing_status: status,
            recruiter_id: localStorage.getItem("userId")
        }
        const options = {
            url: `${basePath}/statuschange/${statusId}`,
            method: "PATCH",
            headers: {
                Accept: "application/json",
            },
            data: data,
        }
        axios(options)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response);
                    if (
                        !filterData?.fromDate &&
                        !filterData?.toDate &&
                        !filterData?.company &&
                        !filterData?.position &&
                        !filterData?.candidate
                    ) {
                        fetchCandidate();
                    }
                    else {
                        fetchFilterCandidate();
                    }
                    notify("Candidate Status Updated Successfully");
                    setStatus(false);
                }
                else {

                    notifyFillTheFields("Unable to update status");
                }
            })
            .catch((e) => {

                console.log(e);
                notifyFillTheFields("Unable to update status");
            });
        setIsUpdate(false);
    }
    // const handleStatus = async (e, candidateId, status) => {
    //     let today = new Date();
    //     let date = today.getDate();
    //     let month = today.getMonth();
    //     let year = today.getFullYear();
    //     let data = {
    //         candidate_status: status,
    //         status_date: `${year}-${month.toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}`
    //     }
    //     console.log(today, `${year}-${month.toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}`);
    //     const options = {
    //         url: `http://localhost:3002/atsstatus/${candidateId}`,
    //         method: "PATCH",
    //         headers: {
    //             Accept: "application/json",
    //         },
    //         data: data,
    //     }
    //     axios(options)
    //         .then((response) => {
    //             if (response.status == 200) {
    //                 setLoading(false);
    //                 console.log(response);
    //                 // fetchData();
    //                 notify("Candidate Status Updated Successfully");
    //                 console.log("Information entered successfully");
    //             }
    //             else {
    //                 setLoading(false);
    //                 notifyFillTheFields("Unable to Update");
    //             }
    //         })
    //         .catch((e) => {
    //             setLoading(false);
    //             console.log(e);
    //             notifyFillTheFields("Unable to Update");
    //         });
    // }

    // updating candidate personal details
    const handleCandidateSubmit = (values) => {
        console.log(values);

        setLoadingUpdate(true);
        let data = {
            candidate: values?.candidate_name,
            candidate_email: values?.candidate_email,
            candidate_phone: `${countryPhone}-${values?.candidate_phone}`,
            candidate_alt_phone: `${countryPhone}-${values?.candidate_alt_phone ? values?.candidate_alt_phone : ""}`,
            candidate_location: values?.candidate_location,
            candidate_experience: `${values?.candidate_experience} Yrs.`,
            candidate_current_ctc: `${values?.candidate_current_ctc} ${"LPA"}`,
            candidate_expected_ctc: `${values?.candidate_expected_ctc} ${values?.candidate_ectc_type === "%" ? `${values?.candidate_ectc_type} Hike` : values?.candidate_ectc_type}`,
            candidate_organization: values?.candidate_organization,
            candidate_designation: values?.candidate_designation,
            candidate_notice_period: `${values?.candidate_notice_period} Days`,
            candidate_qualification: values?.candidate_qualification,
            candidate_gender: values?.candidate_gender,
            candidate_remarks: values?.candidate_remarks,
            candidate_resume: values?.candidate_resume,
            recruiter_id: localStorage.getItem("userId")
        }
        console.log("=========:>>>>>> ", data);
        const options = {
            url: `${basePath}/editatspipeline/${viewCandidate}`,
            method: "PATCH",
            headers: {
                Accept: "application/json",
                'Content-Type': 'multipart/form-data',
            },
            data: data,
        }
        axios(options)
            .then((response) => {
                if (response.status == 200) {

                    console.log(response);
                    if (
                        !filterData?.fromDate &&
                        !filterData?.toDate &&
                        !filterData?.company &&
                        !filterData?.position &&
                        !filterData?.candidate
                    ) {
                        fetchCandidate();
                    }
                    else {
                        fetchFilterCandidate();
                    }
                    setCandidate({
                        candidate_email: "",
                        candidate_phone: "",
                        candidate_alt_phone: "",
                        candidate_location: "",
                        candidate_experience: "",
                        candidate_current_ctc: "",
                        candidate_expected_ctc: "",
                        candidate_organization: "",
                        candidate_designation: "",
                        candidate_notice_period: "",
                        candidate_qualification: "",
                        candidate_gender: "",
                        candidate_remarks: "",
                        candidate_resume: "",
                    });
                    setCandidateEdit(false);
                    setLoadingUpdate(false);
                    notify("Updated Successfully");
                    console.log("Information entered successfully");
                    // if(!response?.data?.candidate?.candidate_resume){
                    //     notifyFillTheFields("Candidate Resume Unable To Update!!");
                    // }
                }
                else {
                    setLoadingUpdate(false);
                    notifyFillTheFields("There is some issue!!");
                }
            })
            .catch((e) => {
                setLoadingUpdate(false);
                console.log(e);
                notifyFillTheFields("Unable to Update");
            });
    }

    const validationSchema = Yup.object().shape({
        candidate_name: Yup.string().required('Name is required'),
        candidate_email: Yup.string().email('Invalid email').required('Email is required'),
        candidate_phone: Yup.string()
            .required('Mobile is required')
            .min(countryPhoneLength, `Mobile must be ${countryPhoneLength} digits`)
            .max(countryPhoneLength, `Mobile must be ${countryPhoneLength} digits`),
        candidate_alt_phone: Yup.string()
            .nullable()
            .notRequired()
            .min(countryPhoneLength, `Mobile must be ${countryPhoneLength} digits`)
            .max(countryPhoneLength, `Mobile must be ${countryPhoneLength} digits`),
        candidate_location: Yup.string().required('Location is required'),
        candidate_qualification: Yup.string().required('Qualification is required'),
        candidate_current_ctc: Yup.string()
            .required('Current CTC is required')
            .min(1, `Current CTC must be ${1} digits`)
            .max(5, `Current CTC must be ${5} digits`),
        candidate_ectc_type: Yup.string().required('Expected CTC Type is required'),
        candidate_expected_ctc: Yup.string()
            .required('Expected CTC is required')
            .min(1, `Expected CTC must be ${1} digits`)
            .max(5, `Expected CTC must be ${5} digits`),
        candidate_organization: Yup.string().required('Organization is required'),
        candidate_designation: Yup.string().required('Designation is required'),
        candidate_experience: Yup.number().required('Experience is required').typeError('Must be a number'),
        candidate_notice_period: Yup.string()
            .required('Notice Period is required')
            .min(1, `Notice Period must be ${1} digits`)
            .max(2, `Notice Period must be ${2} digits`),
        candidate_gender: Yup.string().required('Gender is required'),
        candidate_resume: Yup.mixed()
            .nullable()
            .notRequired()
            .test('fileSize', 'File size must be less than 5MB', (value) => {
                return value ? value.size <= 5 * 1024 * 1024 : true;
            })
            .test('fileType', 'Only PDFs are allowed', (value) => {
                return value ? value.type === 'application/pdf' : true;
            }),
    });

    // handling edit button 
    const handleEdit = (e, idx) => {
        setCtcType(allCandidates[idx]?.candidate_expected_ctc?.split(" ")[1]);
        setCandidate({
            candidate_email: allCandidates[idx]?.candidate_email,
            candidate_phone: allCandidates[idx]?.candidate_phone?.split("-")[1],
            candidate_alt_phone: allCandidates[idx]?.candidate_alt_phone?.split("-")[1],
            candidate_location: allCandidates[idx]?.candidate_location,
            candidate_experience: allCandidates[idx]?.candidate_experience?.split(" ")[0],
            candidate_current_ctc: allCandidates[idx]?.candidate_current_ctc?.split(" ")[0],
            candidate_expected_ctc: allCandidates[idx]?.candidate_expected_ctc?.split(" ")[0],
            candidate_organization: allCandidates[idx]?.candidate_organization,
            candidate_designation: allCandidates[idx]?.candidate_designation,
            candidate_notice_period: allCandidates[idx]?.candidate_notice_period?.split(" ")[0],
            candidate_qualification: allCandidates[idx]?.candidate_qualification,
            candidate_gender: allCandidates[idx]?.candidate_gender,
            candidate_remarks: allCandidates[idx]?.candidate_remarks,
            candidate_resume: allCandidates[idx]?.candidate_resume,
        });
        setCandidateEdit(true);
    }
    console.log("====>>>>>candidate", candidate);

    console.log(viewCompany);

    return (
        <section onClick={() => { }} className='lightBabyGreen baseHeightWidth customScroll mb-5'>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            <div className=' fixed bottom-3 right-4'>
                <div onClick={(e) => {
                    if (
                        !loadingFilter &&
                        !filterData?.candidate &&
                        !filterData?.company &&
                        !filterData?.position &&
                        !filterData?.fromDate &&
                        !filterData?.toDate &&
                        !filterData?.orderBy &&
                        !filterData?.orderDirection
                    ) {
                        fetchCandidate();
                    }
                    else if (!loadingFilter) {
                        fetchFilterCandidate();
                    }
                }} className='cursor-pointer bg-red-600 px-2.5 py-2 rounded-full hoverRed'>
                    <FontAwesomeIcon icon={faRotateRight} size={"lg"} style={{ color: "#ffffff", }} />
                </div>
            </div>
            <div>
                <div className='welcomeBarHeight babyGreen smoothTransition textDarkPurple px-10 rounded-3xl flex items-center justify-between navAnimation  m-auto'>
                    <div className=' text-left'>
                        <h1 className=' text-5xl font-bold'>Screened Candidates</h1>
                        <p className=' text-gray-500 font-semibold'>Ready to start your day</p>
                    </div>
                    <img className=' w-44' src="/img/registration.png" alt="registration" />
                </div>

                {
                    viewCompany ?
                        <div onClick={(e) => { setViewCompany(false); }} className=' w-full h-screen fixed left-0 top-0 flex items-center justify-center blurBack z-20'>
                            <div onClick={(e) => { e.stopPropagation(); }} className='relative bg-white p-5 w-2/4 rounded-xl h-fit navAnimation text-left'>
                                {Array.isArray(allCandidates) && allCandidates?.map((val, idx) => {
                                    return (
                                        <>
                                            {viewCompany === val?.id ?
                                                <>
                                                    <button onClick={(e) => { setViewCompany(false); }} className="absolute bg-red-600 px-1.5 py-0.5    rounded-full w-fit top-1 right-1 z-[11] ">
                                                        <FontAwesomeIcon icon={faXmark} size='xl' style={{ color: "#ffffff", }} />
                                                    </button>
                                                    <div className='max-h-[79vh] overflow-y-scroll pb-5 px-2'>
                                                        {/* <div className=' h-[20vh] rounded-lg overflow-hidden'>
                                                            <img className=' w-full h-full object-cover' src="/img/companyCover.jpg" alt="Company Cover Image" />
                                                        </div> */}
                                                        <div className=' relative'>
                                                            {/* <div className=' absolute flex items-center justify-center w-full left-0 top-[-4rem]'>
                                                                <div className='w-fit rounded-full overflow-hidden p-1 bg-white'>
                                                                    <img className='w-24' src="/img/companyIcon.jpg" alt="company DP" />
                                                                </div>
                                                            </div> */}
                                                            <div className='pt-10 flex flex-col items-center justify-center'>
                                                                <h1 className=' text-4xl font-semibold text-blue-800'>{val?.Position?.Company?.company_name}</h1>
                                                                <h4 className=' text-gray-400'>{val?.Position?.Company?.industry}</h4>
                                                            </div>
                                                            <div>
                                                                <h1 className=' text-2xl font-semibold mb-1'>Summary</h1>
                                                                <p className=' p-2 grayBlurShadow rounded-xl'>{val?.Position?.Company?.summary}</p>
                                                            </div>
                                                            <div className='rounded-lg w-full '>
                                                                <h1 className=' text-2xl font-semibold mb-1'>Contact Details</h1>
                                                                <div className=' grid gridResponsive gap-3'>
                                                                    <div className=' p-3 rounded-xl grayBlurShadow'>
                                                                        <h1 className=' bg-gray-200 rounded-lg px-3 py-1'>Conatct 1</h1>
                                                                        <div className=' px-3'>
                                                                            <h3><span className=' text-gray-400'>Name:</span> {val?.Position?.Company?.poc1_name}</h3>
                                                                            <h3><span className=' text-gray-400'>Designation:</span> {val?.Position?.Company?.poc1_designation}</h3>
                                                                            <h3><span className=' text-gray-400'>Mobile:</span> {val?.Position?.Company?.poc1_phone}</h3>
                                                                            <h3><span className=' text-gray-400'>Email:</span> {val?.Position?.Company?.poc1_email}</h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className=' p-3 rounded-xl grayBlurShadow'>
                                                                        <h1 className=' bg-gray-200 rounded-lg px-3 py-1'>Conatct 2</h1>
                                                                        <div className=' px-3'>
                                                                            <h3><span className=' text-gray-400'>Name:</span> {val?.Position?.Company?.poc2_name}</h3>
                                                                            <h3><span className=' text-gray-400'>Designation:</span> {val?.Position?.Company?.poc2_designation}</h3>
                                                                            <h3><span className=' text-gray-400'>Mobile:</span> {val?.Position?.Company?.poc2_phone}</h3>
                                                                            <h3><span className=' text-gray-400'>Email:</span> {val?.Position?.Company?.poc2_email}</h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h1 className=' text-2xl font-semibold mb-1 mt-2'>Address</h1>
                                                                <p className=' p-2 grayBlurShadow rounded-xl'>{val?.Position?.Company?.address}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : null}
                                        </>)
                                })}
                            </div >
                        </div> : null
                }
                {
                    viewCandidate ?
                        <div onClick={(e) => { }} className=' w-full h-screen fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                            <div onClick={(e) => { e.stopPropagation(); }} className=' bg-white overflow-auto mx-10 mb-5 p-10 rounded-3xl candidateInfoBox'>
                                {Array.isArray(allCandidates) && allCandidates?.map((val, idx) => {
                                    return (
                                        <>
                                            {viewCandidate === val?.id ?
                                                <>
                                                    {!candidateEdit ?
                                                        <div onClick={(e) => { }} className=' w-full h-screen fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                                                            <div onClick={(e) => { e.stopPropagation(); }} className='relative w-[70vw]  bg-white overflow-auto mx-10 mb-5 py-5 px-8 rounded-2xl '>
                                                                <button onClick={(e) => { setViewCandidate(false); setCandidateEdit(false); }} className="absolute bg-red-600 px-1.5 py-0.5    rounded-full w-fit top-1 right-1 z-[11] ">
                                                                    <FontAwesomeIcon icon={faXmark} size='xl' style={{ color: "#ffffff", }} />
                                                                </button>
                                                                <h1 className='text-center text-xl font-semibold py-2 text-blue-950'>Candidate Imformation</h1>
                                                                <div className=' text-left flex flex-col gap-x-2 gap-y-2 max-h-[70vh] overflow-y-scroll'>
                                                                    <div className=' grid grid-cols-2 gap-3'>
                                                                        <div className=''>
                                                                            <h2 className=' w-32'>Name</h2>
                                                                            <input
                                                                                value={val?.candidate}
                                                                                disabled
                                                                                className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' autoFocus type="text" placeholder="Name*" />
                                                                        </div>
                                                                        <div className=''>
                                                                            <h2 className=' w-32'>Email</h2>
                                                                            <input value={val?.candidate_email}
                                                                                disabled
                                                                                className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' autoFocus type="email" placeholder='Email*' />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className=' grid grid-cols-2 gap-3'>
                                                                            <div className=''>
                                                                                <h2 className=' w-32'>Mobile</h2>
                                                                                <input value={val?.candidate_phone}
                                                                                    disabled
                                                                                    className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Mobile*' />
                                                                            </div>
                                                                            <div className=''>
                                                                                <h2 className=' w-32'>Alternate Mobile</h2>
                                                                                <input value={val?.candidate_alt_phone}
                                                                                    disabled
                                                                                    className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Alternate Mobile' />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className=' grid grid-cols-2 gap-3'>
                                                                        <div className=''>
                                                                            <h2 className=' w-32'>Location</h2>
                                                                            <input value={val?.candidate_location}
                                                                                disabled
                                                                                className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Location*' />
                                                                        </div>
                                                                        <div className=''>
                                                                            <h2 className=' w-32'>Qualification</h2>
                                                                            <input value={val?.candidate_qualification}
                                                                                disabled
                                                                                className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Qualification*' />
                                                                        </div>
                                                                    </div>
                                                                    <div className=' grid grid-cols-2 gap-3'>
                                                                        <div className=''>
                                                                            <h2 className=' w-32'>Current CTC</h2>
                                                                            <input value={val?.candidate_current_ctc}
                                                                                disabled
                                                                                className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Current CTC*' />
                                                                        </div>
                                                                        <div className=''>
                                                                            <h2 className=' w-32'>Expected CTC</h2>
                                                                            <input value={val?.candidate_expected_ctc}
                                                                                disabled
                                                                                className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder="Expected CTC*" />
                                                                        </div>
                                                                    </div>
                                                                    <div className=' grid grid-cols-2 gap-3'>
                                                                        <div className=''>
                                                                            <h2 className=' w-32'>Organization</h2>
                                                                            <input value={val?.candidate_organization}
                                                                                disabled
                                                                                className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Current/Past Organization' />
                                                                        </div>
                                                                        <div className=''>
                                                                            <h2 className=' w-32'>Designation</h2>
                                                                            <input value={val?.candidate_designation}
                                                                                disabled
                                                                                className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Current/Past Designation' />
                                                                        </div>
                                                                    </div>
                                                                    <div className=' grid grid-cols-2 gap-3'>
                                                                        <div className=''>
                                                                            <h2 className=' w-32'>Experience</h2>
                                                                            <input value={val?.candidate_experience}
                                                                                disabled
                                                                                className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Experience*' />
                                                                        </div>
                                                                        <div className=''>
                                                                            <h2 className=' w-32'>Notice Period</h2>
                                                                            <input value={val?.candidate_notice_period}
                                                                                disabled
                                                                                className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Notice Period*' />
                                                                        </div>
                                                                    </div>
                                                                    <div className=' grid grid-cols-2 gap-3'>
                                                                        <div className=''>
                                                                            <h2 className=' w-32'>Gender</h2>
                                                                            <input value={val?.candidate_gender}
                                                                                disabled
                                                                                className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Gender*' />
                                                                            {/* <select value={val?.candidate_gender}
                                                                                disabled
                                                                                className=' outline-none bg-gray-200 text-blue-700 font-semibold rounded-lg px-2 w-full py-1' required>
                                                                                <option value="" selected disabled>Gender*</option>
                                                                                <option value="Male">Male</option>
                                                                                <option value="Female">Female</option>
                                                                            </select> */}
                                                                        </div>
                                                                        <div>
                                                                            <h2 className=' '>Candidate Resume</h2>
                                                                            <div> {val?.candidate_resume ?
                                                                                !loadingDownload ?
                                                                                    <button className=" darkPurple text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2 hoverGreen" onClick={(e) => { downloadButton(val?.id, val?.candidate) }} >Download</button> :
                                                                                    <button className=" darkPurple text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2"  >Downloading...</button> :
                                                                                <h2 className=" bg-gray-200 text-red-300 font-semibold outline-none rounded-lg px-2 w-full py-1">Resume Not Uploaded</h2>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className=''>
                                                                        <h2 className=''>Remarks</h2>
                                                                        <textarea value={val.candidate_remarks}
                                                                            disabled
                                                                            className=' outline-none bg-gray-200 text-blue-700 font-semibold rounded-lg px-2 w-full'></textarea>
                                                                    </div>
                                                                    <div className='flex items-center justify-center mt-2'>
                                                                        <button onClick={(e) => { handleEdit(e, idx) }} className=' text-yellow-600 bg-yellow-200 m-2 px-10 py-1 rounded-lg hoverYellow' >Edit</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> :
                                                        <div onClick={(e) => { e.stopPropagation(); }} className=' w-full h-screen fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                                                            <div onClick={(e) => { e.stopPropagation(); }} className='relative w-[70vw]  bg-white overflow-auto mx-10 mb-5 py-5 px-8 rounded-2xl '>
                                                                {!loadingUpdate ?
                                                                    <button onClick={(e) => { setCandidateEdit(false); }} className="absolute bg-white rounded-full w-fit top-1 right-1 z-[11] ">
                                                                        <img className='w-7' src="/img/close.png" alt="close" />
                                                                    </button> :
                                                                    <button className="absolute cursor-not-allowed bg-white rounded-full w-fit top-1 right-1 z-[11] ">
                                                                        <img className='w-7' src="/img/close.png" alt="close" />
                                                                    </button>
                                                                }
                                                                <h1 className='text-center text-xl font-semibold py-2 text-blue-950'>Candidate Imformation</h1>
                                                                <Formik
                                                                    initialValues={{
                                                                        candidate_name: val?.candidate,
                                                                        candidate_email: candidate.candidate_email || '',
                                                                        candidate_phone: candidate.candidate_phone || '',
                                                                        candidate_alt_phone: candidate.candidate_alt_phone || '',
                                                                        candidate_location: candidate.candidate_location || '',
                                                                        candidate_qualification: candidate.candidate_qualification || '',
                                                                        candidate_current_ctc: candidate.candidate_current_ctc || '',
                                                                        candidate_ectc_type: ctcType || "",
                                                                        candidate_expected_ctc: candidate.candidate_expected_ctc || '',
                                                                        candidate_organization: candidate.candidate_organization || '',
                                                                        candidate_designation: candidate.candidate_designation || '',
                                                                        candidate_experience: candidate.candidate_experience || '',
                                                                        candidate_notice_period: candidate.candidate_notice_period || '',
                                                                        candidate_gender: candidate.candidate_gender || '',
                                                                        candidate_resume: '',
                                                                        candidate_remarks: candidate.candidate_remarks || '',
                                                                    }}
                                                                    validationSchema={validationSchema}
                                                                    onSubmit={(values) => {
                                                                        console.log(values);
                                                                        handleCandidateSubmit(values);

                                                                    }}
                                                                >
                                                                    {({ values, setFieldValue }) => (
                                                                        <Form className='text-left flex flex-col gap-x-2 gap-y-2 max-h-[70vh] overflow-y-scroll'>
                                                                            <div className='grid grid-cols-2 gap-3'>
                                                                                <div>
                                                                                    <h2 className='w-32'>Name</h2>
                                                                                    <Field
                                                                                        name="candidate_name"
                                                                                        className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                        type="text"
                                                                                        placeholder="Name*"
                                                                                    />
                                                                                    <ErrorMessage name="candidate_name" component="div" className="text-red-500" />
                                                                                </div>
                                                                                <div>
                                                                                    <h2 className='w-32'>Email</h2>
                                                                                    <Field
                                                                                        name="candidate_email"
                                                                                        className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                        type="email"
                                                                                        placeholder='Email*'
                                                                                    />
                                                                                    <ErrorMessage name="candidate_email" component="div" className="text-red-500" />
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div className='grid grid-cols-2 gap-3'>
                                                                                    <div>
                                                                                        <h2 className='w-32'>Mobile</h2>
                                                                                        <div className="flex items-center gap-2">
                                                                                            <div className='border-2 rounded-lg flex items-center pl-1 w-[7rem]'>
                                                                                                {
                                                                                                    countryPhoneCode.map((val) => {
                                                                                                        return val.phoneCode === countryPhone ? <img key={val.isoCode} className="rounded w-[1.5rem]" src={val.flag} alt={val.name} /> : null
                                                                                                    })
                                                                                                }
                                                                                                <Field as="select" className="outline-none py-1 font-semibold" name="countryPhone" value={countryPhone} onChange={(e) => {
                                                                                                    countryPhoneCode?.forEach((val) => {
                                                                                                        if (e.target.value == val.phoneCode) {
                                                                                                            setCountryPhoneLength(val?.phoneNumberLength);
                                                                                                        }
                                                                                                    })
                                                                                                    setCountryPhone(e.target.value);
                                                                                                }} >
                                                                                                    {
                                                                                                        countryPhoneCode.map((val) => (
                                                                                                            <option key={val.isoCode} value={val.phoneCode}>
                                                                                                                {val.phoneCode}
                                                                                                            </option>
                                                                                                        ))
                                                                                                    }
                                                                                                </Field>
                                                                                            </div>
                                                                                            <Field
                                                                                                name="candidate_phone"
                                                                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                                type="number"
                                                                                                placeholder='Mobile*'
                                                                                            />
                                                                                        </div>
                                                                                        <ErrorMessage name="candidate_phone" component="div" className="text-red-500" />
                                                                                    </div>
                                                                                    <div>
                                                                                        <h2 className='w-32'>Alternate Mobile</h2>
                                                                                        <div className="flex items-center gap-2">
                                                                                            <div className='border-2 rounded-lg flex items-center pl-1 w-[7rem]'>
                                                                                                {
                                                                                                    countryPhoneCode.map((val) => {
                                                                                                        return val.phoneCode === countryPhone ? <img key={val.isoCode} className="rounded w-[1.5rem]" src={val.flag} alt={val.name} /> : null
                                                                                                    })
                                                                                                }
                                                                                                <Field as="select" className="outline-none py-1 font-semibold" name="countryPhone" value={countryPhone} onChange={(e) => {
                                                                                                    countryPhoneCode?.forEach((val) => {
                                                                                                        if (e.target.value == val.phoneCode) {
                                                                                                            setCountryPhoneLength(val?.phoneNumberLength);
                                                                                                        }
                                                                                                    })
                                                                                                    setCountryPhone(e.target.value);
                                                                                                }} >
                                                                                                    {
                                                                                                        countryPhoneCode.map((val) => (
                                                                                                            <option key={val.isoCode} value={val.phoneCode}>
                                                                                                                {val.phoneCode}
                                                                                                            </option>
                                                                                                        ))
                                                                                                    }
                                                                                                </Field>
                                                                                            </div>
                                                                                            <Field
                                                                                                name="candidate_alt_phone"
                                                                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                                type="number"
                                                                                                placeholder='Alternate Mobile*'
                                                                                            />
                                                                                        </div>
                                                                                        <ErrorMessage name="candidate_alt_phone" component="div" className="text-red-500" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='grid grid-cols-2 gap-3'>
                                                                                <div>
                                                                                    <h2 className='w-32'>Location</h2>
                                                                                    <Field
                                                                                        name="candidate_location"
                                                                                        className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                        type="text"
                                                                                        placeholder='Location*'
                                                                                    />
                                                                                    <ErrorMessage name="candidate_location" component="div" className="text-red-500" />
                                                                                </div>
                                                                                <div>
                                                                                    <h2 className='w-32'>Qualification</h2>
                                                                                    <Field
                                                                                        name="candidate_qualification"
                                                                                        className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                        type="text"
                                                                                        placeholder='Qualification*'
                                                                                    />
                                                                                    <ErrorMessage name="candidate_qualification" component="div" className="text-red-500" />
                                                                                </div>
                                                                            </div>
                                                                            <div className='grid grid-cols-2 gap-3'>
                                                                                <div>
                                                                                    <h2 className='w-32'>Current CTC</h2>
                                                                                    <Field
                                                                                        name="candidate_current_ctc"
                                                                                        className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                        type="number"
                                                                                        placeholder='Current CTC*'
                                                                                    />
                                                                                    <ErrorMessage name="candidate_current_ctc" component="div" className="text-red-500" />
                                                                                </div>
                                                                                <div>
                                                                                    <h2 className='w-32'>Expected CTC</h2>
                                                                                    <div className='flex items-center gap-2'>
                                                                                        <div className=" flex-1">
                                                                                            <Field as="select" name="candidate_ectc_type"
                                                                                                className='outline-none border-2 rounded-lg px-2 w-fit py-1' required>
                                                                                                <option disabled value="" >Select</option>
                                                                                                <option value="LPA">LPA</option>
                                                                                                <option value="%">%</option>
                                                                                            </Field>
                                                                                            <ErrorMessage name="candidate_ectc_type" component="div" className="text-red-600 text-sm" />
                                                                                        </div>

                                                                                        {values.candidate_ectc_type === "LPA" ?
                                                                                            <div className="w-full flex-2">
                                                                                                <Field name="candidate_expected_ctc" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="number" placeholder="Expected CTC in LPA*" />
                                                                                                <ErrorMessage name="candidate_expected_ctc" component="div" className="text-red-600 text-sm" />
                                                                                            </div> :
                                                                                            values.candidate_ectc_type === "%" ?
                                                                                                <div className="w-full flex-2">
                                                                                                    <Field name="candidate_expected_ctc" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="number" placeholder="Expected CTC In %*" />
                                                                                                    <ErrorMessage name="candidate_expected_ctc" component="div" className="text-red-600 text-sm" />
                                                                                                </div> : null
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='grid grid-cols-2 gap-3'>
                                                                                <div>
                                                                                    <h2 className='w-32'>Organization</h2>
                                                                                    <Field
                                                                                        name="candidate_organization"
                                                                                        className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                        type="text"
                                                                                        placeholder='Current/Past Organization'
                                                                                    />
                                                                                    <ErrorMessage name="candidate_organization" component="div" className="text-red-500" />
                                                                                </div>
                                                                                <div>
                                                                                    <h2 className='w-32'>Designation</h2>
                                                                                    <Field
                                                                                        name="candidate_designation"
                                                                                        className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                        type="text"
                                                                                        placeholder='Current/Past Designation'
                                                                                    />
                                                                                    <ErrorMessage name="candidate_designation" component="div" className="text-red-500" />
                                                                                </div>
                                                                            </div>
                                                                            <div className='grid grid-cols-2 gap-3'>
                                                                                <div>
                                                                                    <h2 className='w-32'>Experience</h2>
                                                                                    <Field
                                                                                        name="candidate_experience"
                                                                                        className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                        type="number"
                                                                                        placeholder='Experience*'
                                                                                    />
                                                                                    <ErrorMessage name="candidate_experience" component="div" className="text-red-500" />
                                                                                </div>
                                                                                <div className=''>
                                                                                    <h2 className='w-32'>Notice Period</h2>
                                                                                    <Field name="candidate_notice_period" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="number" placeholder="Notice Period In Days*" />
                                                                                    <ErrorMessage name="candidate_notice_period" component="div" className="text-red-500" />
                                                                                </div>
                                                                            </div>
                                                                            <div className='grid grid-cols-2 gap-3'>
                                                                                <div>
                                                                                    <h2 className='w-32'>Gender</h2>
                                                                                    <Field as="select" name="candidate_gender"
                                                                                        className='outline-none border-2 rounded-lg px-2 w-full py-1' required>
                                                                                        <option value="" disabled>Gender*</option>
                                                                                        <option value="Male">Male</option>
                                                                                        <option value="Female">Female</option>
                                                                                    </Field>
                                                                                    <ErrorMessage name="candidate_gender" component="div" className="text-red-500" />
                                                                                </div>
                                                                                <div>
                                                                                    <h2 className='w-32'>Upload Resume*</h2>
                                                                                    <input type="file" accept='.pdf' onChange={(event) => {
                                                                                        setFieldValue("candidate_resume", event.currentTarget.files[0]);
                                                                                    }} />
                                                                                    <ErrorMessage name="candidate_resume" component="div" className="text-red-500" />
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <h2 className=''>Remarks</h2>
                                                                                <Field as="textarea" name="candidate_remarks"
                                                                                    className='outline-none border-2 rounded-lg px-2 w-full'>
                                                                                </Field>
                                                                            </div>
                                                                            <div className='flex items-center mt-5'>
                                                                                {!loadingUpdate ?
                                                                                    <> <div onClick={(e) => { setCandidateEdit(false); }} className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed cursor-pointer' >Cancel</div>
                                                                                        <button type='submit' onClick={() => { console.log("clicked") }} className='darkPurple text-white font-semibold rounded-xl px-4 py-1 hoverGreen'>Submit</button></> :
                                                                                    <> <div className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg cursor-pointer ' >Cancel</div>
                                                                                        <div className='darkPurple text-white font-semibold rounded-xl px-4 py-1 cursor-pointer '>Loading...</div></>
                                                                                }
                                                                            </div>
                                                                        </Form>
                                                                    )}
                                                                </Formik>

                                                            </div>
                                                        </div>
                                                    }
                                                </> : null}
                                        </>
                                    )
                                })
                                }
                            </div>
                        </div> : null
                }
                {positionWiseView ?
                    <>
                        <div onClick={(e) => { setPositionWiseView(false); }} className=' w-full h-screen  fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                            <div onClick={(e) => { e.stopPropagation(); }} className=' relative h-[95vh] w-[95%]  lightBabyGreen rounded-2xl mx-10 px-2 py-4   '>
                                <div className=' absolute bottom-3 right-4'>
                                    <div onClick={(e) => { getPositionWiseData(); }} className='cursor-pointer bg-red-600 px-2.5 py-2 rounded-full hoverRed'>
                                        <FontAwesomeIcon icon={faRotateRight} size={"lg"} style={{ color: "#ffffff", }} />
                                    </div>
                                </div>
                                <button onClick={(e) => { setPositionWiseView(false); }} className="absolute bg-white rounded-full w-fit top-1 right-1 z-[11] ">
                                    <img className='w-7' src="/img/close.png" alt="close" />
                                </button>
                                <div className='h-full w-full  rounded-2xl overflow-y-scroll noScrollbar px-8'>
                                    {/* filter */}
                                    <div className=' flex flex-wrap items-center gap-3 grayBlurShadow bg-white p-3 mb-5 rounded-xl'>
                                        <div className='flex items-center gap-3'>
                                            <div className=' flex flex-col justify-start items-start'>
                                                <label>From: </label>
                                                <input
                                                    className=' border-2 rounded-lg px-2 py-1'
                                                    type="date"
                                                    value={positionFilter?.fromDate}
                                                    onChange={e => { setPositionFilter({ ...positionFilter, fromDate: e.target.value }) }}
                                                />
                                            </div>
                                            <div className=' flex flex-col justify-start items-start'>
                                                <label>To: </label>
                                                <input
                                                    className=' border-2 rounded-lg px-2 py-1'
                                                    type="date"
                                                    value={positionFilter?.toDate}
                                                    onChange={e => { setPositionFilter({ ...positionFilter, toDate: e.target.value }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className='flex flex-col justify-start items-start '>
                                            <label >Company</label>
                                            <input
                                                value={positionFilter?.company}
                                                className=' border-2 rounded-lg px-2 py-1'
                                                onChange={e => { setFilterData({ ...filterData, company: e.target.value }); setPositionFilter({ ...positionFilter, company: e.target.value }) }}
                                                type="text" />
                                        </div>
                                        <div className='flex flex-col justify-start items-start '>
                                            <label >Position</label>
                                            <input
                                                value={positionFilter?.position}
                                                className=' border-2 rounded-lg px-2 py-1'
                                                onChange={e => { setFilterData({ ...filterData, position: e.target.value }); setPositionFilter({ ...positionFilter, position: e.target.value }) }}
                                                type="text" />
                                        </div>
                                        <div className='flex flex-col justify-start items-start mb-2'>
                                            <label >Recruiter</label>
                                            <select value={positionFilter?.recruiter}
                                                className=' border-2 rounded-lg px-2 py-1'
                                                onChange={e => { setFilterData({ ...filterData, recruiter: e.target.value }); setPositionFilter({ ...positionFilter, recruiter: e.target.value }); }} >
                                                <option value="" selected disabled>Select Recruiter</option>
                                                {allUsers?.map((val) => {
                                                    return (
                                                        <option value={val?.id}>{val?.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <button onClick={getPositionWiseData} className=' darkPurple cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>Apply filter</button>
                                        <button onClick={() => {
                                            setPositionFilter({
                                                fromDate: "",
                                                toDate: "",
                                                company: "",
                                                position: "",
                                                orderBy: "sourcing_date",
                                                recruiter: "",
                                                orderDirection: "DESC",
                                                status: ""
                                            });
                                            setFilterData({
                                                fromDate: "",
                                                toDate: "",
                                                company: "",
                                                position: "",
                                                candidate: "",
                                                recruiter: "",
                                                orderBy: "upload_date",
                                                orderDirection: "DESC"
                                            });
                                            getPositionWiseData();
                                        }} className=' text-red-700 bg-red-200  py-2 px-3 rounded-lg hoverRed'>Clear Filter</button>
                                        {/* <div className=' text-left w-fit'>
                                        <button onClick={handleExport} className=' py-2 px-3 bg-green-800 text-white rounded-lg '>{loadingExport ? "Exporting..." : "Excel"}</button>
                                    </div> */}
                                    </div>
                                    {
                                        !loading ?
                                            Array.isArray(positionWiseData) && positionWiseData?.length >= 1 ?
                                                <div className=' bg-white px-3 pt-5 rounded-2xl'>
                                                    <div className=' overflow-auto tableBody  h-[79vh]'>
                                                        <table className=' navAnimation rounded-2xl baseWidth p-5 text-left'>
                                                            <thead className='text-lg tableHead'>
                                                                <tr className=' bg-gray-200'>
                                                                    <th className='whitespace-nowrap text-center' >Sr.No.</th>
                                                                    <th className='whitespace-nowrap text-center cursor-pointer' onClick={() => { handleSort("upload_date") }} >
                                                                        <div className='flex items-center gap-2 justify-center'>Date
                                                                            {positionFilter?.orderBy === "upload_date" && positionFilter?.orderDirection === "ASC" ?
                                                                                <img className='w-4' src="/img/upArrow.png" alt="up" /> :
                                                                                positionFilter?.orderBy === "upload_date" && positionFilter?.orderDirection === "DESC" ?
                                                                                    <img className='w-4' src="/img/downArrow.png" alt="up" /> :
                                                                                    <img className='w-4' src="/img/upDownArrow.png" alt="up" />}
                                                                        </div>
                                                                    </th>
                                                                    <th className='whitespace-nowrap cursor-pointer' onClick={() => { handleSort("company_name") }}><div className='flex items-center gap-2'>Company Name {positionFilter?.orderBy === "company_name" && positionFilter?.orderDirection === "ASC" ? <img className='w-4' src="/img/upArrow.png" alt="up" /> : positionFilter?.orderBy === "company_name" && positionFilter?.orderDirection === "DESC" ? <img className='w-4' src="/img/downArrow.png" alt="up" /> : <img className='w-4' src="/img/upDownArrow.png" alt="up" />}</div></th>
                                                                    <th className='whitespace-nowrap cursor-pointer' onClick={() => { handleSort("position") }}><div className='flex items-center gap-2'>Company Position {positionFilter?.orderBy === "position" && positionFilter?.orderDirection === "ASC" ? <img className='w-4' src="/img/upArrow.png" alt="up" /> : positionFilter?.orderBy === "position" && positionFilter?.orderDirection === "ASC" ? <img className='w-4' src="/img/downArrow.png" alt="up" /> : <img className='w-4' src="/img/upDownArrow.png" alt="up" />}</div></th>
                                                                    <th className='whitespace-nowrap text-center'>Count</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='tableBody '>
                                                                {
                                                                    Array.isArray(positionWiseData) && positionWiseData?.map((val, idx) => {
                                                                        return (
                                                                            <>
                                                                                {/* {val?.created_at?.split("T")[0] === sourceViewModal ? */}
                                                                                <tr key={val?.id} id={val?.id} className=' backWhite font-semibold text-sm'>
                                                                                    <td className='whitespace-nowrap text-center'>{idx + 1}</td>
                                                                                    <td className='whitespace-nowrap text-center'>{val?.upload_date?.split("-")[2]} {monthData[parseInt(val?.upload_date?.split("-")[1])]} {val?.upload_date?.split("-")[0]}</td>
                                                                                    <td className=' hover:text-blue-700 cursor-pointer'
                                                                                        onClick={() => {
                                                                                            setFilterData({
                                                                                                ...filterData,
                                                                                                company: val?.company_name,
                                                                                                position: val?.position_name,
                                                                                                fromDate: "",
                                                                                                toDate: "",
                                                                                                candidate: "",

                                                                                                orderBy: "sourcing_date",
                                                                                                orderDirection: "DESC"
                                                                                            });
                                                                                            setPositionFilter({
                                                                                                ...positionFilter,
                                                                                                company: val?.company_name,
                                                                                                position: val?.position_name,
                                                                                                fromDate: "",
                                                                                                toDate: "",

                                                                                                orderBy: "upload_date",
                                                                                                orderDirection: "DESC"
                                                                                            });
                                                                                            setPositionWiseView(false);
                                                                                        }}
                                                                                    >{val?.company_name}</td>
                                                                                    <td className=' hover:text-blue-700 cursor-pointer'
                                                                                        onClick={() => {
                                                                                            setFilterData({
                                                                                                ...filterData,
                                                                                                company: val?.company_name,
                                                                                                position: val?.position_name,
                                                                                                fromDate: "",
                                                                                                toDate: "",
                                                                                                candidate: "",

                                                                                                orderBy: "sourcing_date",
                                                                                                orderDirection: "DESC"
                                                                                            });
                                                                                            setPositionFilter({
                                                                                                ...positionFilter,
                                                                                                company: val?.company_name,
                                                                                                position: val?.position_name,
                                                                                                fromDate: "",
                                                                                                toDate: "",
                                                                                                orderBy: "upload_date",
                                                                                                orderDirection: "DESC"
                                                                                            });
                                                                                            setPositionWiseView(false);
                                                                                        }}>{val?.position_name}</td>
                                                                                    <td className='whitespace-nowrap text-center'>{val?.candidate_count}</td>
                                                                                </tr>
                                                                                {/* : null} */}

                                                                            </>

                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table >
                                                    </div>
                                                    <div className=' flex items-center justify-between pb-4'>
                                                        <div className=' flex items-center gap-2'>
                                                            <div className='grayBlurShadow px-3 py-1'>
                                                                <select value={limitPositionWise} onChange={(e) => { setLimitPositionWise(e.target.value); }} >
                                                                    <option value="10" selected>10</option>
                                                                    <option value="20" >20</option>
                                                                    <option value="50" >50</option>
                                                                    <option value="100" >100</option>
                                                                    <option value="200" >200</option>
                                                                    <option value="500" >500</option>

                                                                </select>
                                                            </div>
                                                            <div className='grayBlurShadow px-1 py-1 text-sm font-semibold'>
                                                                <h1>Total Records: {totalRecordsPositionWise}</h1>
                                                            </div>
                                                            <div className='grayBlurShadow px-1 py-1  text-sm font-semibold'>
                                                                <h1>Total Pages: {allPagesPositionWise}</h1>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center grayBlurShadow px-3 py-1 rounded-lg'>
                                                            <div onClick={(e) => {
                                                                if (pagePositionWise > 1) {
                                                                    setPage(page - 1);
                                                                }
                                                            }} className={` ${pagePositionWise === 1 ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                                                <img className='w-5' src="/img/leftArrow.png" alt="leftArrow" />
                                                            </div>
                                                            <div className=' px-3'>
                                                                {page}
                                                            </div>
                                                            <div onClick={(e) => {
                                                                if (allPagesPositionWise > pagePositionWise) {
                                                                    setPage(pagePositionWise + 1);
                                                                }
                                                            }} className={` ${pagePositionWise == allPagesPositionWise ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                                                <img className='w-5' src="/img/rightArrow.png" alt="rightArrow" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div className='navAnimation w-full flex flex-col justify-center items-center bg-white mt-5 py-5 mb-10 rounded-3xl '>
                                                    <img className=' w-[25%]' src="/img/addReports.png" alt="" />
                                                    <h1 className=' text-2xl font-bold'>Oops!! "<span className=' text-blue-950'>No Reports Found</span>"</h1>
                                                </div> :
                                            <div className=' h-[60vh] mb-5 w-full bg-white mt-5 rounded-3xl '>
                                                <img className=' m-auto w-1/4' src="/img/loader2.gif" alt="loading..." />
                                            </div>
                                    }
                                </div>

                            </div>
                        </div>
                    </> : null}
                {!loadingFilter ?
                    <div className=' flex flex-wrap items-center gap-3 grayBlurShadow bg-white p-3 mt-5 rounded-xl'>
                        {/* <h1>Date Filter</h1> */}
                        <div className='flex items-center gap-3'>
                            <div className=' flex flex-col justify-start items-start'>
                                <label>From: </label>
                                <input
                                    className=' border-2 rounded-lg px-2 py-1'
                                    type="date"
                                    value={filterData?.fromDate}
                                    onChange={e => { setFilterData({ ...filterData, fromDate: e.target.value }); }}
                                />
                            </div>
                            <div className=' flex flex-col justify-start items-start'>
                                <label>To: </label>
                                <input
                                    className=' border-2 rounded-lg px-2 py-1'
                                    type="date"
                                    value={filterData?.toDate}
                                    onChange={e => { setFilterData({ ...filterData, toDate: e.target.value }); }}
                                />
                            </div>
                        </div>
                        <div className='flex flex-col justify-start items-start mb-2'>
                            <label >candidate</label>
                            <input
                                value={filterData?.candidate}
                                className=' border-2 rounded-lg px-2 py-1'
                                onChange={e => setFilterData({ ...filterData, candidate: e.target.value })}
                                type="text" />
                        </div>
                        <div className='flex flex-col justify-start items-start mb-2'>
                            <label >Company</label>
                            <input
                                value={filterData?.company}
                                className=' border-2 rounded-lg px-2 py-1'
                                onChange={e => { setFilterData({ ...filterData, company: e.target.value }); setPositionFilter({ ...positionFilter, company: e.target.value }) }}
                                type="text" />
                        </div>
                        <div className='flex flex-col justify-start items-start mb-2'>
                            <label >Position</label>
                            <input
                                value={filterData?.position}
                                className=' border-2 rounded-lg px-2 py-1'
                                onChange={e => { setFilterData({ ...filterData, position: e.target.value }); setPositionFilter({ ...positionFilter, position: e.target.value }) }}
                                type="text" />
                        </div>

                        <div className='flex flex-col justify-start items-start mb-2'>
                            <label >Recruiter</label>
                            <select value={filterData?.recruiter}
                                className=' border-2 rounded-lg px-2 py-1'
                                onChange={e => { setFilterData({ ...filterData, recruiter: e.target.value }); setPositionFilter({ ...positionFilter, recruiter: e.target.value }); }} >
                                <option value="" selected disabled>Select Recruiter</option>
                                {allUsers?.map((val) => {
                                    return (
                                        <option value={val?.id}>{val?.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className=' flex items-center gap-2'>
                            <button onClick={handleFilter} className=' darkPurple cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>Apply filter</button>
                            <button onClick={() => {
                                setFilterData({
                                    fromDate: "",
                                    toDate: "",
                                    company: "",
                                    position: "",
                                    candidate: "",
                                    recruiter: "",
                                    orderBy: "sourcing_date",
                                    orderDirection: "DESC"
                                });
                                setPositionFilter({
                                    fromDate: "",
                                    toDate: "",
                                    company: "",
                                    position: "",
                                    orderBy: "upload_date",
                                    recruiter: "",
                                    orderDirection: "DESC",
                                    status: ""
                                });
                                setIsClearFilter(true);
                                // fetchCandidate();
                            }} className=' text-red-700 bg-red-200  py-2 px-3 rounded-lg hoverRed'>Clear Filter</button>
                            <div className=' text-left w-fit'>
                                <button onClick={handleExport} className=' py-2 px-3 bg-green-800 text-white rounded-lg '>{loadingExport ? "Exporting..." : "Excel"}</button>
                            </div>
                            <div className=' text-left w-fit'>
                                <button onClick={(e) => {
                                    setPositionWiseView(true);
                                }} className=' py-2 px-3 bg-purple-700 hover:bg-purple-900 text-white rounded-lg '>Position Details</button>
                            </div>
                        </div>
                    </div> : null}
                {!loading ?
                    Array.isArray(allCandidates) && allCandidates?.length >= 1 ?
                        <section className='bg-white grayBlurShadow px-7 pt-7 navAnimation rounded-3xl mb-8 mt-5'>
                            <div className=' overflow-auto tableBody tableBox'>
                                <table className='  rounded-xl baseWidth text-left'>
                                    <thead className='  tableHead'>
                                        <tr className=' bg-gray-200'>
                                            <th className='whitespace-nowrap text-center' >Sr.No.</th>
                                            <th className='whitespace-nowrap text-center'  >
                                                <div onClick={() => handleScreenedSort("sourcing_date")} className='flex item-center gap-2 justify-center w-full cursor-pointer'>
                                                    Sourced Date
                                                    {filterData?.orderBy === "sourcing_date" && filterData?.orderDirection === "ASC" ?
                                                        <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                        filterData?.orderBy === "sourcing_date" && filterData?.orderDirection === "DESC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                            <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                </div>
                                            </th>
                                            {localStorage.getItem("role") === "Recruiter" || localStorage.getItem("role") === "Team Lead" ?
                                                null : <th className=' whitespace-nowrap text-center'>Recruiter</th>
                                            }
                                            <th className='whitespace-nowrap pr-6'>
                                                <div onClick={() => handleScreenedSort("candidate")} className='flex item-center gap-2 w-full cursor-pointer'>
                                                    Candidate
                                                    {filterData?.orderBy === "candidate" && filterData?.orderDirection === "ASC" ?
                                                        <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                        filterData?.orderBy === "candidate" && filterData?.orderDirection === "DESC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                            <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                </div>
                                            </th>
                                            <th className='whitespace-nowrap' >
                                                <div onClick={() => handleScreenedSort("company_name")} className='flex item-center gap-2 w-full cursor-pointer'>
                                                    Company Name
                                                    {filterData?.orderBy === "company_name" && filterData?.orderDirection === "ASC" ?
                                                        <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                        filterData?.orderBy === "company_name" && filterData?.orderDirection === "DESC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                            <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                </div>
                                            </th>
                                            <th className='whitespace-nowrap' >
                                                <div onClick={() => handleScreenedSort("position")} className='flex item-center gap-2 w-full cursor-pointer'>
                                                    Position Name
                                                    {filterData?.orderBy === "position" && filterData?.orderDirection === "ASC" ?
                                                        <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                        filterData?.orderBy === "position" && filterData?.orderDirection === "DESC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                            <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                </div>
                                            </th>
                                            <th className='whitespace-nowrap text-center'>Company Details</th>
                                            <th className='whitespace-nowrap text-center'>Candidate Details</th>
                                            <th className='whitespace-nowrap text-center'>Candidate Resume</th>
                                            <th className='whitespace-nowrap text-center'>Candidate Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tableBody '>
                                        {
                                            Array.isArray(allCandidates) && allCandidates?.map((val, idx) => {

                                                return (
                                                    <tr key={val?.id} id={val?.id} className=' backWhite font-semibold text-sm'>
                                                        <td className=' text-center'>{idx + 1}</td>
                                                        <td className=' whitespace-nowrap text-center'>{val?.sourcing_date?.split("-")[2]} {monthData[parseInt(val?.sourcing_date?.split("-")[1])]} {val?.sourcing_date?.split("-")[0]}</td>
                                                        {localStorage.getItem("role") === "Recruiter" || localStorage.getItem("role") === "Team Lead" ?
                                                            null :
                                                            <td>
                                                                <h1 className=' p-2 rounded-lg greenBox w-full whitespace-nowrap text-center'>{val?.User?.name}</h1>
                                                            </td>
                                                        }

                                                        <td className=' whitespace-nowrap'>{val?.candidate}</td>
                                                        <td className=' whitespace-nowrap' >{val?.Position?.Company?.company_name}</td>
                                                        <td className=' whitespace-nowrap' >{val?.Position?.position}</td>
                                                        <td className=' text-center'><button onClick={(e) => { setViewCompany(val?.id); }} className=' darkPurple text-white font-semibold rounded-xl px-4 text-sm py-1 hoverGreen'>View</button></td>
                                                        <td className=' text-center'><button onClick={(e) => { setViewCandidate(val?.id) }} className=' darkPurple text-white font-semibold rounded-xl px-4 text-sm py-1 hoverGreen'>View</button></td>
                                                        <td className=' text-center'>
                                                            {val?.candidate_resume ?
                                                                loadingDownload === val?.id ?
                                                                    <button className=" darkPurple text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2 cursor-not-allowed" >Downloading...</button> :
                                                                    <button className=" darkPurple text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2 hoverGreen" onClick={(e) => { downloadButton(val?.id, val?.candidate) }} >Download</button> :
                                                                <button className=" darkPurple opacity-65 text-gray-300 font-semibold rounded-lg px-4 text-sm py-1 mx-2 cursor-not-allowed" >Download</button>}
                                                        </td>
                                                        <td className=' text-center'>
                                                            <div className={`${val?.sourcing_status === "Screened" ? "text-center outline-none py-2 px-1 text-blue-500 blueBox" : "text-center outline-none py-2 px-1 text-green-500 greenBox"} `}>
                                                                <select value={status && isUpdate === val?.id ? status : val?.sourcing_status} onChange={(e) => { setIsUpdate(val?.id); setStatus(e.target.value); }} className=' bg-transparent outline-none' >
                                                                    <option selected disabled value="Screened">Screened</option>
                                                                    <option value="Confirmation Pending">Confirmation Pending</option>
                                                                    <option value="Sent To Client">Sent To Client</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                        <td className=' flex justify-center items-center'>
                                                            {isUpdate === val?.id ?
                                                                <button onClick={(e) => { setIsUpdate(false); handleStatus(e, val?.id) }} className=' bg-green-200 text-green-600 rounded-xl px-3 py-1 font-semibold'>Save</button> :
                                                                <button className=' bg-gray-200 text-gray-400 cursor-not-allowed rounded-xl px-3 py-1 font-semibold'>Save</button>
                                                            }
                                                        </td>

                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className=' flex items-center justify-between py-4'>
                                <div className=' flex items-center gap-2'>
                                    <div className='grayBlurShadow px-3 py-1'>
                                        <select value={limit} onChange={(e) => { setLimit(e.target.value); }} >
                                            <option value="10" selected>10</option>
                                            <option value="20" >20</option>
                                            <option value="50" >50</option>
                                            <option value="100" >100</option>
                                            <option value="200" >200</option>
                                            <option value="500" >500</option>

                                        </select>
                                    </div>
                                    <div className='grayBlurShadow px-1 py-1 text-sm font-semibold'>
                                        <h1>Total Records: {totalRecords}</h1>
                                    </div>
                                    <div className='grayBlurShadow px-1 py-1  text-sm font-semibold'>
                                        <h1>Total Pages: {allPages}</h1>
                                    </div>
                                </div>
                                <div className='flex items-center grayBlurShadow px-3 py-1 rounded-lg'>
                                    <div onClick={(e) => {
                                        if (page > 1) {
                                            setPage(page - 1);
                                        }
                                    }} className={` ${page === 1 ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                        <img className='w-5' src="/img/leftArrow.png" alt="leftArrow" />
                                    </div>
                                    <div className=' px-3'>
                                        {page}
                                    </div>
                                    <div onClick={(e) => {
                                        if (allPages > page) {
                                            setPage(page + 1);
                                        }
                                    }} className={` ${page == allPages ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                        <img className='w-5' src="/img/rightArrow.png" alt="rightArrow" />
                                    </div>
                                </div>
                            </div>
                        </section> :
                        <div className='navAnimation flex flex-col justify-center items-center bg-white mt-5 py-5 mb-10 rounded-3xl '>
                            <img className=' w-[24%]' src="/img/addReports.png" alt="" />
                            <h1 className=' text-2xl font-bold'>Oops!! "<span className=' text-blue-950 text-xl'>No Candidates Found</span>"</h1>
                        </div> :
                    <div className=' loaderBox mb-5 bg-white mt-5 rounded-3xl '>
                        <img className=' m-auto w-1/4' src="/img/loader2.gif" alt="loading..." />
                    </div>
                }
            </div>
        </section>
    );
}

export default ScreenedCandidate;
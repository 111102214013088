import logo from './logo.svg';
import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import Sourcing from './components/Sourcing/Sourcing';
// import Dashboard from './components/Dashboard/Dashboard';

import NotFound from './components/NotFound/NotFound';
import AtsPipeline from './components/AtsPipeline/AtsPipeline';
import SuperAdmin from './components/SuperAdmin/SuperAdmin';
import RoleManager from './components/RoleManager/RoleManager';
import CompanyRegistration from './components/Registration/CompanyRegistration/CompanyRegistration';
import ContextApi from './components/ContextApi/ContextApi';
import CompanyProfile from './components/CompanyProfile/CompanyProfile';
import SuperAdminHome from './components/SuperAdmin/SuperAdminHome';
import ModuleManager from './components/SuperAdmin/ModuleManager';
import CompanyDetails from './components/SuperAdmin/CompanyDetails';
import AccessControl from './components/SuperAdmin/AccessControl';
import UserDetails from './components/SuperAdmin/UserDetails';
import UserRegistration from './components/Registration/UserRegistration/UserRegistration';
import AllPostedJobs from './components/Jobs/AllPostedJobs';
import JobPost from './components/Jobs/JobPost';
import Login from './components/LoginSignup/Login';
import { Suspense, lazy, useEffect } from 'react';
import AssignRecruiter from './components/AssignRecruiter/AssignRecruiter';
import AdminReport from './components/AdminReport/AdminReport';
import InterviewSchedule from './components/InterviewSchedule/InterviewSchedule';
import ScreenedCandidate from './components/AdminReport/ScreenedCandidate';
import ReportAndAnalysis from './components/ReportAndAnalysis/ReportAndAnalysis';
import CvManegement from './components/CvManegement/CvManegement';
const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    let name = localStorage.getItem("name");
    let role = localStorage.getItem("role");
    let token = localStorage.getItem("token");


    if (!name || !role || !token) {
      localStorage.removeItem("name");
      localStorage.removeItem("role");
      localStorage.removeItem("token");
      navigate("/login");
    }

    // if(role==="Super Admin"){
    //   navigate("/main");
    // }
  }, []);
  return (
    <>
      <div className="App customScroll hidden md:block">
        <ContextApi>
          <Suspense fallback={<img className=' m-auto w-1/4' src="/img/loader2.gif" alt="loading..." />}>
            <Routes>
              <Route path='/login' element={<Login />} />
              <Route path='/' element={<Dashboard />} >
                <Route index element={<Home />} />
                <Route path='/master-role' element={<RoleManager />} />
                <Route path='/module' element={<ModuleManager />} />
                <Route path='/daily-sourcing-update/:index' element={<Sourcing />} />
                <Route path='/ats-pipeline/:index' element={<AtsPipeline />} />
                <Route path='/company-profile' element={<CompanyProfile />} />
                <Route path='/company-details/:index' element={<CompanyDetails />} />
                <Route path='/user-details/:index' element={<UserDetails />} />
                <Route path='/all-posted-jobs/:index' element={<AllPostedJobs />} />
                <Route path='/post-job/:index' element={<JobPost />} />
                <Route path='/company-registration/:index' element={<CompanyRegistration />} />
                <Route path='/user-registration/:index' element={<UserRegistration />} />
                <Route path='/access-control' element={<AccessControl />} />
                <Route path='/assign-recruiter/:index' element={<AssignRecruiter />} />
                <Route path='/admin-report/:index' element={<AdminReport />} />
                <Route path='/interview-schedule/:index' element={<InterviewSchedule />} />
                <Route path='/screened-candidates/:index' element={<ScreenedCandidate />} />
                <Route path='/report-and-analysis/:index' element={<ReportAndAnalysis />} />
                <Route path='/cv-management/:index' element={<CvManegement />} />
              </Route>
              <Route path='/main' element={<SuperAdmin />} >
                <Route index element={<Home />} />
                <Route path='/main/master-role' element={<RoleManager />} />
                <Route path='/main/module' element={<ModuleManager />} />
                <Route path='/main/daily-sourcing-update/:index' element={<Sourcing />} />
                <Route path='/main/ats-pipeline/:index' element={<AtsPipeline />} />
                <Route path='/main/company-profile' element={<CompanyProfile />} />
                <Route path='/main/company-details/:index' element={<CompanyDetails />} />
                <Route path='/main/user-details/:index' element={<UserDetails />} />
                <Route path='/main/all-posted-jobs/:index' element={<AllPostedJobs />} />
                <Route path='/main/post-job/:index' element={<JobPost />} />
                <Route path='/main/company-registration/:index' element={<CompanyRegistration />} />
                <Route path='/main/user-registration/:index' element={<UserRegistration />} />
                <Route path='/main/access-control' element={<AccessControl />} />
                <Route path='/main/assign-recruiter/:index' element={<AssignRecruiter />} />
                <Route path='/main/admin-report/:index' element={<AdminReport />} />
                <Route path='/main/interview-schedule/:index' element={<InterviewSchedule />} />
                <Route path='/main/screened-candidates/:index' element={<ScreenedCandidate />} />
                <Route path='/main/report-and-analysis/:index' element={<ReportAndAnalysis />} />
                <Route path='/main/cv-management/:index' element={<CvManegement />} />
              </Route>
              <Route path='*' element={<NotFound />} />
            </Routes>
          </Suspense>
        </ContextApi>
      </div>
      <div className='relative h-screen overflow-hidden w-full flex flex-col items-center lightBabyGreen justify-center md:hidden'>
        <div>
          <div className=' absolute darkPurple top-0 left-0 py-3 px-4 w-full flex items-center justify-between'>
            <img className=' w-40' src="/img/tcoLogo2.png" alt="" />
          </div>
        </div>
        <img className=' min-w-[14rem] max-w-[15rem]' src='/img/desktop-1.png' alt='Desktop Image' />
        <h1 className=' textDarkBlue text-2xl font-semibold'>Open website on desktop</h1>
      </div>
    </>
  );
}

export default App;

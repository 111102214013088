import React, { useCallback, useContext, useEffect, useState } from 'react';
import { debounce } from "lodash";
import { industryList } from '../../Constants/constant';
import { AppContext } from '../../ContextApi/ContextApi';

function IndustryModal(props) {
    const { industry,setIndustry,isModalOpen, setIsModalOpen}=useContext(AppContext);
    const [searchText, setSearchText] = useState("");
    const [functionArr, setFunctionArr] = useState(industryList);
    const [loading, setLoading] = useState(false);
    const handleText = debounce((text) => {
        setSearchText(text);
    }, 800);
    const searchTextByCharacter = () => {
        setLoading(true);
        if (searchText) {
        const lowerCaseSearchText = searchText.toLowerCase();
        const filteredList = industryList.filter((item) => {
            const lowerCaseItem = item.toLowerCase();
            // Check if the item starts with the search text
            return lowerCaseItem.startsWith(lowerCaseSearchText);
        });
        setFunctionArr(filteredList);
    }
    else if (!searchText) {
        setLoading(false);
        setFunctionArr(industryList);
    }
    setLoading(false);
    };
    useEffect(() => {
        searchTextByCharacter();
    }, [searchText])
    console.log(industry);
    return (
        <section onClick={(e)=>{e.stopPropagation();}} className='bg-white w-full grayBlurShadow overflow-hidden rounded-xl'>
            <div className=' flex items-center grayBlurShadow px-4 py-1' ><img className=' h-6 w-6' src="/img/graySearch.png" alt="search" />
                <input type="text" className=' p-1  w-full' placeholder='Industry' onChange={(e) => { e.stopPropagation(); handleText(e.target.value); setLoading(true); }} autoFocus />
            </div>
            <div className='  overflow-y-scroll customScroll industryModal text-left'>
                {!loading? functionArr.length>=1? functionArr?.map((val) => {
                    return <p key={val} onClick={(e)=>{e.stopPropagation(); setIndustry(val); setIsModalOpen(false)}} className='hoverBlurShadowGray transition cursor-pointer py-2 px-5 font-semibold text-sm'>{val}</p>
                }):<p onClick={(e)=>{e.stopPropagation(); setIndustry("other"); setIsModalOpen(false)}} className='hoverBlurShadowGray transition cursor-pointer py-2 px-5 font-semibold text-sm'>Other</p>:
                <img className=' h-10 ml-8 py-1' src="/img/loadingBlue.webp" alt="Loading..." /> }
            </div>
        </section>
    );
}

export default IndustryModal;
import React from 'react';

function Loading(props) {
    return (
        <div className=' w-full h-60'>
            <img className=' m-auto w-44' src="/img/loading2.gif" alt="" />
        </div>
    );
}

export default Loading;
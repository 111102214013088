import React, { useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';

function CompanyProfile(props) {
    const [nav, setNav] = useState("Registration");
    const navigate = useNavigate();
    return (
        <section className = ' lightBabyGreen w-full flex h-screen overflow-hidden'>
        < aside className = '' >
        <nav id='navbarCover' className='relative w-fit h-screen navAnimation'>
            <div className=' h-screen p-4'>
                <header id='navbar' className='header h-full darkPurple text-left p-5 font-semibold text-white'>
                    <div className=''>
                        <div className=' ml-2 mb-5'>
                            <img className=' w-36' src="/img/tcoLogo3.png" alt="" />
                        </div>

                        <nav className='flex flex-col'>
                            <nav onClick={() => { setNav("Registration") }} className={`px-4 py-3 cursor-pointer flex flex-row items-center font-semibold  rounded-full ${nav === "Registration" ? "textDarkPurple babyGreen navAnimation font-bold" : ""}`}>Profile</nav>
                            <nav onClick={() => { setNav("Master") }} className={`px-4 py-3 cursor-pointer flex flex-row items-center font-semibold  rounded-full ${nav === "Master" ? "textDarkPurple babyGreen navAnimation font-bold" : ""}`}>Create New Jobs</nav>
                        </nav>
                    </div>

                </header>
            </div>
        </nav>
        </aside >
        </section >
    );
}

export default CompanyProfile;
import React, { useEffect } from 'react';
import { createContext, useState } from "react";
import { basePath } from '../Constants/constant';
import axios from 'axios';

export const AppContext = createContext(null);

function ContextApi({ children }) {
    const [industry, setIndustry] = useState("");
    const [adminNavbar, setAdminNavbar] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [token, setToken] = useState("");
    const [user, setUser] = useState([]);
    console.log(adminNavbar);
    const getData=()=>{
        let options = {
            url: `${basePath}/verify`,
            method: "GET",
            headers: {
                Accept: "application/json",
                token: localStorage.getItem("token")
            },
        }
        axios(options)
            .then((responce) => {
                console.log(responce);
                if (responce.status === 200) {
                    setUser(responce?.data.permissions);
                    console.log("Information entered successfully");
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }
    useEffect(() => {
        setToken(localStorage.getItem("token"));
            if(localStorage.getItem("token")){
                getData();
            }
    }, [token]);
    console.log(user);
    return (
        <AppContext.Provider value={{
            industry, setIndustry,
            adminNavbar, setAdminNavbar,
            isModalOpen, setIsModalOpen,
            token, setToken,
            user, setUser,
            getData
        }}>
            {children}
        </AppContext.Provider>
    );
}

export default ContextApi;
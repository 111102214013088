import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../ContextApi/ContextApi';
import { useNavigate, useParams } from 'react-router-dom';
import axios, { all } from 'axios';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import "./jobs.css";
import { basePath, monthData } from '../Constants/constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight, faXmark } from '@fortawesome/free-solid-svg-icons'

function AllPostedJobs(props) {
    const { index } = useParams();
    const { industry, setIndustry, isModalOpen, setIsModalOpen, user } = useContext(AppContext);
    const navigate = useNavigate();
    const [allJobs, setAllJobs] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [allCompany, setAllCompany] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedJd, setSelectedJd] = useState(null);
    // const [positionStatus, setPositionStatus] = useState("");
    const [viewModal, setViewModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [isAction, setIsAction] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingFilter, setLoadingFilter] = useState(true);
    const [loadingExport, setLoadingExport] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [loadingTable, setLoadingTable] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [allPages, setAllPages] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [formData, setFormData] = useState({
        id: "",
        company: "",
        position: "",
        location: "",
        min_experience: "",
        max_experience: "",
        minCtc: "",
        maxCtc: "",
        totalPositions: "",
        genderPreference: "",
        qualification: "",
        description: ""
    });
    const [filterData, setFilterData] = useState({
        fromDate: "",
        toDate: "",
        company: "",
        position: "",
        positionStatus: "",
        recruiterId: localStorage.getItem("role") === "Recruiter" || localStorage.getItem("role") === "Team Lead" ? localStorage.getItem("userId") : "",
        orderBy: "upload_date",
        orderDirection: "DESC",
    });

    // const getJobs = async () => {
    //     try {
    //         setLoadingTable(true);
    //         let response = await axios.get("http://localhost:3002/getjobs");
    //         console.log("jobs==>>", response.data);
    //         let data = response?.data.filter((val) => {
    //             if (!val?.recruiter_assign) {
    //                 return val;
    //             }
    //         });
    //         console.log("jobs data==>>", data);
    //         setAllJobs(response.data);
    //         setLoadingTable(false);
    //     } catch (error) {
    //         setLoadingTable(false);
    //         console.log(error);
    //     }
    // }
    const getUserData = async () => {
        try {
            let response = await axios.get(`${basePath}/getRecruiter`);
            console.log("users====>>>>>", response.data);
            setAllUsers(response?.data);
        } catch (error) {
            notifyFillTheFields("There is some server issue!!")
        }
    }

    const getJobs = async () => {
        try {
            setLoadingTable(true);
            let response = await axios.get(`${basePath}/getjobBypage?limit=${limit}&page=${page}`);
            console.log("jobs==>>", response.data);
            setAllJobs(response?.data?.data);
            setTotalRecords(response?.data?.totalRecords);
            setAllPages(response?.data?.pages);
            setLoadingTable(false);
        } catch (error) {
            setLoadingTable(false);
            console.log(error);
        }
    }
    const getFilterJobs = async () => {
        try {
            setLoadingTable(true);
            let response = await axios.get(`${basePath}/getjobBypage?limit=${limit}&page=${page}&filter=${JSON.stringify(filterData)}`);
            console.log("jobs==>>", response.data);
            setAllJobs(response?.data?.data);
            setTotalRecords(response?.data?.totalRecords);
            setAllPages(response?.data?.pages);
            setLoadingTable(false);
        } catch (error) {
            setLoadingTable(false);
            console.log(error);
        }
    }
    const handleJobSort = (columnName) => {
        if (filterData?.orderBy === columnName) {
            if (filterData?.orderDirection === "DESC") {
                setFilterData({ ...filterData, orderBy: columnName, orderDirection: 'ASC' });
            }
            else {
                setFilterData({ ...filterData, orderBy: columnName, orderDirection: 'DESC' });
            }
        }
        else {
            setFilterData({ ...filterData, orderBy: columnName, orderDirection: 'ASC' });
        }
    }
    const getCompany = async () => {
        setLoading(true);
        let response = await axios.get(`${basePath}/getcompany`);
        console.log("company==>>", response.data);
        setAllCompany(response?.data);
        setLoading(false);
    }
    const handlePositionStatus = async (e, position, positionStatus) => {

        let data = {
            position_status: positionStatus
        }
        const options = {
            url: `${basePath}/updatePositionStatus/${position}`,
            method: "PATCH",
            headers: {
                Accept: "application/json",
            },
            data: data,
        }
        axios(options)
            .then((response) => {
                if (response.status == 200) {


                    console.log(response);
                    getFilterJobs();
                    notify("Position Status Changed Successfully");
                    console.log("Information entered successfully");
                }
                else {

                    notifyFillTheFields("Unable to Change Position Status");
                }
            })
            .catch((e) => {

                console.log(e);
                notifyFillTheFields("Unable to Change Position Status");
            });
    }
    const handleExport = async () => {
        try {
            setLoadingExport(true);
            const response = await axios.get(`${basePath}/exportAssignRecruiter?filter=${JSON.stringify(filterData)}`, {
                responseType: 'blob', // Ensure response is treated as a blob
            });

            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a temporary <a> element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Posted_Jobs_${new Date()}.xlsx`);
            document.body.appendChild(link);
            link.click();

            // Clean up resources
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            setLoadingExport(false);
        } catch (error) {
            console.error('Error exporting jobs:', error);
            setLoadingExport(false);
        }
    };

    const downloadButton = async (jdId, jdName) => {
        setLoadingDownload(true);
        try {
            const response = await fetch(`${basePath}/downloadJD/${jdId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            console.log("response JD======>>>>>", response);
            const blob = await response.blob();
            console.log("response blob======>>>>>", blob);
            const url = window.URL.createObjectURL(blob);
            console.log("response url======>>>>>", url);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${jdName.replace(/ /g, "_")}_jd_${new Date().toISOString().split("T")[0]}.${blob?.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? "docx" : blob?.type === 'application/msword' ? "doc" : "pdf"}`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url); // Cleanup the URL object
            setLoadingDownload(false);
        } catch (error) {
            console.error('There was a problem with the download:', error);
            if (error?.status === 404) {
                notifyFillTheFields(error?.response?.error);
            }
            else {
                notifyFillTheFields(error?.response?.error);
            }
            setLoadingDownload(false);
        }
    }

    // const downloadButton = (candidateId) => {
    //     try {
    //         const downloadUrl = `${basePath}/downloadJD/${candidateId}`;
    //         window.open(downloadUrl,"_self");
    //     } catch (error) {
    //         if(error?.status===404){
    //             notifyFillTheFields(error?.response?.error);
    //         }
    //         else{
    //             notifyFillTheFields(error?.response?.error);
    //         }
    //     }
    // };
    useEffect(() => {
        setLoadingFilter(true);
        getUserData();
        if (localStorage.getItem("role") === "Recruiter" || localStorage.getItem("role") === "Team Lead") {
            getFilterJobs();
        } else {
            getJobs();
        }
        setLoadingFilter(false);
        getCompany();
    }, []);

    useEffect(() => {
        if (
            !loadingFilter &&
            !filterData?.company &&
            !filterData?.fromDate &&
            !filterData?.toDate &&
            !filterData?.position &&
            !filterData?.positionStatus &&
            !filterData?.recruiterId &&
            !filterData?.orderBy &&
            !filterData?.orderDirection 
        ) {
            getJobs();
        }
        else if (!loadingFilter) {
            getFilterJobs();
        }
    }, [filterData, limit, page])

    const notify = (msg = "successfull!!") => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const notifyFillTheFields = (msg = "There some issue!!") => toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const handleFileChange = (e) => {
        const jd_upload = e.target.files[0];
        if (jd_upload) {
            setFormData({ ...formData, description: jd_upload });
            setSelectedJd(jd_upload);
            console.log(jd_upload);
        } else {
            // Handle the case where no file is selected
            console.error("No file selected");
        }
    };
    const handleSubmitUpdate = (e) => {
        e.preventDefault();
        console.log(selectedJd);
        setLoadingUpdate(true);
        let data = {
            company_id: formData?.company,
            position: formData?.position,
            location: formData?.location,
            min_experience: `${formData?.min_experience} Yrs.`,
            max_experience: `${formData?.max_experience} Yrs.`,
            min_ctc: `${formData?.minCtc} LPA`,
            max_ctc: `${formData?.maxCtc} LPA`,
            no_of_positions: formData?.totalPositions,
            gender_pref: formData?.genderPreference,
            qualification: formData?.qualification,
            jd_upload: selectedJd,
            recruiter_id: localStorage.getItem("userId")
            // upload_date:new Date(),
        }
        console.log(data);
        if (formData?.company) {

            const options = {
                url: `${basePath}/updatejob/${formData?.id}`,
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    'Content-Type': 'multipart/form-data',
                },
                data: data,
            }
            axios(options)
                .then((response) => {
                    if (response.status == 200) {
                        setLoading(false);
                        document.getElementById("jobpost").reset();
                        setFormData({
                            id: "",
                            company: "",
                            position: "",
                            location: "",
                            min_experience: "",
                            max_experience: "",
                            minCtc: "",
                            maxCtc: "",
                            totalPositions: "",
                            genderPreference: "",
                            qualification: "",
                            description: ""
                        });
                        setEditModal(false);
                        if (
                            !filterData?.company &&
                            !filterData?.fromDate &&
                            !filterData?.toDate &&
                            !filterData?.position &&
                            !filterData?.positionStatus &&
                            !filterData?.recruiterId
                        ) {
                            getJobs();
                        }
                        else if (!loadingFilter) {
                            getFilterJobs();
                        }
                        setLoadingUpdate(false);
                        notify("Job Updated Successfully");
                        console.log("Information entered successfully");
                    }
                    else {
                        setLoadingUpdate(false);
                        notifyFillTheFields("Unable to update job");
                    }
                })
                .catch((e) => {
                    setLoadingUpdate(false);
                    console.log(e);
                    notifyFillTheFields("Unable to update job");
                });
        } else {
            notifyFillTheFields("All Fields Are Required!!!");
        }
    }
    const checkAllFields = () => {
        if (!formData?.company && !formData?.position
            && !formData?.location && !formData?.experience && !formData?.minCtc &&
            !formData?.maxCtc && !formData?.totalPositions && !formData?.genderPreference &&
            !formData?.qualification && !formData?.description) {
            notifyFillTheFields("All Fields Are Required!!!");
        }
    }
    const handleEdit = (idx, currentId) => {

        setFormData({
            id: currentId,
            company: allJobs[idx]?.company_id,
            position: allJobs[idx]?.position,
            location: allJobs[idx]?.location,
            min_experience: allJobs[idx]?.min_experience?.split(" ")[0],
            max_experience: allJobs[idx]?.max_experience?.split(" ")[0],
            minCtc: allJobs[idx]?.min_ctc?.split(" ")[0],
            maxCtc: allJobs[idx]?.max_ctc?.split(" ")[0],
            totalPositions: allJobs[idx]?.no_of_positions,
            genderPreference: allJobs[idx]?.gender_pref,
            qualification: allJobs[idx]?.qualification,
            description: allJobs[idx]?.jd_upload
        });
        setEditModal(true);
    }
    const handleDelete = (e, id) => {
        if (id) {
            setLoading(true);
            const options = {
                url: `${basePath}/deletejob/${id}`,
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                },
            }
            axios(options)
                .then((response) => {
                    if (response.status == 200) {
                        setLoading(false);
                        getJobs();
                        notify("Job Deleted Successfully");
                        console.log("Information Deleted successfully");
                    }
                    else {
                        setLoading(false);
                        alert("Unable to Delete");
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    alert("Unable to delete");
                });
        }
        else {
            notifyFillTheFields("Unable to delete");
        }
    }

    return (
        <section onClick={() => { setIsModalOpen(false); setIsAction(false); }} className='lightBabyGreen baseHeightWidth customScroll mb-5'>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            <div className=' fixed bottom-3 right-4'>
                <div onClick={getJobs} className='cursor-pointer bg-red-600 px-2.5 py-2 rounded-full hoverRed'>
                    <FontAwesomeIcon icon={faRotateRight} size={"lg"} style={{ color: "#ffffff", }} />
                </div>
            </div>
            <div>
                <div className='welcomeBarHeight babyGreen smoothTransition textDarkPurple px-10 rounded-3xl flex items-center justify-between navAnimation  m-auto'>
                    <div className=' text-left'>
                        <h1 className=' text-5xl font-bold'>All Posted Jobs</h1>
                        <p className=' text-gray-500 font-semibold'>Ready to start your day</p>
                    </div>
                    <img className=' w-44' src="/img/registration.png" alt="registration" />
                </div>

                {viewModal ?
                    <div onClick={() => { setViewModal(false); setCurrentIndex(false); }} className=' w-full baseHeightWidth fixed left-0 top-0 flex items-center justify-center blurBack z-20'>
                        <div onClick={(e) => { e.stopPropagation(); }} className='relative bg-white py-10 px-10  w-[52vw] rounded-xl h-fit navAnimation'>
                            <button onClick={(e) => { setViewModal(false); setCurrentIndex(false); }} className="absolute bg-red-600 px-1.5 py-0.5    rounded-full w-fit top-1 right-1 z-[11] ">
                                <FontAwesomeIcon icon={faXmark} size='xl' style={{ color: "#ffffff", }} />
                            </button>
                            <div className=' flex justify-between items-center mb-2'>
                                <h2 className=' bg-blue-600 text-white font-semibold px-3 rounded-full'>{allJobs[currentIndex]?.upload_date.split("T")[0]}</h2>
                                <h1 className={`${allJobs[currentIndex]?.position_status === "Open" ? "bg-green-600" : "bg-red-600"} text-white font-semibold px-3 rounded-full`}>{allJobs[currentIndex]?.position_status}</h1>
                            </div>
                            <h1 className=' text-3xl font-semibold my-3'>{allJobs[currentIndex]?.position}</h1>
                            <h2 className=' text-blue-600 font-semibold'>{allJobs[currentIndex]?.Company.company_name}</h2>
                            <div className=' flex flex-col gap-2 font-semibold'>
                                <div className=' flex justify-between items-center '>
                                    <div className=' text-left'>
                                        <label className=' text-gray-400 text-xs'>Experience</label>
                                        <h2>{allJobs[currentIndex]?.min_experience} <span className='text-gray-500'>To</span> {allJobs[currentIndex]?.max_experience}</h2>
                                    </div>
                                    <div className=' text-right flex flex-col justify-end items-end'>
                                        <label className=' text-gray-400 text-xs'>Qualification</label>
                                        <h2 className="w-[60%]">{allJobs[currentIndex]?.qualification} </h2>
                                    </div>

                                </div>
                                <div className=' flex justify-between items-center'>
                                    <div className=' text-left'>
                                        <label className=' text-gray-400 text-xs'>Location</label>
                                        <h2>{allJobs[currentIndex]?.location} </h2>
                                    </div>
                                    <div className=' text-right'>
                                        <label className=' text-gray-400 text-xs'>Total Positions</label>
                                        <h2>{allJobs[currentIndex]?.no_of_positions} </h2>
                                    </div>
                                </div>
                                <div className=' flex justify-between items-center'>
                                    <div className=' text-left'>
                                        <label className=' text-gray-400 text-xs'>Gender Preference</label>
                                        <h2>{allJobs[currentIndex]?.gender_pref} </h2>
                                    </div>
                                    <div className=' text-right'>
                                        <label className=' text-gray-400 text-xs'>CTC</label>
                                        <h2>₹{allJobs[currentIndex]?.min_ctc} <span className='text-gray-500'>To</span> ₹{allJobs[currentIndex]?.max_ctc}</h2>
                                    </div>
                                </div>
                                <div className=' flex justify-between items-center'>
                                    <div className=' text-left'>
                                        <label className=' text-gray-400 text-xs'>JD Download</label>
                                        <h2> {allJobs[currentIndex]?.jd_upload ?
                                            !loadingDownload ?
                                                <button className=" darkPurple text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2 hoverGreen" onClick={(e) => { downloadButton(allJobs[currentIndex]?.id, allJobs[currentIndex]?.position) }} >Download</button> :
                                                <button className=" darkPurple text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2"  >Downloading...</button> :
                                            <h2>JD Not Uploaded</h2>}  </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                {editModal ?
                    <div onClick={() => { }} className=' w-screen h-screen fixed left-0 top-0 py-5 flex items-center justify-center blurBack z-20'>
                        <div onClick={(e) => { e.stopPropagation(); }} className='relative bg-white p-4 h-full overflow-y-scroll  rounded-xl width60'>
                            {!loadingUpdate ?
                                <button onClick={(e) => { setEditModal(false); setCurrentIndex(false); }} className="absolute bg-red-600 px-1.5 py-0.5    rounded-full w-fit top-1 right-1 z-[11] ">
                                    <FontAwesomeIcon icon={faXmark} size='xl' style={{ color: "#ffffff", }} />
                                </button> :
                                <button className="absolute opacity-70 bg-red-600 px-1.5 py-0.5    rounded-full w-fit top-1 right-1 z-[11] ">
                                    <FontAwesomeIcon icon={faXmark} size='xl' style={{ color: "#ffffff", }} />
                                </button>}
                            <form id='jobpost' onSubmit={handleSubmitUpdate} className=' p-4'>
                                <h1 className=' text-3xl font-bold babyGreen  rounded-xl py-3 mb-5'>Update Job</h1>
                                <div className='relative flex flex-col gap-5 p-7 borderDarkBlue rounded-3xl'>
                                    <h1 className=' bg-white absolute top-15px font-semibold px-1 textDarkBlue'>Job Details</h1>
                                    <div className=' grid gridResponsive gap-3'>
                                        <div className='input-group relative'>
                                            <select value={formData?.company} onChange={(e) => { setFormData({ ...formData, company: parseInt(e.target.value) }) }} className=' w-full outline-none'>
                                                <option value="" selected disabled>--Select Company--</option>
                                                {allCompany?.map((val) => {
                                                    return (
                                                        <option key={val.id} value={val?.id}>{val?.company_name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className='input-group relative'>
                                            <input value={formData?.position} onChange={(e) => { setFormData({ ...formData, position: e.target.value }) }} id='position' placeholder='Position*' className=' w-full' required type="text" />
                                            <label htmlFor="position" className='label'>Position</label>
                                        </div>
                                    </div>
                                    <div className=' grid gridResponsive gap-3'>
                                        <div className='input-group relative'>
                                            <input value={formData?.location} onChange={(e) => { setFormData({ ...formData, location: e.target.value }) }} id='location' placeholder='Location*' className=' w-full' required type="text" />
                                            <label htmlFor="location" className='label'>Location</label>
                                        </div>
                                        <div className=' grid grid-cols-2 gap-3'>
                                            <div className='input-group relative'>
                                                <input value={formData?.min_experience} onChange={(e) => { setFormData({ ...formData, min_experience: e.target.value }) }} id='min_experience' placeholder='Min Experience*' className=' w-full' required type="number" />
                                                <label htmlFor="min_experience" className='label'>Min Experience</label>
                                            </div>
                                            <div className='input-group relative'>
                                                <input value={formData?.max_experience} onChange={(e) => { setFormData({ ...formData, max_experience: e.target.value }) }} id='max_experience' placeholder='Max Experience*' className=' w-full' required type="number" />
                                                <label htmlFor="max_experience" className='label'> Max Experience</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=' grid gridResponsive gap-3'>
                                        <div className=' grid gridResponsive gap-3'>
                                            <div className='input-group relative'>
                                                <input value={formData?.minCtc} onChange={(e) => { setFormData({ ...formData, minCtc: e.target.value }) }} id='min' placeholder='Min CTC*' className=' w-full' required type="number" />
                                                <label htmlFor="min" className='label'>Min CTC</label>
                                            </div>
                                            <div className='input-group relative'>
                                                <input value={formData?.maxCtc} onChange={(e) => { setFormData({ ...formData, maxCtc: e.target.value }) }} id='max' placeholder='Max CTC*' className=' w-full' required type="number" />
                                                <label htmlFor="max" className='label'>Max CTC</label>
                                            </div>
                                        </div>
                                        <div className='input-group relative'>
                                            <input value={formData?.totalPositions} onChange={(e) => { setFormData({ ...formData, totalPositions: e.target.value }) }} id='totalPositions' placeholder='No. of positions*' className=' w-full' required type="number" />
                                            <label htmlFor="totalPositions" className='label'>No. of positions</label>
                                        </div>
                                    </div>
                                    <div className=' grid gridResponsive gap-3'>
                                        <div className='input-group relative'>
                                            <select value={formData?.genderPreference} onChange={(e) => { setFormData({ ...formData, genderPreference: e.target.value }) }} className=' w-full outline-none'>
                                                <option value="" selected disabled>--Select Gender Preference--</option>
                                                <option value={"Male"}>Male</option>
                                                <option value={"Female"}>Female</option>
                                                <option value={"No preference"}>No preference</option>
                                            </select>
                                        </div>
                                        <div className='input-group relative'>
                                            <input value={formData?.qualification} onChange={(e) => { setFormData({ ...formData, qualification: e.target.value }) }} id='qualification' placeholder='Qualification*' className=' w-full' required type="text" />
                                            <label htmlFor="qualification" className='label'>Qualification</label>
                                        </div>
                                    </div>
                                    {/* <div className='input-group relative'>
                                <textarea value={formData?.description} onChange={(e) => { setFormData({ ...formData, description: e.target.value }) }} id='description' cols="20" rows="3" placeholder='Job Description...' required></textarea>
                                <label htmlFor="description" className='label'>Job Description</label>
                            </div> */}
                                    <div className='input-group relative'>
                                        <input onChange={handleFileChange} id='jd' accept='.pdf, .docx, .doc' placeholder='Upload Job Description*' className=' w-full' type="file" />
                                        <label htmlFor="jd" className='label'>Upload Job Description</label>
                                    </div>
                                    <div className=' w-full '>
                                        {!loadingUpdate ?
                                            <button onClick={(e) => { checkAllFields() }} type='submit' className=' w-fit darkPurple text-white font-semibold rounded-xl px-7 text-sm py-2 hoverGreen'>Update</button> :
                                            <button className=' w-fit darkPurple text-white font-semibold rounded-xl px-7 text-sm py-2 '>Loading...</button>}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> : null}
                {!loadingFilter ?
                    <div className=' flex flex-wrap items-center gap-3 grayBlurShadow bg-white p-3 mt-5 rounded-xl'>
                        {/* <h1>Date Filter</h1> */}
                        {user[index]?.add_access ?
                            <div onClick={(e) => { localStorage.getItem("role") === "Super Admin" ? navigate(`/main/post-job/${index}`) : navigate(`/post-job/${index}`) }} className='darkPurple w-fit my-2 ml-2 cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'><img className=' w-8 ' src="/img/addBtn.png" alt="" />
                                <button>Post New Job</button>
                            </div> : null}
                        <div className='flex items-center gap-3'>
                            <div className=' flex flex-col justify-start items-start'>
                                <label>From: </label>
                                <input
                                    className=' border-2 rounded-lg px-2 py-1'
                                    type="date"
                                    value={filterData?.fromDate}
                                    onChange={e => setFilterData({ ...filterData, fromDate: e.target.value })}
                                />
                            </div>
                            <div className=' flex flex-col justify-start items-start'>
                                <label>To: </label>
                                <input
                                    className=' border-2 rounded-lg px-2 py-1'
                                    type="date"
                                    value={filterData?.toDate}
                                    onChange={e => setFilterData({ ...filterData, toDate: e.target.value })}
                                />
                            </div>
                        </div>

                        <div className='flex flex-col justify-start items-start '>
                            <label >Company</label>
                            <input
                                value={filterData?.company}
                                className=' border-2 rounded-lg px-2 py-1'
                                onChange={e => setFilterData({ ...filterData, company: e.target.value })}
                                type="text" />
                        </div>
                        <div className='flex flex-col justify-start items-start '>
                            <label >Position</label>
                            <input
                                value={filterData?.position}
                                className=' border-2 rounded-lg px-2 py-1'
                                onChange={e => setFilterData({ ...filterData, position: e.target.value })}
                                type="text" />
                        </div>
                        {localStorage.getItem("role") === "Recruiter" || localStorage.getItem("role") === "Team Lead" ?
                            null :
                            <div key={"recruiter"} className='flex flex-col justify-start items-start'>
                                <label >Recruiter</label>
                                <select value={filterData?.recruiterId}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => setFilterData({ ...filterData, recruiterId: e.target.value })} >
                                    <option value="" selected disabled>Select Recruiter</option>
                                    {allUsers?.map((val) => {
                                        return (
                                            <option value={val?.id}>{val?.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        }
                        <div className='flex flex-col justify-start items-start '>
                            <label >Position Status</label>
                            <select value={filterData?.positionStatus}
                                className=' border-2 rounded-lg px-2 py-1 w-full'
                                onChange={e => setFilterData({ ...filterData, positionStatus: e.target.value })}>
                                <option value="All">All</option>
                                <option value="Open">Open</option>
                                <option value="Close">Close</option>
                            </select>
                        </div>
                        <button onClick={getFilterJobs} className=' darkPurple cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>Apply filter</button>
                        <button onClick={() => {
                            setFilterData({
                                fromDate: "",
                                toDate: "",
                                company: "",
                                position: "",
                                positionStatus: "",
                                recruiterId: localStorage.getItem("role") === "Recruiter" || localStorage.getItem("role") === "Team Lead" ? localStorage.getItem("userId") : "",
                                orderBy: "upload_date",
                                orderDirection: "DESC",
                            })
                            // getFilterJobs();
                        }} className=' text-red-700 bg-red-200  py-2 px-3 rounded-lg hoverRed'>Clear FIlter</button>
                        <div className=' text-left w-fit'>
                            <button onClick={handleExport} className=' py-2 px-3 bg-green-800 text-white rounded-lg '>{loadingExport ? "Exporting..." : "Excel"}</button>
                        </div>
                    </div> : null}
                {!loadingTable ?
                    Array.isArray(allJobs) && allJobs.length >= 1 ?
                        <section className='bg-white grayBlurShadow px-7 pt-7 mt-4 rounded-3xl mb-8'>
                            <div className=' overflow-auto tableBody tableBox'>
                                <table className=' navAnimation rounded-xl baseWidth text-left'>
                                    <thead className=' text-lg tableHead'>
                                        <tr className=' bg-gray-200'>
                                            <th className='whitespace-nowrap text-center'>Sr.No.</th>
                                            <th className='whitespace-nowrap text-center'>
                                                <div onClick={() => handleJobSort("upload_date")} className='flex item-center gap-2 pr-2 justify-center w-full cursor-pointer'>
                                                    Posted Date
                                                    {filterData?.orderBy === "upload_date" && filterData?.orderDirection === "ASC" ?
                                                        <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                        filterData?.orderBy === "upload_date" && filterData?.orderDirection === "DESC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                            <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                </div>
                                            </th>
                                            <th  >
                                                <div onClick={() => handleJobSort("company_name")} className='flex item-center gap-2 pr-2 justify-center w-full cursor-pointer'>
                                                    Company
                                                    {filterData?.orderBy === "company_name" && filterData?.orderDirection === "ASC" ?
                                                        <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                        filterData?.orderBy === "company_name" && filterData?.orderDirection === "DESC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                            <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                </div>
                                            </th>
                                            <th >
                                                <div onClick={() => handleJobSort("position")} className='flex item-center gap-2 pr-2 justify-center w-full cursor-pointer'>
                                                    Position
                                                    {filterData?.orderBy === "position" && filterData?.orderDirection === "ASC" ?
                                                        <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                        filterData?.orderBy === "position" && filterData?.orderDirection === "DESC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                            <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                </div>
                                            </th>
                                            {/* <th  >Qualification</th> */}
                                            <th >Location</th>
                                            {/* <th >Min CTC</th> */}
                                            {/* <th>Max CTC</th> */}
                                            <th>
                                                <div onClick={() => handleJobSort("max_ctc")} className='flex item-center gap-2 pr-2 justify-center w-full cursor-pointer'>
                                                    CTC
                                                    {filterData?.orderBy === "max_ctc" && filterData?.orderDirection === "ASC" ?
                                                        <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                        filterData?.orderBy === "max_ctc" && filterData?.orderDirection === "DESC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                            <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                </div>
                                            </th>
                                            <th>Experience</th>
                                            {/* <th className='whitespace-nowrap'>No. of Positions</th> */}
                                            <th className='whitespace-nowrap'>Position Status</th>
                                            {user[index]?.view_access || user[index]?.edit_access
                                                || user[index]?.delete_access ?
                                                <th>Actions</th> : null}
                                        </tr>
                                    </thead>
                                    <tbody className='tableBody '>
                                        {
                                            allJobs?.map((val, idx) => {
                                                return (
                                                    <tr key={val?.id} id={val?.id} className=' backWhite font-semibold text-sm'>
                                                        <td className='text-center'>{idx + 1}</td>
                                                        <td className='whitespace-nowrap text-center'>{`${val?.created_at?.split("-")[2]} ${monthData[parseInt(val?.created_at?.split("-")[1])]} ${val?.created_at?.split("-")[0]}`}</td>
                                                        <td className='whitespace-nowrap'>{val?.Company.company_name}</td>
                                                        <td className='whitespace-nowrap'>{val?.position}</td>
                                                        {/* <td className='whitespace-nowrap'>{val?.qualification}</td> */}
                                                        <td >{val?.location}</td>
                                                        {/* <td>{val?.min_ctc}</td> */}
                                                        <td className='whitespace-nowrap'>{val?.max_ctc}</td>
                                                        <td className='whitespace-nowrap'>{val?.min_experience} - {val?.max_experience}</td>
                                                        {/* <td>{val?.no_of_positions}</td> */}
                                                        {/* <td>{val?.gender_pref==="Male"? <span className='text-blue-700 font-bold'>{val?.gender_pref}</span>: val?.gender_pref==="Female"?<span className='text-pink-700 font-bold'>{val?.gender_pref}</span>:<span className=' font-semibold'>{val?.gender_pref}</span> }</td> */}
                                                        <td>
                                                            <select value={val?.position_status} onChange={(e) => { handlePositionStatus(e, val?.id, e.target.value) }} className={`whitespace-nowrap w-full text-center outline-none py-2 px-1 ${val?.position_status === "Open" ? "text-green-500 greenBox" : "text-red-500 redBox"}`} >
                                                                <option value="Open">Open</option>
                                                                <option value="Close">Close</option>
                                                            </select>
                                                        </td>

                                                        {user[index]?.view_access || user[index]?.edit_access
                                                            || user[index]?.delete_access ?
                                                            <td className=' flex justify-center items-center'>
                                                                <div className=' relative w-fit'>
                                                                    <div onClick={(e) => { e.stopPropagation(); setIsAction(val?.id); }} className=' flex flex-row gap-1 cursor-pointer w-fit p-2 rounded-lg hoverGreen'>
                                                                        <div className=' w-2 h-2 darkPurple rounded-full '></div>
                                                                        <div className=' w-2 h-2 darkPurple rounded-full '></div>
                                                                        <div className=' w-2 h-2 darkPurple rounded-full '></div>
                                                                    </div>
                                                                    {isAction === val?.id ?
                                                                        < div className={`absolute ${allJobs?.length - 2 <= idx && allJobs?.length > 2 ? "bottom-5 z-10 right-2" : "right-2 z-10 top-5"} w-40 h-fit p-3 rounded-xl border-2 bg-white grayBlurShadow`} >
                                                                            <div className='flex flex-col'>
                                                                                {user[index]?.view_access ?
                                                                                    <button onClick={(e) => { setViewModal(true); setCurrentIndex(idx); setIsAction(false); }}
                                                                                        className=' darkPurple text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2 hoverGreen'>
                                                                                        View</button> : null}
                                                                                {user[index]?.edit_access ?
                                                                                    <button onClick={() => { handleEdit(idx, val?.id); }}
                                                                                        className=' text-yellow-600 bg-yellow-200 m-2 px-4 py-1 rounded-lg hoverYellow' >
                                                                                        Edit</button> : null}
                                                                                {user[index]?.delete_access ?
                                                                                    <button onClick={(e) => { handleDelete(e, val?.id) }}
                                                                                        className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed'>
                                                                                        Delete</button> : null}
                                                                            </div>
                                                                        </div> : null
                                                                    }
                                                                </div>
                                                            </td> : null}
                                                    </tr>
                                                )
                                            })

                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className=' flex items-center justify-between py-4'>
                                <div className=' flex items-center gap-2'>
                                    <div className='grayBlurShadow px-3 py-1'>
                                        <select value={limit} onChange={(e) => { setLimit(e.target.value); }} >
                                            <option value="10" selected>10</option>
                                            <option value="20" >20</option>
                                            <option value="50" >50</option>
                                            <option value="100" >100</option>
                                            <option value="200" >200</option>
                                            <option value="500" >500</option>
                                            <option value="All" >All</option>
                                        </select>
                                    </div>
                                    <div className='grayBlurShadow px-1 py-1 text-sm font-semibold'>
                                        <h1>Total Records: {totalRecords}</h1>
                                    </div>
                                    <div className='grayBlurShadow px-1 py-1  text-sm font-semibold'>
                                        <h1>Total Pages: {allPages}</h1>
                                    </div>
                                </div>
                                <div className='flex items-center grayBlurShadow px-3 py-1 rounded-lg'>
                                    <div onClick={(e) => {
                                        if (page > 1) {
                                            setPage(page - 1);
                                        }
                                    }} className={` ${page === 1 ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                        <img className='w-5' src="/img/leftArrow.png" alt="leftArrow" />
                                    </div>
                                    <div className=' px-3'>
                                        {page}
                                    </div>
                                    <div onClick={(e) => {
                                        if (allPages > page) {
                                            setPage(page + 1);
                                        }
                                    }} className={` ${page == allPages ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                        <img className='w-5' src="/img/rightArrow.png" alt="rightArrow" />
                                    </div>
                                </div>
                            </div>
                        </section> :
                        <div className='navAnimation flex flex-col justify-center items-center bg-white mt-4 py-10 rounded-3xl '>
                            <img className=' w-[27%]' src="/img/addReports.png" alt="" />
                            <h1 className=' text-2xl font-bold'>Oops!! "<span className=' text-blue-950'>No Jobs Found</span>"</h1>
                        </div>
                    :
                    <div className=' loaderBox mb-5 bg-white mt-5 rounded-3xl '>
                        <img className=' m-auto w-1/3' src="/img/loader2.gif" alt="loading..." />
                    </div>
                }
            </div >
        </section >
    );
}

export default AllPostedJobs;
import React, { useContext, useEffect, useState } from 'react';
import { basePath, countryPhoneCode, homeTableHeading, tableData } from '../Constants/constant';
import { debounce } from "lodash";
import "./home.css";
import { AppContext } from '../ContextApi/ContextApi';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight, faXmark } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';

function Home(props) {
    const navigate = useNavigate();
    const { industry, setIndustry, isModalOpen, setIsModalOpen, user, setUser } = useContext(AppContext);
    const [allJobs, setAllJobs] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [dashboardData, setDashboardData] = useState([]);
    const [dashboardAccess, setDashboardAccess] = useState(false);
    const [atsIndex, setAtsIndex] = useState(false);
    const [loadingDashboard, setLoadingDashboard] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loadingFilter, setLoadingFilter] = useState(true);
    const [loadingExport, setLoadingExport] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [allPages, setAllPages] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [filterData, setFilterData] = useState({
        fromDate: "",
        toDate: "",
        company: "",
        position: "",
        location: "",
        gender: "",
        recruiterId: localStorage.getItem("role") === "Recruiter" ? localStorage.getItem("userId") : "",
        qualification: "",
    });

    // filter all posted jobs
    const filterJobs = async () => {
        try {
            setLoading(true);
            let response = await axios.get(`${basePath}/getjobBypage?limit=${limit}&page=${page}&filter=${JSON.stringify(filterData)}`);
            console.log("jobs Filter==>>", response);
            setAllJobs(response?.data?.data);
            setTotalRecords(response?.data?.totalRecords);
            setAllPages(response?.data?.pages);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    const getDashboardData = async () => {
        try {
            setLoadingDashboard(true);
            let response = await axios.get(`${basePath}/getdashboardreport?id=${localStorage.getItem("userId")}`);
            console.log("getDashboardData==>>", response);
            setDashboardData([response?.data]);
            setLoadingDashboard(false);
        } catch (error) {
            setLoadingDashboard(false);
            console.log(error);
        }
    }
    const getUserData = async () => {
        let response = await axios.get(`${basePath}/getRecruiter`);
        console.log(response.data);
        setAllUsers(response?.data);
    }
    const handleFilter = debounce(() => {
        if (
            filterData?.fromDate ||
            filterData?.toDate ||
            filterData?.company ||
            filterData?.position ||
            filterData?.location ||
            filterData?.recruiterId ||
            filterData?.gender ||
            filterData?.qualification
        ) {
            filterJobs();
        }
    }, 800);
    const handleExport = async () => {
        try {
            setLoadingExport(true);
            const response = await axios.get(`${basePath}/exportAssignRecruiter?filter=${JSON.stringify(filterData)}`, {
                responseType: 'blob', // Ensure response is treated as a blob
            });

            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a temporary <a> element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `AssignJobs_to_${localStorage.getItem("name")}.xlsx`);
            document.body.appendChild(link);
            link.click();

            // Clean up resources
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            setLoadingExport(false);
        } catch (error) {
            console.error('Error exporting jobs:', error);
            setLoadingExport(false);
        }
    };
    useEffect(() => {
        getDashboardData();
        getUserData();
        setLoadingFilter(true);
        filterJobs();
        setLoadingFilter(false);
        
    }, [])
    useEffect(()=>{
        let access = user?.filter((val, idx) => {
            if (val?.Module?.module_name === "ATS Pipeline") {
                setAtsIndex(idx);
                return val;
            }
        });
        console.log("access====>>>>",access);
        setDashboardAccess(access);
    },[user])
    useEffect(() => {
        if (!loadingFilter) {
            filterJobs();
        }
    }, [page, limit])
    useEffect(() => {
        if (!loadingFilter) {
            filterJobs();
        }
    }, [filterData])
console.log("dashboardData====>>>>",dashboardData);

    return (
        <section className=' relative w-full smoothTransition'>
            <div className=' fixed bottom-3 right-4'>
                <div onClick={getDashboardData} className='cursor-pointer bg-red-600 px-2.5 py-2 rounded-full hoverRed'>
                    <FontAwesomeIcon icon={faRotateRight} size={"lg"} style={{ color: "#ffffff", }} />
                </div>
            </div>
            <div className=''>
                <div className='welcomeBarHeight babyGreen smoothTransition textDarkPurple px-10 rounded-3xl m-auto flex items-center justify-between navAnimation welcomeBox'>
                    <div className=' text-left'>
                        <h1 className=' text-6xl font-extrabold nameBox  h-18 textEllipsis'>Hii, {localStorage.getItem("name")}</h1>
                        <p className=' text-gray-500 font-semibold'>Ready to start your day</p>
                    </div>
                    <img className=' w-52' src="/img/homeImg.png" alt="" />
                </div>
            </div>
            <section className=' w-full'>
                <div className=''>
                    {!loadingDashboard ?
                        Array.isArray(dashboardData) && dashboardData?.length >= 1 ?
                            <div className=' grayBlurShadow grid grid-cols-4 p-7  w-full gap-5 mt-4 mb-10 bg-white rounded-2xl'>
                                <div onClick={(e) => {
                                    if (dashboardAccess?.length >= 1 && dashboardAccess[0]?.list_access) {
                                        localStorage.getItem("role") === "Super Admin" ?
                                            navigate(`/main/all-posted-jobs/${atsIndex}`) : navigate(`/all-posted-jobs/${atsIndex}`)
                                    }
                                }} className=' bg-white grayBlurShadow rounded-xl py-10 border-purple-400 border-2 cursor-pointer'>
                                    <h1 className=' text-purple-600 font-semibold text-xl'>Open Jobs</h1>
                                    <h1 className=' text-2xl font-bold'>{dashboardData[0]?.total_open_positions}</h1>
                                </div>
                                {/* {
                                    dashboardData?.map((val)=>{
                                        return(
                                            <div onClick={(e) => {
                                                if (dashboardAccess?.length >= 1 && dashboardAccess[0]?.list_access) {
                                                    localStorage.getItem("role") === "Super Admin" ?
                                                        navigate(`/main/ats-pipeline/${atsIndex}?status=${val?.status}`) : navigate(`/ats-pipeline/${atsIndex}?status=${val?.status}`)
                                                }
                                            }} className=' bg-white grayBlurShadow rounded-xl py-10 border-blue-400 border-2'>
                                                <h1 className=' text-blue-600 font-semibold text-xl'>{val?.status}</h1>
                                                <h1 className=' text-xl font-bold'>{val?.count}</h1>
                                            </div>
                                        )
                                    })
                                } */}
                                <div onClick={(e) => {
                                    if (dashboardAccess?.length >= 1 && dashboardAccess[0]?.list_access) {
                                        localStorage.getItem("role") === "Super Admin" ?
                                            navigate(`/main/ats-pipeline/${atsIndex}?status=${"Sent To Client"}`) : navigate(`/ats-pipeline/${atsIndex}?status=${"Sent To Client"}`)
                                    }
                                }} className=' bg-white grayBlurShadow rounded-xl py-10 border-blue-400 border-2 cursor-pointer'>
                                    <h1 className=' text-blue-600 font-semibold text-xl'>Sent To Client</h1>
                                    <h1 className=' text-xl font-bold'>{dashboardData[0]?.total_cv_sent}</h1>
                                </div>
                                <div onClick={(e) => {
                                    if (dashboardAccess?.length >= 1 && dashboardAccess[0]?.list_access) {
                                        localStorage.getItem("role") === "Super Admin" ?
                                            navigate(`/main/ats-pipeline/${atsIndex}?status=${"Shortlisted"}`) : navigate(`/ats-pipeline/${atsIndex}?status=${"Shortlisted"}`)
                                    }
                                }}
                                 className=' bg-white grayBlurShadow rounded-xl py-10 border-fuchsia-400 border-2 cursor-pointer'>
                                    <h1 className=' text-fuchsia-600 font-semibold text-xl'>Shortlisted</h1>
                                    <h1 className=' text-xl font-bold'>{dashboardData[0]?.total_cv_shortlisted}</h1>
                                </div>
                                <div onClick={(e) => {
                                    if (dashboardAccess?.length >= 1 && dashboardAccess[0]?.list_access) {
                                        localStorage.getItem("role") === "Super Admin" ?
                                            navigate(`/main/ats-pipeline/${atsIndex}?status=${"Interview Scheduled"}`) : navigate(`/ats-pipeline/${atsIndex}?status=${"Interview Scheduled"}`)
                                    }
                                }} className=' bg-white grayBlurShadow rounded-xl py-10 border-cyan-400 border-2 cursor-pointer'>
                                    <h1 className=' text-cyan-600 font-semibold text-xl whitespace-nowrap'>Interview Scheduled</h1>
                                    <h1 className=' text-xl font-semibold'>{dashboardData[0]?.total_cv_interview_scheduled}</h1>
                                </div>
                                <div onClick={(e) => {
                                    if (dashboardAccess?.length >= 1 && dashboardAccess[0]?.list_access) {
                                        localStorage.getItem("role") === "Super Admin" ?
                                            navigate(`/main/ats-pipeline/${atsIndex}?status=${"Interview Done"}`) : navigate(`/ats-pipeline/${atsIndex}?status=${"Interview Done"}`)
                                    }
                                }} className=' bg-white grayBlurShadow rounded-xl py-10 borderCherry cursor-pointer'>
                                    <h1 className=' textCherry font-semibold text-xl whitespace-nowrap'>Interview Done</h1>
                                    <h1 className=' text-xl font-semibold'>{dashboardData[0]?.total_cv_interviewed}</h1>
                                </div>
                                <div onClick={(e) => {
                                    if (dashboardAccess?.length >= 1 && dashboardAccess[0]?.list_access) {
                                        localStorage.getItem("role") === "Super Admin" ?
                                            navigate(`/main/ats-pipeline/${atsIndex}?status=${"Final Selection"}`) : navigate(`/ats-pipeline/${atsIndex}?status=${"Final Selection"}`)
                                    }
                                }} className=' bg-white grayBlurShadow rounded-xl py-10 border-green-400 border-2 cursor-pointer'>
                                    <h1 className=' text-green-600 font-semibold text-xl'>Final Selection</h1>
                                    <h1 className=' text-xl font-semibold'>{dashboardData[0]?.total_cv_final_selection}</h1>
                                </div>

                                <div onClick={(e) => {
                                    if (dashboardAccess?.length >= 1 && dashboardAccess[0]?.list_access) {
                                        localStorage.getItem("role") === "Super Admin" ?
                                            navigate(`/main/ats-pipeline/${atsIndex}?status=${"Offer Letter Sent"}`) : navigate(`/ats-pipeline/${atsIndex}?status=${"Offer Letter Sent"}`)
                                    }
                                }} className=' bg-white grayBlurShadow rounded-xl py-10  border-blue-600 border-2  cursor-pointer'>
                                    <h1 className=' text-blue-800 font-semibold text-xl'>Offer Letter Sent</h1>
                                    <h1 className=' text-xl font-semibold'>{dashboardData[0]?.total_cv_offer_letter_sent}</h1>
                                </div>
                                <div onClick={(e) => {
                                    if (dashboardAccess?.length >= 1 && dashboardAccess[0]?.list_access) {
                                        localStorage.getItem("role") === "Super Admin" ?
                                            navigate(`/main/ats-pipeline/${atsIndex}?status=${"Final Joining"}`) : navigate(`/ats-pipeline/${atsIndex}?status=${"Final Joining"}`)
                                    }
                                }} className=' bg-white grayBlurShadow rounded-xl py-10 border-green-500 border-2 cursor-pointer'>
                                    <h1 className=' text-green-700 font-semibold text-xl'>Final Joining</h1>
                                    <h1 className=' text-xl font-semibold'>{dashboardData[0]?.total_cv_final_join}</h1>
                                </div>
                                <div onClick={(e) => {
                                    if (dashboardAccess?.length >= 1 && dashboardAccess[0]?.list_access) {
                                        localStorage.getItem("role") === "Super Admin" ?
                                            navigate(`/main/ats-pipeline/${atsIndex}?status=${"Backout"}`) : navigate(`/ats-pipeline/${atsIndex}?status=${"Backout"}`)
                                    }
                                }} className=' bg-white grayBlurShadow rounded-xl py-10 border-red-700 border-2 cursor-pointer'>
                                    <h1 className=' text-red-800 font-semibold text-xl'>Backout</h1>
                                    <h1 className=' text-xl font-semibold'>{dashboardData[0]?.total_cv_backout}</h1>
                                </div>
                                <div onClick={(e) => {
                                    if (dashboardAccess?.length >= 1 && dashboardAccess[0]?.list_access) {
                                        localStorage.getItem("role") === "Super Admin" ?
                                            navigate(`/main/ats-pipeline/${atsIndex}?status=${"CV Rejected"}`) : navigate(`/ats-pipeline/${atsIndex}?status=${"CV Rejected"}`)
                                    }
                                }} className=' bg-white grayBlurShadow rounded-xl py-10 border-red-500 border-2 cursor-pointer'>
                                    <h1 className=' text-red-600 font-semibold text-xl'>CV Rejected</h1>
                                    <h1 className=' text-xl font-semibold'>{dashboardData[0]?.total_cv_rejected}</h1>
                                </div>
                                <div onClick={(e) => {
                                    if (dashboardAccess?.length >= 1 && dashboardAccess[0]?.list_access) {
                                        localStorage.getItem("role") === "Super Admin" ?
                                            navigate(`/main/ats-pipeline/${atsIndex}?status=${"Rejected Post Interview"}`) : navigate(`/ats-pipeline/${atsIndex}?status=${"Rejected Post Interview"}`)
                                    }
                                }} className=' bg-white grayBlurShadow rounded-xl py-10 border-amber-800 border-2 cursor-pointer'>
                                    <h1 className=' text-yellow-700 font-semibold text-xl'>Rejected Post Interview</h1>
                                    <h1 className=' text-xl font-semibold'>{dashboardData[0]?.total_cv_rejected_post_interview}</h1>
                                </div>
                                <div onClick={(e) => {
                                    if (dashboardAccess?.length >= 1 && dashboardAccess[0]?.list_access) {
                                        localStorage.getItem("role") === "Super Admin" ?
                                            navigate(`/main/ats-pipeline/${atsIndex}?status=${"Interview Feedback Pending"}`) : navigate(`/ats-pipeline/${atsIndex}?status=${"Interview Feedback Pending"}`)
                                    }
                                }} className=' bg-white grayBlurShadow rounded-xl py-10 border-yellow-400 border-2 cursor-pointer'>
                                    <h1 className=' text-yellow-600 font-semibold text-xl'>Interview Feedback Pending</h1>
                                    <h1 className=' text-xl font-semibold'>{dashboardData[0]?.total_cv_feedback_pending}</h1>
                                </div>
                                {/* <div className=' bg-white grayBlurShadow rounded-xl py-10'>
                                    <h1>CV Screened</h1>
                                    <h1 className=' text-xl font-semibold'>{dashboardData[0]?.total_cv_screened}</h1>
                                </div>
                                <div className=' bg-white grayBlurShadow rounded-xl py-10'>
                                    <h1>Comfirmation Pending</h1>
                                    <h1 className=' text-xl font-semibold'>{dashboardData[0]?.total_cv_confirmation_pending}</h1>
                                </div> */}






                            </div> :
                            <div className='navAnimation min-h-[60vh] flex flex-col justify-center items-center bg-white mt-5 py-5 mb-10 rounded-3xl '>
                                <img className=' w-[24%]' src="/img/addReports.png" alt="" />
                                <h1 className=' text-2xl font-bold'>Oops!! "<span className=' text-blue-950'>No Reports Found</span>"</h1>
                            </div> :
                        <div className=' loaderBox mb-5 bg-white mt-5 rounded-3xl '>
                            <img className=' m-auto w-1/4' src="/img/loader2.gif" alt="loading..." />
                        </div>}
                </div>
                {/* {
                    localStorage.getItem("role") === "Recruiter" ?
                        <div>
                            {!loadingFilter ?
                                <>
                                    <div className=' flex flex-wrap items-center gap-3 grayBlurShadow bg-white p-3 mt-5 rounded-xl'>
                                        <h1>Date Filter</h1>
                                        <div className='flex items-center gap-3'>
                                            <div className=' flex flex-col justify-start items-start'>
                                                <label>From: </label>
                                                <input
                                                    className=' border-2 rounded-lg px-2 py-1'
                                                    type="date"
                                                    value={filterData?.fromDate}
                                                    onChange={e => setFilterData({ ...filterData, fromDate: e.target.value })}
                                                />
                                            </div>
                                            <div className=' flex flex-col justify-start items-start'>
                                                <label>To: </label>
                                                <input
                                                    className=' border-2 rounded-lg px-2 py-1'
                                                    type="date"
                                                    value={filterData?.toDate}
                                                    onChange={e => setFilterData({ ...filterData, toDate: e.target.value })}
                                                />
                                            </div>
                                        </div>

                                        <div className='flex flex-col justify-start items-start mb-2'>
                                            <label >Company</label>
                                            <input
                                                value={filterData?.company}
                                                className=' border-2 rounded-lg px-2 py-1'
                                                onChange={e => setFilterData({ ...filterData, company: e.target.value })}
                                                type="text" />
                                        </div>
                                        <div className='flex flex-col justify-start items-start mb-2'>
                                            <label >Position</label>
                                            <input
                                                value={filterData?.position}
                                                className=' border-2 rounded-lg px-2 py-1'
                                                onChange={e => setFilterData({ ...filterData, position: e.target.value })}
                                                type="text" />
                                        </div>
                                        <div className='flex flex-col justify-start items-start mb-2'>
                                            <label >Location</label>
                                            <input
                                                value={filterData?.location}
                                                className=' border-2 rounded-lg px-2 py-1'
                                                onChange={e => setFilterData({ ...filterData, location: e.target.value })}
                                                type="text" />
                                        </div>
                                        <div className='flex flex-col justify-start items-start mb-2'>
                                            <label >Qualification</label>
                                            <input
                                                value={filterData?.qualification}
                                                className=' border-2 rounded-lg px-2 py-1'
                                                onChange={e => setFilterData({ ...filterData, qualification: e.target.value })}
                                                type="text" />
                                        </div>

                                        <button onClick={filterJobs} className=' darkPurple cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>Apply filter</button>
                                        <button onClick={() => {
                                            setFilterData({
                                                fromDate: "",
                                                toDate: "",
                                                company: "",
                                                position: "",
                                                location: "",
                                                gender: "",
                                                recruiterId: localStorage.getItem("role") === "Recruiter" ? localStorage.getItem("userId") : "",
                                                qualification: "",
                                            })
                                            filterJobs();
                                        }} className=' text-red-700 bg-red-200  py-2 px-3 rounded-lg hoverRed'>Clear FIlter</button>
                                        <div className=' text-left w-fit'>
                                            <button onClick={handleExport} className=' py-2 px-3 bg-green-800 text-white rounded-lg '>{loadingExport ? "Exporting..." : "Excel"}</button>
                                        </div>
                                    </div>

                                </> : null}
                            {!loading ?
                                Array.isArray(allJobs) && allJobs?.length >= 1 ?
                                    <section className='bg-white grayBlurShadow px-7 pt-7 rounded-3xl mb-8 mt-5'>
                                        <div className=' overflow-auto tableBody tableBox'>
                                            <table className=' navAnimation rounded-xl baseWidth'>
                                                <thead className='  tableHead'>
                                                    <tr className=' bg-gray-200'>
                                                        <th >Sr.No.</th>
                                                        <th>Posted Date</th>
                                                        <th >Company</th>
                                                        <th >Position</th>
                                                        <th>Qualification</th>
                                                        <th >Location</th>
                                                        <th >Min CTC</th>
                                                        <th>Max CTC</th>
                                                        <th>No. of Positions</th>
                                                        <th className='whitespace-nowrap'>Gender Preference</th>
                                                        <th className='whitespace-nowrap'>Assign Recruiter</th>

                                                    </tr>
                                                </thead>
                                                <tbody className='tableBody '>
                                                    {
                                                        Array.isArray(allJobs) && allJobs?.map((val, idx) => {

                                                            return (
                                                                <tr key={val?.id} id={val?.id} className=' backWhite font-semibold text-sm'>
                                                                    <td>{idx + 1}</td>
                                                                    <td>{val?.upload_date?.split("T")[0]}</td>
                                                                    <td >{val?.Company?.company_name}</td>
                                                                    <td className=' whitespace-nowrap' >{val?.position}</td>
                                                                    <td >{val?.qualification}</td>
                                                                    <td >{val?.location}</td>
                                                                    <td>{val?.min_ctc} LPA</td>
                                                                    <td>{val?.max_ctc} LPA</td>
                                                                    <td>{val?.no_of_positions}</td>
                                                                    <td>{val?.gender_pref}</td>
                                                                    <td className='whitespace-nowrap'>
                                                                        {
                                                                            val?.assignRecuiters?.length >= 1 && val?.assignRecuiters
                                                                                ?.map((item) => {

                                                                                    return <h1 className=' p-2 rounded-lg greenBox w-full whitespace-nowrap'>{item?.User?.name}</h1>
                                                                                })
                                                                        }
                                                                    </td>

                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className=' flex items-center justify-between py-4'>
                                            <div className=' flex items-center gap-2'>
                                                <div className='grayBlurShadow px-3 py-1'>
                                                    <select value={limit} onChange={(e) => { setLimit(e.target.value); }} >
                                                        <option value="10" selected>10</option>
                                                        <option value="20" >20</option>
                                                        <option value="50" >50</option>
                                                        <option value="100" >100</option>
                                                        <option value="200" >200</option>
                                                        <option value="500" >500</option>
                                                        <option value="All" >All</option>
                                                    </select>
                                                </div>
                                                <div className='grayBlurShadow px-1 py-1 text-sm font-semibold'>
                                                    <h1>Total Records: {totalRecords}</h1>
                                                </div>
                                                <div className='grayBlurShadow px-1 py-1  text-sm font-semibold'>
                                                    <h1>Total Pages: {allPages}</h1>
                                                </div>
                                            </div>
                                            <div className='flex items-center grayBlurShadow px-3 py-1 rounded-lg'>
                                                <div onClick={(e) => {
                                                    if (page > 1) {
                                                        setPage(page - 1);
                                                    }
                                                }} className={` ${page === 1 ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                                    <img className='w-5' src="/img/leftArrow.png" alt="leftArrow" />
                                                </div>
                                                <div className=' px-3'>
                                                    {page}
                                                </div>
                                                <div onClick={(e) => {
                                                    if (allPages > page) {
                                                        setPage(page + 1);
                                                    }
                                                }} className={` ${page == allPages ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                                    <img className='w-5' src="/img/rightArrow.png" alt="rightArrow" />
                                                </div>
                                            </div>
                                        </div>
                                    </section> :
                                    <div className='navAnimation flex flex-col justify-center items-center bg-white mt-5 py-5 mb-10 rounded-3xl '>
                                        <img className=' w-[24%]' src="/img/addReports.png" alt="" />
                                        <h1 className=' text-2xl font-bold'>Oops!! "<span className=' text-blue-950'>No Reports Found</span>"</h1>
                                    </div> :
                                <div className=' loaderBox mb-5 bg-white mt-5 rounded-3xl '>
                                    <img className=' m-auto w-1/4' src="/img/loader2.gif" alt="loading..." />
                                </div>
                            }
                        </div> : null
                } */}
                {/* {
                    localStorage.getItem("role") === "Admin" ?
                        <div>
                            {!loadingFilter ?
                                <>
                                    <div className=' flex flex-wrap items-center gap-3 grayBlurShadow bg-white p-3 mt-5 rounded-xl'>
                                        <h1>Date Filter</h1>
                                        <div className='flex items-center gap-3'>
                                            <div className=' flex flex-col justify-start items-start'>
                                                <label>From: </label>
                                                <input
                                                    className=' border-2 rounded-lg px-2 py-1'
                                                    type="date"
                                                    value={filterData?.fromDate}
                                                    onChange={e => setFilterData({ ...filterData, fromDate: e.target.value })}
                                                />
                                            </div>
                                            <div className=' flex flex-col justify-start items-start'>
                                                <label>To: </label>
                                                <input
                                                    className=' border-2 rounded-lg px-2 py-1'
                                                    type="date"
                                                    value={filterData?.toDate}
                                                    onChange={e => setFilterData({ ...filterData, toDate: e.target.value })}
                                                />
                                            </div>
                                        </div>

                                        <div className='flex flex-col justify-start items-start mb-2'>
                                            <label >Company</label>
                                            <input
                                                value={filterData?.company}
                                                className=' border-2 rounded-lg px-2 py-1'
                                                onChange={e => setFilterData({ ...filterData, company: e.target.value })}
                                                type="text" />
                                        </div>
                                        <div className='flex flex-col justify-start items-start mb-2'>
                                            <label >Position</label>
                                            <input
                                                value={filterData?.position}
                                                className=' border-2 rounded-lg px-2 py-1'
                                                onChange={e => setFilterData({ ...filterData, position: e.target.value })}
                                                type="text" />
                                        </div>
                                        <div className='flex flex-col justify-start items-start mb-2'>
                                            <label >Location</label>
                                            <input
                                                value={filterData?.location}
                                                className=' border-2 rounded-lg px-2 py-1'
                                                onChange={e => setFilterData({ ...filterData, location: e.target.value })}
                                                type="text" />
                                        </div>
                                        <div className='flex flex-col justify-start items-start mb-2'>
                                            <label >Qualification</label>
                                            <input
                                                value={filterData?.qualification}
                                                className=' border-2 rounded-lg px-2 py-1'
                                                onChange={e => setFilterData({ ...filterData, qualification: e.target.value })}
                                                type="text" />
                                        </div>
                                        <div className='flex flex-col justify-start items-start mb-2'>
                                            <label >Recruiter</label>
                                            <select value={filterData?.recruiterId}
                                                className=' border-2 rounded-lg px-2 py-1'
                                                onChange={e => setFilterData({ ...filterData, recruiterId: e.target.value })} >
                                                <option value="" selected disabled>Select Recruiter</option>
                                                {allUsers?.map((val) => {
                                                    return (
                                                        <option value={val?.id}>{val?.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className='flex flex-col justify-start items-start mb-2'>
                                            <label >Gender</label>
                                            <select value={filterData?.gender}
                                                className=' border-2 rounded-lg px-2 py-1'
                                                onChange={e => setFilterData({ ...filterData, gender: e.target.value })}>
                                                <option value="" selected disabled>Select Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="No preference">No preference</option>
                                            </select>
                                        </div>
                                        <button onClick={handleFilter} className=' darkPurple cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>Apply filter</button>
                                        <button onClick={() => {
                                            setFilterData({
                                                fromDate: "",
                                                toDate: "",
                                                company: "",
                                                position: "",
                                                location: "",
                                                gender: "",
                                                recruiterId: localStorage.getItem("role") === "Recruiter" ? localStorage.getItem("userId") : "",
                                                qualification: "",
                                            });
                                            filterJobs();
                                        }} className=' text-red-700 bg-red-200  py-2 px-3 rounded-lg hoverRed'>Clear FIlter</button>
                                        <div className=' text-left w-fit'>
                                            <button onClick={handleExport} className=' py-2 px-3 bg-green-800 text-white rounded-lg '>{loadingExport ? "Exporting..." : "Excel"}</button>
                                        </div>
                                    </div>

                                </> : null}
                            {!loading ?
                                Array.isArray(allJobs) && allJobs?.length >= 1 ?
                                    <section className='bg-white grayBlurShadow px-7 pt-7 rounded-3xl mb-8 mt-5'>
                                        <div className=' overflow-auto tableBody tableBox'>
                                            <table className=' navAnimation rounded-xl baseWidth'>
                                                <thead className='  tableHead'>
                                                    <tr className=' bg-gray-200'>
                                                        <th >Sr.No.</th>
                                                        <th>Posted Date</th>
                                                        <th >Company</th>
                                                        <th >Position</th>
                                                        <th>Qualification</th>
                                                        <th >Location</th>
                                                        <th >Min CTC</th>
                                                        <th>Max CTC</th>
                                                        <th className='whitespace-nowrap'>No. of Positions</th>
                                                        <th className='whitespace-nowrap'>Gender Preference</th>
                                                        <th className='whitespace-nowrap'>Assign Recruiter</th>

                                                    </tr>
                                                </thead>
                                                <tbody className='tableBody '>
                                                    {
                                                        Array.isArray(allJobs) && allJobs?.map((val, idx) => {

                                                            return (
                                                                <tr key={val?.id} id={val?.id} className=' backWhite font-semibold text-sm'>
                                                                    <td>{idx + 1}</td>
                                                                    <td>{val?.upload_date?.split("T")[0]}</td>
                                                                    <td >{val?.Company?.company_name}</td>
                                                                    <td className=' whitespace-nowrap' >{val?.position}</td>
                                                                    <td >{val?.qualification}</td>
                                                                    <td >{val?.location}</td>
                                                                    <td>{val?.min_ctc} LPA</td>
                                                                    <td>{val?.max_ctc} LPA</td>
                                                                    <td>{val?.no_of_positions}</td>
                                                                    <td>{val?.gender_pref}</td>
                                                                    <td className='whitespace-nowrap'>
                                                                        {val?.User?.name}
                                                                    </td>

                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className=' flex items-center justify-between py-4'>
                                            <div className=' flex items-center gap-2'>
                                                <div className='grayBlurShadow px-3 py-1'>
                                                    <select value={limit} onChange={(e) => { setLimit(e.target.value); }} >
                                                        <option value="10" selected>10</option>
                                                        <option value="20" >20</option>
                                                        <option value="50" >50</option>
                                                        <option value="100" >100</option>
                                                        <option value="200" >200</option>
                                                        <option value="500" >500</option>
                                                        <option value="All" >All</option>
                                                    </select>
                                                </div>
                                                <div className='grayBlurShadow px-1 py-1 text-sm font-semibold'>
                                                    <h1>Total Records: {totalRecords}</h1>
                                                </div>
                                                <div className='grayBlurShadow px-1 py-1  text-sm font-semibold'>
                                                    <h1>Total Pages: {allPages}</h1>
                                                </div>
                                            </div>
                                            <div className='flex items-center grayBlurShadow px-3 py-1 rounded-lg'>
                                                <div onClick={(e) => {
                                                    if (page > 1) {
                                                        setPage(page - 1);
                                                    }
                                                }} className={` ${page === 1 ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                                    <img className='w-5' src="/img/leftArrow.png" alt="leftArrow" />
                                                </div>
                                                <div className=' px-3'>
                                                    {page}
                                                </div>
                                                <div onClick={(e) => {
                                                    if (allPages > page) {
                                                        setPage(page + 1);
                                                    }
                                                }} className={` ${page == allPages ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                                    <img className='w-5' src="/img/rightArrow.png" alt="rightArrow" />
                                                </div>
                                            </div>
                                        </div>
                                    </section> :
                                    <div className='navAnimation flex flex-col justify-center items-center bg-white mt-5 py-5 mb-10 rounded-3xl '>
                                        <img className=' w-[24%]' src="/img/addReports.png" alt="" />
                                        <h1 className=' text-2xl font-bold'>Oops!! "<span className=' text-blue-950'>No Reports Found</span>"</h1>
                                    </div> :
                                <div className=' loaderBox mb-5 bg-white mt-5 rounded-3xl '>
                                    <img className=' m-auto w-1/4' src="/img/loader2.gif" alt="loading..." />
                                </div>
                            }
                        </div> : null
                } */}
            </section>


        </section >
    );
}

export default Home;
import React, { useContext, useEffect, useState } from 'react';
import axios, { Axios } from 'axios';
import Switch from "react-switch";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { basePath } from '../Constants/constant';
import { AppContext } from '../ContextApi/ContextApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight } from '@fortawesome/free-solid-svg-icons'

function AccessControl(props) {
    const { getData } = useContext(AppContext);
    const [allRoles, setAllRoles] = useState([]);
    const [allModules, setAllModules] = useState([]);
    const [filterModule, setFilterModule] = useState([]);
    const [allAccess, setAllAccess] = useState([]);
    const [filterAccess, setFilterAccess] = useState([]);
    const [viewModal, setViewModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [isAction, setIsAction] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [roleId, setRoleId] = useState(false);
    const [moduleId, setModuleId] = useState(false);
    const [edit_access, setEdit_access] = useState(false);
    const [accessModal, setAccessModal] = useState(false);
    const [accessData, setAccessData] = useState({
        id: "",
        list_access: false,
        view_access: false,
        add_access: false,
        edit_access: false,
        delete_access: false,
    })
    // get all roles
    const getRoleData = async () => {
        try {
            let response = await axios.get(`${basePath}/getroles`);
            console.log(response?.data[0].id);
            setRoleId(response?.data[0].id);
            setAllRoles(response?.data);
        } catch (error) {
            console.log(error);
        }
    }
    //get all modules
    const getModuleData = async () => {
        try {
            let response = await axios.get(`${basePath}/getmodules`);
            console.log(response);
            setModuleId(response?.data[0].id);
            setAllModules(response?.data);
        } catch (error) {
            console.log(error);
        }
    }
    //get all access comtrol data
    const getPermissionData = async () => {
        try {
            setLoading(true);
            let response = await axios.get(`${basePath}/getpermissions`);
            console.log(response?.data);
            setAllAccess(response?.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    //filter all access control data
    const filterPermissionData = () => {
        // getPermissionData();
        let data = allAccess?.filter((val) => {
            return val?.role_id === roleId
        });
        let n = allModules?.length;
        let mod = [];
        while (n > 0) {
            let check = allAccess?.every((val) => {
                return val?.role_id === roleId && val?.module_id === allModules[n - 1].id;
            });
            console.log(check, allModules[n - 1].id);
            if (!check) {
                mod.push(allModules[n - 1]?.module_name);
            }
            n--;
        }
        // let data2= allAccess?.filter((val)=>{

        // });
        // data2= data2.filter((val)=>{
        //     if()
        // })
        setFilterModule(mod);

        setFilterAccess(data);
    }

    useEffect(() => {
        getRoleData();
        getModuleData();
        getPermissionData();
    }, []);
    useEffect(() => {
        if (roleId) {
            filterPermissionData();
        }
    }, [roleId, allAccess]);
    // function to show status success
    const notify = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    // function to show status for fail
    const notifyFillTheFields = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const submit = () => {
        let data = {
            role_id: roleId,
            module_id: moduleId,
            list_access: accessData?.list_access,
            view_access: accessData?.view_access,
            add_access: accessData?.add_access,
            edit_access: accessData?.edit_access,
            delete_access: accessData?.delete_access,
        }
        const options = {
            url: `${basePath}/permission`,
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            data: data,
        }
        if (roleId && moduleId) {
            axios(options)
                .then((response) => {
                    if (response.status == 200) {
                        setLoading(false);
                        setAccessModal(false);
                        setAccessData({
                            list_access: false,
                            view_access: false,
                            add_access: false,
                            edit_access: false,
                            delete_access: false,
                        });
                        getPermissionData();
                        notify("Access Control Added Successfully");
                        console.log("Information Updated successfully");
                        getData();
                    }
                    else {
                        setLoading(false);
                        notifyFillTheFields("Unable to Add Access Control");
                    }
                })
                .catch(() => {
                    setLoading(false);
                    notifyFillTheFields("Unable to update");
                });
        } else {
            if (!roleId && !moduleId) {
                notifyFillTheFields("Role And Module Are Required");
            }
            else if (!roleId) {
                notifyFillTheFields("Role Is Required");
            }
            else {
                notifyFillTheFields("Module Is Required");
            }
        }
    }
    const handleSubmitUpdate = () => {

        let data = {
            role_id: roleId,
            module_id: moduleId,
            list_access: accessData?.list_access,
            view_access: accessData?.view_access,
            add_access: accessData?.add_access,
            edit_access: accessData?.edit_access,
            delete_access: accessData?.delete_access,
        }
        const options = {
            url: `${basePath}/updatepermission/${accessData?.id}`,
            method: "PUT",
            headers: {
                Accept: "application/json",
            },
            data: data,
        }
        axios(options)
            .then((response) => {
                if (response?.status == 200) {
                    setAccessModal(false);
                    setAccessData({
                        list_access: false,
                        view_access: false,
                        add_access: false,
                        edit_access: false,
                        delete_access: false,
                    });
                    getPermissionData();
                    setLoadingUpdate(false);
                    notify("Access Control Updated Successfully");
                    console.log("Information Updated successfully");
                }
                else {
                    setLoadingUpdate(false);
                    notifyFillTheFields("Unable to update Access Control");
                }
            })
            .catch(() => {
                setLoadingUpdate(false);
                notifyFillTheFields("Unable to update Access Control");
            });
        setEdit_access(false);
    }
    console.log(roleId);
    const handleEdit = (e, idx, id, role_Id, module_Id) => {
        setEdit_access(id);
        setRoleId(role_Id);
        setModuleId(module_Id);
        setIsAction(false);
        setAccessData({
            id: id,
            view_access: filterAccess[idx]?.view_access,
            add_access: filterAccess[idx]?.add_access,
            edit_access: filterAccess[idx]?.edit_access,
            delete_access: filterAccess[idx]?.delete_access,
        });
    }
    const handleDelete = (e, id) => {
        if (id) {
            setLoading(true);
            const options = {
                url: `${basePath}/deletepermission/${id}`,
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                },
            }
            axios(options)
                .then((response) => {
                    if (response.status == 200) {
                        setLoading(false);
                        getPermissionData();
                        filterPermissionData();
                        getData();
                        notify("Access Control Deleted Successfully");
                        console.log("Information Deleted successfully");
                    }
                    else {
                        setLoading(false);
                        notifyFillTheFields("Unable to Delete");

                    }
                })
                .catch((e) => {
                    console.log(e);
                    setLoading(false);
                    notifyFillTheFields("Unable to Delete");
                });
        }
        else {
            notifyFillTheFields("Unable to delete");
        }

    }
    return (
        <section onClick={() => { setAccessModal(false); setIsAction(false); }} className='lightBabyGreen relative w-full mb-10'>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            <div className=' fixed bottom-3 right-4'>
                <div onClick={getPermissionData} className='cursor-pointer bg-red-600 px-2.5 py-2 rounded-full hoverRed'>
                    <FontAwesomeIcon icon={faRotateRight} size={"lg"} style={{ color: "#ffffff", }} />
                </div>
            </div>
            <div className='welcomeBarHeight babyGreen smoothTransition textDarkPurple px-10 rounded-3xl  flex items-center justify-between navAnimation '>
                <div className=' text-left'>
                    <h1 className=' text-5xl font-bold'>Access Control</h1>
                    <p className=' text-gray-500 font-semibold'>Ready to start your day</p>
                </div>
                <img className=' w-44' src="/img/master.png" alt="" />
            </div>
            <div onClick={(e) => { e.stopPropagation(); setRoleId(false); setModuleId(false); setAccessModal(true) }} className=' darkPurple w-fit my-2 cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>
                <img className=' w-8 ' src="/img/addBtn.png" alt="" />
                <button>Create Access</button>
            </div>
            {accessModal ?
                <div onClick={() => { setRoleId(allRoles[0]?.role_id); setAccessModal(false) }} className=' w-full h-screen fixed left-0 top-0 flex items-center justify-center blurBack z-20'>
                    <div onClick={(e) => { e.stopPropagation(); }} className=' p-5 bg-white rounded-xl'>
                        <table>
                            <thead>
                                <tr className=' bg-gray-200'>
                                    <th>Role</th>
                                    <th>Module</th>
                                    <th>list</th>
                                    <th>view</th>
                                    <th>add</th>
                                    <th>edit</th>
                                    <th>delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <select onChange={(e) => { setRoleId(parseInt(e.target.value)) }} >
                                            <option value="" disabled selected>Select Role</option>
                                            {allRoles?.map((val) => {
                                                return <option value={val?.id}>{val?.role_name}</option>
                                            })}
                                        </select>
                                    </td>
                                    <td>
                                        <select onChange={(e) => { setModuleId(parseInt(e.target.value)) }}>
                                            <option value="" disabled selected>Select Module</option>
                                            {allModules?.map((val) => {
                                                return <option value={val?.id}>{val?.module_name}</option>
                                            })}
                                        </select>
                                    </td>
                                    <td>
                                        <Switch onChange={() => { setAccessData({ ...accessData, list_access: !accessData?.list_access, }) }} checked={accessData?.list_access} />
                                    </td>
                                    <td>
                                        <Switch onChange={() => { setAccessData({ ...accessData, view_access: !accessData?.view_access }) }} checked={accessData?.view_access} />
                                    </td>
                                    <td>
                                        <Switch onChange={() => { setAccessData({ ...accessData, add_access: !accessData?.add_access }) }} checked={accessData?.add_access} />
                                    </td>
                                    <td>
                                        <Switch onChange={() => { setAccessData({ ...accessData, edit_access: !accessData?.edit_access }) }} checked={accessData?.edit_access} />
                                    </td>
                                    <td>
                                        <Switch onChange={() => { setAccessData({ ...accessData, delete_access: !accessData?.delete_access }) }} checked={accessData?.delete_access} />
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                        <div className=' mt-2'>
                            <button onClick={() => { submit(); }} className=' bg-green-200 text-green-600 rounded-xl px-3 py-1 font-semibold hoverGreen'>Save</button>
                        </div>
                    </div>
                </div> : null}

            <section className=' bg-white grayBlurShadow p-7 rounded-3xl mb-8'>
                {/* <div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Role</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allRoles?.map((val)=>{
                                    return(
                                        <tr key={val?.id}>
                                            <td>{val?.role_name}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        
                    </div> */}
                <div className=' flex items-center p-2 mb-2 grayBlurShadow border-2 rounded-lg w-fit'>
                    <h1 className=' text-left font-semibold pl-1 mr-2'>Role</h1>
                    <select value={roleId} className=' outline-none text-xl font-semibold text-blue-800' onChange={(e) => { setEdit_access(false); setRoleId(parseInt(e.target.value)) }} >
                        {allRoles?.map((val) => {
                            return (
                                <option className=' ' key={val?.id} value={parseInt(val.id)}>{val?.role_name}</option>
                            )
                        })}
                    </select>
                </div>
                {!loading ?
                    <div className=' overflow-auto tableBody tableBox'>
                        {filterAccess.length > 0 ?
                            <table className=' navAnimation rounded-2xl baseWidth'>
                                <thead className=' text-lg tableHead z-10 headingRow'>
                                    <tr className=' bg-gray-200'>
                                        <th>Module</th>
                                        <th>List</th>
                                        <th>view</th>
                                        <th>add</th>
                                        <th>edit</th>
                                        <th>delete</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody className='tableBody '>
                                    {
                                        filterAccess?.map((val, idx) => {
                                            return (
                                                <>
                                                    {
                                                        // edit access control
                                                        edit_access === val.id ?
                                                            <tr key={val?.id} id={val?.id} className=' backWhite font-semibold text-sm'>
                                                                <td >{val?.Module.module_name}</td>
                                                                <td ><Switch onChange={() => { setAccessData({ ...accessData, list_access: accessData?.list_access ? false : true }) }} checked={accessData?.list_access} disabled={false} /></td>
                                                                <td ><Switch onChange={() => { setAccessData({ ...accessData, view_access: accessData?.view_access ? false : true }) }} checked={accessData?.view_access} disabled={false} /></td>
                                                                <td ><Switch onChange={() => { setAccessData({ ...accessData, add_access: accessData?.add_access ? false : true }) }} checked={accessData?.add_access} disabled={false} /></td>
                                                                <td ><Switch onChange={() => { setAccessData({ ...accessData, edit_access: accessData?.edit_access ? false : true }) }} checked={accessData?.edit_access} disabled={false} /></td>
                                                                <td><Switch onChange={() => { setAccessData({ ...accessData, delete_access: accessData?.delete_access ? false : true }) }} checked={accessData?.delete_access} disabled={false} /></td>
                                                                <td className=' flex justify-center items-center'>
                                                                    <div className=' relative w-fit'>
                                                                        <button onClick={() => { handleSubmitUpdate() }} className=' bg-green-200 text-green-600 rounded-xl px-3 py-1 font-semibold'>{!loadingUpdate?"Save":"Saving..."}</button>
                                                                    </div>
                                                                </td>
                                                            </tr> :
                                                            // view access control
                                                            <tr key={val?.id} id={val?.id} className=' backWhite font-semibold text-sm'>
                                                                <td >{val?.Module.module_name}</td>
                                                                <td ><Switch checked={val?.list_access} disabled={true} /></td>
                                                                <td ><Switch checked={val?.view_access} disabled={true} /></td>
                                                                <td ><Switch checked={val?.add_access} disabled={true} /></td>
                                                                <td ><Switch checked={val?.edit_access} disabled={true} /></td>
                                                                <td><Switch checked={val?.delete_access} disabled={true} /></td>
                                                                <td className=' flex justify-center items-center'>
                                                                    <div className=' relative w-fit'>
                                                                        {edit_access === val?.id ?
                                                                            <button onClick={() => setEdit_access(false)} className=' bg-green-200 text-green-600 rounded-xl px-3 py-1 font-semibold'>Save</button> :
                                                                            <div onClick={(e) => { e.stopPropagation(); setIsAction(val?.id); }} className=' flex flex-row gap-1 cursor-pointer w-fit p-2 rounded-lg hoverGreen'>
                                                                                <div className=' w-2 h-2 darkPurple rounded-full '></div>
                                                                                <div className=' w-2 h-2 darkPurple rounded-full '></div>
                                                                                <div className=' w-2 h-2 darkPurple rounded-full '></div>
                                                                            </div>}

                                                                        {isAction === val?.id ?
                                                                            < div className={`absolute ${allModules?.length - 2 <= idx && allModules?.length > 2 ? "bottom-5 z-10 right-2" : "right-2 z-10 top-5"} w-40 h-fit p-3 rounded-xl border-2 bg-white grayBlurShadow`} >
                                                                                <div className='flex flex-col'>
                                                                                    <button onClick={(e) => {  handleEdit(e, idx, val?.id, val?.role_id, val?.module_id) }} className=' text-yellow-600 bg-yellow-200 m-2 px-4 py-1 rounded-lg hoverYellow' >Edit</button>
                                                                                    <button onClick={(e) => { handleDelete(e, val?.id) }} className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed'>Delete</button>
                                                                                </div>
                                                                            </div> : null
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </table> :
                            <div className=' flex flex-col items-center'>
                                <img className=' w-1/4' src="/img/addReports.png" alt="" />
                                <h1 className=' text-xl font-bold'>Oops!! "<span className=' text-blue-950'>Access Control Data Not Found!!!</span>"</h1>
                            </div>}
                    </div> : <div className=' loaderBox mb-5 bg-white mt-5 rounded-3xl '>
                        <img className=' m-auto w-1/4' src="/img/loader2.gif" alt="loading..." />
                    </div>
                }

            </section>
        </section>
    );
}

export default AccessControl;
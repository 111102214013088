import React, { useState } from 'react';
import axios from 'axios';
import { basePath } from '../Constants/constant';

// Tailwind CSS Progress Bar Component
const ProgressBar = ({ progress }) => {
  return (
    <div className="w-full h-4 bg-gray-200 rounded-full overflow-hidden">
      <div
        className="h-full bg-yellow-500 transition-all duration-500 ease-in-out"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

const BulkCVUploader = ({getCvData}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [warningMessage, setWarningMessage] = useState('');

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);

    // Check if the total number of files exceeds 100
    if (files.length > 100) {
      setError('You can only upload up to 100 PDF files at a time.');
      return;
    }

    const pdfFiles = files.filter((file) => file.type === 'application/pdf');
    
    // Check if there are non-PDF files
    if (pdfFiles.length !== files.length) {
      setError('Only PDF files are allowed.');
      return;
    }

    setError(null); // Reset any previous errors
    setSelectedFiles(pdfFiles);
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      alert('Please select at least one PDF file to upload.');
      return;
    }

    setIsUploading(true);
    setError(null);
    setSuccessMessage('');
    setUploadProgress(0);

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('pdfs', file);
    });

    try {
      const response = await axios.post(`${basePath}/upload-resumes`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          console.log(`Loaded: ${loaded}, Total: ${total}`);

          if (total && loaded <= total) {
            const percentCompleted = Math.round((loaded / total) * 100);
            console.log(`Upload Progress: ${percentCompleted}%`);
            setUploadProgress(percentCompleted);
          } else {
            console.warn('Unexpected progress values:', { loaded, total });
            setUploadProgress(0);
          }
        },
      });

      const { success, warning } = response.data;

      // Set success message if available
      if (success) {
        setSuccessMessage(success);
      }
  
      // Set warning message if available
      if (warning) {
        setWarningMessage(warning);
      }
      getCvData();
      // setSuccessMessage('Files uploaded successfully!');
      // setBulkCvUploadView(!bulkCvUploadView);
      setSelectedFiles([]);
    } catch (err) {
      console.error(err);
      setError('An error occurred during the upload. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 border border-gray-300 rounded-lg shadow-md bg-white">
      <div className="bg-[#e1f351] p-4 rounded-t-[10px] text-center">
        <h2 className="text-2xl font-bold">Bulk PDF Uploader</h2>
      </div>

      <input
        type="file"
        accept="application/pdf"
        multiple
        onChange={handleFileChange}
        disabled={isUploading}
        className="mt-4 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
      />

      {selectedFiles.length > 0 && (
        <div className="mt-4">
          <h4 className="font-semibold">Selected Files ({selectedFiles.length}):</h4>
          <ul className="list-disc ml-5 text-left">
            {selectedFiles.map((file, idx) => (
              <li key={idx} className="text-sm text-gray-700">{file.name}</li>
            ))}
          </ul>
        </div>
      )}

      <button
        onClick={handleUpload}
        disabled={isUploading || selectedFiles.length === 0}
        className="mt-4 py-2 px-6 bg-green-600 text-white font-semibold rounded-lg hover:bg-green-700 transition-all duration-300 disabled:opacity-50"
      >
        {isUploading ? uploadProgress == 100 ? 'Almost Completed...' :'Uploading...' : 'Upload'}
      </button>

      {isUploading && (
        <div className="mt-4">
          <ProgressBar progress={uploadProgress} />
          <p className="text-sm mt-2">{uploadProgress}%</p>
        </div>
      )}

      {successMessage && (
        <div className="mt-4 text-green-600 font-bold">
          {successMessage}
        </div>
      )}
      {warningMessage && (
        <div className="mt-4 text-red-600 font-bold">
          {warningMessage}
        </div>
      )}

      {error && (
        <div className="mt-4 text-red-600 font-semibold">
          {error}
        </div>
      )}
    </div>
  );
};

export default BulkCVUploader;

import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../ContextApi/ContextApi';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight } from '@fortawesome/free-solid-svg-icons'
import { basePath } from '../Constants/constant';

function UserDetails(props) {
    const { index } = useParams();
    const { industry, setIndustry, isModalOpen, setIsModalOpen, user } = useContext(AppContext);
    const navigate = useNavigate();
    const [allUsers, setAllUsers] = useState([]);
    const [userIndex, setUserIndex] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [isAction, setIsAction] = useState(false);
    const [loadingFilter, setLoadingFilter] = useState(true);
    const [loadingExport, setLoadingExport] = useState(false);
    const [loading, setLoading] = useState(true);
    const [allRoles, setAllRoles] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [allPages, setAllPages] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [formData, setFormData] = useState({
        id: "",
        firstName: "",
        lastName: "",
        designation: "",
        email: "",
        phone: "",
        role_id: "",
        username_login: "",
        password_login: ""
    });
    const [filterData, setFilterData] = useState({
        name: "",
        designation: "",
    });

    // const getUserData = async () => {
    //     try {
    //         setLoading(true);
    //         let response = await axios.get("http://localhost:3002/getusers");
    //         console.log(response.data);
    //         setAllUsers(response?.data);
    //         setLoading(false);
    //     } catch (error) {
    //         setLoading(false);
    //         console.log(error);
    //     }
    // }
    const getUserData = async () => {
        try {
            setLoading(true);
            let response = await axios.get(`${basePath}/getuserbyPage?limit=${limit}&page=${page}`);
            console.log(response.data);
            setAllUsers(response?.data?.user);
            setTotalRecords(response?.data?.totalRecords);
            setAllPages(response?.data?.pages);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    const getFilterUserData = async () => {
        try {
            setLoading(true);
            let response = await axios.get(`${basePath}/getuserbyPage?limit=${limit}&page=${page}&filter=${JSON.stringify(filterData)}`);
            console.log(response.data);
            setAllUsers(response?.data?.user);
            setTotalRecords(response?.data?.totalRecords);
            setAllPages(response?.data?.pages);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    const handleExport = async () => {
        try {
            setLoadingExport(true);
            const response = await axios.get(`${basePath}/getuserbyPage?download=yes&filter=${JSON.stringify(filterData)}`, {
                responseType: 'blob', // Ensure response is treated as a blob
            });

            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a temporary <a> element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `User_${new Date()}.xlsx`);
            document.body.appendChild(link);
            link.click();

            // Clean up resources
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            setLoadingExport(false);
        } catch (error) {
            console.error('Error exporting jobs:', error);
            setLoadingExport(false);
        }
    };
    const getRoleData = async () => {
        let response = await axios.get(`${basePath}/getroles`);
        console.log(response.data);
        setAllRoles(response?.data);
    }
    useEffect(() => {
        if (!loadingFilter && !filterData?.designation && !filterData?.name) {
            getUserData();
        } else if (!loadingFilter) {
            getFilterUserData();
        }
    }, [limit, page, filterData]);

    useEffect(() => {
        getRoleData();
        setLoadingFilter(true);
        getUserData();
        setLoadingFilter(false);
    }, []);


    const notify = (msg = "successfull!!") => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const notifyFillTheFields = (msg = "There some issue!!") => toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });

    const handleSubmitEditForm = (e) => {
        e.preventDefault();
        let data = {
            name: `${formData?.firstName.trim()} ${formData?.lastName.trim()}`,
            designation: formData?.designation,
            email: formData?.email,
            phone: formData?.phone,
            role_id: formData?.role_id,
            username_login: formData?.username_login,
            password_login: formData?.password_login
        };

        if (formData?.email) {
            setLoading(true);
            const options = {
                url: `${basePath}/updateuser/${formData?.id}`,
                method: "PUT",
                headers: {
                    Accept: "application/json",
                },
                data: data,
            }
            axios(options)
                .then((response) => {
                    if (response.status == 200) {
                        setLoading(false);
                        getUserData();
                        notify("User Data Updated Successfully");
                        setFormData({
                            id: "",
                            firstName: "",
                            lastName: "",
                            designation: "",
                            email: "",
                            phone: "",
                            role_id: "",
                            username_login: "",
                            password_login: ""
                        });
                        setEditModal(false);

                        console.log("Information entered successfully");
                    }
                    else {
                        setLoading(false);
                        notifyFillTheFields("Unable To Update");
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    console.log(e);
                    notifyFillTheFields("Unable To Update");
                });
        }
        else {
            console.log("fill fields");
            notifyFillTheFields();
        }
    }

    const handleEdit = (idx) => {
        console.log(allUsers[idx]?.name.split(" "));
        setFormData({
            id: allUsers[idx]?.id,
            firstName: allUsers[idx]?.name.split(" ")[0],
            lastName: allUsers[idx]?.name.split(" ")[1],
            designation: allUsers[idx]?.designation,
            email: allUsers[idx]?.email,
            phone: allUsers[idx]?.phone,
            role_id: allUsers[idx]?.role_id,
            username_login: allUsers[idx]?.username_login,
            password_login: allUsers[idx]?.password_login
        });
        setEditModal(true);
    }
    const handleDelete = (e, id) => {
        if (id) {
            setLoading(true);
            const options = {
                url: `${basePath}/deleteuser/${id}`,
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                },
            }
            axios(options)
                .then((response) => {
                    if (response.status == 200) {
                        setLoading(false);
                        getUserData();
                        notify("User Deleted Successfully");
                        console.log("Information Deleted successfully");
                    }
                    else {
                        setLoading(false);
                        alert("Unable to Delete");
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    alert("Unable to delete");
                });
        }
        else {
            notifyFillTheFields("Unable to delete");
        }
    }


    return (
        <section onClick={() => { setIsModalOpen(false); setIsAction(false); }} className='lightBabyGreen baseHeightWidth customScroll mb-5'>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            <div className=' absolute bottom-3 right-4'>
                <div onClick={getUserData} className='cursor-pointer bg-red-600 px-2.5 py-2 rounded-full hoverRed'>
                    <FontAwesomeIcon icon={faRotateRight} size={"lg"} style={{ color: "#ffffff", }} />
                </div>
            </div>
            <div>
                <div className='babyGreen smoothTransition textDarkPurple px-10 rounded-3xl flex items-center justify-between  welcomeBarHeight m-auto'>
                    <div className=' text-left'>
                        <h1 className=' text-5xl font-bold'>User Details</h1>
                        <p className=' text-gray-500 font-semibold'>Ready to start your day</p>
                    </div>
                    <img className=' w-44' src="/img/registration.png" alt="registration" />
                </div>

                {!loadingFilter ?
                    <div className=' flex flex-wrap items-center gap-3 grayBlurShadow bg-white p-3 mt-5 mb-3 rounded-xl'>
                        {
                            user[index]?.add_access ?
                                <div onClick={(e) => { localStorage.getItem("role") === "Super Admin" ? navigate(`/main/user-registration/${index}`) : navigate(`/user-registration/${index}`) }} className='darkPurple w-fit my-2 ml-2 cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'><img className=' w-8 ' src="/img/addBtn.png" alt="" />
                                    <button>Add New User</button>
                                </div> : null
                        }
                        <div className='flex flex-col justify-start items-start mb-2'>
                            <label >Name</label>
                            <input
                                value={filterData?.name}
                                className=' border-2 rounded-lg px-2 py-1'
                                onChange={e => setFilterData({ ...filterData, name: e.target.value })}
                                type="text" />
                        </div>
                        <div className='flex flex-col justify-start items-start mb-2'>
                            <label >Designation</label>
                            <input
                                value={filterData?.designation}
                                className=' border-2 rounded-lg px-2 py-1'
                                onChange={e => setFilterData({ ...filterData, designation: e.target.value })}
                                type="text" />
                        </div>
                        <div className='flex items-center gap-3'>
                            <button onClick={getFilterUserData} className=' darkPurple cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>Apply filter</button>
                            <button onClick={() => {
                                setFilterData({
                                    name: "",
                                    designation: "",
                                })
                                // getFilterUserData();
                            }} className=' text-red-700 bg-red-200  py-2 px-3 rounded-lg hoverRed'>Clear FIlter</button>
                            <div className=' text-left w-fit'>
                                <button onClick={handleExport} className=' py-2 px-3 bg-green-800 text-white rounded-lg '>{loadingExport ? "Exporting..." : "Excel"}</button>
                            </div>
                        </div>
                    </div>
                    : null
                }
                {viewModal ?
                    <div onClick={() => { setViewModal(false); setUserIndex(false); }} className=' w-full h-screen fixed left-0 top-0 flex items-center justify-center blurBack z-20'>
                        <div onClick={(e) => { e.stopPropagation(); }} className='relative bg-white p-5 w-2/4 rounded-xl h-fit navAnimation text-left'>
                            <button onClick={(e) => { setViewModal(false); setUserIndex(false); }} className="absolute bg-white rounded-full w-fit top-1 right-1 z-[11] ">
                                <img className='w-7' src="/img/close.png" alt="close" />
                            </button>
                            <div className=''>
                                <div className=' h-[20vh] rounded-lg overflow-hidden'>
                                    <img className=' w-full h-full object-cover' src="/img/profileCover.jpg" alt="User Cover Image" />
                                </div>
                                <div className=' relative'>
                                    <div className=' absolute flex items-center justify-center w-full left-0 top-[-4rem]'>
                                        <div className='w-fit rounded-full overflow-hidden p-0.5 bg-white'>
                                            <img className='w-24' src="/img/userDp.webp" alt="User DP" />
                                        </div>
                                    </div>
                                    <div className='pt-10 flex flex-col items-center justify-center'>
                                        <h1 className=' text-4xl font-semibold text-blue-800'>{allUsers[userIndex]?.name}</h1>
                                        <h4 className=' bg-green-200 px-5 py-1 rounded-full font-semibold mt-2'>{allUsers[userIndex]?.designation}</h4>
                                    </div>
                                    <div className='flex flex-row gap-4 mt-4'>

                                    </div>
                                    <div className=' bg-white p-5'>
                                        <div className='flex items-center justify-between gap-4 mt-4'>
                                            <div>
                                                <p className=' text-gray-300 h-3 text-sm'>Email:</p>
                                                <h2 className='text-red-700 font-semibold text-lg'>{allUsers[userIndex]?.email}</h2>
                                            </div>
                                            <div>
                                                <p className=' text-gray-300 h-3 text-sm'>Mobile:</p>
                                                <h2 className='text-yellow-700 font-semibold text-lg'>{allUsers[userIndex]?.phone}</h2>
                                            </div>
                                            <div>
                                                <p className=' text-gray-300 h-3 text-sm'>Username:</p>
                                                <h2 className='text-violet-800 font-semibold text-lg'>{allUsers[userIndex]?.username_login}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                {editModal ?
                    <div onClick={() => { setEditModal(false); setUserIndex(false); }} className=' w-screen h-screen fixed left-0 top-0 py-5 flex items-center justify-center blurBack z-20'>
                        <div onClick={(e) => { e.stopPropagation(); }} className='relative bg-white p-4 h-full rounded-xl width60'>
                            <button onClick={(e) => { setEditModal(false); setUserIndex(false); }} className="absolute bg-white rounded-full w-fit top-1 right-1 z-[11] ">
                                <img className='w-7' src="/img/close.png" alt="close" />
                            </button>
                            <form id='user' onSubmit={handleSubmitEditForm} className=' bg-white p-4 '>
                                <h1 className=' text-3xl font-bold babyGreen  rounded-xl py-3 mb-10'>User Registration</h1>
                                <div className='relative flex flex-col gap-5 p-7 borderDarkBlue rounded-3xl'>
                                    <h1 className=' bg-white absolute top-15px font-semibold px-1 textDarkBlue'>User Details</h1>
                                    <div className=' grid gridResponsive gap-3'>
                                        <div className='input-group relative'>
                                            <input value={formData?.firstName} onChange={(e) => { setFormData({ ...formData, firstName: e.target.value }) }} id='firstName' placeholder='First Name*' className=' w-full' required type="text" />
                                            <label htmlFor="firstName" className='label'>First Name</label>
                                        </div>
                                        <div className='input-group relative'>
                                            <input value={formData?.lastName} onChange={(e) => { setFormData({ ...formData, lastName: e.target.value }) }} id='lastName' placeholder='Last Name*' className=' w-full' required type="text" />
                                            <label htmlFor="lastName" className='label'>Last Name</label>
                                        </div>
                                    </div>
                                    <div className=' grid gridResponsive gap-3'>
                                        <div className='input-group relative'>
                                            <input value={formData?.email} onChange={(e) => { setFormData({ ...formData, email: e.target.value }) }} id='email' placeholder='Email*' className=' w-full' required type="text" />
                                            <label htmlFor="email" className='label'>Email</label>
                                        </div>
                                        <div className='input-group relative'>
                                            <input value={formData?.phone} onChange={(e) => { setFormData({ ...formData, phone: e.target.value }) }} id='phone' placeholder='Mobile*' className=' w-full' required type="text" />
                                            <label htmlFor="phone" className='label'>Mobile</label>
                                        </div>
                                    </div>
                                    <div className=' grid gridResponsive gap-3'>
                                        <div className='input-group relative'>
                                            <input value={formData?.designation} onChange={(e) => { setFormData({ ...formData, designation: e.target.value }) }} id='designation' placeholder='Designation*' className=' w-full' required type="text" />
                                            <label htmlFor="designation" className='label'>Designation</label>
                                        </div>
                                        <div className='input-group relative'>
                                            <select value={formData?.role_id} onChange={(e) => { setFormData({ ...formData, role_id: parseInt(e.target.value) }) }} className=' w-full outline-none'>
                                                <option value="" selected disabled>--Select Role--</option>
                                                {allRoles?.map((val) => {
                                                    return (
                                                        <option value={parseInt(val?.id)}>{val?.role_name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className=' grid gridResponsive gap-3'>
                                        <div className='input-group relative'>
                                            <input value={formData?.username_login} onChange={(e) => { setFormData({ ...formData, username_login: e.target.value }) }} id='username' placeholder='Username' className=' w-full' required type="text" />
                                            <label htmlFor="username" className='label'>Username</label>
                                        </div>
                                        <div className='input-group relative'>
                                            <input value={formData?.password_login} onChange={(e) => { setFormData({ ...formData, password_login: e.target.value }) }} id='password' placeholder='Password' className=' w-full' required type="text" />
                                            <label htmlFor="password" className='label'>Password</label>
                                        </div>
                                    </div>
                                    <div>
                                        <button type='submit' className=' w-fit darkPurple text-white font-semibold rounded-xl px-7 text-sm py-2 hoverGreen'>Update</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> : null}
                {!loading ?
                    Array.isArray(allUsers) && allUsers?.length >= 1 ?
                        <section className='bg-white grayBlurShadow px-7 pt-7 rounded-3xl mt-5 mb-8'>
                            <div className=' overflow-auto tableBody tableBox'>
                                <table className=' navAnimation rounded-2xl baseWidth text-left'>
                                    <thead className=' text-lg tableHead'>
                                        <tr className=' bg-gray-200'>
                                            <th className=' text-center' >Sr.No.</th>
                                            <th >Name</th>
                                            <th >Designation</th>
                                            <th >Email</th>
                                            <th >Phone</th>
                                            {user[index]?.view_access || user[index]?.edit_access
                                                || user[index]?.delete_access ?
                                                <th>Actions</th> : null}
                                        </tr>
                                    </thead>
                                    <tbody className='tableBody '>
                                        {
                                            Array.isArray(allUsers) && allUsers?.map((val, idx) => {
                                                return (
                                                    <tr key={val?.id} id={val?.id} className=' backWhite font-semibold text-sm'>
                                                        <td className=' text-center'>{idx + 1}</td>
                                                        <td className=' whitespace-nowrap'>{val?.name}</td>
                                                        <td className=' whitespace-nowrap'>{val?.designation}</td>
                                                        <td className=' whitespace-nowrap' >{val?.email}</td>
                                                        <td className=' whitespace-nowrap'>{val?.phone}</td>
                                                        {user[index]?.view_access || user[index]?.edit_access
                                                            || user[index]?.delete_access ?
                                                            <td className=' flex justify-center items-center'>
                                                                <div className=' relative w-fit'>
                                                                    <div onClick={(e) => { e.stopPropagation(); setIsAction(val?.id); }} className=' flex flex-row gap-1 cursor-pointer w-fit p-2 rounded-lg hoverGreen'>
                                                                        <div className=' w-2 h-2 darkPurple rounded-full '></div>
                                                                        <div className=' w-2 h-2 darkPurple rounded-full '></div>
                                                                        <div className=' w-2 h-2 darkPurple rounded-full '></div>
                                                                    </div>
                                                                    {isAction === val?.id ?
                                                                        < div className={`absolute ${allUsers?.length - 2 <= idx && allUsers?.length > 3 ? "bottom-5 z-10 right-2" : "right-2 z-10 top-5"} w-40 h-fit p-3 rounded-xl border-2 bg-white grayBlurShadow`} >
                                                                            <div className='flex flex-col'>
                                                                                {user[index]?.view_access ?
                                                                                    <button onClick={(e) => { setViewModal(true); setUserIndex(idx); setIsAction(false); }}
                                                                                        className=' darkPurple text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2 hoverGreen'>
                                                                                        View</button> : null}
                                                                                {user[index]?.edit_access ?
                                                                                    <button onClick={() => { handleEdit(idx); }}
                                                                                        className=' text-yellow-600 bg-yellow-200 m-2 px-4 py-1 rounded-lg hoverYellow' >
                                                                                        Edit</button> : null}
                                                                                {user[index]?.delete_access ?
                                                                                    <button onClick={(e) => { handleDelete(e, val?.id) }}
                                                                                        className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed'>
                                                                                        Delete</button> : null}
                                                                            </div>
                                                                        </div> : null
                                                                    }
                                                                </div>
                                                            </td> : null}
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className=' flex items-center justify-between py-4'>
                                <div className=' flex items-center gap-2'>
                                    <div className='grayBlurShadow px-3 py-1'>
                                        <select value={limit} onChange={(e) => { setLimit(e.target.value); }} >
                                            <option value="10" selected>10</option>
                                            <option value="20" >20</option>
                                            <option value="50" >50</option>
                                            <option value="100" >100</option>
                                            <option value="200" >200</option>
                                            <option value="500" >500</option>
                                            <option value="All" >All</option>
                                        </select>
                                    </div>
                                    <div className='grayBlurShadow px-1 py-1 text-sm font-semibold'>
                                        <h1>Total Records: {totalRecords}</h1>
                                    </div>
                                    <div className='grayBlurShadow px-1 py-1  text-sm font-semibold'>
                                        <h1>Total Pages: {allPages}</h1>
                                    </div>
                                </div>
                                <div className='flex items-center grayBlurShadow px-3 py-1 rounded-lg'>
                                    <div onClick={(e) => {
                                        if (page > 1) {
                                            setPage(page - 1);
                                        }
                                    }} className={` ${page === 1 ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                        <img className='w-5' src="/img/leftArrow.png" alt="leftArrow" />
                                    </div>
                                    <div className=' px-3'>
                                        {page}
                                    </div>
                                    <div onClick={(e) => {
                                        if (allPages > page) {
                                            setPage(page + 1);
                                        }
                                    }} className={` ${page == allPages ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                        <img className='w-5' src="/img/rightArrow.png" alt="rightArrow" />
                                    </div>
                                </div>
                            </div>
                        </section> :
                        <div className='navAnimation flex flex-col justify-center items-center bg-white mt-5 py-5 mb-10 rounded-3xl '>
                            <img className=' w-[28%]' src="/img/addReports.png" alt="" />
                            <h1 className=' text-2xl font-bold'>Oops!! "<span className=' text-blue-950'>Users Not Found</span>"</h1>
                        </div> :
                    <div className=' loaderBox mb-5 bg-white mt-5 rounded-3xl '>
                        <img className=' m-auto w-1/3' src="/img/loader2.gif" alt="loading..." />
                    </div>
                }

            </div>
        </section>
    );
}

export default UserDetails;
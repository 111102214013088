// export const basePath = "http://localhost:3002"
// export const basePath="http://143.110.190.50:3882"
export const basePath = "https://atsapi.abstarthr.in"

export const getCurrentDate = () => {
    return `${new Date().toISOString()?.split("T")[0]}T${new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}`;
}

export const formatDateAndTime = (dateTimeString, format12Hour) => {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');

    if (format12Hour === 12) {
        const ampm = hours >= 12 ? 'PM' : 'AM';
        // Convert hours from 24-hour to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        hours = String(hours).padStart(2, '0');
        return `${day}-${monthData[parseInt(month)]}-${year}T${hours}:${minutes} ${ampm}`;
    } else {
        hours = String(hours).padStart(2, '0');
        return `${day}-${monthData[parseInt(month)]}-${year}T${hours}:${minutes}`;
    }
};
export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');

    hours = String(hours).padStart(2, '0');
    return `${day} ${monthData[parseInt(month)]} ${year}`;

};

export const navList = [
    { name: "Home", path: "" },
    { name: "Daily Sourcing Update", path: "daily-sourcing-update" },
    { name: "ATS Pipeline", path: "ats-pipeline" },
    { name: "Tasks", path: "tasks" },
    { name: "Settings", path: "settings" },
    { name: "Profile", path: "profile" },
];
export const atsStatusList = [
    { name: "CV Rejected", value: "CV Rejected" },
    { name: "Shortlisted", value: "Shortlisted" },
    { name: "Duplicate CV", value: "Duplicate CV" },
    { name: "Interview Scheduled", value: "Interview Scheduled" },
    { name: "Interview Feedback Pending", value: "Interview Feedback Pending" },
    { name: "Shortlisted Next Round", value: "Shortlisted Next Round" },
    { name: "Interview Done", value: "Interview Done" },
    { name: "Hold Post Interview", value: "Hold Post Interview" },
    { name: "Rejected Post Interview", value: "Rejected Post Interview" },
    { name: "Final Selection", value: "Final Selection" },
    { name: "Final Joining", value: "Final Joining" },
    { name: "Backout", value: "Backout" },

];

// ATS pipeline status
export const atsStatusSentToClient = [
    { name: "CV Rejected", value: "CV Rejected" },
    { name: "Shortlisted", value: "Shortlisted" },
    { name: "Duplicate CV", value: "Duplicate CV" },
    { name: "Backout", value: "Backout" },
];
export const atsStatusShortlisted = [
    // { name: "Shortlisted", value: "Shortlisted" },
    { name: "Interview Scheduled", value: "Interview Scheduled" },
    { name: "Backout", value: "Backout" },
];
export const atsStatusFinalSelection = [
    // { name: "Final Selection", value: "Final Selection" },
    { name: "Candidate Joined", value: "Final Joining" },
    { name: "Backout", value: "Backout" },
];
export const atsStatusFinalJoining = [
    // { name: "Final Joining", value: "Final Joining" },
    { name: "Backout", value: "Backout" },
];

// Interview schedule status
export const interviewStatusInterview = [
    { name: "Interview Feedback Pending", value: "Interview Feedback Pending" },
];
export const interviewInterviewFeedbackPending = [
    { name: "Shortlisted Next Round", value: "Shortlisted Next Round" },
    { name: "Rejected Post Interview", value: "Rejected Post Interview" },
    { name: "Hold Post Interview", value: "Hold Post Interview" },
    { name: "Backout", value: "Backout" },
    { name: "Final Selection", value: "Final Selection" },
];

export const monthData = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const countryPhoneCode = [
    {
        name: "United States",
        isoCode: "US",
        phoneCode: "+1",
        phoneNumberLength: 10,
        flag: "https://flagcdn.com/us.svg"
    },
    {
        name: "Canada",
        isoCode: "CA",
        phoneCode: "+1",
        phoneNumberLength: 10,
        flag: "https://flagcdn.com/ca.svg"
    },
    {
        name: "United Kingdom",
        isoCode: "GB",
        phoneCode: "+44",
        phoneNumberLength: 10,
        flag: "https://flagcdn.com/gb.svg"
    },
    {
        name: "Australia",
        isoCode: "AU",
        phoneCode: "+61",
        phoneNumberLength: 9,
        flag: "https://flagcdn.com/au.svg"
    },
    {
        name: "India",
        isoCode: "IN",
        phoneCode: "+91",
        phoneNumberLength: 10,
        flag: "https://flagcdn.com/in.svg"
    },
    {
        name: "Germany",
        isoCode: "DE",
        phoneCode: "+49",
        phoneNumberLength: 10,
        flag: "https://flagcdn.com/de.svg"
    },
    {
        name: "France",
        isoCode: "FR",
        phoneCode: "+33",
        phoneNumberLength: 9,
        flag: "https://flagcdn.com/fr.svg"
    },
    {
        name: "Japan",
        isoCode: "JP",
        phoneCode: "+81",
        phoneNumberLength: 10,
        flag: "https://flagcdn.com/jp.svg"
    },
    {
        name: "China",
        isoCode: "CN",
        phoneCode: "+86",
        phoneNumberLength: 11,
        flag: "https://flagcdn.com/cn.svg"
    },
    {
        name: "Brazil",
        isoCode: "BR",
        phoneCode: "+55",
        phoneNumberLength: 11,
        flag: "https://flagcdn.com/br.svg"
    },
    {
        name: "South Africa",
        isoCode: "ZA",
        phoneCode: "+27",
        phoneNumberLength: 9,
        flag: "https://flagcdn.com/za.svg"
    },
    {
        name: "Russia",
        isoCode: "RU",
        phoneCode: "+7",
        phoneNumberLength: 10,
        flag: "https://flagcdn.com/ru.svg"
    },
    {
        name: "Mexico",
        isoCode: "MX",
        phoneCode: "+52",
        phoneNumberLength: 10,
        flag: "https://flagcdn.com/mx.svg"
    },
    {
        name: "Italy",
        isoCode: "IT",
        phoneCode: "+39",
        phoneNumberLength: 10,
        flag: "https://flagcdn.com/it.svg"
    },
    {
        name: "Spain",
        isoCode: "ES",
        phoneCode: "+34",
        phoneNumberLength: 9,
        flag: "https://flagcdn.com/es.svg"
    },
    {
        name: "Bahrain",
        isoCode: "BH",
        phoneCode: "+973",
        phoneNumberLength: 8,
        flag: "https://flagcdn.com/bh.svg"
    },
    {
        name: "Iran",
        isoCode: "IR",
        phoneCode: "+98",
        phoneNumberLength: 10,
        flag: "https://flagcdn.com/ir.svg"
    },
    {
        name: "Iraq",
        isoCode: "IQ",
        phoneCode: "+964",
        phoneNumberLength: 10,
        flag: "https://flagcdn.com/iq.svg"
    },
    {
        name: "Israel",
        isoCode: "IL",
        phoneCode: "+972",
        phoneNumberLength: 9,
        flag: "https://flagcdn.com/il.svg"
    },
    {
        name: "Jordan",
        isoCode: "JO",
        phoneCode: "+962",
        phoneNumberLength: 9,
        flag: "https://flagcdn.com/jo.svg"
    },
    {
        name: "Kuwait",
        isoCode: "KW",
        phoneCode: "+965",
        phoneNumberLength: 8,
        flag: "https://flagcdn.com/kw.svg"
    },
    {
        name: "Lebanon",
        isoCode: "LB",
        phoneCode: "+961",
        phoneNumberLength: 8,
        flag: "https://flagcdn.com/lb.svg"
    },
    {
        name: "Oman",
        isoCode: "OM",
        phoneCode: "+968",
        phoneNumberLength: 8,
        flag: "https://flagcdn.com/om.svg"
    },
    {
        name: "Qatar",
        isoCode: "QA",
        phoneCode: "+974",
        phoneNumberLength: 8,
        flag: "https://flagcdn.com/qa.svg"
    },
    {
        name: "Saudi Arabia",
        isoCode: "SA",
        phoneCode: "+966",
        phoneNumberLength: 9,
        flag: "https://flagcdn.com/sa.svg"
    },
    {
        name: "Syria",
        isoCode: "SY",
        phoneCode: "+963",
        phoneNumberLength: 9,
        flag: "https://flagcdn.com/sy.svg"
    },
    {
        name: "Turkey",
        isoCode: "TR",
        phoneCode: "+90",
        phoneNumberLength: 10,
        flag: "https://flagcdn.com/tr.svg"
    },
    {
        name: "United Arab Emirates",
        isoCode: "AE",
        phoneCode: "+971",
        phoneNumberLength: 9,
        flag: "https://flagcdn.com/ae.svg"
    },
    {
        name: "Yemen",
        isoCode: "YE",
        phoneCode: "+967",
        phoneNumberLength: 9,
        flag: "https://flagcdn.com/ye.svg"
    },
    {
        name: "Nigeria",
        isoCode: "NG",
        phoneCode: "+234",
        phoneNumberLength: 10,
        flag: "https://flagcdn.com/ng.svg"
    },
    {
        name: "Zimbabwe",
        isoCode: "ZW",
        phoneCode: "+263",
        phoneNumberLength: 9,
        flag: "https://flagcdn.com/zw.svg"
    }
];

export const homeTableHeading = [
    "Company",
    "Position",
    "Location",
    "Openings",
    "CTC",
    "Date"
]
export const industryList = [
    "Accounting / Auditing",
    "Advertising & Marketing",
    "Agriculture / Forestry / Fishing",
    "Analytics / KPO / Research",
    "Animation & VFX",
    "Architecture / Interior Design",
    "Auto Components",
    "Automobile",
    "Aviation",
    "BPO / Call Centre",
    "Banking",
    "Beauty & Personal Care",
    "Beverage",
    "Biotechnology",
    "Building Material",
    "Chemicals",
    "Clinical Research / Contract Research",
    "Consumer Electronics & Appliances",
    "Content Development / Language",
    "Courier / Logistics",
    "Defence & Aerospace",
    "Design",
    "E-Learning / EdTech",
    "Education / Training",
    "Electrical Equipment",
    "Electronic Components / Semiconductors",
    "Electronics Manufacturing",
    "Emerging Technologies",
    "Engineering & Construction",
    "Events / Live Entertainment",
    "FMCG",
    "Facility Management Services",
    "Fertilizers / Pesticides / Agro chemicals",
    "Film / Music / Entertainment",
    "FinTech / Payments",
    "Financial Services",
    "Fitness & Wellness",
    "Food Processing",
    "Furniture & Furnishing",
    "Gaming",
    "Gems & Jewellery",
    "Government / Public Administration",
    "Hardware & Networking",
    "Hotels & Restaurants",
    "IT Services & Consulting",
    "Import & Export",
    "Industrial Automation",
    "Industrial Equipment / Machinery",
    "Insurance",
    "Internet",
    "Investment Banking / Venture Capital / Private Equity",
    "Iron & Steel",
    "Law Enforcement / Security Services",
    "Leather",
    "Legal",
    "Manufacturing",
    "Management Consulting",
    "Medical Devices & Equipment",
    "Medical Services / Hospital",
    "Metals & Mining",
    "Miscellaneous",
    "NBFC",
    "NGO / Social Services / Industry Associations",
    "Oil & Gas",
    "Packaging & Containers",
    "Petrochemical / Plastics / Rubber",
    "Pharmaceutical & Life Sciences",
    "Ports & Shipping",
    "Power",
    "Printing & Publishing",
    "Pulp & Paper",
    "Railways",
    "Real Estate",
    "Recruitment / Staffing",
    "Retail",
    "Software Product",
    "Sports / Leisure & Recreation",
    "TV / Radio",
    "Telecom / ISP",
    "Textile & Apparel",
    "Travel & Tourism",
    "Urban Transport",
    "Water Treatment / Waste Management"]
export const tableData = [
    {
        company: "Sarvesh Pvt.",
        position: "Software developer",
        location: "Mumbai",
        openings: 4,
        ctc: 6,
        date: "18 Apr 2024"
    },
    {
        company: "Sarvesh Pvt. Ltd.",
        position: "Software developer",
        location: "Mumbai",
        openings: 4,
        ctc: 6,
        date: "18 Apr 2024"
    },
    {
        company: "Sarvesh Pvt. Ltd.",
        position: "Software developer",
        location: "Mumbai",
        openings: 4,
        ctc: 6,
        date: "18 Apr 2024"
    },
    {
        company: "Sarvesh Pvt. Ltd.",
        position: "Software developer",
        location: "Mumbai",
        openings: 4,
        ctc: 6,
        date: "18 Apr 2024"
    },
    {
        company: "Sarvesh Pvt. Ltd.",
        position: "Software developer",
        location: "Mumbai",
        openings: 4,
        ctc: 6,
        date: "18 Apr 2024"
    },
    {
        company: "Sarvesh Pvt. Ltd.",
        position: "Software developer",
        location: "Mumbai",
        openings: 4,
        ctc: 6,
        date: "18 Apr 2024"
    },
    {
        company: "Sarvesh Pvt. Ltd.",
        position: "Software developer",
        location: "Mumbai",
        openings: 4,
        ctc: 6,
        date: "18 Apr 2024"
    },
    {
        company: "Sarvesh Pvt.",
        position: "Software developer",
        location: "Mumbai",
        openings: 4,
        ctc: 6,
        date: "18 Apr 2024"
    },
    {
        company: "Sarvesh Pvt. Ltd.",
        position: "Software developer",
        location: "Mumbai",
        openings: 4,
        ctc: 6,
        date: "18 Apr 2024"
    },
    {
        company: "Sarvesh Pvt. Ltd.",
        position: "Software developer",
        location: "Mumbai",
        openings: 4,
        ctc: 6,
        date: "18 Apr 2024"
    },
    {
        company: "Sarvesh Pvt. Ltd.",
        position: "Software developer",
        location: "Mumbai",
        openings: 4,
        ctc: 6,
        date: "18 Apr 2024"
    },
    {
        company: "Sarvesh Pvt. Ltd.",
        position: "Software developer",
        location: "Mumbai",
        openings: 4,
        ctc: 6,
        date: "18 Apr 2024"
    },
    {
        company: "Sarvesh Pvt. Ltd.",
        position: "Software developer",
        location: "Mumbai",
        openings: 4,
        ctc: 6,
        date: "18 Apr 2024"
    },
    {
        company: "Sarvesh Pvt. Ltd.",
        position: "Software developer",
        location: "Mumbai",
        openings: 4,
        ctc: 6,
        date: "18 Apr 2024"
    },
];

// export const sourceReportData=[]



import React, { useContext, useEffect, useState } from 'react';
import { basePath, monthData } from '../Constants/constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { AppContext } from '../ContextApi/ContextApi';
import { debounce } from "lodash";
import axios from 'axios';

function ReportAndAnalysis(props) {
    const { industry, setIndustry, isModalOpen, setIsModalOpen } = useContext(AppContext);
    const [allReport, setAllReport] = useState(true);
    const [allUsers, setAllUsers] = useState([]);
    const [reportType, setReportType] = useState("recruiterWise");
    const [loading, setLoading] = useState(false);
    const [loadingFilter, setLoadingFilter] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [allPages, setAllPages] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [filterData, setFilterData] = useState({
        fromDate: "",
        toDate: "",
        recruiter: "",
        company: "",
        position: "",
    });


    const notify = (msg = "successfull!!") => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const notifyFillTheFields = (msg = "There some issue!!") => toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });



    async function getReport() {
        try {
            setLoading(true);
            let res = await axios.get(`${basePath}/reportandanalysis?limit=${limit}&page=${page}&id=${localStorage.getItem("userId")}`);
            console.log("getReport===>", res.data);
            setAllReport(res.data);
            setTotalRecords(res?.data?.totalRecords);
            setAllPages(res?.data?.pages);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    const getUserData = async () => {
        let response = await axios.get(`${basePath}/getRecruiter`);
        console.log(response.data);
        setAllUsers(response?.data);
    }
    const handleExport = async () => {
        try {
            setLoadingExport(true);
            const response = await axios.get(`${basePath}/reportandanalysis?download=yes&filter=${JSON.stringify(filterData)}`, {
                responseType: 'blob', // Ensure response is treated as a blob
            });

            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a temporary <a> element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'ATS_Pipeline.xlsx');
            document.body.appendChild(link);
            link.click();

            // Clean up resources
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            setLoadingExport(false);
        } catch (error) {
            console.error('Error exporting jobs:', error);
            setLoadingExport(false);
        }
    };


    useEffect(() => {
        getReport();
        getUserData();
    }, [])

    return (
        <section onClick={() => { setIsModalOpen(false); }} className='lightBabyGreen baseHeightWidth customScroll mb-5'>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            <div className=' absolute bottom-3 right-4'>
                <div onClick={(e) => { getReport(); }} className='cursor-pointer bg-red-600 px-2.5 py-2 rounded-full hoverRed'>
                    <FontAwesomeIcon icon={faRotateRight} size={"lg"} style={{ color: "#ffffff", }} />
                </div>
            </div>
            <div className='welcomeBarHeight babyGreen smoothTransition textDarkPurple px-10 rounded-3xl flex items-center justify-between navAnimation  m-auto'>
                <div className=' text-left'>
                    <h1 className=' text-5xl font-bold'>Report And Analysis</h1>
                    <p className=' text-gray-500 font-semibold'>Ready to start your day</p>
                </div>
                <img className=' w-44' src="/img/registration.png" alt="registration" />
            </div>
            <div className=' flex items-center justify-evenly p-2'>
            <div onClick={(e) => {
                setReportType("recruiterWise");
            }} className='darkPurple w-fit cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>

                <button>Recruiter Wise Data</button>
            </div>
            <div onClick={(e) => {
                setReportType("companyWise");
            }} className='darkPurple w-fit  cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>
                
                <button>Compnay Wise Data</button>
            </div>
            </div>
            {!loadingFilter ?
                <>
                    <div className=' grayBlurShadow bg-white p-3 mt-5 rounded-xl'>
                        {/* <h1>Date Filter</h1> */}
                        <div className='flex flex-wrap items-center gap-3 mb-3'>
                        <div className='flex items-center gap-3'>
                            <div className=' flex flex-col justify-start items-start'>
                                <label>From: </label>
                                <input
                                    className=' border-2 rounded-lg px-2 py-1'
                                    type="date"
                                    value={filterData?.fromDate}
                                    onChange={e => setFilterData({ ...filterData, fromDate: e.target.value })}
                                />
                            </div>
                            <div className=' flex flex-col justify-start items-start'>
                                <label>To: </label>
                                <input
                                    className=' border-2 rounded-lg px-2 py-1'
                                    type="date"
                                    value={filterData?.toDate}
                                    onChange={e => setFilterData({ ...filterData, toDate: e.target.value })}
                                />
                            </div>
                        </div>

                        {
                            reportType === "companyWise" ?
                                <>
                                    <div className='flex flex-col justify-start items-start mb-2'>
                                        <label >Company</label>
                                        <input
                                            value={filterData?.company}
                                            className=' border-2 rounded-lg px-2 py-1'
                                            onChange={e => setFilterData({ ...filterData, company: e.target.value })}
                                            type="text" />
                                    </div>
                                    <div className='flex flex-col justify-start items-start mb-2'>
                                        <label >Position</label>
                                        <input
                                            value={filterData?.position}
                                            className=' border-2 rounded-lg px-2 py-1'
                                            onChange={e => setFilterData({ ...filterData, position: e.target.value })}
                                            type="text" />
                                    </div>
                                </> : null
                        }
                        {
                             reportType === "recruiterWise" ?
                                <div className='flex flex-col justify-start items-start mb-2'>
                                    <label >Recruiter</label>
                                    <select value={filterData?.recruiter}
                                        className=' border-2 rounded-lg px-2 py-1'
                                        onChange={e => setFilterData({ ...filterData, recruiter: e.target.value })} >
                                        <option value="" selected disabled>Select Recruiter</option>
                                        {allUsers?.map((val) => {
                                            return (
                                                <option value={val?.id}>{val?.name}</option>
                                            )
                                        })}
                                    </select>
                                </div> : null
                        }
                        </div>


                        <div className='flex gap-3 justify-start items-start'>
                            <button onClick={(e) => { }} className=' darkPurple cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>Apply filter</button>
                            <button onClick={() => {
                                setFilterData({
                                    fromDate: "",
                                    toDate: "",
                                    company: "",
                                    position: "",
                                    recruiter: ""
                                })

                            }} className=' text-red-700 bg-red-200  py-2 px-3 rounded-lg hoverRed'>Clear FIlter</button>
                            <div className=' text-left w-fit'>
                                <button onClick={handleExport} className=' py-2 px-3 bg-green-800 text-white rounded-lg '>{loadingExport ? "Exporting..." : "Excel"}</button>
                            </div>
                        </div>
                    </div>

                </> : null
            }
            {
                !loading ?
                    Array.isArray(allReport) && allReport?.length >= 1 ?
                        <section className='bg-white grayBlurShadow px-7 pt-7 rounded-3xl mb-8 mt-5  overflow-auto  '>
                            <div className=' overflow-auto tableBody tableBox'>
                                <table className=' navAnimation rounded-2xl baseWidth '>
                                    <thead className='  text-lg tableHead'>
                                        <tr className=' bg-gray-200'>
                                            <th>Sr. No.</th>
                                            <th >Date</th>
                                            <th className=' whitespace-nowrap'>Recruiter Name</th>
                                            <th className=' whitespace-nowrap'>CV Sourced</th>
                                            <th className=' whitespace-nowrap'>Sent To Client</th>
                                            <th >CV Rejected</th>
                                            <th >Shortlisted</th>
                                            <th className=' whitespace-nowrap' >Interview Schedule</th>
                                            <th className=' whitespace-nowrap'>Interview Done</th>
                                            <th className=' whitespace-nowrap'>Rejected Post Interview</th>
                                            <th className=' whitespace-nowrap'>Final Selection</th>
                                            <th className=' whitespace-nowrap'>Offer letter Sent</th>
                                            <th className=' whitespace-nowrap'>Final Joining</th>
                                            <th className=' whitespace-nowrap'>Backout</th>
                                            <th className=' whitespace-nowrap'>Feedback Pending</th>

                                        </tr>
                                    </thead>
                                    <tbody className='tableBody '>
                                        {
                                            Array.isArray(allReport) && allReport?.map((val, idx) => {
                                                return (
                                                    <tr key={val?._id} id={val?._id} className=' backWhite font-semibold text-sm'>
                                                        <td>{idx + 1}</td>

                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className=' flex items-center justify-between py-4'>
                                <div className=' flex items-center gap-2'>
                                    <div className='grayBlurShadow px-3 py-1'>
                                        <select value={limit} onChange={(e) => { setLimit(e.target.value); }} >
                                            <option value="10" selected>10</option>
                                            <option value="20" >20</option>
                                            <option value="50" >50</option>
                                            <option value="100" >100</option>
                                            <option value="200" >200</option>
                                            <option value="500" >500</option>
                                            <option value="All" >All</option>
                                        </select>
                                    </div>
                                    <div className='grayBlurShadow px-1 py-1 text-sm font-semibold'>
                                        <h1>Total Records: {totalRecords}</h1>
                                    </div>
                                    <div className='grayBlurShadow px-1 py-1  text-sm font-semibold'>
                                        <h1>Total Pages: {allPages}</h1>
                                    </div>
                                </div>
                                <div className='flex items-center grayBlurShadow px-3 py-1 rounded-lg'>
                                    <div onClick={(e) => {
                                        if (page > 1) {
                                            setPage(page - 1);
                                        }
                                    }} className={` ${page === 1 ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                        <img className='w-5' src="/img/leftArrow.png" alt="leftArrow" />
                                    </div>
                                    <div className=' px-3'>
                                        {page}
                                    </div>
                                    <div onClick={(e) => {
                                        if (allPages > page) {
                                            setPage(page + 1);
                                        }
                                    }} className={` ${page == allPages ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                        <img className='w-5' src="/img/rightArrow.png" alt="rightArrow" />
                                    </div>
                                </div>
                            </div>
                        </section> :
                        <div className='navAnimation flex flex-col justify-center items-center bg-white mt-5 py-5 mb-10 rounded-3xl '>
                            <img className=' w-[28%]' src="/img/addReports.png" alt="" />
                            <h1 className=' text-2xl font-bold'>Oops!! "<span className=' text-blue-950'>No Reports Found</span>"</h1>
                        </div> :
                    <div className=' loaderBox mb-5 bg-white mt-5 rounded-3xl '>
                        <img className=' m-auto w-1/4' src="/img/loader2.gif" alt="loading..." />
                    </div>
            }

        </section>
    );
}

export default ReportAndAnalysis;
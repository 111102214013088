import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../ContextApi/ContextApi';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { basePath } from '../Constants/constant';

function JobPost(props) {
    const { index } = useParams();
    const { industry, setIndustry, isModalOpen, setIsModalOpen } = useContext(AppContext);
    const navigate = useNavigate();
    const [viewModal, setViewModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [isAction, setIsAction] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allCompany, setAllCompany] = useState([]);
    const [selectedJd, setSelectedJd] = useState(null);
    const [formData, setFormData] = useState({
        company: "",
        position: "",
        location: "",
        min_experience: "",
        max_experience: "",
        minCtc: "",
        maxCtc: "",
        totalPositions: "",
        genderPreference: "",
        qualification: "",
        description: ""
    })
    const getCompany = async () => {

        let response = await axios.get(`${basePath}/getcompany`);
        console.log("company==>>", response.data);
        setAllCompany(response?.data);

    }
    useEffect(() => {
        getCompany();
    }, []);
    const notify = (msg = "successfull!!") => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const notifyFillTheFields = (msg = "There some issue!!") => toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });

    const handleFileChange = (e) => {
        const jd_upload = e.target.files[0];
        if (jd_upload) {
            setFormData({ ...formData, description: jd_upload });
            setSelectedJd(jd_upload);
            console.log(jd_upload);
        } else {
            // Handle the case where no file is selected
            console.error("No file selected");
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            company_id: formData?.company,
            position: formData?.position,
            location: formData?.location,
            min_experience: `${formData?.min_experience} Yrs.`,
            max_experience: `${formData?.max_experience} Yrs.`,
            min_ctc: `${formData?.minCtc} LPA`,
            max_ctc: `${formData?.maxCtc} LPA`,
            no_of_positions: formData?.totalPositions,
            gender_pref: formData?.genderPreference,
            qualification: formData?.qualification,
            jd_upload: selectedJd,
            recruiter_id: localStorage.getItem("userId")
        }
        console.log(data);
        if (formData?.company) {
            setLoading(true);
            const options = {
                url: `${basePath}/job`,
                method: "POST",
                headers: {
                    Accept: "application/json",
                    'Content-Type': 'multipart/form-data',
                },
                data: data,
            }
            axios(options)
                .then((response) => {
                    if (response.status == 200) {
                        setLoading(false);
                        document.getElementById("jobpost").reset();
                        setFormData({
                            company: "",
                            position: "",
                            location: "",
                            min_experience: "",
                            max_experience: "",
                            minCtc: "",
                            maxCtc: "",
                            totalPositions: "",
                            genderPreference: "",
                            qualification: "",
                            description: ""
                        });
                        notify("Job Posted Successfully");
                        console.log("Information entered successfully");
                    }
                    else {
                        setLoading(false);
                        notifyFillTheFields("Unable to post job");
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    console.log(e);
                    notifyFillTheFields("There is a network or server issue!!");
                });
        } else {
            notifyFillTheFields("All Fields Are Required!!!");
        }
    }
    const checkAllFields = () => {
        if (!formData?.company && !formData?.position
            && !formData?.location && !formData?.min_experience && !formData?.max_experience && !formData?.minCtc &&
            !formData?.maxCtc && !formData?.totalPositions && !formData?.genderPreference &&
            !formData?.qualification && !formData?.description) {
            notifyFillTheFields("All Fields Are Required!!!");
        }
    }
    const handleChange = (e) => {
        const inputValue = e.target.value;
        // Regex to check if the value contains only digits
        if (/^\d+$/.test(inputValue) || inputValue === '') {
            setFormData({ ...formData, totalPositions: inputValue })
        }
    };
    return (
        <section onClick={() => { setIsModalOpen(false) }} className='lightBabyGreen baseHeightWidth customScroll '>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            <div className=' customScroll'>
                <div className='welcomeBarHeight babyGreen smoothTransition textDarkPurple px-10 rounded-3xl flex items-center justify-between navAnimation width80 m-auto'>
                    <div className=' text-left'>
                        <h1 className=' text-5xl font-bold'>Create New Job</h1>
                        <p className=' text-gray-500 font-semibold'>Ready to start your day</p>
                    </div>
                    <img className=' w-44' src="/img/registration.png" alt="registration" />
                </div>
                <div className=' w-full my-3 pr-4 flex items-center justify-end'>
                    <button onClick={() => { localStorage.getItem("role") === "Super Admin" ? navigate(`/main/all-posted-jobs/${index}`) : navigate(`/all-posted-jobs/${index}`); }} className=' text-left w-fit darkPurple hoverGreen text-white rounded-lg px-3 py-1'>Back</button>
                </div>
                <div className=' bg-white px-10 py-3 mb-9 grayBlurShadow rounded-3xl navAnimation'>
                    <form id='jobpost' onSubmit={handleSubmit} className=' p-4'>
                        <h1 className=' text-3xl font-bold babyGreen  rounded-xl py-3 mb-5'>Post Job</h1>
                        <div className='relative flex flex-col gap-5 p-7 borderDarkBlue rounded-3xl'>
                            <h1 className=' bg-white absolute top-15px font-semibold px-1 textDarkBlue'>Job Details</h1>
                            <div className=' grid gridResponsive gap-3 '>
                                <div className='input-group relative'>
                                    <select value={formData?.company} onChange={(e) => { setFormData({ ...formData, company: parseInt(e.target.value) }) }} className=' w-full outline-none'>
                                        <option value="" selected disabled>--Select Company--</option>
                                        {allCompany?.map((val) => {
                                            return (
                                                <option key={val.id} value={val?.id}>{val?.company_name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className='input-group relative'>
                                    <input value={formData?.position} onChange={(e) => { setFormData({ ...formData, position: e.target.value }) }} id='position' placeholder='Position*' className=' w-full' required type="text" />
                                    <label htmlFor="position" className='label'>Position</label>
                                </div>
                            </div>
                            <div className=' grid gridResponsive gap-3'>
                                <div className='input-group relative'>
                                    <input value={formData?.location} onChange={(e) => { setFormData({ ...formData, location: e.target.value }) }} id='location' placeholder='Location*' className=' w-full' required type="text" />
                                    <label htmlFor="location" className='label'>Location</label>
                                </div>
                                <div className=' grid grid-cols-2 gap-3'>
                                    <div className='input-group relative'>
                                        <input value={formData?.min_experience} onChange={(e) => { setFormData({ ...formData, min_experience: e.target.value }) }} id='min_experience' placeholder='Min Experience*' className=' w-full' required type="number" />
                                        <label htmlFor="min_experience" className='label'>Min Experience</label>
                                    </div>
                                    <div className='input-group relative'>
                                        <input value={formData?.max_experience} onChange={(e) => { setFormData({ ...formData, max_experience: e.target.value }) }} id='max_experience' placeholder='Max Experience*' className=' w-full' required type="number" />
                                        <label htmlFor="max_experience" className='label'> Max Experience</label>
                                    </div>
                                </div>

                            </div>
                            <div className=' grid gridResponsive gap-3'>
                                <div className=' grid grid-cols-2 gap-3'>
                                    <div className='input-group relative'>
                                        <input value={formData?.minCtc} onChange={(e) => { setFormData({ ...formData, minCtc: e.target.value }) }} id='min' placeholder='Min CTC*' className=' w-full' required type="number" />
                                        <label htmlFor="min" className='label'>MinCTC(Lac)</label>
                                    </div>
                                    <div className='input-group relative'>
                                        <input value={formData?.maxCtc} onChange={(e) => { setFormData({ ...formData, maxCtc: e.target.value }) }} id='max' placeholder='Max CTC*' className=' w-full' required type="number" />
                                        <label htmlFor="max" className='label'>MaxCTC(Lac)</label>
                                    </div>
                                </div>
                                <div className='input-group relative'>
                                    <input value={formData?.totalPositions} onChange={(e) => { handleChange(e); }} id='totalPositions' placeholder='No. of positions*' className=' w-full' pattern="^[5-9]+$" required type="number" />
                                    <label htmlFor="totalPositions" className='label'>No. of positions</label>
                                </div>
                            </div>
                            <div className=' grid gridResponsive gap-3'>
                                <div className='input-group relative'>
                                    <select value={formData?.genderPreference} onChange={(e) => { setFormData({ ...formData, genderPreference: e.target.value }) }} className=' w-full outline-none'>
                                        <option value="" selected disabled>--Select Gender Preference--</option>
                                        <option value={"Male"}>Male</option>
                                        <option value={"Female"}>Female</option>
                                        <option value={"No preference"}>No preference</option>
                                    </select>
                                </div>
                                <div className='input-group relative'>
                                    <input value={formData?.qualification} onChange={(e) => { setFormData({ ...formData, qualification: e.target.value }) }} id='qualification' placeholder='Qualification*' className=' w-full' required type="text" />
                                    <label htmlFor="qualification" className='label'>Qualification</label>
                                </div>
                            </div>
                            {/* <div className='input-group relative'>
                                <textarea value={formData?.description} onChange={(e) => { setFormData({ ...formData, description: e.target.value }) }} id='description' cols="20" rows="3" placeholder='Job Description...' required></textarea>
                                <label htmlFor="description" className='label'>Job Description</label>
                            </div> */}
                            <div className='input-group relative'>
                                <input onChange={handleFileChange} id='jd' placeholder='Upload Job Description*' accept='.pdf, .doc, .docx' className=' w-full' required type="file" />
                                <label htmlFor="jd" className='label'>Upload Job Description</label>
                            </div>
                            <div className=' w-full text-right'>
                                {!loading ?
                                    <button onClick={(e) => { checkAllFields() }} type='submit' className=' w-fit darkPurple text-white font-semibold rounded-xl px-7 text-sm py-2 hoverGreen'>Submit</button> :
                                    <button className=' w-fit darkPurple text-white font-semibold rounded-xl px-7 text-sm py-2 '>Loading...</button>
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>

    );
}

export default JobPost;
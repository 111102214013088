import React, { useContext, useEffect, useRef, useState } from 'react';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import "./sourcing.css";
import axios from "axios";
import { basePath, countryPhoneCode, monthData } from '../Constants/constant';
import { useParams } from 'react-router-dom';
import { AppContext } from '../ContextApi/ContextApi';
import { debounce } from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';

function Sourcing(props) {
    const { index } = useParams();
    const { industry, setIndustry, isModalOpen, setIsModalOpen, user, setUser } = useContext(AppContext);
    let dateRef = useRef(null);
    let candidateRef = useRef(null);
    let companyRef = useRef(null);
    let positionRef = useRef(null);
    let locationRef = useRef(null);
    let ctcRef = useRef(null);
    let sourcedFromRef = useRef(null);
    let relevantRef = useRef(null);
    let statusRef = useRef(null);
    let remarkRef = useRef(null);
    const [confirmation, setConfirmation] = useState(false);
    const [confirmationDelete, setConfirmationDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [sourceModal, setSourceModal] = useState(false);
    const [sourceViewModal, setSourceViewModal] = useState(false);
    const [sourceDate, setSourceDate] = useState("");
    const [isUpdate, setIsUpdate] = useState(false);
    const [status, setStatus] = useState(false);
    const [totalResume, setTotalResume] = useState(0);
    const [totalRelevants, setTotalRelevants] = useState(0);
    const [totalPending, setTotalPending] = useState(0);
    const [totalSent, setTotalSent] = useState(0);
    const [countryPhone, setCountryPhone] = useState("+91");
    const [countryPhoneLength, setCountryPhoneLength] = useState(10);
    const [candidateEdit, setCandidateEdit] = useState(false);
    const [sourceRow, setSourceRow] = useState([
        {
            id: 1,
            candidate: "",
            company: "",
            position: "",
            location: "",
            ctc: "",
            cvSourcedFrom: "",
            relevant: false,
            status: false,
            remarks: "",
        },
    ]);
    const [viewCandidate, setViewCandidate] = useState(false);
    const [viewCandidateEdit, setViewCandidateEdit] = useState(false);
    const [ctcType, setCtcType] = useState("");
    const [loadingFilter, setLoadingFilter] = useState(true);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [loadingSourcing, setLoadingSourcing] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ectcType, setEctcType] = useState("");
    const [allCompany, setAllCompany] = useState([]);
    const [allPositions, setAllPositions] = useState([]);
    const [allSourceReport, setAllSourceReport] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [allPages, setAllPages] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalRecords2, setTotalRecords2] = useState(0);
    const [allPages2, setAllPages2] = useState(0);
    const [page2, setPage2] = useState(1);
    const [limit2, setLimit2] = useState(10);
    const [date, setDate] = useState("");
    const [sourceReportData, setSourceReportData] = useState([
        {
            sourcing_date: "",
            candidate: "",
            company: "",
            position: "",
            location: "",
            min_ctc: "",
            max_ctc: "",
            cv_sourced_from: "",
            relevant: "",
            sourcing_status: "",
            remarks: "",
            candidate_email: "",
            candidate_phone: "",
            candidate_alt_phone: "",
            candidate_location: "",
            candidate_experience: "",
            candidate_current_ctc: "",
            candidate_expected_ctc: "",
            candidate_organization: "",
            candidate_designation: "",
            candidate_notice_period: "",
            candidate_qualification: "",
            candidate_gender: "",
            candidate_remarks: "",
            candidate_resume: "",
        },
    ]);
    const [candidate, setCandidate] = useState({
        candidate_id: "",
        candidate_name: "",
        candidate_email: "",
        candidate_phone: "",
        candidate_alt_phone: "",
        candidate_location: "",
        candidate_experience: "",
        candidate_current_ctc: "",
        candidate_expected_ctc: "",
        candidate_organization: "",
        candidate_designation: "",
        candidate_notice_period: "",
        candidate_qualification: "",
        candidate_gender: "",
        candidate_remarks: "",
        candidate_resume: "",
    });
    const [candidateInfo, setCandidateInfo] = useState({
        candidate_email: "",
        candidate_phone: "",
        candidate_alt_phone: "",
        candidate_location: "",
        candidate_experience: "",
        candidate_current_ctc: "",
        candidate_expected_ctc: "",
        candidate_organization: "",
        candidate_designation: "",
        candidate_notice_period: "",
        candidate_qualification: "",
        candidate_gender: "",
        candidate_remarks: "",
        candidate_resume: "",
    });
    const [candidateId, setCandidateId] = useState(false);
    const [candidateHasInfo, setCandidateHasInfo] = useState(false);
    const [allReports, setAllReports] = useState("");
    const [filterData, setFilterData] = useState({
        candidate: "",
        company: "",
        position: "",
        location: "",
        relevant: "",
        status: "",
    });
    const [dateFilter, setDateFilter] = useState({
        fromDate: "",
        toDate: "",
    });
    const [candidateRemarks, setCandidateRemarks] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        let today = new Date();
        let date = today.getDate();
        let month = today.getMonth() + 1;
        let year = today.getFullYear();
        setSourceDate(`${year}-${month.toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}`);
    }, [])

    const notify = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const notifyFillTheFields = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });


    const handleDate = (e) => {
        console.log(e.target.valueAsDate);
        let newDate = e.target.valueAsDate;
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        setSourceDate(`${year}-${month.toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}`);
    }
    console.log(sourceDate);

    async function fetchData(e) {
        try {
            setLoading(true);
            let res = await axios.get(`${basePath}/getSourcingReportByDate?limit=${limit}&page=${page}&date=${date}&id=${localStorage.getItem("userId")}`);
            console.log("===>>>>>");
            console.log(res.data);
            setAllReports(res.data.Candidates);
            setTotalRecords(res?.data?.totalRecords);
            setAllPages(res?.data?.pages);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    async function fetchFilterData(e) {
        console.log(filterData);
        try {
            setLoading(true);
            let res = await axios.get(`${basePath}/getSourcingReportByDate?limit=${limit}&page=${page}&date=${date}&id=${localStorage.getItem("userId")}&filter=${JSON.stringify(filterData)}`);
            console.log("Filter===>>>>>");
            console.log(res.data);
            setAllReports(res.data.Candidates);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    const downloadButton = async (canId, candidateName) => {
        setLoadingDownload(canId);
        try {
            const response = await fetch(`${basePath}/downloadresume/${canId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${candidateName.replace(/ /g, "_")}_resume_${new Date().toISOString().split("T")[0]}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url); // Cleanup the URL object
            setLoadingDownload(false);
        } catch (error) {
            console.error('There was a problem with the download:', error);
            if (error?.status === 404) {
                notifyFillTheFields(error?.response?.error);
            }
            else {
                notifyFillTheFields("There was a problem with the download");
            }
            setLoadingDownload(false);
        }
    }
    async function totalSourceData() {
        try {
            setIsLoading(true);
            let res = await axios.get(`${basePath}/getfilteredupdatebypage?limit=${limit2}&page=${page2}&id=${localStorage.getItem("userId")}`);
            console.log("totalSourceData===>", res.data);
            setAllSourceReport(res.data?.update);
            setTotalRecords2(res?.data?.totalRecords);
            setAllPages2(res?.data?.pages);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }
    async function handleDateFilter() {
        try {
            setIsLoading(true);
            let res = await axios.get(`${basePath}/getfilteredupdatebypage?limit=${limit2}&page=${page2}&id=${localStorage.getItem("userId")}&filter=${JSON.stringify(dateFilter)}`);
            console.log("totalSourceData===>", res.data);
            setAllSourceReport(res.data?.update);
            setTotalRecords2(res?.data?.totalRecords);
            setAllPages2(res?.data?.pages);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }
    const handleCalculateExport = async () => {
        try {
            setLoadingExport(true);
            const response = await axios.get(`${basePath}/getfilteredupdatebypage?download=yes&date=${date}&filter=${JSON.stringify(filterData)}`, {
                responseType: 'blob', // Ensure response is treated as a blob
            });

            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a temporary <a> element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Daily_Sourcing_Update_${date}.xlsx`);
            document.body.appendChild(link);
            link.click();

            // Clean up resources
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            setLoadingExport(false);
        } catch (error) {
            console.error('Error exporting jobs:', error);
            setLoadingExport(false);
        }
    }
    const handleExport = async () => {
        try {
            setLoadingExport(true);
            const response = await axios.get(`${basePath}/getSourcingReportByDate?download=yes&date=${date}&id=${localStorage.getItem("userId")}&filter=${JSON.stringify(filterData)}`, {
                responseType: 'blob', // Ensure response is treated as a blob
            });

            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a temporary <a> element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Daily_Sourcing_Update_${date}.xlsx`);
            document.body.appendChild(link);
            link.click();

            // Clean up resources
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            setLoadingExport(false);
        } catch (error) {
            console.error('Error exporting jobs:', error);
            setLoadingExport(false);
        }
    };

    useEffect(() => {
        totalSourceData();
    }, [page2, limit2])
    useEffect(() => {
        if (
            filterData?.candidate ||
            filterData?.company ||
            filterData?.position ||
            filterData?.location ||
            filterData?.relevant ||
            filterData?.status
        ) {
            fetchFilterData();
        }
        else if (date) {
            fetchData();
        }
    }, [page, limit, date])
    async function fetchCompany() {
        try {
            let res = await axios.get(`${basePath}/getcompanies?id=${localStorage.getItem("userId")}`);
            console.log("===>>>>>2");
            console.log(res);
            // let company = [...new Set(res?.data?.map((val) => val?.id))];
            // console.log(company);
            setAllCompany(res?.data);
        } catch (error) {
            console.log(error);
        }
    }
    async function fetchPositions(e, id) {
        let res = await axios.get(`${basePath}/getcompanies/${parseInt(id)}/positions?id=${localStorage.getItem("userId")}`);

        console.log(res.data);
        let temp = allPositions?.length >= 1 && allPositions?.filter((val) => {
            if (val?.company_id == id) {
                return val;
            }
        });
        console.log("===>>>>>3");
        // setAllPositions(val)
        console.log(temp);
        if (!temp || temp?.length === 0) {
            setAllPositions([...allPositions, ...res?.data]);
        }

    }
    useEffect(() => {
        // fetchData();
        fetchCompany();
    }, []);

    console.log("===>>4");
    console.log(allPositions);
    const handlePosition = (e, index) => {
        let newRow = [...sourceReportData];
        newRow[index].position = e.target.value;
        let positionArr = allPositions?.filter((val) => {
            if (e.target.value == val?.id) {
                return val;
            }
        });
        newRow[index].location = positionArr[0]?.location;
        newRow[index].min_ctc = positionArr[0]?.min_ctc?.split(" ")[0];
        newRow[index].max_ctc = positionArr[0]?.max_ctc?.split(" ")[0];
        setSourceReportData(newRow);
    }

    const handleAddRow = (e) => {
        // console.log(
        //     candidateRef?.current.value,
        //     companyRef?.current.value,
        //     positionRef?.current.value,
        //     locationRef?.current.value,
        //     ctcRef?.current.value,
        //     sourcedFromRef?.current.value,
        //     relevantRef?.current.value,
        //     statusRef?.current.value
        // );
        let rowIndex = sourceReportData?.length - 1;
        if (
            sourceReportData[rowIndex].candidate &&
            sourceReportData[rowIndex].company &&
            sourceReportData[rowIndex].position &&
            sourceReportData[rowIndex].cv_sourced_from &&
            sourceReportData[rowIndex].sourcing_status &&
            sourceReportData[rowIndex].relevant
            // sourceReportData[rowIndex].candidate_email.includes("@") &&
            // sourceReportData[rowIndex].candidate_phone &&
            // sourceReportData[rowIndex].candidate_location &&
            // sourceReportData[rowIndex].candidate_experience &&
            // sourceReportData[rowIndex].candidate_current_ctc &&
            // sourceReportData[rowIndex].candidate_expected_ctc &&
            // sourceReportData[rowIndex].candidate_qualification &&
            // sourceReportData[rowIndex].candidate_gender &&
            // sourceReportData[rowIndex].candidate_resume
        ) {
            setSourceReportData([...sourceReportData,
            {
                sourcing_date: sourceDate,
                candidate: "",
                company: "",
                position: "",
                location: "",
                min_ctc: "",
                max_ctc: "",
                cv_sourced_from: "",
                relevant: "",
                sourcing_status: "",
                remarks: "",
                candidate_email: "",
                candidate_phone: "",
                candidate_alt_phone: "",
                candidate_location: "",
                candidate_experience: "",
                candidate_current_ctc: "",
                candidate_expected_ctc: "",
                candidate_organization: "",
                candidate_designation: "",
                candidate_notice_period: "",
                candidate_qualification: "",
                candidate_gender: "",
                candidate_remarks: "",
                candidate_resume: "",
            },]);

            // setSourceRow([...sourceRow,
            // {
            //     id: sourceRow?.length + 1,
            //     candidate: "",
            //     company: "",
            //     position: "",
            //     location: "",
            //     min_ctc: "",
            //     max_ctc: "",
            //     cvSourcedFrom: "",
            //     relevant: "",
            //     status: "",
            //     remarks: "",
            // },])
        }
        else {
            notifyFillTheFields("Please fill all the fields");
        }
    }
    console.log(sourceReportData);
    // const handleSubmit = (e) => {
    //     if (
    //         candidateRef?.current.value, companyRef?.current.value,
    //         positionRef?.current.value, locationRef?.current.value,
    //         ctcRef?.current.value, sourcedFromRef?.current.value,
    //         relevantRef?.current.value, statusRef?.current.value
    //     ) {
    //         setErrorMsg("");
    //         setSourceModal(false);
    //         setreportDataModal(true);
    //         if (newSourceReportData) {
    //             setNewSourceReportData([...newSourceReportData,
    //             {
    //                 // id: newSourceReportData?.length + 1,
    //                 sourcing_date: sourceDate,
    //                 candidate: candidateRef?.current.value,
    //                 company: companyRef?.current.value,
    //                 position: positionRef?.current.value,
    //                 location: locationRef?.current.value,
    //                 ctc: ctcRef?.current.value,
    //                 cv_sourced_from: sourcedFromRef?.current.value,
    //                 relevant: relevantRef?.current.value,
    //                 sourcing_status: statusRef?.current.value,
    //                 remarks: remarkRef?.current.value,
    //             },]);
    //             setIsSubmit(true);
    //         }
    //         else {
    //             setNewSourceReportData([
    //                 {
    //                     // id: 1,
    //                     sourcing_date: sourceDate,
    //                     candidate: candidateRef?.current.value,
    //                     company: companyRef?.current.value,
    //                     position: positionRef?.current.value,
    //                     location: locationRef?.current.value,
    //                     ctc: ctcRef?.current.value,
    //                     cv_sourced_from: sourcedFromRef?.current.value,
    //                     relevant: relevantRef?.current.value,
    //                     sourcing_status: statusRef?.current.value,
    //                     remarks: remarkRef?.current.value,
    //                 },]);
    //             setIsSubmit(true);
    //         }
    //     }
    //     else {
    //         notifyFillTheFields("Please fill all the fields")
    //     }
    // }

    const handlePostData = async () => {
        setLoadingSourcing(true);
        let data = {
            sourcing_date: sourceDate,
            candidate: sourceReportData[0]?.candidate,
            company: sourceReportData[0]?.company,
            position: sourceReportData[0]?.position,
            location: sourceReportData[0]?.location,
            min_ctc: `${sourceReportData[0]?.min_ctc} LPA`,
            max_ctc: `${sourceReportData[0]?.max_ctc} LPA`,
            cv_sourced_from: sourceReportData[0]?.cv_sourced_from,
            relevant: sourceReportData[0]?.relevant,
            sourcing_status: sourceReportData[0]?.sourcing_status,
            remarks: sourceReportData[0]?.remarks,
            candidate_email: sourceReportData[0]?.candidate_email,
            candidate_phone: sourceReportData[0]?.candidate_phone,
            candidate_alt_phone: sourceReportData[0]?.candidate_alt_phone,
            candidate_location: sourceReportData[0]?.candidate_location,
            candidate_experience: sourceReportData[0]?.candidate_experience,
            candidate_current_ctc: sourceReportData[0]?.candidate_current_ctc,
            candidate_expected_ctc: sourceReportData[0]?.candidate_expected_ctc,
            candidate_organization: sourceReportData[0]?.candidate_organization,
            candidate_designation: sourceReportData[0]?.candidate_designation,
            candidate_notice_period: sourceReportData[0]?.candidate_notice_period,
            candidate_qualification: sourceReportData[0]?.candidate_qualification,
            candidate_gender: sourceReportData[0]?.candidate_gender,
            candidate_remarks: sourceReportData[0]?.candidate_remarks,
            candidate_resume: sourceReportData[0]?.candidate_resume,
        }


        console.log(data);
        if (sourceReportData?.length > 1) {

            const options = {
                url: `${basePath}/bulksourcingreport?id=${localStorage.getItem("userId")}`,
                method: "POST",
                headers: {
                    Accept: "application/json",
                    'Content-Type': 'multipart/form-data',
                },
                data: sourceReportData,
            }
            axios(options)
                .then((response) => {
                    console.log("response=====>>>>", response);
                    if (response.status == 200) {
                        setLoadingSourcing(false);
                        if (
                            !dateFilter?.fromDate &&
                            !dateFilter?.toDate
                        ) {
                            totalSourceData();
                        } else {
                            handleDateFilter();
                        }
                        setSourceReportData(
                            [{
                                sourcing_date: "",
                                candidate: "",
                                company: "",
                                position: "",
                                location: "",
                                min_ctc: "",
                                max_ctc: "",
                                cv_sourced_from: "",
                                relevant: "",
                                sourcing_status: "",
                                remarks: "",
                                candidate_email: "",
                                candidate_phone: "",
                                candidate_alt_phone: "",
                                candidate_location: "",
                                candidate_experience: "",
                                candidate_current_ctc: "",
                                candidate_expected_ctc: "",
                                candidate_organization: "",
                                candidate_designation: "",
                                candidate_notice_period: "",
                                candidate_qualification: "",
                                candidate_gender: "",
                                candidate_remarks: "",
                                candidate_resume: "",
                            },]);
                        notify("Successfully Added");
                    }
                })
                .catch((error) => {
                    setLoadingSourcing(false);
                    console.log(error);
                    if (error.response?.status === 404) {
                        notifyFillTheFields(error.response?.data?.error);
                    } else {
                        notifyFillTheFields("Unable To Add Candidate");
                    }
                });
        }
        else {
            const options = {
                url: `${basePath}/addsourcingreport?id=${localStorage.getItem("userId")}`,
                method: "POST",
                headers: {
                    Accept: "application/json",
                    'Content-Type': 'multipart/form-data',
                },
                data: data,
            }
            axios(options)
                .then((response) => {
                    console.log("response=====>>>>", response);
                    if (response.status == 200) {
                        setLoadingSourcing(false);
                        console.log(response);
                        totalSourceData();
                        // fetchData();
                        setSourceReportData(
                            [{
                                sourcing_date: "",
                                candidate: "",
                                company: "",
                                position: "",
                                location: "",
                                min_ctc: "",
                                max_ctc: "",
                                cv_sourced_from: "",
                                relevant: "",
                                sourcing_status: "",
                                remarks: "",
                                candidate_email: "",
                                candidate_phone: "",
                                candidate_alt_phone: "",
                                candidate_location: "",
                                candidate_experience: "",
                                candidate_current_ctc: "",
                                candidate_expected_ctc: "",
                                candidate_organization: "",
                                candidate_designation: "",
                                candidate_notice_period: "",
                                candidate_qualification: "",
                                candidate_gender: "",
                                candidate_remarks: "",
                                candidate_resume: "",
                            },]);
                        notify("Candidate Added Successfully.");
                    }
                })
                .catch((error) => {
                    setLoadingSourcing(false);
                    console.log(error);
                    if (error.response?.status === 404) {
                        notifyFillTheFields(error.response?.data?.error);
                    } else {
                        notifyFillTheFields("There is some network or server issue!! Please try again later.");
                    }
                });
            // console.log(resJson);
            // if (resJson.status === 200) {
            //     totalSourceData();
            //     notify("Successfully Added");
            //     setNewSourceReportData(false);
            // }
        }
        setIsSubmit(false);
    }
    const handleDeleteRow = (e, index) => {
        let data = sourceReportData?.filter((val, idx) => {
            if (idx != index) {
                return val;
            }
        });
        setSourceReportData(data);
    }
    // useEffect(() => {
    //     if (isSubmit) {
    //         handlePostData();
    //     }

    // }, [isSubmit]);


    useEffect(() => {
        let resume = 0;
        Array.isArray(allSourceReport) && allSourceReport?.forEach((val) => {
            resume += parseInt(val?.total_cv_sourced);
        });
        let relevant = 0;
        Array.isArray(allSourceReport) && allSourceReport?.forEach((val) => {
            relevant += parseInt(val?.total_cv_relevant);
        });
        let pending = 0;
        Array.isArray(allSourceReport) && allSourceReport?.forEach((val) => {
            pending += parseInt(val?.total_confirmation_pending);
        });
        let sentTo = 0;
        Array.isArray(allSourceReport) && allSourceReport?.forEach((val) => {
            sentTo += parseInt(val?.total_sent_to_client);
        });
        setTotalResume(resume);
        setTotalRelevants(relevant);
        setTotalPending(pending);
        setTotalSent(sentTo);
        console.log(resume, relevant, pending, sentTo);
    }, [allSourceReport])

    const handleSourcedView = (e, inDate) => {
        setSourceViewModal(inDate);
    }

    const handleStatus = async (e, statusId) => {

        let data = {
            sourcing_status: status,
            recruiter_id: localStorage.getItem("userId")
        }
        console.log("handleStatus=========>>>>>>", data);
        const options = {
            url: `${basePath}/statuschange/${statusId}`,
            method: "PATCH",
            headers: {
                Accept: "application/json",
            },
            data: data,
        }
        axios(options)
            .then((response) => {
                if (response.status == 200) {
                    setLoading(false);
                    console.log(response);
                    if (
                        filterData?.candidate ||
                        filterData?.company ||
                        filterData?.position ||
                        filterData?.location ||
                        filterData?.relevant ||
                        filterData?.status
                    ) {
                        fetchFilterData();
                    }
                    else if (date) {
                        fetchData();
                    }
                    // setStatus(false);
                    setCandidateRemarks(false);
                    totalSourceData();
                    setConfirmation(false);
                    notify("Candidate Status Updated Successfully");
                    setStatus(false);
                }
                else {
                    setLoading(false);
                    notifyFillTheFields("Unable to post1");
                }
            })
            .catch((e) => {
                setLoading(false);
                console.log(e);
                notifyFillTheFields("There is some network or server issue!! Please try again later.");
            });
        setIsUpdate(false);
    }
    // const validationSchema = Yup.object().shape({
    //     candidate: Yup.string().required('Name is required'),
    //     candidate_email: Yup.string().email('Invalid email').required('Email is required'),
    //     candidate_phone: Yup.string().required('Mobile is required').min(countryPhoneLength, `Mobile must be ${countryPhoneLength} digits`).max(countryPhoneLength, `Mobile must be ${countryPhoneLength} digits`),
    //     candidate_alt_phone: Yup.string().nullable().notRequired().min(countryPhoneLength, `Mobile must be ${countryPhoneLength} digits`).max(countryPhoneLength, `Mobile must be ${countryPhoneLength} digits`),
    //     candidate_location: Yup.string().required('Location is required'),
    //     candidate_qualification: Yup.string().required('Qualification is required'),
    //     candidate_current_ctc: Yup.string().required('Current CTC is required').min(1, `Current CTC must be ${1} digits`).max(5, `Current CTC must be ${5} digits`),
    //     candidate_ectc_type: Yup.string().required('Expected CTC Type is required'),
    //     candidate_expected_ctc: Yup.string().required('Expected CTC is required').min(1, `Expected CTC must be ${1} digits`).max(5, `Expected CTC must be ${5} digits`),
    //     candidate_organization: Yup.string().required('Organization is required'),
    //     candidate_designation: Yup.string().required('Designation is required'),
    //     candidate_experience: Yup.number().required('Experience is required').typeError('Must be a number'),
    //     candidate_notice_period: Yup.string().required('Notice Period is required').min(1, `Notice Period must be ${1} digits`).max(2, `Notice Period must be ${2} digits`),
    //     candidate_gender: Yup.string().required('Gender is required'),
    //     candidate_resume: Yup.mixed().nullable()
    //     .notRequired() // File is optional
    //     .test('fileSize', 'File size must be less than 5MB', (value) => {
    //       return value && value.size <= 5 * 1024 * 1024; // 5MB in bytes
    //     })
    //     .test('fileType', 'Only PDFs are allowed', (value) => {
    //       return value && value.type === 'application/pdf';
    //     }),
    //     // candidate_resume: Yup.mixed().required('Resume is required'),
    // });
    // const handleStatus = async (e, candidateId, status) => {
    //     let today = new Date();
    //     let date = today.getDate();
    //     let month = today.getMonth();
    //     let year = today.getFullYear();
    //     let data = {
    //         candidate_status: status,
    //         status_date: `${year}-${month.toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}`
    //     }
    //     console.log(today, `${year}-${month.toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}`);
    //     const options = {
    //         url: `http://localhost:3002/atsstatus/${candidateId}`,
    //         method: "PATCH",
    //         headers: {
    //             Accept: "application/json",
    //         },
    //         data: data,
    //     }
    //     axios(options)
    //         .then((response) => {
    //             if (response.status == 200) {
    //                 setLoading(false);
    //                 console.log(response);
    //                 // fetchData();
    //                 notify("Candidate Status Updated Successfully");
    //                 console.log("Information entered successfully");
    //             }
    //             else {
    //                 setLoading(false);
    //                 notifyFillTheFields("Unable to Update");
    //             }
    //         })
    //         .catch((e) => {
    //             setLoading(false);
    //             console.log(e);
    //             notifyFillTheFields("Unable to Update");
    //         });
    // }

    // updating candidate personal details
    const validationSchema = Yup.object().shape({
        candidate_name: Yup.string().required('Name is required'),
        candidate_email: Yup.string().email('Invalid email').required('Email is required'),
        candidate_phone: Yup.string()
            .required('Mobile is required')
            .min(countryPhoneLength, `Mobile must be ${countryPhoneLength} digits`)
            .max(countryPhoneLength, `Mobile must be ${countryPhoneLength} digits`),
        candidate_alt_phone: Yup.string()
            .nullable()
            .notRequired()
            .min(countryPhoneLength, `Mobile must be ${countryPhoneLength} digits`)
            .max(countryPhoneLength, `Mobile must be ${countryPhoneLength} digits`),
        candidate_location: Yup.string().required('Location is required'),
        candidate_qualification: Yup.string().required('Qualification is required'),
        candidate_current_ctc: Yup.string()
            .required('Current CTC is required')
            .min(1, `Current CTC must be ${1} digits`)
            .max(5, `Current CTC must be ${5} digits`),
        candidate_ectc_type: Yup.string().required('Expected CTC Type is required'),
        candidate_expected_ctc: Yup.string()
            .required('Expected CTC is required')
            .min(1, `Expected CTC must be ${1} digits`)
            .max(5, `Expected CTC must be ${5} digits`),
        candidate_organization: Yup.string().required('Organization is required'),
        candidate_designation: Yup.string().required('Designation is required'),
        candidate_experience: Yup.number().required('Experience is required').typeError('Must be a number'),
        candidate_notice_period: Yup.string()
            .required('Notice Period is required')
            .min(1, `Notice Period must be ${1} digits`)
            .max(2, `Notice Period must be ${2} digits`),
        candidate_gender: Yup.string().required('Gender is required'),
        candidate_resume: Yup.mixed()
            .nullable()
            .notRequired()
            .test('fileSize', 'File size must be less than 5MB', (value) => {
                return value ? value.size <= 5 * 1024 * 1024 : true;
            })
            .test('fileType', 'Only PDFs are allowed', (value) => {
                return value ? value.type === 'application/pdf' : true;
            }),
    });

    const handleCandidateEdit = (values) => {
        console.log("=========>>>>>>values", values, candidate, candidateId);
        setLoadingUpdate(true);
        let data = {
            candidate: values?.candidate_name,
            candidate_email: values?.candidate_email,
            candidate_phone: `${countryPhone}-${values?.candidate_phone}`,
            candidate_alt_phone: `${countryPhone}-${values?.candidate_alt_phone ? values?.candidate_alt_phone:""}`,
            candidate_location: values?.candidate_location,
            candidate_experience: `${values?.candidate_experience} Yrs.`,
            candidate_current_ctc: `${values?.candidate_current_ctc} ${"LPA"}`,
            candidate_expected_ctc: `${values?.candidate_expected_ctc} ${values?.candidate_ectc_type === "%" ? `${values?.candidate_ectc_type} Hike` : values?.candidate_ectc_type}`,
            candidate_organization: values?.candidate_organization,
            candidate_designation: values?.candidate_designation,
            candidate_notice_period: `${values?.candidate_notice_period} Days`,
            candidate_qualification: values?.candidate_qualification,
            candidate_gender: values?.candidate_gender,
            candidate_remarks: values?.candidate_remarks,
            candidate_resume: values?.candidate_resume,
            recruiter_id: localStorage.getItem("userId"),
            position_id: values?.position_id
        }

        console.log("========>>>>data", data);

        const options = {
            url: `${basePath}/editatspipeline/${candidateId}`,
            method: "PATCH",
            headers: {
                Accept: "application/json",
                'Content-Type': 'multipart/form-data',
            },
            data: data,
        }
        axios(options)
            .then((response) => {
                if (response.status == 200) {
                    setLoadingUpdate(false);
                    console.log(response);
                    // fetchFilterData();
                    setCandidate({
                        candidate_email: "",
                        candidate_phone: "",
                        candidate_alt_phone: "",
                        candidate_location: "",
                        candidate_experience: "",
                        candidate_current_ctc: "",
                        candidate_expected_ctc: "",
                        candidate_organization: "",
                        candidate_designation: "",
                        candidate_notice_period: "",
                        candidate_qualification: "",
                        candidate_gender: "",
                        candidate_remarks: "",
                        candidate_resume: "",
                    });
                    setViewCandidate(false);

                    if (!confirmation) {
                        notify("Candidate info Updated Successfully");
                    }
                    // setConfirmation(false);
                    console.log("Information entered successfully");

                }
                else {
                    setLoadingUpdate(false);
                    notifyFillTheFields("There is some network or server issue!! Please try again later.");
                }
            })
            .catch((error) => {
                setLoadingUpdate(false);
                console.log(error);
                if (error?.status === 404) {
                    notifyFillTheFields(error?.response?.data?.error);
                }
                else {
                    notifyFillTheFields("There is some network or server issue!! Please try again later.");
                }

            });

        handleStatus("e", candidateId);
    }

    const handleDelete = (id) => {
        setLoadingDelete(true);
        const options = {
            url: `${basePath}/deleteCandidate/${id}`,
            method: "DELETE",
            headers: {
                Accept: "application/json",
            },
        }
        axios(options)
            .then((response) => {
                if (response.status == 200) {
                    setLoadingDelete(false);
                    if (
                        filterData?.candidate ||
                        filterData?.company ||
                        filterData?.position ||
                        filterData?.location ||
                        filterData?.relevant ||
                        filterData?.status
                    ) {
                        fetchFilterData();
                    }
                    else if (date) {
                        fetchData();
                    }
                    setConfirmationDelete(false);

                    notify("Candidate Deleted Successfully");
                    console.log("Information Deleted successfully");
                }
                else {
                    setLoadingDelete(false);
                    notifyFillTheFields("Unable to Delete");
                }
            })
            .catch((e) => {
                setLoadingDelete(false);
                notifyFillTheFields("There is some network or server issue!! Please try again later.");
            });

    }

    // handling edit button 
    const handleEdit = (e, idx, id, positionId) => {
        console.log("allReports[idx]?.candidate ", allReports[idx],);
        setCandidate({
            candidate_id: id,
            candidate_name: allReports[idx]?.candidate,
            candidate_email: "",
            candidate_phone: "",
            candidate_alt_phone: "",
            candidate_location: "",
            candidate_experience: "",
            candidate_current_ctc: "",
            candidate_expected_ctc: "",
            candidate_organization: "",
            candidate_designation: "",
            candidate_notice_period: "",
            candidate_qualification: "",
            candidate_gender: "",
            candidate_remarks: "",
            candidate_resume: "",
            position_id: positionId
        });
        setViewCandidate(true);
    }
    const editCandidate = (values) => {
        // let exp = `${values?.candidate_experience}`;
        // let cctc = `${values?.candidate_current_ctc}`;
        // let ectc = `${values?.candidate_expected_ctc}`;
        // let nPeriod = `${values?.candidate_notice_period}`;
        // if (
        //     values?.candidate_email.includes("@") &&
        //     values?.candidate_phone?.length === countryPhoneLength &&
        //     values?.candidate_location &&
        //     exp?.length >= 1 &&
        //     exp?.length <= 4 &&
        //     cctc?.length >= 1 &&
        //     cctc?.length <= 5 &&
        //     ectc?.length >= 1 &&
        //     ectc?.length <= 5 &&
        //     values?.candidate_organization &&
        //     values?.candidate_designation &&
        //     nPeriod?.length >= 1 &&
        //     nPeriod?.length <= 2 &&
        //     values?.candidate_qualification &&
        //     values?.candidate_gender
        // ) {
            let data = {
                candidate: values?.candidate_name,
                candidate_email: values?.candidate_email,
                candidate_phone: `${countryPhone}-${values?.candidate_phone}`,
                candidate_alt_phone: `${countryPhone}-${values?.candidate_alt_phone ? values?.candidate_alt_phone:""}`,
                candidate_location: values?.candidate_location,
                candidate_experience: `${values?.candidate_experience} Yrs.`,
                candidate_current_ctc: `${values?.candidate_current_ctc} ${"LPA"}`,
                candidate_expected_ctc: `${values?.candidate_expected_ctc} ${values?.candidate_ectc_type === "%" ? `${values?.candidate_ectc_type} Hike` : values?.candidate_ectc_type}`,
                candidate_organization: values?.candidate_organization,
                candidate_designation: values?.candidate_designation,
                candidate_notice_period: `${values?.candidate_notice_period} Days`,
                candidate_qualification: values?.candidate_qualification,
                candidate_gender: values?.candidate_gender,
                candidate_remarks: values?.candidate_remarks,
                candidate_resume: values?.candidate_resume,
                recruiter_id: localStorage.getItem("userId")
            }
            console.log("===>>>>editCandidate", data);
            setLoadingUpdate(true);
            const options = {
                url: `${basePath}/editatspipeline/${viewCandidateEdit}`,
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    'Content-Type': 'multipart/form-data',
                },
                data: data,
            }
            axios(options)
                .then((response) => {
                    if (response.status == 200) {

                        console.log(response);
                        if (
                            !filterData?.candidate &&
                            !filterData?.company &&
                            !filterData?.position &&
                            !filterData?.location &&
                            !filterData?.relevant &&
                            !filterData?.status
                        ) {
                            fetchData();
                        }
                        else {
                            fetchFilterData();
                        }
                        setCandidateInfo({
                            candidate_email: "",
                            candidate_phone: "",
                            candidate_alt_phone: "",
                            candidate_location: "",
                            candidate_experience: "",
                            candidate_current_ctc: "",
                            candidate_expected_ctc: "",
                            candidate_organization: "",
                            candidate_designation: "",
                            candidate_notice_period: "",
                            candidate_qualification: "",
                            candidate_gender: "",
                            candidate_remarks: "",
                            candidate_resume: "",
                        });
                        setCandidateEdit(false);
                        setLoadingUpdate(false);
                        notify("Updated Successfully");
                        console.log("Information entered successfully");
                    }
                    else {
                        setLoadingUpdate(false);
                        notifyFillTheFields("Unable to Update");
                    }
                })
                .catch((e) => {
                    setLoadingUpdate(false);
                    console.log(e);
                    notifyFillTheFields("There is some network or server issue!! Please try again later.");
                });
        

    }

    // handling edit button 
    const candidateEditInfo = (e, idx) => {
        setCtcType(allReports[idx]?.candidate_expected_ctc?.split(" ")[1]);
        setCandidateInfo({
            candidate: allReports[idx]?.candidate,
            candidate_email: allReports[idx]?.candidate_email,
            candidate_phone: allReports[idx]?.candidate_phone?.split("-")[1],
            candidate_alt_phone: allReports[idx]?.candidate_alt_phone?.split("-")[1],
            candidate_location: allReports[idx]?.candidate_location,
            candidate_experience: allReports[idx]?.candidate_experience?.split(" ")[0],
            candidate_current_ctc: allReports[idx]?.candidate_current_ctc?.split(" ")[0],
            candidate_expected_ctc: allReports[idx]?.candidate_expected_ctc?.split(" ")[0],
            candidate_organization: allReports[idx]?.candidate_organization,
            candidate_designation: allReports[idx]?.candidate_designation,
            candidate_notice_period: allReports[idx]?.candidate_notice_period?.split(" ")[0],
            candidate_qualification: allReports[idx]?.candidate_qualification,
            candidate_gender: allReports[idx]?.candidate_gender,
            candidate_remarks: allReports[idx]?.candidate_remarks,
            candidate_resume: allReports[idx]?.candidate_resume,
        });
        setCandidateEdit(true);
    }
    const handleClose = (e, idx) => {
        if (
            candidate?.candidate_email &&
            candidate?.candidate_phone &&
            candidate?.candidate_alt_phone &&
            candidate?.candidate_location &&
            candidate?.candidate_experience &&
            candidate?.candidate_current_ctc &&
            candidate?.candidate_expected_ctc &&
            candidate?.candidate_organization &&
            candidate?.candidate_designation &&
            candidate?.candidate_notice_period &&
            candidate?.candidate_qualification &&
            candidate?.candidate_gender
        ) {
            setViewCandidate(false);
        }
        else {
            setStatus("Confirmation Pending");
            setViewCandidate(false);
        }
    }

    const handleCandidateSubmit = (values, idx) => {
        console.log("values=======>>>>>>", values);
        let newRow = [...sourceReportData];
        newRow[idx].candidate = values?.candidate_name;
        newRow[idx].candidate_email = values?.candidate_email;
        newRow[idx].candidate_phone = `${countryPhone}-${values?.candidate_phone}`;
        newRow[idx].candidate_alt_phone = `${countryPhone}-${values?.candidate_alt_phone ? values?.candidate_alt_phone:""}`;
        newRow[idx].candidate_location = values?.candidate_location;
        newRow[idx].candidate_qualification = values?.candidate_qualification;
        newRow[idx].candidate_current_ctc = `${values?.candidate_current_ctc} LPA`;
        newRow[idx].candidate_expected_ctc = `${values?.candidate_expected_ctc} ${values?.candidate_ectc_type === "%" ? `${values?.candidate_ectc_type} Hike` : values?.candidate_ectc_type}`;
        newRow[idx].candidate_organization = values?.candidate_organization;
        newRow[idx].candidate_designation = values?.candidate_designation;
        newRow[idx].candidate_experience = `${values?.candidate_experience} Yrs.`;
        newRow[idx].candidate_notice_period = `${values?.candidate_notice_period} Days`;
        newRow[idx].candidate_gender = values?.candidate_gender;
        newRow[idx].candidate_resume = values?.candidate_resume;
        newRow[idx].candidate_remarks = values?.candidate_remarks;
        setSourceReportData(newRow);
        setViewCandidate(false);
        setCandidateHasInfo(true);


    }
    const handleCloseCandidateSubmit = (e, idx) => {
        console.log("idx=======>>>>>>", idx);
        if (sourceReportData[idx].candidate_email.includes("@") &&
            sourceReportData[idx].candidate_phone &&
            sourceReportData[idx].candidate_location &&
            sourceReportData[idx].candidate_experience &&
            sourceReportData[idx].candidate_current_ctc &&
            sourceReportData[idx].candidate_expected_ctc &&
            sourceReportData[idx].candidate_qualification &&
            sourceReportData[idx].candidate_gender &&
            sourceReportData[idx].candidate_resume
        ) {
            setViewCandidate(false);
            setCandidateHasInfo(true);
        }
        else {
            setViewCandidate(false);
            let newRow = [...sourceReportData];
            newRow[idx].sourcing_status = "Confirmation Pending";
            setSourceReportData(newRow);
            // notifyFillTheFields("Fill required fields!!")
        }
    }

    // filter all posted jobs
    const filterJobs = async () => {
        try {
            setLoading(true);
            let response = await axios.get(`${basePath}/getjobBypage?limit=${limit}&page=${page}&filter=${JSON.stringify(filterData)}`);
            console.log("jobs Filter==>>", response);
            // setAllJobs(response?.data?.data);
            // setTotalRecords(response?.data?.totalRecords);
            // setAllPages(response?.data?.pages);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    const handleFilter = debounce(() => {
        if (
            filterData?.candidate ||
            filterData?.company ||
            filterData?.position ||
            filterData?.location ||
            filterData?.relevant ||
            filterData?.status
        ) {
            filterJobs();
        }
    }, 800);



    console.log("====>");
    console.log(allReports);
    console.log(candidate);

    console.log(viewCandidateEdit);
    // console.log("====>");
    // console.log(sourceRow);

    return (
        <section className='  w-full'>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
            <div className=' absolute bottom-3 right-4'>
                <div onClick={totalSourceData} className='cursor-pointer bg-red-600 px-2.5 py-2 rounded-full hoverRed'>
                    <FontAwesomeIcon icon={faRotateRight} size={"lg"} style={{ color: "#ffffff", }} />
                </div>
            </div>
            <div className='babyGreen smoothTransition textDarkPurple px-10 rounded-3xl welcomeBarHeight flex items-center justify-between navAnimation '>
                <div className=' text-left'>
                    <h1 className=' text-5xl font-bold'>Daily Sourcing Update</h1>
                    <p className=' text-gray-500 font-semibold'>Ready to start your day</p>
                </div>
                <img className=' w-44' src="/img/master.png" alt="" />
            </div>
            <div className=' '>

                <div className=' flex flex-wrap items-center gap-3 grayBlurShadow bg-white p-3 mt-5 rounded-xl'>
                    <div className='flex items-center gap-3'>
                        {
                            user[index]?.add_access ?
                                <div className='   w-fit mx-3 my-4'>
                                    <div onClick={(e) => {
                                        setSourceModal(true); setCandidateHasInfo(false); setSourceReportData(
                                            [{
                                                sourcing_date: "",
                                                candidate: "",
                                                company: "",
                                                position: "",
                                                location: "",
                                                min_ctc: "",
                                                max_ctc: "",
                                                cv_sourced_from: "",
                                                relevant: "",
                                                sourcing_status: "",
                                                remarks: "",
                                                candidate_email: "",
                                                candidate_phone: "",
                                                candidate_alt_phone: "",
                                                candidate_location: "",
                                                candidate_experience: "",
                                                candidate_current_ctc: "",
                                                candidate_expected_ctc: "",
                                                candidate_organization: "",
                                                candidate_designation: "",
                                                candidate_notice_period: "",
                                                candidate_qualification: "",
                                                candidate_gender: "",
                                                candidate_remarks: "",
                                                candidate_resume: "",
                                            },]);

                                    }} className='darkPurple cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'><img className=' w-8 ' src="/img/addBtn.png" alt="" /> <button>Add Sourcing Updates</button></div>
                                </div> : null
                        }
                        <div className=' flex flex-col justify-start items-start'>
                            <label>From: </label>
                            <input
                                className=' border-2 rounded-lg px-2 py-1'
                                type="date"
                                value={dateFilter?.fromDate}
                                onChange={e => setDateFilter({ ...dateFilter, fromDate: e.target.value })}
                            />
                        </div>
                        <div className=' flex flex-col justify-start items-start'>
                            <label>To: </label>
                            <input
                                className=' border-2 rounded-lg px-2 py-1'
                                type="date"
                                value={dateFilter?.toDate}
                                onChange={e => setDateFilter({ ...dateFilter, toDate: e.target.value })}
                            />
                        </div>
                    </div>
                    <button onClick={handleDateFilter} className=' darkPurple cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>Apply filter</button>
                    <button onClick={() => {
                        setDateFilter({
                            fromDate: "",
                            toDate: "",
                        })
                        totalSourceData();
                    }} className=' text-red-700 bg-red-200  py-2 px-3 rounded-lg hoverRed'>Clear FIlter</button>
                    <div className=' text-left w-fit'>
                        <button onClick={handleCalculateExport} className=' py-2 px-3 bg-green-800 text-white rounded-lg '>{loadingExport ? "Exporting..." : "Excel"}</button>
                    </div>
                </div>
            </div>
            {confirmation ?
                <div onClick={(e) => { }} className=' w-full h-screen fixed left-0 top-0 z-50 flex items-center justify-center blurBack'>
                    <div onClick={(e) => { e.stopPropagation(); }} className='relative   bg-white overflow-auto mx-10 mb-5 py-5 px-8 rounded-2xl navAmination '>
                        <div>
                            <h1 className=' text-blue-500 font-semibold pb-5'>
                                Are you sure to change status to
                                <span className='text-blue-900 font-semibold'>" {status} "</span> ?
                            </h1>
                            {status === "Rejected" ?
                                <div>
                                    <h2 className=' text-left'>Candidate Name: <span className='text-blue-900 font-semibold'>{allReports[parseInt(confirmation)]?.candidate}</span></h2>
                                    <h2 className=' text-left'>Candidate Remarks:</h2>
                                    <textarea onChange={(e) => { setCandidateRemarks(e.target.value); }} name="candidate_remarks" className=' outline-none border-2 rounded-lg px-2 w-full'></textarea>
                                </div> : null}
                            <div className=' flex gap-4 items-center justify-center'>
                                <button
                                    className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed'
                                    onClick={(e) => {
                                        setStatus("Confirmation Pending");
                                        setCandidateId("");
                                        setIsUpdate("");
                                        setConfirmation(false);
                                    }}>Cancel</button>
                                <button
                                    className='darkPurple text-white font-semibold rounded-xl px-4 py-1 hoverGreen'
                                    onClick={(e) => {
                                        let allValues = {
                                            candidate: null,
                                            candidate_email: null,
                                            candidate_phone: null,
                                            candidate_alt_phone: null,
                                            candidate_location: null,
                                            candidate_qualification: null,
                                            candidate_current_ctc: null,
                                            candidate_ectc_type: null,
                                            candidate_expected_ctc: null,
                                            candidate_organization: null,
                                            candidate_designation: null,
                                            candidate_experience: null,
                                            candidate_notice_period: null,
                                            candidate_gender: null,
                                            candidate_resume: null,
                                            candidate_remarks: candidateRemarks,
                                            position_id: parseInt(confirmation)?.id || ""
                                        }
                                        if (allReports[parseInt(confirmation)]?.id && status === "Rejected") {
                                            if (candidateRemarks) {
                                                handleCandidateEdit(allValues);
                                                // handleStatus(e, allReports[parseInt(confirmation)]?.id);
                                            }
                                            else {
                                                notifyFillTheFields(`Please add remark!!`);
                                            }
                                        }
                                        else if (allReports[parseInt(confirmation)]?.id && status) {
                                            handleStatus(e, allReports[parseInt(confirmation)]?.id);
                                        }
                                        else {
                                            notifyFillTheFields(`${candidateId} - ${status}`);
                                        }
                                    }}
                                >Save</button>
                            </div>
                        </div>
                    </div>
                </div> : null
            }
            {confirmationDelete ?
                <div onClick={(e) => { }} className=' w-full h-screen fixed left-0 top-0 z-50 flex items-center justify-center blurBack'>
                    <div onClick={(e) => { e.stopPropagation(); }} className='relative   bg-white overflow-auto mx-10 mb-5 py-5 px-8 rounded-2xl navAmination '>
                        <div>
                            <h1 className=' text-blue-500 font-semibold pb-5'>
                                Are you sure to delete candidate
                                <span className='text-blue-900 font-semibold'>" {allReports[parseInt(confirmationDelete)]?.candidate} "</span> ?
                            </h1>
                            <div className=' flex gap-4 items-center justify-center'>
                                <button
                                    className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed'
                                    onClick={(e) => {
                                        setCandidateId("");
                                        setIsUpdate("");
                                        setConfirmationDelete(false);
                                    }}>Cancel</button>
                                <button
                                    className='darkPurple text-white font-semibold rounded-xl px-4 py-1 hoverGreen'
                                    onClick={(e) => {
                                        if (candidateId) {
                                            handleDelete(candidateId);
                                        }
                                    }}
                                >Delete</button>
                            </div>
                        </div>
                    </div>
                </div> : null
            }
            {viewCandidateEdit ?
                <div onClick={(e) => { }} className=' w-full h-screen fixed left-0 top-0 z-50 flex items-center justify-center blurBack'>
                    <div onClick={(e) => { e.stopPropagation(); }} className=' bg-white overflow-auto mx-10 mb-5 p-10 rounded-3xl candidateInfoBox'>
                        {Array.isArray(allReports) && allReports?.map((val, idx) => {
                            return (
                                <>
                                    {viewCandidateEdit === val?.id ?
                                        <>
                                            {!candidateEdit ?
                                                <div onClick={(e) => { }} className=' w-full h-screen fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                                                    <div onClick={(e) => { e.stopPropagation(); }} className='relative w-[70vw]  bg-white overflow-auto mx-10 mb-5 py-5 px-8 rounded-2xl '>
                                                        <button onClick={(e) => { setViewCandidateEdit(false); setCandidateEdit(false); }} className="absolute bg-red-600 px-1.5 py-0.5    rounded-full w-fit top-1 right-1 z-[11] ">
                                                            <FontAwesomeIcon icon={faXmark} size='xl' style={{ color: "#ffffff", }} />
                                                        </button>
                                                        <h1 className='text-center text-xl font-semibold py-2 text-blue-950'>Candidate Imformation</h1>
                                                        <div className=' text-left flex flex-col gap-x-2 gap-y-2 max-h-[70vh] overflow-y-scroll'>
                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Name</h2>
                                                                    <input
                                                                        value={val?.candidate}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' autoFocus type="text" placeholder="Name*" />
                                                                </div>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Email</h2>
                                                                    <input value={val?.candidate_email}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' autoFocus type="email" placeholder='Email*' />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className=' grid grid-cols-2 gap-3'>
                                                                    <div className=''>
                                                                        <h2 className=' w-32'>Mobile</h2>
                                                                        <input value={val?.candidate_phone}
                                                                            disabled
                                                                            className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Mobile*' />
                                                                    </div>
                                                                    <div className=''>
                                                                        <h2 className=' w-32'>Alternate Mobile</h2>
                                                                        <input value={val?.candidate_alt_phone}
                                                                            disabled
                                                                            className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Alternate Mobile' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Location</h2>
                                                                    <input value={val?.candidate_location}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Location*' />
                                                                </div>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Qualification</h2>
                                                                    <input value={val?.candidate_qualification}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Qualification*' />
                                                                </div>
                                                            </div>
                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Current CTC</h2>
                                                                    <input value={val?.candidate_current_ctc}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Current CTC*' />
                                                                </div>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Expected CTC</h2>
                                                                    <input value={val?.candidate_expected_ctc}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder="Expected CTC*" />
                                                                </div>
                                                            </div>
                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Organization</h2>
                                                                    <input value={val?.candidate_organization}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Current/Past Organization' />
                                                                </div>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Designation</h2>
                                                                    <input value={val?.candidate_designation}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Current/Past Designation' />
                                                                </div>
                                                            </div>
                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Experience</h2>
                                                                    <input value={val?.candidate_experience}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Experience*' />
                                                                </div>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Notice Period</h2>
                                                                    <input value={val?.candidate_notice_period}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Notice Period*' />
                                                                </div>
                                                            </div>
                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                <div className=''>
                                                                    <h2 className=' w-32'>Gender</h2>
                                                                    <input value={val?.candidate_gender}
                                                                        disabled
                                                                        className=' bg-gray-200 text-blue-700 font-semibold outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Gender*' />
                                                                </div>
                                                                <div>
                                                                    <h2 className=' '>Candidate Resume</h2>
                                                                    <div> {val?.candidate_resume ?
                                                                        !loadingDownload ?
                                                                            <button className=" darkPurple text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2 hoverGreen" onClick={(e) => { downloadButton(val?.id, val?.candidate) }} >Download</button> :
                                                                            <button className=" darkPurple text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2"  >Downloading...</button> :
                                                                        <h2 className=" bg-gray-200 text-red-300 font-semibold outline-none rounded-lg px-2 w-full py-1">Resume Not Uploaded</h2>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className=''>
                                                                <h2 className=''>Remarks</h2>
                                                                <textarea value={val.candidate_remarks}
                                                                    disabled
                                                                    className=' outline-none bg-gray-200 text-blue-700 font-semibold rounded-lg px-2 w-full'></textarea>
                                                            </div>
                                                            <div className='flex items-center justify-center mt-2'>
                                                                <button onClick={(e) => { candidateEditInfo(e, idx) }} className=' text-yellow-600 bg-yellow-200 m-2 px-10 py-1 rounded-lg hoverYellow' >Edit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div onClick={(e) => { e.stopPropagation(); }} className=' w-full h-screen fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                                                    <div onClick={(e) => { e.stopPropagation(); }} className='relative w-[70vw]  bg-white overflow-auto mx-10 mb-5 py-5 px-8 rounded-2xl '>
                                                        {!loadingUpdate ?
                                                            <button onClick={(e) => { setCandidateEdit(false); }} className="absolute bg-white rounded-full w-fit top-1 right-1 z-[11] ">
                                                                <img className='w-7' src="/img/close.png" alt="close" />
                                                            </button> :
                                                            <button className="absolute cursor-not-allowed bg-white rounded-full w-fit top-1 right-1 z-[11] ">
                                                                <img className='w-7' src="/img/close.png" alt="close" />
                                                            </button>
                                                        }
                                                        <h1 className='text-center text-xl font-semibold py-2 text-blue-950'>Candidate Imformation</h1>
                                                        <Formik
                                                            initialValues={{
                                                                candidate_name: candidateInfo?.candidate,
                                                                candidate_email: candidateInfo?.candidate_email || '',
                                                                candidate_phone: candidateInfo?.candidate_phone || '',
                                                                candidate_alt_phone: candidateInfo?.candidate_alt_phone || '',
                                                                candidate_location: candidateInfo?.candidate_location || '',
                                                                candidate_qualification: candidateInfo?.candidate_qualification || '',
                                                                candidate_current_ctc: candidateInfo?.candidate_current_ctc || '',
                                                                candidate_ectc_type: ctcType || "",
                                                                candidate_expected_ctc: candidateInfo?.candidate_expected_ctc || '',
                                                                candidate_organization: candidateInfo?.candidate_organization || '',
                                                                candidate_designation: candidateInfo?.candidate_designation || '',
                                                                candidate_experience: candidateInfo?.candidate_experience || '',
                                                                candidate_notice_period: candidateInfo?.candidate_notice_period || '',
                                                                candidate_gender: candidateInfo?.candidate_gender || '',
                                                                candidate_resume: '',
                                                                candidate_remarks: candidateInfo?.candidate_remarks || '',
                                                            }}
                                                            validationSchema={validationSchema}
                                                            onSubmit={(values) => {
                                                                console.log(values);
                                                                editCandidate(values);

                                                            }}
                                                        >
                                                            {({ values, setFieldValue }) => (
                                                                <Form className='text-left flex flex-col gap-x-2 gap-y-2 max-h-[70vh] overflow-y-scroll'>
                                                                    <div className='grid grid-cols-2 gap-3'>
                                                                        <div>
                                                                            <h2 className='w-32'>Name</h2>
                                                                            <Field
                                                                                name="candidate_name"
                                                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                type="text"
                                                                                placeholder="Name*"
                                                                            />
                                                                            <ErrorMessage name="candidate_name" component="div" className="text-red-500" />
                                                                        </div>
                                                                        <div>
                                                                            <h2 className='w-32'>Email</h2>
                                                                            <Field
                                                                                name="candidate_email"
                                                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                type="email"
                                                                                placeholder='Email*'
                                                                            />
                                                                            <ErrorMessage name="candidate_email" component="div" className="text-red-500" />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className='grid grid-cols-2 gap-3'>
                                                                            <div>
                                                                                <h2 className='w-32'>Mobile</h2>
                                                                                <div className="flex items-center gap-2">
                                                                                    <div className='border-2 rounded-lg flex items-center pl-1 w-[7rem]'>
                                                                                        {
                                                                                            countryPhoneCode.map((val) => {
                                                                                                return val.phoneCode === countryPhone ? <img key={val.isoCode} className="rounded w-[1.5rem]" src={val.flag} alt={val.name} /> : null
                                                                                            })
                                                                                        }
                                                                                        <Field as="select" className="outline-none py-1 font-semibold" name="countryPhone" value={countryPhone} onChange={(e) => {
                                                                                            countryPhoneCode?.forEach((val) => {
                                                                                                if (e.target.value == val.phoneCode) {
                                                                                                    setCountryPhoneLength(val?.phoneNumberLength);
                                                                                                }
                                                                                            })
                                                                                            setCountryPhone(e.target.value);
                                                                                        }} >
                                                                                            {
                                                                                                countryPhoneCode.map((val) => (
                                                                                                    <option key={val.isoCode} value={val.phoneCode}>
                                                                                                        {val.phoneCode}
                                                                                                    </option>
                                                                                                ))
                                                                                            }
                                                                                        </Field>
                                                                                    </div>
                                                                                    <Field
                                                                                        name="candidate_phone"
                                                                                        className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                        type="number"
                                                                                        placeholder='Mobile*'
                                                                                    />
                                                                                </div>
                                                                                <ErrorMessage name="candidate_phone" component="div" className="text-red-500" />
                                                                            </div>
                                                                            <div>
                                                                                <h2 className='w-32'>Alternate Mobile</h2>
                                                                                <div className="flex items-center gap-2">
                                                                                    <div className='border-2 rounded-lg flex items-center pl-1 w-[7rem]'>
                                                                                        {
                                                                                            countryPhoneCode.map((val) => {
                                                                                                return val.phoneCode === countryPhone ? <img key={val.isoCode} className="rounded w-[1.5rem]" src={val.flag} alt={val.name} /> : null
                                                                                            })
                                                                                        }
                                                                                        <Field as="select" className="outline-none py-1 font-semibold" name="countryPhone" value={countryPhone} onChange={(e) => {
                                                                                            countryPhoneCode?.forEach((val) => {
                                                                                                if (e.target.value == val.phoneCode) {
                                                                                                    setCountryPhoneLength(val?.phoneNumberLength);
                                                                                                }
                                                                                            })
                                                                                            setCountryPhone(e.target.value);
                                                                                        }} >
                                                                                            {
                                                                                                countryPhoneCode.map((val) => (
                                                                                                    <option key={val.isoCode} value={val.phoneCode}>
                                                                                                        {val.phoneCode}
                                                                                                    </option>
                                                                                                ))
                                                                                            }
                                                                                        </Field>
                                                                                    </div>
                                                                                    <Field
                                                                                        name="candidate_alt_phone"
                                                                                        className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                        type="number"
                                                                                        placeholder='Alternate Mobile*'
                                                                                    />
                                                                                </div>
                                                                                <ErrorMessage name="candidate_alt_phone" component="div" className="text-red-500" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='grid grid-cols-2 gap-3'>
                                                                        <div>
                                                                            <h2 className='w-32'>Location</h2>
                                                                            <Field
                                                                                name="candidate_location"
                                                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                type="text"
                                                                                placeholder='Location*'
                                                                            />
                                                                            <ErrorMessage name="candidate_location" component="div" className="text-red-500" />
                                                                        </div>
                                                                        <div>
                                                                            <h2 className='w-32'>Qualification</h2>
                                                                            <Field
                                                                                name="candidate_qualification"
                                                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                type="text"
                                                                                placeholder='Qualification*'
                                                                            />
                                                                            <ErrorMessage name="candidate_qualification" component="div" className="text-red-500" />
                                                                        </div>
                                                                    </div>
                                                                    <div className='grid grid-cols-2 gap-3'>
                                                                        <div>
                                                                            <h2 className='w-32'>Current CTC</h2>
                                                                            <Field
                                                                                name="candidate_current_ctc"
                                                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                type="number"
                                                                                placeholder='Current CTC*'
                                                                            />
                                                                            <ErrorMessage name="candidate_current_ctc" component="div" className="text-red-500" />
                                                                        </div>
                                                                        <div>
                                                                            <h2 className='w-32'>Expected CTC</h2>
                                                                            <div className='flex items-center gap-2'>
                                                                                <div className=" flex-1">
                                                                                    <Field as="select" name="candidate_ectc_type"
                                                                                        className='outline-none border-2 rounded-lg px-2 w-fit py-1' required>
                                                                                        <option disabled value="" >Select</option>
                                                                                        <option value="LPA">LPA</option>
                                                                                        <option value="%">%</option>
                                                                                    </Field>
                                                                                    <ErrorMessage name="candidate_ectc_type" component="div" className="text-red-600 text-sm" />
                                                                                </div>

                                                                                {values.candidate_ectc_type === "LPA" ?
                                                                                    <div className="w-full flex-2">
                                                                                        <Field name="candidate_expected_ctc" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="number" placeholder="Expected CTC in LPA*" />
                                                                                        <ErrorMessage name="candidate_expected_ctc" component="div" className="text-red-600 text-sm" />
                                                                                    </div> :
                                                                                    values.candidate_ectc_type === "%" ?
                                                                                        <div className="w-full flex-2">
                                                                                            <Field name="candidate_expected_ctc" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="number" placeholder="Expected CTC In %*" />
                                                                                            <ErrorMessage name="candidate_expected_ctc" component="div" className="text-red-600 text-sm" />
                                                                                        </div> : null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='grid grid-cols-2 gap-3'>
                                                                        <div>
                                                                            <h2 className='w-32'>Organization</h2>
                                                                            <Field
                                                                                name="candidate_organization"
                                                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                type="text"
                                                                                placeholder='Current/Past Organization'
                                                                            />
                                                                            <ErrorMessage name="candidate_organization" component="div" className="text-red-500" />
                                                                        </div>
                                                                        <div>
                                                                            <h2 className='w-32'>Designation</h2>
                                                                            <Field
                                                                                name="candidate_designation"
                                                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                type="text"
                                                                                placeholder='Current/Past Designation'
                                                                            />
                                                                            <ErrorMessage name="candidate_designation" component="div" className="text-red-500" />
                                                                        </div>
                                                                    </div>
                                                                    <div className='grid grid-cols-2 gap-3'>
                                                                        <div>
                                                                            <h2 className='w-32'>Experience</h2>
                                                                            <Field
                                                                                name="candidate_experience"
                                                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                type="number"
                                                                                placeholder='Experience*'
                                                                            />
                                                                            <ErrorMessage name="candidate_experience" component="div" className="text-red-500" />
                                                                        </div>
                                                                        <div className=''>
                                                                            <h2 className='w-32'>Notice Period</h2>
                                                                            <Field name="candidate_notice_period" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="number" placeholder="Notice Period In Days*" />
                                                                            <ErrorMessage name="candidate_notice_period" component="div" className="text-red-500" />
                                                                        </div>
                                                                    </div>
                                                                    <div className='grid grid-cols-2 gap-3'>
                                                                        <div>
                                                                            <h2 className='w-32'>Gender</h2>
                                                                            <Field as="select" name="candidate_gender"
                                                                                className='outline-none border-2 rounded-lg px-2 w-full py-1' required>
                                                                                <option value="" disabled>Gender*</option>
                                                                                <option value="Male">Male</option>
                                                                                <option value="Female">Female</option>
                                                                            </Field>
                                                                            <ErrorMessage name="candidate_gender" component="div" className="text-red-500" />
                                                                        </div>
                                                                        <div>
                                                                            <h2 className='w-32'>Upload Resume*</h2>
                                                                            <input type="file" accept='.pdf' onChange={(event) => {
                                                                                setFieldValue("candidate_resume", event.currentTarget.files[0]);
                                                                            }} />
                                                                            <ErrorMessage name="candidate_resume" component="div" className="text-red-500" />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <h2 className=''>Remarks</h2>
                                                                        <Field as="textarea" name="candidate_remarks"
                                                                            className='outline-none border-2 rounded-lg px-2 w-full'>
                                                                        </Field>
                                                                    </div>
                                                                    <div className='flex items-center mt-5'>
                                                                        {!loadingUpdate ?
                                                                            <> <button onClick={(e) => { setCandidateEdit(false); }} className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed' >Cancel</button>
                                                                                <button type='submit' onClick={() => { }} className='darkPurple text-white font-semibold rounded-xl px-4 py-1 hoverGreen'>Submit</button></> :
                                                                            <> <button className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg ' >Cancel</button>
                                                                                <button className='darkPurple text-white font-semibold rounded-xl px-4 py-1 '>Loading...</button></>
                                                                        }
                                                                    </div>
                                                                </Form>
                                                            )}
                                                        </Formik>

                                                    </div>
                                                </div>
                                            }
                                        </> : null}
                                </>
                            )
                        })
                        }
                    </div>
                </div> : null}
            {sourceModal ?
                <div onClick={(e) => { setSourceModal(false); }} className=' w-full h-screen fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                    <div onClick={(e) => { e.stopPropagation(); }} className=' relative grayBlurShadow bg-white mx-5 mb-10 p-10 rounded-2xl overflow-y-scroll scrollbar-hide navAnimation text-left addSourceTable'>
                        <button onClick={(e) => { setSourceModal(false); }} className="absolute bg-white rounded-full w-fit top-1 right-1 z-[11] ">
                            <img className='w-7' src="/img/close.png" alt="close" />
                        </button>
                        <div>
                            <h1 className=' text-lg font-semibold mb-4'>Sourcing Report For:  <span className=' text-blue-950'>{sourceDate.split("-")[2]}-{monthData[parseInt(sourceDate.split("-")[1])]}-{sourceDate.split("-")[0]}</span></h1>
                        </div>
                        <div className=' overflow-auto sourcingTable '>
                            <table className=' navAnimation rounded-xl baseWidth'>
                                <thead className=' text-lg tableHead'>
                                    <tr className=' bg-gray-200'>
                                        <th>Candidate</th>
                                        <th>Company</th>
                                        <th>Position</th>
                                        <th>Location</th>
                                        <th className='whitespace-nowrap'>Min CTC</th>
                                        <th className='whitespace-nowrap'>Max CTC</th>
                                        <th className='whitespace-nowrap'>CV Sourced From</th>
                                        <th>Relevant</th>
                                        <th>Status</th>
                                        {
                                            candidateHasInfo ?
                                                <th className='whitespace-nowrap'>Candidate Info</th> : null
                                        }

                                        <th>Candidate Remarks</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className='tableBody '>
                                    {sourceReportData?.map((val, index) => {
                                        return (
                                            <tr key={index} className='relative backWhite font-semibold text-sm'>
                                                <td>
                                                    <input value={sourceReportData[index].candidate} onChange={(e) => {
                                                        let newRow = [...sourceReportData];
                                                        newRow[index].candidate = e.target.value;
                                                        setSourceReportData(newRow);
                                                    }} type="text" />
                                                </td>
                                                <td>
                                                    <select value={sourceReportData[index].company} ref={companyRef}
                                                        onChange={(e) => {
                                                            let newRow = [...sourceReportData];
                                                            newRow[index].sourcing_date = sourceDate;
                                                            newRow[index].company = e.target.value;
                                                            newRow[index].position = "";
                                                            newRow[index].location = "";
                                                            newRow[index].min_ctc = "";
                                                            newRow[index].max_ctc = "";
                                                            newRow[index].cv_sourced_from = "";
                                                            newRow[index].relevant = "";
                                                            newRow[index].sourcing_status = "";
                                                            newRow[index].remarks = "";
                                                            setSourceReportData(newRow);
                                                            fetchPositions(e, e.target.value);
                                                        }} className=' border-2 rounded-lg text-center'>
                                                        <option value="" selected disabled>company</option>
                                                        {
                                                            allCompany?.map((val) => {
                                                                return <option value={val?.id}>{val?.company_name}</option>
                                                            })
                                                        }
                                                    </select>
                                                </td>
                                                <td >
                                                    <select value={sourceReportData[index].position} ref={positionRef}
                                                        onChange={(e) => { handlePosition(e, index) }} className=' border-2 rounded-lg text-center '>
                                                        <option value="" selected disabled>position</option>
                                                        {
                                                            allPositions?.map((val) => {
                                                                return (val?.company_id == sourceReportData[index].company ?
                                                                    <option value={val?.id}>{val?.position}</option> : null)
                                                            })
                                                        }
                                                    </select>
                                                </td>
                                                <td>
                                                    <input value={sourceReportData[index].location} disabled type="text" />
                                                </td>
                                                <td >
                                                    <input value={sourceReportData[index].min_ctc} disabled type="text" />
                                                </td>
                                                <td >
                                                    <input value={sourceReportData[index].max_ctc} disabled type="text" />
                                                </td>
                                                <td>
                                                    <input value={sourceReportData[index].cv_sourced_from} onChange={(e) => {
                                                        let newRow = [...sourceReportData];
                                                        newRow[index].cv_sourced_from = e.target.value;
                                                        setSourceReportData(newRow);
                                                    }} type="text" />
                                                </td>
                                                <td>
                                                    <select value={sourceReportData[index].relevant} onChange={(e) => {
                                                        let newRow = [...sourceReportData];
                                                        newRow[index].relevant = e.target.value;
                                                        setSourceReportData(newRow);
                                                    }} className=' border-2 rounded-lg text-center'>
                                                        <option value="" disabled selected > Select</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <select value={sourceReportData[index].sourcing_status} onChange={(e) => {
                                                        let newRow = [...sourceReportData];
                                                        newRow[index].sourcing_status = e.target.value;
                                                        if (e.target.value === "Screened") {
                                                            setViewCandidate(index);
                                                        }
                                                        setSourceReportData(newRow);
                                                    }} className=' border-2 rounded-lg text-center'>
                                                        <option value="" disabled selected > Select</option>
                                                        <option value="Rejected">Rejected</option>
                                                        <option value="Confirmation Pending">Confirmation Pending</option>
                                                        <option value="Screened">Screened</option>
                                                    </select>
                                                </td>
                                                {
                                                    candidateHasInfo ?
                                                        sourceReportData[index].sourcing_status === "Screened" ?
                                                            <td className=' text-center'>
                                                                <button onClick={(e) => { setViewCandidate(index); }} className=' darkPurple text-white font-semibold rounded-xl px-4 text-sm py-1 hoverGreen'>View</button>
                                                            </td> :
                                                            <td className=' text-center'>
                                                                <button onClick={(e) => { }} className=' bg-gray-200 cursor-not-allowed text-gray-400 font-semibold rounded-xl px-4 text-sm py-1 '>View</button>
                                                            </td> : null
                                                }
                                                {
                                                    sourceReportData[index].sourcing_status === "Screened" && viewCandidate === index ?
                                                        <div onClick={(e) => { e.stopPropagation(); }} className=' w-full h-screen fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                                                            <div onClick={(e) => { e.stopPropagation(); }} className='relative w-[70vw]  bg-white overflow-auto mx-10 mb-5 py-5 px-8 rounded-2xl '>
                                                                <Formik
                                                                    initialValues={{
                                                                        candidate_name: sourceReportData[index]?.candidate || '',
                                                                        candidate_email: sourceReportData[index]?.candidate_email || '',
                                                                        candidate_phone: sourceReportData[index]?.candidate_phone?.split("-")[1] || '',
                                                                        candidate_alt_phone: sourceReportData[index]?.candidate_alt_phone?.split("-")[1] || '',
                                                                        candidate_location: sourceReportData[index]?.candidate_location || '',
                                                                        candidate_qualification: sourceReportData[index]?.candidate_qualification || '',
                                                                        candidate_current_ctc: sourceReportData[index]?.candidate_current_ctc?.split(" ")[0] || '',
                                                                        candidate_ectc_type: sourceReportData[index]?.candidate_expected_ctc?.split(" ")[1] || '',
                                                                        candidate_expected_ctc: sourceReportData[index]?.candidate_expected_ctc?.split(" ")[0] || '',
                                                                        candidate_organization: sourceReportData[index]?.candidate_organization || '',
                                                                        candidate_designation: sourceReportData[index]?.candidate_designation || '',
                                                                        candidate_experience: sourceReportData[index]?.candidate_experience?.split(" ")[0] || '',
                                                                        candidate_notice_period: sourceReportData[index]?.candidate_notice_period?.split(" ")[0] || '',
                                                                        candidate_gender: sourceReportData[index]?.candidate_gender || '',
                                                                        candidate_remarks: sourceReportData[index]?.candidate_remarks || '',
                                                                        candidate_resume: null
                                                                    }}
                                                                    validationSchema={validationSchema}
                                                                    onSubmit={(values) => {
                                                                        console.log(values);
                                                                        handleCandidateSubmit(values, index);
                                                                    }}
                                                                >
                                                                    {({ values, setFieldValue }) => (
                                                                        <Form className=' w-full bg-white'>
                                                                            <button
                                                                                type="button"
                                                                                onClick={(e) => { handleCloseCandidateSubmit(e, index) }}
                                                                                className="absolute bg-white rounded-full w-fit top-1 right-1 z-[11]"
                                                                            >
                                                                                <img className='w-7' src="/img/close.png" alt="close" />
                                                                            </button>
                                                                            <h1 className='text-center text-xl font-semibold py-2 text-blue-950'>Candidate Information</h1>
                                                                            <div className='text-left flex flex-col gap-x-2 gap-y-4 max-h-[70vh] overflow-y-scroll'>
                                                                                <div className='grid grid-cols-2 gap-3'>
                                                                                    <div className=''>
                                                                                        <h2 className='w-32'>Name</h2>
                                                                                        <Field name="candidate_name" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="text" placeholder="Name*" />
                                                                                        <ErrorMessage name="candidate_name" component="div" className="text-red-600 text-sm" />
                                                                                    </div>
                                                                                    <div className=''>
                                                                                        <h2 className='w-32'>Email</h2>
                                                                                        <Field name="candidate_email" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="email" placeholder='Email*' />
                                                                                        <ErrorMessage name="candidate_email" component="div" className="text-red-600 text-sm" />
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div className='grid grid-cols-2 gap-3'>
                                                                                        <div>
                                                                                            <h2 className='w-32'>Mobile</h2>
                                                                                            <div className="flex items-center gap-2">
                                                                                                <div className='border-2 rounded-lg flex items-center pl-1 w-[7rem]'>
                                                                                                    {
                                                                                                        countryPhoneCode.map((val) => {
                                                                                                            return val.phoneCode === countryPhone ? <img key={val.isoCode} className="rounded w-[1.5rem]" src={val.flag} alt={val.name} /> : null
                                                                                                        })
                                                                                                    }
                                                                                                    <Field as="select" className="outline-none py-1 font-semibold" name="countryPhone" value={countryPhone} onChange={(e) => {
                                                                                                        countryPhoneCode?.forEach((val) => {
                                                                                                            if (e.target.value == val.phoneCode) {
                                                                                                                setCountryPhoneLength(val?.phoneNumberLength);
                                                                                                            }
                                                                                                        })
                                                                                                        setCountryPhone(e.target.value);
                                                                                                    }} >
                                                                                                        {
                                                                                                            countryPhoneCode.map((val) => (
                                                                                                                <option key={val.isoCode} value={val.phoneCode}>
                                                                                                                    {val.phoneCode}
                                                                                                                </option>
                                                                                                            ))
                                                                                                        }
                                                                                                    </Field>
                                                                                                </div>
                                                                                                <Field
                                                                                                    name="candidate_phone"
                                                                                                    className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                                    type="number"
                                                                                                    placeholder='Mobile*'
                                                                                                />
                                                                                            </div>
                                                                                            <ErrorMessage name="candidate_phone" component="div" className="text-red-500" />
                                                                                        </div>
                                                                                        <div>
                                                                                            <h2 className='w-32'>Alternate Mobile</h2>
                                                                                            <div className="flex items-center gap-2">
                                                                                                <div className='border-2 rounded-lg flex items-center pl-1 w-[7rem]'>
                                                                                                    {
                                                                                                        countryPhoneCode.map((val) => {
                                                                                                            return val.phoneCode === countryPhone ? <img key={val.isoCode} className="rounded w-[1.5rem]" src={val.flag} alt={val.name} /> : null
                                                                                                        })
                                                                                                    }
                                                                                                    <Field as="select" className="outline-none py-1 font-semibold" name="countryPhone" value={countryPhone} onChange={(e) => {
                                                                                                        countryPhoneCode?.forEach((val) => {
                                                                                                            if (e.target.value == val.phoneCode) {
                                                                                                                setCountryPhoneLength(val?.phoneNumberLength);
                                                                                                            }
                                                                                                        })
                                                                                                        setCountryPhone(e.target.value);
                                                                                                    }} >
                                                                                                        {
                                                                                                            countryPhoneCode.map((val) => (
                                                                                                                <option key={val.isoCode} value={val.phoneCode}>
                                                                                                                    {val.phoneCode}
                                                                                                                </option>
                                                                                                            ))
                                                                                                        }
                                                                                                    </Field>
                                                                                                </div>
                                                                                                <Field
                                                                                                    name="candidate_alt_phone"
                                                                                                    className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                                    type="number"
                                                                                                    placeholder='Alternate Mobile*'
                                                                                                />
                                                                                            </div>
                                                                                            <ErrorMessage name="candidate_alt_phone" component="div" className="text-red-500" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='grid grid-cols-2 gap-3'>
                                                                                    <div className=''>
                                                                                        <h2 className='w-32'>Location</h2>
                                                                                        <Field name="candidate_location" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Location*' />
                                                                                        <ErrorMessage name="candidate_location" component="div" className="text-red-600 text-sm" />
                                                                                    </div>
                                                                                    <div className=''>
                                                                                        <h2 className='w-32'>Qualification</h2>
                                                                                        <Field name="candidate_qualification" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Qualification*' />
                                                                                        <ErrorMessage name="candidate_qualification" component="div" className="text-red-600 text-sm" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='grid grid-cols-2 gap-3'>
                                                                                    <div className=''>
                                                                                        <h2 className='w-32'>Current CTC</h2>
                                                                                        <Field name="candidate_current_ctc" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="number" placeholder='Current CTC*' />
                                                                                        <ErrorMessage name="candidate_current_ctc" component="div" className="text-red-600 text-sm" />
                                                                                    </div>
                                                                                    <div className=''>
                                                                                        <h2 className='w-32'>Expected CTC</h2>
                                                                                        <div className='flex items-center gap-2'>
                                                                                            <div className=" flex-1">
                                                                                                <Field as="select" name="candidate_ectc_type"
                                                                                                    className='outline-none border-2 rounded-lg px-2 w-fit py-1' required>
                                                                                                    <option disabled value="" >Select</option>
                                                                                                    <option value="LPA">LPA</option>
                                                                                                    <option value="%">%</option>
                                                                                                </Field>
                                                                                                <ErrorMessage name="candidate_ectc_type" component="div" className="text-red-600 text-sm" />
                                                                                            </div>

                                                                                            {values.candidate_ectc_type === "LPA" ?
                                                                                                <div className="w-full flex-2">
                                                                                                    <Field name="candidate_expected_ctc" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="number" placeholder="Expected CTC in LPA*" />
                                                                                                    <ErrorMessage name="candidate_expected_ctc" component="div" className="text-red-600 text-sm" />
                                                                                                </div> :
                                                                                                values.candidate_ectc_type === "%" ?
                                                                                                    <div className="w-full flex-2">
                                                                                                        <Field name="candidate_expected_ctc" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="number" placeholder="Expected CTC In %*" />
                                                                                                        <ErrorMessage name="candidate_expected_ctc" component="div" className="text-red-600 text-sm" />
                                                                                                    </div> : null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='grid grid-cols-2 gap-3'>
                                                                                    <div className=''>
                                                                                        <h2 className='w-32'>Organization</h2>
                                                                                        <Field name="candidate_organization" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Current/Past Organization*' />
                                                                                        <ErrorMessage name="candidate_organization" component="div" className="text-red-600 text-sm" />
                                                                                    </div>
                                                                                    <div className=''>
                                                                                        <h2 className='w-32'>Designation</h2>
                                                                                        <Field name="candidate_designation" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Current/Past Designation*' />
                                                                                        <ErrorMessage name="candidate_designation" component="div" className="text-red-600 text-sm" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='grid grid-cols-2 gap-3'>
                                                                                    <div className=''>
                                                                                        <h2 className='w-32'>Experience</h2>
                                                                                        <Field name="candidate_experience" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Experience*' />
                                                                                        <ErrorMessage name="candidate_experience" component="div" className="text-red-600 text-sm" />
                                                                                    </div>
                                                                                    <div className=''>
                                                                                        <h2 className='w-32'>Notice Period</h2>
                                                                                        <Field name="candidate_notice_period" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="number" placeholder="Notice Period In Days*" />
                                                                                        <ErrorMessage name="candidate_notice_period" component="div" className="text-red-500" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='grid grid-cols-2 gap-3'>
                                                                                    <div className=''>
                                                                                        <h2 className='w-32'>Gender</h2>
                                                                                        <Field as="select" name="candidate_gender" className='outline-none border-2 rounded-lg px-2 w-full py-1'>
                                                                                            <option value="" disabled>Gender*</option>
                                                                                            <option value="Male">Male</option>
                                                                                            <option value="Female">Female</option>
                                                                                        </Field>
                                                                                        <ErrorMessage name="candidate_gender" component="div" className="text-red-600 text-sm" />
                                                                                    </div>
                                                                                    <div className=''>
                                                                                        <h2 className='w-32'>Upload Resume*</h2>
                                                                                        <input
                                                                                            name="candidate_resume"
                                                                                            type="file"
                                                                                            accept='.pdf'
                                                                                            onChange={(event) => {
                                                                                                setFieldValue("candidate_resume", event.currentTarget.files[0]);
                                                                                            }}
                                                                                        />
                                                                                        <ErrorMessage name="candidate_resume" component="div" className="text-red-600 text-sm" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className=''>
                                                                                    <h2 className=''>Candidate Remarks</h2>
                                                                                    <Field as="textarea" name="candidate_remarks" className='outline-none border-2 rounded-lg px-2 w-full'></Field>
                                                                                </div>
                                                                            </div>
                                                                            <div className='flex items-center mt-5'>
                                                                                <button type="submit" className='darkPurple text-white font-semibold rounded-xl px-4 py-1 hoverGreen'>Submit</button>
                                                                            </div>
                                                                        </Form>
                                                                    )}
                                                                </Formik>
                                                            </div>
                                                        </div> : null
                                                }
                                                <td>
                                                    {/* <input value={sourceReportData[index].remarks} onChange={(e) => {
                                                        let newRow = [...sourceReportData];
                                                        newRow[index].remarks = e.target.value;
                                                        setSourceReportData(newRow);
                                                    }} type="text" /> */}
                                                    <textarea value={sourceReportData[index].candidate_remarks} onChange={(e) => {
                                                        let newRow = [...sourceReportData];
                                                        newRow[index].candidate_remarks = e.target.value;
                                                        setSourceReportData(newRow);
                                                    }}></textarea>
                                                </td>
                                                <td>
                                                    {sourceReportData?.length > 1 ?
                                                        <button onClick={(e) => { handleDeleteRow(e, index) }} className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed'>Delete</button> :
                                                        <button className=' text-red-300 bg-red-200 mx-2 px-2 py-1 rounded-lg cursor-not-allowed'>Delete</button>}
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                        {/* <button onClick={handleAddRow} className=' darkPurple text-lg w-fit font-semibold py-1 px-3 rounded-lg mt-4 text-white hoverGreen'><span>+</span> Add Row</button> */}
                        {errorMsg ? <h1>{errorMsg}</h1> : null}
                        <div className=' flex justify-center'>
                            {!loadingSourcing ?
                                <button onClick={handlePostData} className=' bg-slate-600 text-lg w-fit font-semibold py-2 px-10 rounded-lg mt-4 text-white hoverGreen'>Submit</button> :
                                <button className=' bg-slate-600 text-lg w-fit font-semibold py-2 px-10 rounded-lg mt-4 text-white'>Loading...</button>
                            }

                        </div>
                    </div>
                </div> : null}
            {sourceViewModal ?
                <>
                    <div onClick={(e) => { setSourceViewModal(false); setIsUpdate(false); }} className=' w-full h-screen  fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                        <div onClick={(e) => { e.stopPropagation(); }} className=' relative h-[95vh] w-[95%]  lightBabyGreen rounded-2xl mx-10 px-2 py-4   '>
                            <div className=' absolute bottom-3 right-4'>
                                <div onClick={fetchFilterData} className='cursor-pointer bg-red-600 px-2.5 py-2 rounded-full hoverRed'>
                                    <FontAwesomeIcon icon={faRotateRight} size={"lg"} style={{ color: "#ffffff", }} />
                                </div>
                            </div>
                            <button onClick={(e) => { setSourceViewModal(false); setIsUpdate(false); }} className="absolute bg-white rounded-full w-fit top-1 right-1 z-[11] ">
                                <img className='w-7' src="/img/close.png" alt="close" />
                            </button>
                            <div className='h-full w-full  rounded-2xl overflow-y-scroll noScrollbar px-8'>
                                {/* filter */}
                                <div className=' flex flex-wrap items-center gap-3 grayBlurShadow bg-white p-3 mb-5 rounded-xl'>
                                    <div className='flex flex-col justify-start items-start mb-2'>
                                        <label >Candidate</label>
                                        <input
                                            value={filterData?.candidate}
                                            className=' border-2 rounded-lg px-2 py-1'
                                            onChange={e => setFilterData({ ...filterData, candidate: e.target.value })}
                                            type="text" />
                                    </div>
                                    <div className='flex flex-col justify-start items-start mb-2'>
                                        <label >Company</label>
                                        <input
                                            value={filterData?.company}
                                            className=' border-2 rounded-lg px-2 py-1'
                                            onChange={e => setFilterData({ ...filterData, company: e.target.value })}
                                            type="text" />
                                    </div>
                                    <div className='flex flex-col justify-start items-start mb-2'>
                                        <label >Position</label>
                                        <input
                                            value={filterData?.position}
                                            className=' border-2 rounded-lg px-2 py-1'
                                            onChange={e => setFilterData({ ...filterData, position: e.target.value })}
                                            type="text" />
                                    </div>
                                    <div className='flex flex-col justify-start items-start mb-2'>
                                        <label >Location</label>
                                        <input
                                            value={filterData?.location}
                                            className=' border-2 rounded-lg px-2 py-1'
                                            onChange={e => setFilterData({ ...filterData, location: e.target.value })}
                                            type="text" />
                                    </div>
                                    <div className='flex flex-col justify-start items-start mb-2'>
                                        <label >Relevant</label>
                                        <select value={filterData?.relevant}
                                            className=' border-2 rounded-lg px-2 py-1'
                                            onChange={e => setFilterData({ ...filterData, relevant: e.target.value })}>
                                            <option value="" selected disabled>Select</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>

                                        </select>
                                    </div>
                                    <div className='flex flex-col justify-start items-start mb-2'>
                                        <label >Sourcing Status</label>
                                        <select value={filterData?.status}
                                            className=' border-2 rounded-lg px-2 py-1'
                                            onChange={e => setFilterData({ ...filterData, status: e.target.value })}>
                                            <option value="" selected disabled>Select</option>
                                            <option value="Rejected">Rejected</option>
                                            <option value="Confirmation Pending">Confirmation Pending</option>
                                            <option value="Screened">Screened</option>

                                        </select>
                                    </div>

                                    <button onClick={fetchFilterData} className=' darkPurple cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>Apply filter</button>
                                    <button onClick={() => {
                                        setFilterData({
                                            candidate: "",
                                            company: "",
                                            position: "",
                                            location: "",
                                            relevant: "",
                                            status: "",
                                        })
                                        fetchData();
                                    }} className=' text-red-700 bg-red-200  py-2 px-3 rounded-lg hoverRed'>Clear FIlter</button>
                                    <div className=' text-left w-fit'>
                                        <button onClick={handleExport} className=' py-2 px-3 bg-green-800 text-white rounded-lg '>{loadingExport ? "Exporting..." : "Excel"}</button>
                                    </div>
                                </div>
                                {
                                    !loading ?
                                        Array.isArray(allReports) && allReports?.length >= 1 ?
                                            <div className=' bg-white px-3 pt-5 rounded-2xl'>
                                                <div className=' overflow-auto tableBody  h-[79vh]'>
                                                    <table className=' navAnimation rounded-2xl baseWidth p-5 text-left'>
                                                        <thead className='text-lg tableHead'>
                                                            <tr className=' bg-gray-200'>
                                                                <th className='whitespace-nowrap text-center' >Sr.No.</th>
                                                                <th className='whitespace-nowrap text-center'>Sourcing Date</th>
                                                                <th>Candidate</th>
                                                                <th className='whitespace-nowrap'>Company Name</th>
                                                                <th className='whitespace-nowrap'>Company Position</th>
                                                                <th className='whitespace-nowrap'>Company Location</th>
                                                                {/* <th>min CTC</th> */}
                                                                {/* <th>max CTC</th> */}
                                                                <th className='whitespace-nowrap'>Position CTC</th>
                                                                <th className='whitespace-nowrap'>CV Sourced From</th>
                                                                <th>Relevant</th>
                                                                <th className='whitespace-nowrap text-center'>Candidate Info</th>
                                                                <th className='whitespace-nowrap'>Candidate Remarks</th>
                                                                <th className='whitespace-nowrap text-center'>Status</th>
                                                                <th className='whitespace-nowrap text-center'>Action</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody className='tableBody '>
                                                            {
                                                                Array.isArray(allReports) && allReports?.map((val, idx) => {
                                                                    return (
                                                                        <>
                                                                            {/* {val?.created_at?.split("T")[0] === sourceViewModal ? */}
                                                                            <tr key={val?.id} id={val?.id} className=' backWhite font-semibold text-sm'>
                                                                                <td className='whitespace-nowrap text-center'>{idx + 1}</td>
                                                                                <td className='whitespace-nowrap text-center'>{val?.sourcing_date?.split("-")[2]} {monthData[parseInt(val?.sourcing_date?.split("-")[1])]} {val?.sourcing_date?.split("-")[0]}</td>
                                                                                <td className=' whitespace-nowrap'>{val?.candidate}</td>
                                                                                <td className='whitespace-nowrap'>{val?.Position.Company.company_name}</td>
                                                                                <td className=' whitespace-nowrap'>{val?.Position.position}</td>
                                                                                <td>{val?.Position.location}</td>
                                                                                {/* <td>{val?.Position.min_ctc}</td> */}
                                                                                <td className=' whitespace-nowrap'>{val?.Position.min_ctc} - {val?.Position.max_ctc}</td>
                                                                                <td>{val?.cv_sourced_from}</td>
                                                                                <td className='whitespace-nowrap text-center'>
                                                                                    <div className={` whitespace-nowrap py-2 px-1  ${val?.relevant === "Yes" ? " text-green-500 greenBox" : "text-red-600 redBox"}`} >
                                                                                        {val?.relevant}</div>
                                                                                </td>
                                                                                <td className='whitespace-nowrap text-center'>
                                                                                    {
                                                                                        val?.sourcing_status === "Screened" || val?.sourcing_status === "Sent To Client" ?
                                                                                            <div className=' text-center'>
                                                                                                <button onClick={(e) => { setViewCandidateEdit(val?.id); }} className=' darkPurple text-white font-semibold rounded-xl px-4 text-sm py-1 hoverGreen'>View</button>
                                                                                            </div> :
                                                                                            <div className=' text-center'>
                                                                                                <button onClick={(e) => { }} className=' bg-gray-200 cursor-not-allowed text-gray-400 font-semibold rounded-xl px-4 text-sm py-2 '>View</button>
                                                                                            </div>
                                                                                    }
                                                                                </td>
                                                                                <td className={` whitespace-nowrap ${val?.candidate_remarks?.length > 20 ? " textEllipse" : "text-center"}`}>{val?.candidate_remarks ? val?.candidate_remarks : <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700'>NA</div>}</td>
                                                                                <td className='whitespace-nowrap text-center'>
                                                                                    {/* <div className={` whitespace-nowrap py-2 px-1  ${val?.sourcing_status === "rejected" ? "text-red-500 redBox" : val?.sourcing_status === "confirmation pending" ? "text-yellow-400 yellowBox" : "text-green-500 greenBox"}`}>
                                                                        {val?.sourcing_status}</div> */}
                                                                                    {val?.relevant === "No" || val?.sourcing_status === "Rejected" ?
                                                                                        <h3 className={` whitespace-nowrap w-full text-center outline-none py-2 px-3 text-red-500 redBox`}>
                                                                                            Rejected
                                                                                        </h3> :
                                                                                        val?.sourcing_status === "Screened" ?
                                                                                            <h3 className={` whitespace-nowrap w-full text-center outline-none py-2 px-3 text-blue-500 blueBox`}>
                                                                                                Screened
                                                                                            </h3> :
                                                                                            val?.sourcing_status === "Sent To Client" ?
                                                                                                <h3 className={` whitespace-nowrap w-full text-center outline-none py-2 px-3 text-green-500 greenBox`}>
                                                                                                    Sent To Client
                                                                                                </h3> :
                                                                                                <select value={status && isUpdate === val?.id ? status : val?.sourcing_status}
                                                                                                    onChange={(e) => {
                                                                                                        if (e.target.value === "Screened") {
                                                                                                            handleEdit(e, idx, val?.id, val?.Position?.id);
                                                                                                        }
                                                                                                        else if (e.target.value === "Rejected") {
                                                                                                            setConfirmation(`${idx}`);
                                                                                                        }

                                                                                                        setCandidateId(val?.id);
                                                                                                        setIsUpdate(val?.id);
                                                                                                        setStatus(e.target.value);
                                                                                                    }} className={` whitespace-nowrap py-2 px-1 text-center  ${val?.sourcing_status === "Rejected" ? "text-red-500 redBox" : val?.sourcing_status === "Confirmation Pending" ? "text-yellow-400 yellowBox" : "text-blue-500 blueBox"}`}>
                                                                                                    <option value="" disabled selected > Select</option>
                                                                                                    <option value="Rejected">Rejected</option>
                                                                                                    <option value="Confirmation Pending">Confirmation Pending</option>
                                                                                                    <option value="Screened">Screened</option>
                                                                                                </select>}
                                                                                </td>
                                                                                {
                                                                                    isUpdate === val?.id && viewCandidate ?

                                                                                        <div onClick={(e) => { e.stopPropagation(); }} className=' w-full h-screen fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                                                                                            <div onClick={(e) => { e.stopPropagation(); }} className='relative w-[70vw]  bg-white overflow-auto mx-10 mb-5 py-5 px-8 rounded-2xl '>
                                                                                                <button onClick={(e) => { handleClose(e, idx) }} className="absolute bg-white rounded-full w-fit top-1 right-1 z-[11] ">
                                                                                                    <img className='w-7' src="/img/close.png" alt="close" />
                                                                                                </button>
                                                                                                <h1 className='text-center text-xl font-semibold py-2 text-blue-950'>Candidate Imformation</h1>
                                                                                                <Formik
                                                                                                    initialValues={{
                                                                                                        candidate_name: val?.candidate,
                                                                                                        candidate_email: val?.candidate_email || "",
                                                                                                        candidate_phone: val?.candidate_phone || "",
                                                                                                        candidate_alt_phone: val?.candidate_alt_phone || null,
                                                                                                        candidate_location: val?.candidate_location || "",
                                                                                                        candidate_qualification: val?.candidate_qualification || "",
                                                                                                        candidate_current_ctc: val?.candidate_current_ctc?.split(" ")[0] || "",
                                                                                                        candidate_ectc_type: val?.candidate_expected_ctc?.split(" ")[1] || "",
                                                                                                        candidate_expected_ctc: val?.candidate_expected_ctc?.split(" ")[0] || "",
                                                                                                        candidate_organization: val?.candidate_organization || "",
                                                                                                        candidate_designation: val?.candidate_designation || "",
                                                                                                        candidate_experience: val?.candidate_experience?.split(" ")[0] || "",
                                                                                                        candidate_notice_period: val?.candidate_notice_period || "",
                                                                                                        candidate_gender: val?.candidate_gender || "",
                                                                                                        candidate_resume: null,
                                                                                                        candidate_remarks: val?.candidate_remarks || "",
                                                                                                        position_id: val?.Position?.id || ""
                                                                                                    }}
                                                                                                    validationSchema={validationSchema}
                                                                                                    onSubmit={values => {
                                                                                                        console.log(values);
                                                                                                        // setCandidate({
                                                                                                        //     candidate_email: values?.candidate_email,
                                                                                                        //     candidate_phone: `${countryPhone}-${values?.candidate_phone}`,
                                                                                                        //     candidate_alt_phone: `${countryPhone}-${values?.candidate_alt_phone}`,
                                                                                                        //     candidate_location: values?.candidate_location,
                                                                                                        //     candidate_experience: values?.candidate_experience,
                                                                                                        //     candidate_current_ctc: `${values?.candidate_current_ctc} LPA`,
                                                                                                        //     candidate_expected_ctc: `${values?.candidate_expected_ctc} ${values?.candidate_ectc_type === "%" ? `${values?.candidate_ectc_type} Hike` : values?.candidate_ectc_type}`,
                                                                                                        //     candidate_organization: values?.candidate_organization,
                                                                                                        //     candidate_designation: values?.candidate_designation,
                                                                                                        //     candidate_notice_period: values?.candidate_notice_period,
                                                                                                        //     candidate_qualification: values?.candidate_qualification,
                                                                                                        //     candidate_gender: values?.candidate_gender,
                                                                                                        //     candidate_remarks: values?.candidate_remarks,
                                                                                                        //     candidate_resume: values?.candidate_resume,
                                                                                                        //     position_id: val?.Position?.id
                                                                                                        // });
                                                                                                        // setViewCandidate(false);
                                                                                                        handleCandidateEdit(values);
                                                                                                    }}
                                                                                                >
                                                                                                    {({ values, setFieldValue }) => (
                                                                                                        <Form className=' text-left flex flex-col gap-x-2 gap-y-4 max-h-[70vh] overflow-y-scroll'>
                                                                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                                                                <div>
                                                                                                                    <h2 className=' w-32'>Name</h2>
                                                                                                                    <Field name="candidate_name" className=' border-2 outline-none rounded-lg px-2 w-full py-1' type="text" placeholder="Name*" />
                                                                                                                    <ErrorMessage name="candidate_name" component="div" className="text-red-500" />
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <h2 className=' w-32'>Email</h2>
                                                                                                                    <Field name="candidate_email" className=' border-2 outline-none rounded-lg px-2 w-full py-1' type="email" placeholder='Email*' />
                                                                                                                    <ErrorMessage name="candidate_email" component="div" className="text-red-500" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <div className=' grid grid-cols-2 gap-3'>
                                                                                                                    <div>
                                                                                                                        <h2 className='w-32'>Mobile</h2>
                                                                                                                        <div className="flex items-center gap-2">
                                                                                                                            <div className='border-2 rounded-lg flex items-center pl-1 w-[7rem]'>
                                                                                                                                {
                                                                                                                                    countryPhoneCode.map((val) => {
                                                                                                                                        return val.phoneCode === countryPhone ? <img key={val.isoCode} className="rounded w-[1.5rem]" src={val.flag} alt={val.name} /> : null
                                                                                                                                    })
                                                                                                                                }
                                                                                                                                <Field as="select" className="outline-none py-1 font-semibold" name="countryPhone" value={countryPhone} onChange={(e) => {
                                                                                                                                    countryPhoneCode?.forEach((val) => {
                                                                                                                                        if (e.target.value == val.phoneCode) {
                                                                                                                                            setCountryPhoneLength(val?.phoneNumberLength);
                                                                                                                                        }
                                                                                                                                    })
                                                                                                                                    setCountryPhone(e.target.value);
                                                                                                                                }} >
                                                                                                                                    {
                                                                                                                                        countryPhoneCode.map((val) => (
                                                                                                                                            <option key={val.isoCode} value={val.phoneCode}>
                                                                                                                                                {val.phoneCode}
                                                                                                                                            </option>
                                                                                                                                        ))
                                                                                                                                    }
                                                                                                                                </Field>
                                                                                                                            </div>
                                                                                                                            <Field
                                                                                                                                name="candidate_phone"
                                                                                                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                                                                type="number"
                                                                                                                                placeholder='Mobile*'
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                        <ErrorMessage name="candidate_phone" component="div" className="text-red-500" />
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <h2 className='w-32'>Alternate Mobile</h2>
                                                                                                                        <div className="flex items-center gap-2">
                                                                                                                            <div className='border-2 rounded-lg flex items-center pl-1 w-[7rem]'>
                                                                                                                                {
                                                                                                                                    countryPhoneCode.map((val) => {
                                                                                                                                        return val.phoneCode === countryPhone ? <img key={val.isoCode} className="rounded w-[1.5rem]" src={val.flag} alt={val.name} /> : null
                                                                                                                                    })
                                                                                                                                }
                                                                                                                                <Field as="select" className="outline-none py-1 font-semibold" name="countryPhone" value={countryPhone} onChange={(e) => {
                                                                                                                                    countryPhoneCode?.forEach((val) => {
                                                                                                                                        if (e.target.value == val.phoneCode) {
                                                                                                                                            setCountryPhoneLength(val?.phoneNumberLength);
                                                                                                                                        }
                                                                                                                                    })
                                                                                                                                    setCountryPhone(e.target.value);
                                                                                                                                }} >
                                                                                                                                    {
                                                                                                                                        countryPhoneCode.map((val) => (
                                                                                                                                            <option key={val.isoCode} value={val.phoneCode}>
                                                                                                                                                {val.phoneCode}
                                                                                                                                            </option>
                                                                                                                                        ))
                                                                                                                                    }
                                                                                                                                </Field>
                                                                                                                            </div>
                                                                                                                            <Field
                                                                                                                                name="candidate_alt_phone"
                                                                                                                                className='border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                                                                type="number"
                                                                                                                                placeholder='Alternate Mobile*'
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                        <ErrorMessage name="candidate_alt_phone" component="div" className="text-red-500" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                                                                <div>
                                                                                                                    <h2 className=' w-32'>Location</h2>
                                                                                                                    <Field name="candidate_location" className=' border-2 outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Location*' />
                                                                                                                    <ErrorMessage name="candidate_location" component="div" className="text-red-500" />
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <h2 className=' w-32'>Qualification</h2>
                                                                                                                    <Field name="candidate_qualification" className=' border-2 outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Qualification*' />
                                                                                                                    <ErrorMessage name="candidate_qualification" component="div" className="text-red-500" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                                                                <div>
                                                                                                                    <h2 className=' w-32'>Current CTC</h2>
                                                                                                                    <Field name="candidate_current_ctc" className=' border-2 outline-none rounded-lg px-2 w-full py-1' type="number" placeholder='Current CTC*' />
                                                                                                                    <ErrorMessage name="candidate_current_ctc" component="div" className="text-red-500" />
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <h2 className=' w-32'>Expected CTC</h2>
                                                                                                                    <div className='flex items-center gap-2 w-full'>
                                                                                                                        <div className=" flex-1">
                                                                                                                            <Field as="select" name="candidate_ectc_type"
                                                                                                                                className='outline-none border-2 rounded-lg px-2 w-fit py-1' required>
                                                                                                                                <option disabled value="" >Select</option>
                                                                                                                                <option value="LPA">LPA</option>
                                                                                                                                <option value="%">%</option>
                                                                                                                            </Field>
                                                                                                                            <ErrorMessage name="candidate_ectc_type" component="div" className="text-red-600 text-sm" />
                                                                                                                        </div>
                                                                                                                        {values.candidate_ectc_type === "LPA" ?
                                                                                                                            <div className="w-full flex-2">
                                                                                                                                <Field name="candidate_expected_ctc" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="number" placeholder="Expected CTC in LPA*" />
                                                                                                                                <ErrorMessage name="candidate_expected_ctc" component="div" className="text-red-600 text-sm" />
                                                                                                                            </div> :
                                                                                                                            values.candidate_ectc_type === "%" ?
                                                                                                                                <div className="w-full flex-2">
                                                                                                                                    <Field name="candidate_expected_ctc" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="number" placeholder="Expected CTC In %*" />
                                                                                                                                    <ErrorMessage name="candidate_expected_ctc" component="div" className="text-red-600 text-sm" />
                                                                                                                                </div> : null
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                                                                <div>
                                                                                                                    <h2 className=' w-32'>Organization</h2>
                                                                                                                    <Field name="candidate_organization" className=' border-2 outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Current/Past Organization' />
                                                                                                                    <ErrorMessage name="candidate_organization" component="div" className="text-red-500" />
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <h2 className=' w-32'>Designation</h2>
                                                                                                                    <Field name="candidate_designation" className=' border-2 outline-none rounded-lg px-2 w-full py-1' type="text" placeholder='Current/Past Designation' />
                                                                                                                    <ErrorMessage name="candidate_designation" component="div" className="text-red-500" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                                                                <div>
                                                                                                                    <h2 className=' w-32'>Experience</h2>
                                                                                                                    <Field name="candidate_experience" className=' border-2 outline-none rounded-lg px-2 w-full py-1' type="number" placeholder='Experience*' />
                                                                                                                    <ErrorMessage name="candidate_experience" component="div" className="text-red-500" />
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <h2 className=' w-32'>Notice Period</h2>

                                                                                                                    <Field name="candidate_notice_period" className='border-2 outline-none rounded-lg px-2 w-full py-1' type="number" placeholder="Notice Period*" />
                                                                                                                    <ErrorMessage name="candidate_notice_period" component="div" className="text-red-500" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className=' grid grid-cols-2 gap-3'>
                                                                                                                <div>
                                                                                                                    <h2 className=' w-32'>Gender</h2>
                                                                                                                    <Field as="select" name="candidate_gender" className=' outline-none border-2 rounded-lg px-2 w-full py-1'>
                                                                                                                        <option value="" disabled>Gender*</option>
                                                                                                                        <option value="Male">Male</option>
                                                                                                                        <option value="Female">Female</option>
                                                                                                                    </Field>
                                                                                                                    <ErrorMessage name="candidate_gender" component="div" className="text-red-500" />
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <h2 className=' w-32'>Upload Resume*</h2>
                                                                                                                    <input name="candidate_resume" type="file" accept='.pdf'
                                                                                                                        onChange={(event) => {
                                                                                                                            setFieldValue("candidate_resume", event.currentTarget.files[0]);
                                                                                                                        }}
                                                                                                                        className=' border-2 outline-none rounded-lg px-2 w-full py-1'
                                                                                                                    />
                                                                                                                    <ErrorMessage name="candidate_resume" component="div" className="text-red-500" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <h2 className=''>Remarks</h2>
                                                                                                                <Field as="textarea" name="candidate_remarks" className=' outline-none border-2 rounded-lg px-2 w-full'></Field>
                                                                                                            </div>
                                                                                                            <div className='flex items-center mt-5'>
                                                                                                                <button onClick={(e) => { handleClose(e, idx); }} className=' text-red-700 bg-red-200 mx-2 px-2 py-1 rounded-lg hoverRed' >Cancel</button>
                                                                                                                <button type='submit' className='darkPurple text-white font-semibold rounded-xl px-4 py-1 hoverGreen'>Submit</button>
                                                                                                            </div>
                                                                                                        </Form>
                                                                                                    )}
                                                                                                </Formik>
                                                                                            </div>
                                                                                        </div> : null
                                                                                }
                                                                                <td className=' flex justify-center items-center'>
                                                                                    {val?.sourcing_status === "Confirmation Pending" || val?.sourcing_status === "Screened" ?
                                                                                        !loadingDelete ?
                                                                                            <button onClick={(e) => { setCandidateId(val?.id); setConfirmationDelete(`${idx}`) }} className=' whitespace-nowrap w-full text-center outline-none py-2 px-3 text-red-500 redBox hoverRed'>Delete</button> :
                                                                                            <button className=' whitespace-nowrap w-full text-center outline-none py-2 px-3 text-red-500 redBox'>Loading...</button> :
                                                                                        <button className=' opacity-80 cursor-not-allowed whitespace-nowrap w-full text-center outline-none py-2 px-3 text-gray-500 bg-gray-200 rounded-lg '>Delete</button>
                                                                                    }

                                                                                </td>


                                                                            </tr>
                                                                            {/* : null} */}

                                                                        </>

                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table >
                                                </div>
                                                <div className=' flex items-center justify-between pb-4'>
                                                    <div className=' flex items-center gap-2'>
                                                        <div className='grayBlurShadow px-3 py-1'>
                                                            <select value={limit} onChange={(e) => { setLimit(e.target.value); }} >
                                                                <option value="10" selected>10</option>
                                                                <option value="20" >20</option>
                                                                <option value="50" >50</option>
                                                                <option value="100" >100</option>
                                                                <option value="200" >200</option>
                                                                <option value="500" >500</option>
                                                                <option value="All" >All</option>
                                                            </select>
                                                        </div>
                                                        <div className='grayBlurShadow px-1 py-1 text-sm font-semibold'>
                                                            <h1>Total Records: {totalRecords}</h1>
                                                        </div>
                                                        <div className='grayBlurShadow px-1 py-1  text-sm font-semibold'>
                                                            <h1>Total Pages: {allPages}</h1>
                                                        </div>
                                                    </div>
                                                    <div className='flex items-center grayBlurShadow px-3 py-1 rounded-lg'>
                                                        <div onClick={(e) => {
                                                            if (page > 1) {
                                                                setPage(page - 1);
                                                            }
                                                        }} className={` ${page === 1 ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                                            <img className='w-5' src="/img/leftArrow.png" alt="leftArrow" />
                                                        </div>
                                                        <div className=' px-3'>
                                                            {page}
                                                        </div>
                                                        <div onClick={(e) => {
                                                            if (allPages > page) {
                                                                setPage(page + 1);
                                                            }
                                                        }} className={` ${page == allPages ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                                            <img className='w-5' src="/img/rightArrow.png" alt="rightArrow" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> :
                                            <div className='navAnimation w-full flex flex-col justify-center items-center bg-white mt-5 py-5 mb-10 rounded-3xl '>
                                                <img className=' w-[25%]' src="/img/addReports.png" alt="" />
                                                <h1 className=' text-2xl font-bold'>Oops!! "<span className=' text-blue-950'>No Reports Found</span>"</h1>
                                            </div> :
                                        <div className=' h-[60vh] mb-5 w-full bg-white mt-5 rounded-3xl '>
                                            <img className=' m-auto w-1/4' src="/img/loader2.gif" alt="loading..." />
                                        </div>
                                }
                            </div>

                        </div>
                    </div>
                </> : null}

            {
                !isLoading ?
                    Array.isArray(allSourceReport) && allSourceReport?.length >= 1 ?
                        <>
                            <section className='bg-white grayBlurShadow px-7 pt-7 rounded-3xl mb-8 mt-5 '>
                                <div className=' overflow-auto tableBody tableBox'>
                                    <table className=' navAnimation rounded-2xl baseWidth'>
                                        <thead className=' text-lg tableHead headingRow'>
                                            <tr className=' bg-gray-200'>
                                                <th >Sr.No.</th>
                                                <th>Date</th>
                                                <th>CV's Sourced</th>
                                                <th>Relevant CV's</th>
                                                <th>Pending</th>
                                                <th>Sent</th>
                                                <th>View Details</th>
                                            </tr>
                                        </thead>
                                        <tbody className='tableBody  '>
                                            {
                                                Array.isArray(allSourceReport) && allSourceReport?.map((val, idx) => {
                                                    return (
                                                        <tr key={val?.id} id={val?.id} className=' backWhite font-semibold text-sm'>
                                                            <td>{idx + 1}</td>
                                                            <td>{val?.update_date.split("-")[2]} {monthData[parseInt(val?.update_date.split("-")[1])]} {val?.update_date.split("-")[0]}</td>
                                                            <td>{val?.total_cv_sourced}</td>
                                                            <td>{val?.total_cv_relevant}</td>
                                                            <td>{val?.total_confirmation_pending}</td>
                                                            <td>{val?.total_sent_to_client}</td>
                                                            <td> <button className=' darkPurple text-white font-semibold rounded-xl px-4 text-sm py-1 hoverGreen' onClick={(e) => { setSourceViewModal(val?.update_date); setDate(val?.update_date); }}>View</button></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            <tr className=' bg-gray-600 text-white rounded-lg text-xl font-semibold'>
                                                <td></td>
                                                <th>Total</th>
                                                <td>{totalResume}</td>
                                                <td>{totalRelevants}</td>
                                                <td>{totalPending}</td>
                                                <td>{totalSent}</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className=' flex items-center justify-between py-4'>
                                    <div className=' flex items-center gap-2'>
                                        <div className='grayBlurShadow px-3 py-1'>
                                            <select value={limit2} onChange={(e) => { setLimit2(e.target.value); }} >
                                                <option value="10" selected>10</option>
                                                <option value="20" >20</option>
                                                <option value="50" >50</option>
                                                <option value="100" >100</option>
                                                <option value="200" >200</option>
                                                <option value="500" >500</option>
                                                <option value="All" >All</option>
                                            </select>
                                        </div>
                                        <div className='grayBlurShadow px-1 py-1 text-sm font-semibold'>
                                            <h1>Total Records: {totalRecords2}</h1>
                                        </div>
                                        <div className='grayBlurShadow px-1 py-1  text-sm font-semibold'>
                                            <h1>Total Pages: {allPages2}</h1>
                                        </div>
                                    </div>
                                    <div className='flex items-center grayBlurShadow px-3 py-1 rounded-lg'>
                                        <div onClick={(e) => {
                                            if (page2 > 1) {
                                                setPage2(page2 - 1);
                                            }
                                        }} className={` ${page2 === 1 ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                            <img className='w-5' src="/img/leftArrow.png" alt="leftArrow" />
                                        </div>
                                        <div className=' px-3'>
                                            {page2}
                                        </div>
                                        <div onClick={(e) => {
                                            if (allPages2 > page2) {
                                                setPage2(page2 + 1);
                                            }
                                        }} className={` ${page2 == allPages2 ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                            <img className='w-5' src="/img/rightArrow.png" alt="rightArrow" />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </> :
                        <div className='navAnimation flex flex-col justify-center items-center bg-white mt-5 py-5 mb-10 rounded-3xl '>
                            <img className=' w-[25%]' src="/img/addReports.png" alt="" />
                            <h1 className=' text-2xl font-bold'>Oops!! "<span className=' text-blue-950'>No Reports Found</span>"</h1>
                        </div> :
                    <div className=' loaderBox mb-5 bg-white mt-5 rounded-3xl '>
                        <img className=' m-auto w-1/4' src="/img/loader2.gif" alt="loading..." />
                    </div>
            }
        </section>
    );
}


export default Sourcing;